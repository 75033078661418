import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Stack, Divider, Typography, Box } from "@mui/material";
import NotificationItem from "./NotificationItem";
import { NotificationState } from 'ReduxStore/Reducers/eijentNotification.reducer';
import { useSelector } from 'react-redux';
import { Actions } from "ReduxStore/Actions";
import { useDispatch } from "react-redux";
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import ListLoader from '../ListLoader';

interface NotificationPopoverProps {
  open: boolean;
}
export default function NotificationList({ open }: NotificationPopoverProps): JSX.Element {
  const {
    notificationList,
    loading,
    totalResults,
  } = useSelector((state: any) => state.eijentNotification) as NotificationState;
  const dispatch = useDispatch();
  const { message: xpUsersWebSocketMessage } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;
  const [pageNo, setPageNo] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [sortBy, setSortBy] = useState<string>("updatedAt");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const getNotificationList = (page?: number) => {
    let params: { pageNo: number; limit: number; sortBy: string; } = {
      pageNo: page ? page : pageNo,
      limit,
      sortBy,
    };
    dispatch(Actions.eijentNotificationListRequest(params));
  };

  useEffect(() => {
    if (pageNo > 1) { // Ensure we fetch only when pageNo is greater than 1
      getNotificationList();
    }
  }, [pageNo]);

  useEffect(() => {
    if (!open) {
      setLimit(20);
      getNotificationList(1);
    }
  }, [open]);

  useEffect(() => {
    if (notificationList && notificationList?.length >= totalResults) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [notificationList, totalResults]);


  const handleScroll = useCallback(() => {
    if (loading || !hasMore || !containerRef.current) return;
    const container = containerRef.current;
    if (container.scrollHeight - container.scrollTop <= container.clientHeight + 10) {
      if (notificationList?.length !== totalResults) {
        setPageNo((prevPage) => prevPage + 1); // Trigger loading more data
      }
    }
  }, [loading, hasMore, notificationList, totalResults]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  // Function to filter and sort read notifications by priority and createdAt
  const processNotifications = (list: any[], isRead: boolean) => {
    const priorityOrder = { high: 1, medium: 2, low: 3 };
    
    return list
      .filter((item) => item.isRead === isRead)
      .sort((a, b) => {
        const priorityA = priorityOrder[a.priority || "low"];
        const priorityB = priorityOrder[b.priority || "low"];
        return priorityA !== priorityB
          ? priorityA - priorityB
          : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
  };

  const unreadNotifications = processNotifications(notificationList, false);
  const readNotifications = processNotifications(notificationList, true);
  return (
    <>
      <Box py={0.5} px={2}
        sx={{
          // position: 'sticky',
          // top: '0',
          zIndex: 1,
          backgroundColor: '#f4f6f7',
          borderBottom: '1px solid #c2cccc',
        }}>

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', }}>Notifications</Typography>
      </Box>
      <Stack
        sx={{
          overflow: "auto",
          maxHeight: "calc(100vh - 150px - 40px)",
          px: 0.5,
          position: 'relative',
        }}
        ref={containerRef} // Attach ref to the scrollable container
      >
        {unreadNotifications.length > 0 && (
          <>
            <Box py={0.5} px={2}
              sx={{
                position: 'sticky',
                top: '0',
                zIndex: 1,
                backgroundColor: '#f4f6f7',
                borderBottom: '1px solid #d6d6d6',
              }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Unread</Typography>
            </Box>
            {unreadNotifications.map((item, index) => (
              <React.Fragment key={item._id}>
                <NotificationItem notification={item} />
                {/* <Divider /> */}
              </React.Fragment>
            ))}
          </>
        )}

        {/* Read Section */}
        {readNotifications.length > 0 && (
          <>
            <Box py={0.5} px={2}
              sx={{
                position: 'sticky',
                top: '0',
                zIndex: 1,
                backgroundColor: '#f4f6f7',
                borderBottom: '1px solid #c2cccc',
              }}>

              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', }}>Read</Typography>
            </Box>

            {readNotifications.map((item, index) => (
              <React.Fragment key={item._id}>
                <NotificationItem notification={item} />
                {/* <Divider /> */}
              </React.Fragment>
            ))}
          </>
        )}

        {notificationList?.length === 0 && (
          <Box
            sx={{
              width: 537,
              minWidth: 300,
              borderRadius: '4px',
              '&:hover .read-checkbox': {
                visibility: 'visible',
              },
            }}
            py={5}
            px={2}
          >
            <Typography variant="body2" align='center'>You're all caught up! No new notifications.</Typography>
          </Box>
        )}
      </Stack>
      {loading && pageNo > 1 && <ListLoader />}
    </>
  );
}
