import { createReducer } from 'reduxsauce';
import { Constants } from '../Actions';

// ✅ Add FieldData type
export interface FieldData {
  fieldId: string;
  fieldName: string;
  internalName: string;
  placeHolder: string;
  toolTip: string;
  groupId: string;
  fieldType: string;
  dropdownString: string;
  isMandatory: boolean;
  isReadonly: boolean;
  isQuickAdd: boolean;
  trackHistory: boolean;
  isUnique: boolean;
  orderIndex: number;
  _id: string;
}

export type FieldManagementGroup = {
  _id?: string;
  isDeleted?: boolean;
  name: string;
  internalName: string;
  opportunityGroupType: string;
  fieldData?: FieldData[]; // ✅ This must be optional
  userId?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type FieldManagementGroupsState = {
  fieldManagementGroups: FieldManagementGroup[];
  fieldManagementGroup: FieldManagementGroup | null;
  loading: boolean;
  error: string | null;
  message: string | null;
  lastAction: null,
  isUpdatingField: boolean; // ✅ Add this line
};

const INITIAL_STATE: FieldManagementGroupsState = {
  fieldManagementGroups: [],
  fieldManagementGroup: null,
  loading: false,
  error: null,
  message: null,
  lastAction: null,
  isUpdatingField: false, // ✅ Add this property 
};

const getFieldGroupsListRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null,
});

/* const getFieldGroupsListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  fieldManagementGroups: action.fieldManagementGroups,
  lastAction: action.actionType || "create", 
   message: state.lastAction === "updateField" ? state.message : action.message, 
  error: null,
}); */

const getFieldGroupsListSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    fieldManagementGroups: action.fieldManagementGroups,
    isUpdatingField: false, // Reset flag after update
    // message: state.isUpdatingField ? state.message : action.message,  
    lastAction: action.actionType || "fetchList",
    error: null,
  };
};


const getFieldGroupsListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const createFieldGroupRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null,
});

const createFieldGroupSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  fieldManagementGroups: [...state.fieldManagementGroups, action.fieldManagementGroup], // Append new group
  message: action.message,
  error: null,
});

const createFieldGroupFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
  message: action.error,
  lastAction: action.actionType || "createGroup"
});

const updateFieldGroupRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null,

});


const updateFieldGroupSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  fieldManagementGroups: state.fieldManagementGroups.map(group =>
    group?._id === action.updatedGroup?._id ? { ...group, ...action.updatedGroup } : group
  ),
  fieldManagementGroup: action.updatedGroup,
  message: action.message,
  lastAction: action.actionType || "update",
  error: null,
});

const updateFieldGroupFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
  message: action.error
});

const deleteFieldGroupRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null,
  isDeleting: true,
});

const deleteFieldGroupSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  isDeleting: false,
  fieldManagementGroups: state.fieldManagementGroups.filter(
    (group) => group._id !== action.fieldManagementGroupId
  ),
  message: action.message,
  lastAction: "deleteGroup",
  error: null,
});


const deleteFieldGroupFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  lastAction: "deleteGroup",
  error: action.error,
});

const clearMessage = (state) => ({
  ...state,
  message: null,
  lastAction: null,
});


const clearError = (state) => ({
  ...state,
  error: null,
  lastAction: null,
});

const HANDLERS = {
  [Constants.GET_FIELD_GROUPS_LIST_REQUEST]: getFieldGroupsListRequest,
  [Constants.GET_FIELD_GROUPS_LIST_SUCCESS]: getFieldGroupsListSuccess,
  [Constants.GET_FIELD_GROUPS_LIST_FAILURE]: getFieldGroupsListFailure,

  [Constants.CREATE_FIELD_GROUP_REQUEST]: createFieldGroupRequest,
  [Constants.CREATE_FIELD_GROUP_SUCCESS]: createFieldGroupSuccess,
  [Constants.CREATE_FIELD_GROUP_FAILURE]: createFieldGroupFailure,

  [Constants.UPDATE_FIELD_GROUP_REQUEST]: updateFieldGroupRequest,
  [Constants.UPDATE_FIELD_GROUP_SUCCESS]: updateFieldGroupSuccess,
  [Constants.UPDATE_FIELD_GROUP_FAILURE]: updateFieldGroupFailure,

  [Constants.DELETE_FIELD_GROUP_REQUEST]: deleteFieldGroupRequest,
  [Constants.DELETE_FIELD_GROUP_SUCCESS]: deleteFieldGroupSuccess,
  [Constants.DELETE_FIELD_GROUP_FAILURE]: deleteFieldGroupFailure,

  [Constants.CLEAR_MESSAGE]: clearMessage,
  [Constants.CLEAR_ERROR]: clearError,

};

export default createReducer(INITIAL_STATE, HANDLERS);
