import { Stack, Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorBoundary from 'eijent/components/ErrorBoundary';
import { xpAccountIntegrationGmailFetchImage } from 'ReduxStore/API';
import { useGmail } from '../context/GmailContext';

type Message = {
  id: string;
  labelIds: string[];
  payload: {
    body: any;
    filename: string;
    headers: { name: string; value: string; }[];
    mimeType: string;
    partId: string;
    parts: any[];
  };
  sizeEstimate: number;
  snippet: string;
  threadId: string;
  draftId?: string;
};

export default function EmailBody({ mail, mailCount }: { mail: Message, mailCount: number; }) {
  const [showMore, setShowMore] = useState(false);
  const [imageAttachments, setImageAttachments] = useState<Record<string, any>>(
    {},
  );
  const { gmailId } = useGmail();
  const [attachmentData, setAttachmentData] = useState<Record<string, any>>({});

  useEffect(() => {
    // Set image attachments from mail payload
    if (mail?.payload?.parts) {
      const images = mail.payload.parts.reduce((acc, part) => {
        if (part.mimeType?.startsWith('image/') && part.body?.attachmentId) {
          acc[part.body.attachmentId] = {
            mimeType: part.mimeType,
            attachmentId: part.body.attachmentId,
          };
        }
        return acc;
      }, {} as Record<string, any>);
      setImageAttachments(images);
    }
  }, [mail]);

  useEffect(() => {
    // Fetch attachment data when imageAttachments changes
    const fetchAttachments = async () => {
      for (const [attachmentId, info] of Object.entries(imageAttachments)) {
        try {
          const data = await fetchAttachment(mail.id, info.attachmentId);
          if (data) {
            setAttachmentData((prev) => ({
              ...prev,
              [attachmentId]: data,
            }));
          }
        } catch (error) {
          console.error(`Failed to fetch attachment ${attachmentId}:`, error);
        }
      }
    };

    if (Object.keys(imageAttachments).length > 0) {
      fetchAttachments();
    }
  }, [imageAttachments, mail.id]);

  const fetchAttachment = async (messageId: string, attachmentId: string) => {
    try {
      const response = await xpAccountIntegrationGmailFetchImage({
        messageId,
        attachmentId,
        gmailId,
      });
      if (!response) {
        console.error('No data in response:', response);
        return null;
      }

      return response;
    } catch (error) {
      console.error('Error fetching attachment:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      return null;
    }
  };

  const toggleShowMore = () => setShowMore(!showMore);

  const EmailContent = ({ getHtmlOfContent, imageAttachments }) => {
    const shadowRef = useRef(null);
    const shadowRootRef = useRef(null);

    useEffect(() => {
      if (!shadowRef.current) return;

      if (!shadowRootRef.current) {
        shadowRootRef.current = shadowRef.current.attachShadow({
          mode: 'open',
        });
      }

      const fetchContent = async () => {
        const htmlContent = await getHtmlOfContent();
        let style;
        if (htmlContent) {
          if (Object.keys(imageAttachments).length > 0) {
            style = document.createElement('style');
            style.textContent = `
            img { 
              max-width: 100%; 
              height: auto; 
              display: block;
            }
            * { max-width: 100%; }
          `;
          }

          const contentContainer = document.createElement('div');
          contentContainer.style.all = 'initial';

          // Create a temporary div to parse the HTML
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = htmlContent;


          if (Object.keys(imageAttachments).length > 0) {
            const images = tempDiv.getElementsByTagName('img');
            Array.from(images).forEach((img, i) => {
              const src = img.getAttribute('src');
              // Get the first (and should be only) data URL from imageAttachments
              const image = Object.values(attachmentData)[i];
              img.src = image as string;
            }
            );
          }

          contentContainer.innerHTML = tempDiv.innerHTML;

          // Update shadow DOM
          shadowRootRef.current.innerHTML = '';
          if (style) {
            shadowRootRef.current.appendChild(style);
          }
          shadowRootRef.current.appendChild(contentContainer);
        }
      };

      fetchContent();
    }, [getHtmlOfContent, imageAttachments]);

    return (
      <div
        ref={shadowRef}
        style={{ width: '100%', height: 'auto', border: 'none' }}
      />
    );
  };

  const splitHtmlAtGmailQuote = (htmlString: string | Promise<string>) => {
    if (!htmlString || typeof htmlString !== 'string')
      return { part1: '', part2: '' };

    // Regular expression to find the first occurrence of a div with the class "gmail_quote"
    const regex = /<div[^>]*class=["'][^"']*gmail_quote[^"']*["'][^>]*>/;

    // Find the position of the first match
    const match = htmlString.match(regex);

    if (match && match.index !== undefined) {
      // Split the string into two parts
      const splitIndex = match.index;
      const part1 = htmlString.slice(0, splitIndex);
      const part2 = htmlString.slice(splitIndex);
      return { part1, part2 };
    }

    // If no match is found, return the original string as part1
    return { part1: htmlString, part2: '' };
  };


  // const getHtmlOfContentFn = async (mail) => {
  //   if (!mail?.payload) return '';

  //   let htmlContent = '';
  //   let imageAttachments = {};

  //   if(mail.payload.mimeType === 'text/html' && mail.payload.body?.data){
  //     htmlContent = Buffer.from(mail.payload?.body?.data, 'base64').toString('ascii');
  //     return
  //  }

  //   const processPart = (part) => {
  //     if (part.mimeType === 'text/html' && part.body?.data) {
  //         htmlContent = Buffer.from(part.body.data, 'base64').toString('ascii');
  //     } else if (
  //       part.mimeType.startsWith('image/') &&
  //       part.body?.attachmentId
  //     ) {
  //       imageAttachments[part.body.attachmentId] = {
  //         mimeType: part.mimeType,
  //         attachmentId: part.body.attachmentId,
  //       };
  //     }

  //     if (part.parts?.length) {
  //       part.parts.forEach(processPart);
  //     }
  //   };
  //   if (
  //     mail.payload.mimeType === 'multipart/mixed' ||
  //     mail.payload.mimeType === 'multipart/alternative' ||
  //     mail.payload.mimeType === 'multipart/related'
  //   ) {
  //     processPart(mail.payload);
  //   }

  //   return htmlContent;
  // };

  const getHtmlOfContentFn = (mail) => {
    if (
      mail.payload.mimeType === 'multipart/mixed' ||
      mail.payload.mimeType === 'multipart/alternative' ||
      mail.payload.mimeType === 'multipart/related'
    ) {
      if (mail?.payload?.parts.length > 1) {
        if (mail.payload.parts[0]?.mimeType === 'multipart/alternative') {
          if (mail?.payload?.parts[0]?.parts?.length > 1) {
            if (mail?.payload?.parts[0]?.parts[0]?.mimeType === 'text/html') {
              return Buffer.from(
                mail?.payload?.parts[0]?.parts[0]?.body?.data,
                'base64',
              ).toString('ascii');
            } else if (
              mail?.payload?.parts[0]?.parts[1]?.mimeType === 'text/html'
            ) {
              return Buffer.from(
                mail?.payload?.parts[0]?.parts[1]?.body?.data,
                'base64',
              ).toString('ascii');
            }
          }
        } else {
          if (mail.payload?.parts[0]?.mimeType === 'text/html') {
            return Buffer.from(
              mail.payload?.parts[0]?.body?.data,
              'base64',
            ).toString('ascii');
          } else if (mail?.payload?.parts[1]?.mimeType === 'text/html') {
            return Buffer.from(
              mail?.payload?.parts[1]?.body?.data,
              'base64',
            ).toString('utf-8');
          }
        }
      } else if (mail?.payload?.parts.length) {
        return Buffer.from(
          mail?.payload?.parts[0]?.body?.data,
          'base64',
        ).toString('ascii');
      } else {
        return '';
      }
    } else if (mail.payload.mimeType === 'text/html') {
      return Buffer.from(mail.payload?.body?.data, 'base64').toString('ascii');
    }
  };

  const renderAttachments = () => {
    return Object.entries(imageAttachments).map(([attachmentId, info]) => {
      const data = attachmentData[attachmentId];
      if (!data) return null;

      // Convert binary string to Uint8Array
      const binaryString = atob(data.data);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create blob and object URL
      const blob = new Blob([bytes], { type: info.mimeType });
      const imageUrl = URL.createObjectURL(blob);

      return (
        <img
          key={attachmentId}
          src={imageUrl}
          alt="Email attachment"
          style={{ maxWidth: '100%' }}
          onLoad={() => URL.revokeObjectURL(imageUrl)}
        />
      );
    });
  };
  return (
    <ErrorBoundary>
      <Stack
        py={2}
        borderBottom={'1px solid #F4F6F7'}
        flexGrow={1}
        overflow={`${mailCount === 1 ? 'auto' : ''}`}
        position={'relative'}
      // maxHeight={450}
      >
        <Box className="email-body" pb={0} mb={2}>
          <EmailContent
            getHtmlOfContent={() => {
              const html = getHtmlOfContentFn(mail);
              const parts = splitHtmlAtGmailQuote(html);
              return parts.part1;
            }}
            imageAttachments={imageAttachments}
          />
          {splitHtmlAtGmailQuote(getHtmlOfContentFn(mail))?.part2?.length !==
            0 && (
              <button
                onClick={toggleShowMore}
                style={{
                  backgroundColor: '#edecf5',
                  border: 'none',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  marginBottom: '10px',
                }}
              >
                <MoreHorizIcon style={{ fontSize: '16px' }} />
              </button>
            )}
          {showMore && (
            <EmailContent
              getHtmlOfContent={() =>
                splitHtmlAtGmailQuote(getHtmlOfContentFn(mail))?.part2
              }
              imageAttachments={imageAttachments}
            />
          )}
        </Box>
      </Stack>
    </ErrorBoundary>
  );
}
