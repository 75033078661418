import {
  Box,
  ClickAwayListener,
  TextField,
  TextFieldProps
} from '@mui/material';
import { ChangeEvent, KeyboardEvent } from 'react';

interface EditableTextFieldProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  error: string;
  type?: string;
  placeholder?: string;
  textFieldProps?: Partial<TextFieldProps>;
  fieldType?: string;
}

const EditableTextField: React.FC<EditableTextFieldProps> = ({
  value,
  onChange,
  onSave,
  error,
  type = 'text',
  placeholder = 'enter value here',
  textFieldProps = {},
  fieldType
}) => {
  return (
    <ClickAwayListener onClickAway={() => {
      onSave();
    }}>
      <Box display={'inline-block'} id="textfield-container">
        <TextField
          variant="outlined"
          size="small"
          type={type}
          name="name"
          placeholder={placeholder}
          autoFocus
          value={value}
          onChange={onChange}
          onKeyUp={(e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          error={error !== ''}
          color={error ? 'error' : 'primary'}
          helperText={error}
          {...textFieldProps}
          multiline={fieldType === 'longText'}
          rows={fieldType === 'longText' ? 4 : undefined}
          sx={{
            maxWidth: "500px",
            "& .MuiOutlinedInput-root": {
                pr: "4px !important",
                borderColor: "#D9D9D9 !important",
                minHeight:"3.25rem",
                height:"auto",
            },
          }}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default EditableTextField; 