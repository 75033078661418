import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Stack, Stepper, Step, StepLabel, Alert, FormControl, FormLabel, TextField, InputAdornment, FormControlLabel, Checkbox, Divider } from "@mui/material";
import {
  ArrowLeftIcon,
  ReportingIconActive,
  CancelIcon,
  ArrowRightWhiteIcon,
  SettingIcon,
} from "../../../components/CustomIcons";
import { useTheme } from '@mui/material/styles';
import { GlobalSettings } from "ReduxStore/Reducers/transactionalEmails.reducer";

type TransactionalEmailSettingModalProps = {
  openedFrom: string;
  showSettingModal: boolean;
  setShowSettingModal: (showSettingModal: boolean) => void;
  senderName_: string;
  replyTo_: string;
  globalSettings?: GlobalSettings;
  saveSettings: (senderName: string, replyTo: string) => void;
}

const steps = ["Type", "Initial journey data", "Define Workflow", "Confirmation",];

const TransactionalEmailSettingModal = ({
  openedFrom,
  showSettingModal, 
  setShowSettingModal,
  senderName_,
  replyTo_,
  globalSettings,
  saveSettings
}: TransactionalEmailSettingModalProps) => {
  const theme = useTheme();

  const PopUpStyle = {
    width: '100%',
    maxWidth: '420px',
    backgroundColor: '#F4F6F7',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0px 3px 6px #00000029',
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 200px)",
    height: "calc(100vh - 60px)",
    // bgcolor: "#F2F2F2",
    bgcolor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up('xs')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('sm')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('xl')]: {
      width: "calc(100% - 200px)",
      height: "calc(100vh - 60px)",
    },
  };
  

  const [senderName, setSenderName] = useState<string>(senderName_);
  const [replyTo, setReplyTo] = useState<string>(replyTo_);
  const [useGlobalSettingsChecked, setUseGlobalSettingsChecked] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  useEffect(() => {
    console.log('senderName_, replyTo_ useeffect', senderName_, replyTo_);
    setInitialvalues()
  }, [senderName_, replyTo_]);

  const setInitialvalues = () => {
    if(openedFrom === 'view' && senderName_ == "" && replyTo_ == "") {
      setUseGlobalSettingsChecked(true);
      setSenderName(globalSettings?.senderName || '');
      setReplyTo(globalSettings?.replyTo || '');
    }else{
      setSenderName(senderName_);      
      setReplyTo(replyTo_);
      setUseGlobalSettingsChecked(false);
    }
  }

  const cancelSaveSettings = () => {
    setInitialvalues();
    setShowSettingModal(false);
  }

  const handleUseGlobalSettingCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseGlobalSettingsChecked(e.target.checked);
    if(e.target.checked){
      setSenderName(globalSettings?.senderName || '');
      setReplyTo(globalSettings?.replyTo || '');
    }else{
      setSenderName(senderName_);
      setReplyTo(replyTo_);
    }
  }

  useEffect(() => {
    if(senderName === "" || replyTo === ""){
      setDisableSaveButton(true);
    }else{
      setDisableSaveButton(false);
    }
  }, [senderName, replyTo]);

  return (        
    <Modal
      open={showSettingModal}
      onClose={()=>setShowSettingModal(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={{
        backdrop: { timeout: 200, sx: { backdropFilter: "blur(4px)", backgroundColor: "rgba(255, 255, 255, 0.5)" } },
      }}
    >
      <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} >
        <Box sx={PopUpStyle}>
          <Stack spacing={2}>   

            <Box>
              <Typography variant="subtitle1" fontWeight="500">
                Settings
              </Typography>
              <Divider />
            </Box>    

            <FormControl fullWidth>
              <FormLabel htmlFor="senderName" sx={{ fontWeight: '400' }}>Sender Name</FormLabel>
              <TextField
                id="senderName"
                type="text"
                name="senderName"
                value={senderName}
                onChange={(e) => setSenderName(e.target.value)}
                disabled={useGlobalSettingsChecked}
                placeholder="Sender Name"
                required
                fullWidth
                variant="outlined"
                size="small"
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position="end"  >
                      <Typography color='error'>
                        *
                      </Typography>
                    </InputAdornment>,
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel htmlFor="replyTo" sx={{ fontWeight: '400' }}>Reply to</FormLabel>
              <TextField
                id="replyTo"
                type="email"
                name="replyTo"
                value={replyTo}
                onChange={(e) => setReplyTo(e.target.value)}
                disabled={useGlobalSettingsChecked}
                placeholder="your@email.com"
                autoComplete="email"
                required
                fullWidth
                variant="outlined"
                size="small"
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position="end"  >
                      <Typography color='error'>
                        *
                      </Typography>
                    </InputAdornment>,
                  },
                }}
              />
            </FormControl>

            {openedFrom === 'view' && (
              <FormControlLabel
                control={<Checkbox checked={useGlobalSettingsChecked} onChange={handleUseGlobalSettingCheckbox} />}
                label="Use global settings"
              />
            )}

            <Stack direction={'row'} gap={1}>
              <Button size="small"
                variant="contained"
                color="primary"
                onClick={()=>saveSettings(senderName, replyTo)} 
                disabled={disableSaveButton}
              >
                Save Settings
              </Button>
              <Button size="small"
                variant="contained"
                // @ts-ignore
                color="white"
                onClick={cancelSaveSettings}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>

        </Box>          
      </Stack>
    </Modal>    
  );
};

export default TransactionalEmailSettingModal;
