/* StyledToasterContent.tsx (Custom Toaster Styling) */
import { styled } from '@mui/material';
import { MaterialDesignContent } from 'notistack';

const StyledToasterContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-SnackbarContainer ': {
    top: '100px',
  },
  '&.notistack-MuiContent-success': {
    // transformStyle: 'preserve-3d',
    color: '#5578fa !important',
    border: '2px solid #5578fa',

    '#notistack-snackbar' :{
      alignItems: 'baseline !important',
      gap: '10px',
      marginLeft: '-10px'
    },
    // '&::after': {
    //       content: '""',
    //       position: 'absolute',
    //       width: '125px',
    //       height: '74px',
    //       transform: 'translate3d(1px, 0px, -1px)',
    //       background: 'transparent linear-gradient(114deg, #5578FA 0%, #CBFFE3 100%) 0% 0% no-repeat padding-box',
    //       borderRadius: '26px',
    //       filter: 'blur(10px)',
    //     }
  },
  '&.notistack-MuiContent': {
    padding: '12px 24px',
    backgroundColor: '#fff',
    color: "#000000",
    borderRadius: '8px',
  },
}));

export default StyledToasterContent;