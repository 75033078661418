import { Checkbox, MenuItem, Select, SelectChangeEvent, TextField, InputAdornment } from '@mui/material';
import { createNotification } from 'helpers';
import { useEffect, useState } from 'react';
import { getActiveUsersForCompanyAPI } from 'ReduxStore/API';

type User = {
  _id: string;
  email: string;
};

export default function DropdownForUserType({
  value,
  onChange,
}: {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
}) {
  const [users, setUsers] = useState<User[]>([]);
  const [searchTextForUsers, setSearchTextForUsers] = useState('');
  const [
    searchTextForUsersDebouncedValue,
    setSearchTextForUsersDebouncedValue,
  ] = useState('');
  const [usersAreLoading, setUsersAreLoading] = useState(true);

  useEffect(() => {
    fetchAllTheActiveUsers();
  }, [searchTextForUsersDebouncedValue]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearchTextForUsersDebouncedValue(searchTextForUsers);
    }, 500);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTextForUsers]);

  const fetchAllTheActiveUsers = async () => {
    try {
      setUsersAreLoading(true);
      const data = {
        searchText: searchTextForUsers,
      };
      const response = await getActiveUsersForCompanyAPI(data);
      if (response.data.success === true) {
        setUsers(response.data.users);
      } else {
        setUsers([]);
        createNotification('error', 'Something went wrong');
      }
    } catch (error) {
      setUsers([]);
      console.log('error', error);
    } finally {
      setUsersAreLoading(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextForUsers(event.target.value);
  };

  return (
    <Select
      variant="outlined"
      size="small"
      value={value as string[]}
      onChange={onChange}
      fullWidth
      multiple
      displayEmpty
      onOpen={() => setSearchTextForUsers('')}
      renderValue={(selected) => {
        const selectedArray = selected as string[];
        if (selectedArray.length === 0) {
          if (usersAreLoading) return 'Loading...';
          if (!users?.length) return 'There are no records to display';
          return 'Search User';
        }
        
        const selectedEmails = selectedArray
          .map((id) => users.find((user) => user._id === id)?.email)
          .filter(Boolean)
          .join('\n');

        return (
          <div title={selectedEmails}>
            {`${selectedArray.length} ${
              selectedArray.length === 1 ? 'User' : 'Users'
            } Selected`}
          </div>
        );
      }}
      MenuProps={{
        PaperProps: {
          style: { maxHeight: 300 },
        },
      }}
    >
      <MenuItem
        dense
        disableRipple
        disableTouchRipple
        style={{ cursor: 'default' }}
        onClick={(e) => e.preventDefault()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <TextField
          size="small"
          fullWidth
          placeholder="Search users..."
          value={searchTextForUsers}
          onChange={handleSearchChange}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          autoFocus
        />
      </MenuItem>

      {usersAreLoading ? (
        <MenuItem disabled>Loading...</MenuItem>
      ) : users.length === 0 ? (
        <MenuItem disabled>No users found</MenuItem>
      ) : (
        users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            <Checkbox checked={value.includes(user._id)} />
            {user.email}
          </MenuItem>
        ))
      )}
    </Select>
  );
}
