import { Checkbox, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FilterType } from '../../utils/FilterTypesContext';
import { OpportunityStates } from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import { useSelector } from 'react-redux';
import CommonMultiSelect from './CommonMultiSelect';

type User = {
  _id: string;
  email: string;
};

export default function DropdownForAssignedTo({
  value,
  onChange,
}: {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
}) {
  const activeUsersForCompany = useSelector((state: any) => state.Users.activeUsersForCompany) as User[];

  return (
    <Select
      multiple
      variant="outlined"
      size="small"
      value={value || ''}
      onChange={onChange}
      fullWidth
      displayEmpty
      renderValue={(selected) => {
        if (!selected) return "Select";
        const selectedUsers = selected as string[]; 

        if (selectedUsers.length === 0) return "Select";

        const selectedItems = selectedUsers
          .map((value) => activeUsersForCompany?.find((option) => option._id === value)?.email)
          .join('\n');

        return (
          <div title={selectedItems}>
            {`${selectedUsers.length} ${selectedUsers.length === 1 ? 'Assignee' : 'Assignees'} Selected`}
          </div>
        );
      }}
    >
      {activeUsersForCompany?.map((user: User) => (
        <MenuItem key={user._id} value={user._id}>
          <Checkbox checked={value?.includes(user._id)} />
          {user.email}
        </MenuItem>
      ))}
    </Select>
  );
}
