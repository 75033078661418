import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Popover,
  Box,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import { FilterIcon } from '../../components/CustomIcons';

import AddIcon from '@mui/icons-material/Add';
import SaveFilterModel from './SaveFilterModel';
import FilterRow from './components/FilterRow';
import QuickFilters from './components/QuickFilters';
import { useFilterTypes } from './utils/FilterTypesContext';
import SelectedFilters from './components/SelectedFilters';
import { Filter as FilterType, UserPreferencesState } from 'ReduxStore/Reducers/userPreferences.reducer';
import { useSelector } from 'react-redux';
import Loader from 'Components/Loader/loader';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';



export default function Filter({ fetchAllItems }: { fetchAllItems: (bodyParamsForfiltering: any) => void; }) {
  const location = useLocation();
  const moduleName = location.pathname.split('/')[1];
  const initialRenderRef = useRef(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSaveFilter, setOpenSaveFilter] = useState(false);
  const [error, setError] = useState({});
  const { setSelectedFilterTypes, setAppliedQuickFilter, appliedQuickFilter, filterRows, setFilterRows, noFilterAppliedManuallyRef } = useFilterTypes();
  // Initialize with empty array instead of one filter
  const [isUpdating, setIsUpdating] = useState(false);

  const userPreferences = useSelector((state: any) => state.UserPreferences) as UserPreferencesState;

  const filterValues = useMemo(() =>
    filterRows.map(row => row.value),
    [filterRows]
  );

  useEffect(() => {
    if (userPreferences?.fetchUserPreferencesSuccess) {
      setAppliedQuickFilter(userPreferences.userPreferences.find(preference => preference.moduleName === moduleName)?.lastAppliedFilter);
      const query = userPreferences.userPreferences.find(preference => preference.moduleName === moduleName)?.lastAppliedFilter?.query || [];
      setFilterRows(query.map((row, index) => ({
        id: index,
        filterType: { value: row.column, label: row.label, type: row.fieldType, isCustomField: row.isCustomField },
        operator: row.condition,
        value: row.value,
      })));
    }
  }, [userPreferences?.fetchUserPreferencesSuccess]);

  // Update useEffect to depend on filterValues instead of filterRows
  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    } else {
      if (isValid()) {
        const filterQuery = filterRows.map((row) => {
          if (row.filterType.isCustomField) {
            return {
              column: row.filterType.value.includes('customFields') ? row.filterType.value : `customFields.${row.filterType.value}`,
              condition: row.operator,
              value: row.value,
              fieldType: row.filterType.type,
              isCustomField: row.filterType.isCustomField,
              label: row.filterType.label,
            };
          } else {
            return {
              column: row.filterType.value,
              condition: row.operator,
              value: row.value,
              fieldType: row.filterType.type,
              isCustomField: row.filterType.isCustomField,
              label: row.filterType.label,
            };
          }
        });

        const bodyParamsForfiltering = {
          filterQuery: filterQuery,
          filterName: appliedQuickFilter?.name || '',
          filterColor: appliedQuickFilter?.color || '',
          // filterSorting: appliedQuickFilter.sorting,
          filterId: appliedQuickFilter?._id || '',
          noFilterAppliedManually: noFilterAppliedManuallyRef.current,
        };
        noFilterAppliedManuallyRef.current = false;
        fetchAllItems(bodyParamsForfiltering);
      }
    }
  }, [filterValues]);

  useEffect(() => {
    setSelectedFilterTypes(filterRows.map(row => row.filterType.label));
  }, [filterRows]);

  useEffect(() => {
    if (userPreferences?.updateUserPreferencesSuccess) {
      setOpenSaveFilter(false);
    }
  }, [userPreferences?.updateUserPreferencesSuccess]);

  const isValid = () => {
    for (const row of filterRows) {
      if (!row.filterType.value) {
        setError(prev => ({ ...prev, [row.id]: "Filter type is empty" }));
        return false;
      }
      if (!row.operator) {
        setError(prev => ({ ...prev, [row.id]: "Operator is empty" }));
        return false;
      }
      if (Array.isArray(row.value)) {
        if (row.value.length === 0 || row.value.some(v => !v)) {
          setError(prev => ({ ...prev, [row.id]: "Value array is empty or contains empty values" }));
          return false;
        }
      } else if (!row.value) {
        setError(prev => ({ ...prev, [row.id]: "Value is empty" }));
        return false;
      }
    }
    return true;
  };

  const isValidValue = useMemo(() => {
    return isValid();
  }, [filterRows]);

  // Add a ref to track the next ID
  const nextIdRef = useRef(1);

  const handleClick = (event: {
    currentTarget: React.SetStateAction<null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  const handleFilterTypeChange = (id: number, value: string, type: string, isCustomField: boolean, label: string) => {
    setFilterRows(filterRows.map(row => {
      if (['list', 'user', 'states', 'source', 'contact', 'account', 'assignedTo'].includes(type)) {
        return row.id === id ? { ...row, filterType: { value: value, label: label, type: type, isCustomField: isCustomField }, value: [] } : row;
      } else if (['text', 'number', 'eijentId'].includes(type)) {
        return row.id === id ? { ...row, filterType: { value: value, label: label, type: type, isCustomField: isCustomField }, value: '' } : row;
      } else if (type === 'date') {
        return row.id === id ? { ...row, filterType: { value: value, label: label, type: type, isCustomField: isCustomField }, value: '' } : row;
      } else {
        return row.id === id ? { ...row, filterType: { value: value, label: label, type: type, isCustomField: isCustomField }, value: '' } : row
      }
    }));
  };

  const handleOperatorChange = (id: number, value: string) => {
    setFilterRows(filterRows.map(row => {
      if (row.filterType.type === 'date' && value === 'fromToDate') {
        if (row.operator === 'fromToDate') {
          return row.id === id ? { ...row, operator: value, value: [] } : row;
        } else {
          return row.id === id ? { ...row, operator: value, value: '' } : row;
        }
      } else {
        return row.id === id ? { ...row, operator: value } : row;
      }
    }));
  };

  const handleValueChange = (id: number, value: string, name: string) => {
    setFilterRows(filterRows.map(row => {
      if (row.filterType.type === 'date' && row.operator === 'fromToDate') {
        if (name === 'start-date') {
          return row.id === id ? { ...row, value: [value, row.value?.[1] || ''] } : row;
        } else if (name === 'end-date') {
          return row.id === id ? { ...row, value: [row.value?.[0] || '', value] } : row;
        }
      } else {
        return row.id === id ? { ...row, value: value } : row;
      }
    }));
  };


  const addAnotherFilter = () => {
    setFilterRows([...filterRows, {
      id: nextIdRef.current,
      filterType: { value: "", label: "", type: "", isCustomField: false },
      operator: "",
      value: ""
    }]);
    nextIdRef.current += 1;
  };

  const handleDeleteFilter = (id: number) => {
    setFilterRows(filterRows.filter(row => row.id !== id));
  };

  const handleOpenUpdatePopup = (isUpdating: boolean) => {
    setIsUpdating(isUpdating);
    setOpenSaveFilter(true);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        aria-describedby={id}
        sx={{
          backgroundColor: open ? '#F4F6F7' : '#ffffff',
          borderBottomLeftRadius: open ? '0px' : 8,
          borderBottomRightRadius: open ? '0px' : 8,
          transitionDuration: '200ms',
          '&::before': {
            content: '""',
            position: 'absolute',
            bottom: open ? '-4px' : 0,
            left: '0',
            right: '0',
            // width: "52px",
            height: open ? '4px' : 0,
            transitionDuration: '200ms',
            backgroundColor: '#F4F6F7',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
          },
        }}
        // @ts-ignore
        color="white" size="medium"
        startIcon={<FilterIcon />}
      >Filter</Button>

      {/* Filter Popup */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '4px',
              backgroundColor: '#F4F6F7',
              boxShadow: '0px 3px 6px #00000029',
            },
          },
        }}
      >
        <Box
          display={'flex'}
          mx={3}
          py={3}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderBottom={'1px solid #D9D9D9'}
        >
          <Typography variant="h2">Filters</Typography>
          <Button
            variant="contained"
            // @ts-ignore
            color="white"
            size="small"
            onClick={() => {
              noFilterAppliedManuallyRef.current = true;
              setFilterRows([]);
              setAppliedQuickFilter({} as FilterType);
            }}
          >
            Clear
          </Button>
        </Box>
        <Stack
          gap={3}
          sx={{
            overflowY: 'auto',
            flexGrow: 1,
            maxHeight: 'calc(100vh - 330px)',
            // width: '100%',
            minWidth: '500px',
            width: '700px',
            p: 3,
          }}
        >
          {/* ---Selected filters--- */}
          <SelectedFilters appliedQuickFilter={appliedQuickFilter} />

          {/* ---Quick filters--- */}
          <QuickFilters setFilterRows={setFilterRows} />

          {/* ---Add filter--- */}
          <Stack gap={1} alignItems={'start'} overflow={'auto'} mr={-2}>
            <Box
              display={'flex'}
              width={'100%'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {/* ---Add button--- */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                sx={{
                  fontSize: '15px',
                }}
                onClick={() => addAnotherFilter()}
              >
                Add Filters
              </Button>

              {filterRows.length > 0 && isValidValue && (
                <Box display={'flex'} gap={0.5} alignItems={'center'}>
                  <LoadingButton
                    loading={userPreferences?.isLoading}
                    loadingIndicator="Saving…"
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{
                      fontSize: '15px',
                    }}
                    onClick={() => handleOpenUpdatePopup(appliedQuickFilter?.name ? true : false)}
                    disabled={!isValidValue}
                  >
                    Save
                  </LoadingButton>
                  {openSaveFilter && (
                    <SaveFilterModel
                      open={openSaveFilter}
                      onClose={() => setOpenSaveFilter(false)}
                      filterRows={filterRows}
                      isUpdating={isUpdating}
                      appliedQuickFilter={appliedQuickFilter}
                      handleCloseFilter={handleCloseFilter}
                    />
                  )}
                </Box>
              )}
            </Box>
            <Stack gap={1} width={'100%'} height={'100%'} overflow={'auto'} pr={2}>
              {/* Replace the single FilterRow with mapped filter rows */}
              {filterRows.map((row) => (
                <FilterRow
                  key={row.id}
                  filterType={row.filterType}
                  operator={row.operator}
                  value={row.value}
                  onFilterTypeChange={(e, filterType: string, isCustomField: boolean, label: string) => handleFilterTypeChange(row.id, e.target.value as string, filterType, isCustomField, label)}
                  onOperatorChange={(e) => handleOperatorChange(row.id, e.target.value as string)}
                  onValueChange={(e) => handleValueChange(row.id, e.target.value as string, e.target.name as string)}
                  onDelete={() => handleDeleteFilter(row.id)}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Popover>
      {/* {userPreferences.isLoading && <Loader />} */}
    </>
  );
}
