const getTransactionalEmailTemplateUnlayerConfig = ({opportunityCustomFields}:any) => {
  let opportunityDynamicCustomField = {
    opportunityName: {
      name: "OpportunityName",
      value: "{{{opportunityName}}}"
    },
    opportunityStatus: {
      name: "OpportunityStatus",
      value: "{{{opportunityStatus}}}"
    },
    opportunityAssignedTo: {
      name: "OpportunityAssignedTo",
      value: "{{{opportunityAssignedTo}}}"
    },
  }
      
  if(opportunityCustomFields && opportunityCustomFields.length > 0) {
    for (let x = 0; x < opportunityCustomFields.length; x++) {
      opportunityDynamicCustomField[opportunityCustomFields[x]?.fieldData?.fieldName] = {
        name: opportunityCustomFields[x].fieldData?.fieldName ,
        value: "{{{" + opportunityCustomFields[x]?.fieldData?.fieldName + "}}}"
      }
    }
  }  

  return {
    id: 'transactionalEmailTemplateEditor',
    displayMode: 'email',
    mergeTags: {
      customeField: {
        name: "Opportunity",
        mergeTags: opportunityDynamicCustomField,
      },
    },
  };
};

export default getTransactionalEmailTemplateUnlayerConfig;
