import Loader from 'Components/Loader/loader';
import debounce from 'lodash/debounce';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  Box,
  Typography,
  Avatar,
  Stack,
  Button,
  Checkbox,
  IconButton,
  TableSortLabel,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  OpportunitiesIcon,
  CheckAllIcon,
  UnCheckAllIcon,
  DeleteIcon,
  MoreIcon,
  CancelIcon,
} from '../../../../components/CustomIcons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { OpportunityOpportunitiesContext } from 'Contexts/OpportunityOpportunitiesProvider';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { useSelector } from 'react-redux';
import {
  AccountCf,
  ContactCf,
  Opportunity,
  OpportunityState,
} from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import {
  CustomField,
  CustomFields_State,
} from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import { authPermissionValidation } from 'helpers/permissionsHelper';
import { createNotification } from 'helpers';
import { permissionsConstants } from 'constants/permissionsConstant';
import { formatDateField, rgbToHex2 } from 'helpers/common';
import CustomTooltip from 'Components/CustomHTMLTooltip';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import OpportunityOpportunitiesAPI from 'ReduxStore/API/opportunity/opportunityOpportunitiesAPI';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OutputIcon from '@mui/icons-material/Output';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'eijent/components/Pagination/Pagination';
import useCustomizedPreference from 'eijent/internals/customHooks/useCustomizedPreference';
import Filters from 'eijent/internals/Filters/Filters';
import { Creators as UserPreferencesActions } from 'ReduxStore/Actions/userPreferences.action';
import { CustomFieldArrayCell } from '../../cells/CustomFieldArrayCell';
import { CustomFieldCell } from '../../cells/CustomFieldCell';
import { DefaultHeadersContentCell } from '../../cells/DefaultHeadersContentCell';
import DropdownSelectionPopUp from 'eijent/components/commonComponents/DropdownSelectionPopUp';
import {
  OpportunityStates as OpportunityDynamicStates,
  OpportunityStates_State,
} from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';

import QuickFilterDisplay from '../components/QuickFilterDisplay';
import moment from 'moment';
const defaultvisibleColumns: { id: string; show: boolean; order: number }[] = [
  { id: 'name', show: true, order: 1 },
  { id: 'source', show: true, order: 2 },
  { id: 'uniqueEijentId', show: true, order: 3 },
  { id: 'assignedTo', show: true, order: 4 },
];

const tableHeadStyle: React.CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '10px',
  fontSize: '12px',
  lineHeight: 1,
  color: '#c1c1c1',
  borderColor: '#C1C1C1',
  backgroundColor: '#ffffff',
};

const tableHeaders = [
  { id: 'uniqueEijentId', label: 'EIJENT ID' },
  { id: 'name', label: 'NAME', width: '44px', pl: 4, active: true },
  { id: 'state', label: 'STATE' },
  { id: 'assignedTo', label: 'ASSIGNED TO' },
  { id: 'source', label: 'SOURCE' },
  // { id: 'updatedAt', label: 'UPDATED AT' },
];

export type FetchAccountsQueryParams = {
  pageNo: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
  searchText: string;
  from: string;
  status?: string;
};

export default function OpportunityList() {
  const initialRender = useRef(true);

  const opportunityOpportunitiesContext = useContext(
    OpportunityOpportunitiesContext,
  );
  if (!opportunityOpportunitiesContext) {
    throw new Error(
      'Opportunities must be used within an opportunityOpportunitiesContextProvider',
    );
  }
  const {
    pageNo,
    setPageNo,
    limit,
    setLimit,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    searchText,
    setSearchText,
    // filterByState,
    // setFilterByState,
    // filterByProject,
    // setFilterByProject,
    // filterBySource,
    // setFilterBySource,
    // filterByLastImported,
    // setFilterByLastImported,
    // filterByCustomField,
    // setFilterByCustomField,
  } = opportunityOpportunitiesContext;

  const {
    opportunities,
    filterCount,
    totalCount,
    isLoading,
    updateMultipleOpportunityOpportunitiesSuccess,
    deleteOpportunityOpportunitySuccess,
    createOpportunityOpportunitySuccess,
    isLoading: isOpportunitiesLoading,
  } = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  const {
    customFields,
    isLoading: isCustomFieldsLoading,
    fetchAllOpportunityCustomFieldSuccess,
  } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  //custom states
  const {
    opportunityStates: opportunityDynamicStates,
    fetchAllOpportunityStateSuccess,
  } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  // const [transactionEmailsComp, setTransactionEmailsComp] =
  //   useState<boolean>(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [openStatusConfirmationModel, setOpenStatusConfirmationModel] =
    useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [openAssignToPopup, setOpenAssignToPopup] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOppForAssigning, setSelectedOppForAssigning] =
    useState<string>();
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  // const uniqueEijentId = location.pathname.split('/').at(-1);
  const currentPath = location.pathname.split('/').at(1);
  const [tableHeadersForCustomization, setTableHeadersForCustomization] =
    useState<{ id: string; label: string; order: number; show: boolean }[]>([]);
  const { activeUsersForCompany } = useSelector((state: any) => state.Users);
  const [selectedAssignedToId, setSelectedAssignedToId] = useState<{
    _id: string;
    email: string;
  }>();
  const [dynamicStates, setDynamicStates] = useState<OpportunityDynamicStates>(
    [],
  );
  const [bodyParamsForfiltering, setBodyParamsForfiltering] = useState<any>([]);

  const [filterIdToDelete, setFilterIdToDelete] = useState<string>('');

  const moduleName = 'opportunities';
  useEffect(() => {
    fetchAllOpportunityCustomField();
    fetchAllOpportunityStates();
    fetchAllTheActiveUsers();
    // setTransactionEmailsComp(
    //   (location.state as { transactionEmailsComp?: any; })
    //     ?.transactionEmailsComp,
    // );
  }, []);

  useEffect(() => {
    if (fetchAllOpportunityStateSuccess) {
      setDynamicStates(opportunityDynamicStates);
    }
  }, [fetchAllOpportunityStateSuccess]);

  const visibleColumns = useCustomizedPreference(
    'opportunities',
    defaultvisibleColumns,
  );

  // Create a stable string representation of visible columns for dependency check
  const visibleColumnsKey = useMemo(() => {
    return visibleColumns
      .map((col) => `${col.id}-${col.show}-${col.order}`)
      .join('|');
  }, [visibleColumns]);

  const abortControllerRef = useRef<AbortController | null>(null);

  // Create the debounced function using useCallback to maintain reference
  const updatePreferences = useCallback(
    debounce((tableHeadersForCustomization) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      // Log to verify the action is being called

      // Try using the Actions object directly
      dispatch(
        UserPreferencesActions.updateUserPreferencesRequest(
          [
            {
              moduleName: 'opportunities',
              visibleColumns: tableHeadersForCustomization,
            },
          ],
          abortControllerRef.current.signal,
        ),
      );
    }, 500),
    [dispatch], // Add dispatch to dependencies
  );

  useEffect(() => {
    if (fetchAllOpportunityCustomFieldSuccess) {
      const basicHeadersWithOrder = [];
      const basicHeadersWithoutOrder = [];
      const customFieldsWithOrder = [];
      const customFieldsWithoutOrder = [];
      let order = 1;

      tableHeaders.forEach((header) => {
        const visibleColumn = visibleColumns.find(
          (col) => col.id === header.id,
        );
        if (visibleColumn?.order) {
          order++;
          basicHeadersWithOrder.push({
            id: header.id,
            label: header.label,
            order: visibleColumn.order,
            show: visibleColumn?.show || false,
          });
        }
      });

      customFields?.forEach((cf) => {
        const visibleColumn = visibleColumns.find((col) => col.id === cf._id);
        if (visibleColumn?.order) {
          order++;
          customFieldsWithOrder.push({
            id: cf._id,
            label: cf.fieldData.fieldName,
            order: visibleColumn.order,
            show: visibleColumn?.show || false,
          });
        }
      });

      const headers = [
        ...basicHeadersWithOrder,
        // { id: 'assignedTo', label: 'Assigned To', order: visibleColumns.find(column => column.id === 'assignedTo')?.order || 5, show: visibleColumns.find(column => column.id === 'assignedTo')?.show || false },
        ...customFieldsWithOrder,
      ].sort((a, b) => a.order - b.order);

      tableHeaders.forEach((header) => {
        const visibleColumn = visibleColumns.find(
          (col) => col.id === header.id,
        );
        if (!visibleColumn) {
          order++;
          basicHeadersWithoutOrder.push({
            id: header.id,
            label: header.label,
            order: order,
            show: false,
          });
        }
      });

      customFields?.forEach((cf) => {
        const visibleColumn = visibleColumns.find((col) => col.id === cf._id);
        if (!visibleColumn) {
          order++;
          customFieldsWithoutOrder.push({
            id: cf._id,
            label: cf.fieldData.fieldName,
            order: order,
            show: false,
          });
        }
      });

      const allheadersTitle = [
        ...headers,
        ...basicHeadersWithoutOrder,
        ...customFieldsWithoutOrder,
      ];
      setTableHeadersForCustomization(allheadersTitle);
    }
  }, [customFields, fetchAllOpportunityCustomFieldSuccess, visibleColumnsKey]);

  const handleCustomizationOptionsChange = (
    options: { id: string; show: boolean; order: number; label: string }[],
  ) => {
    setTableHeadersForCustomization(options);
    updatePreferences(options);
  };

  useEffect(() => {
    if (updateMultipleOpportunityOpportunitiesSuccess) {
      setSelectedCheckboxes([]);
    }
  }, [updateMultipleOpportunityOpportunitiesSuccess]);

  useEffect(() => {
    if (currentPath === 'opportunities') {
      setLoading(true);
      fetchAllOpportunityOpportunities();
    }
  }, [
    pageNo,
    limit,
    sortBy,
    sortOrder,
    searchText,
    // filterByState,
    // filterByProject,
    // filterBySource,
    // filterByLastImported,
    // filterByCustomField,
    currentPath,
  ]);

  useEffect(() => {
    if (deleteOpportunityOpportunitySuccess) {
      setSelectedCheckboxes([]);
    }
  }, [deleteOpportunityOpportunitySuccess]);

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [pageNo]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleEndCountForPagination(totalCount, limit, pageNo, setEndCount);
    }
  }, [totalCount]);

  const queryParams: FetchAccountsQueryParams = {
    pageNo,
    limit,
    sortBy,
    sortOrder,
    searchText,
    from: 'viewOpportunity',
  };

  const fetchAllOpportunityOpportunities = (bodyParamsForfiltering?: any) => {
    const data = {
      queryParams,
      bodyParams: {
        filterQuery: bodyParamsForfiltering?.filterQuery || [],
        filterName: bodyParamsForfiltering?.filterName || '',
        filterColor: bodyParamsForfiltering?.filterColor || '',
        filterId: bodyParamsForfiltering?.filterId || '',
        noFilterAppliedManually:
          bodyParamsForfiltering?.noFilterAppliedManually || false,
      },
    };
    setBodyParamsForfiltering(bodyParamsForfiltering?.filterQuery || []);

    dispatch(Actions.fetchAllOpportunityOpportunitiesRequest(data));
    setLoading(false);
  };

  const fetchAllOpportunityCustomField = () => {
    const data = {
      customFieldType: 'opportunity',
      from: 'viewOpportunity',
    };
    dispatch(Actions.fetchAllOpportunityCustomFieldRequest(data));
  };

  const fetchAllOpportunityStates = () => {
    const data = {
      opportunityStateType: 'opportunity',
    };
    dispatch(Actions.fetchAllOpportunityStateRequest(data));
  };

  const fetchAllTheActiveUsers = () => {
    try {
      dispatch(Actions.getActiveUsersForCompanyRequest({}));
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSelectedCheckboxes([]);
  };

  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    accountId: string,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, accountId]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((id) => id !== accountId),
      );
    }
  };

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      opportunities.forEach((opportunity) => arr.push(opportunity._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleUpdateMultiple = (key: string, value: any, ids?: string[]) => {
    if (!ids && !selectedCheckboxes.length && !selectedOppForAssigning) {
      createNotification('error', 'Please select opportunities');
    } else {
      const bodyParams = {
        ...bodyParamsForfiltering,
        ids: selectedOppForAssigning
          ? [selectedOppForAssigning]
          : ids || selectedCheckboxes, // ids will come from status dropdown component
        toUpdate: {
          [key]: value,
        },
      };

      if (bodyParams.toUpdate.assignedToIds) {
        queryParams.from = 'assignOpportunity';
      } else if (bodyParams.toUpdate.status) {
        queryParams.from = 'changeOpportunityStatus';
      } else if (bodyParams.toUpdate.isDeleted) {
        queryParams.from = 'deleteOpportunity';
      }

      const data = {
        bodyParams,
        queryParams,
      };
      dispatch(Actions.updateMultipleOpportunityOpportunitiesRequest(data));
    }
    openStatusConfirmationModel && setOpenStatusConfirmationModel(false);
    handleCloseDropdowns();
    closeDeleteConfirmationModel();
  };

  const handleCloseDropdowns = () => {
    setOpenSettings(false);
    setOpenAction(false);
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const handleOpenDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(true);
  };

  const handleOpenDropdownSelectionPopup = (
    setOpenFn: React.Dispatch<React.SetStateAction<boolean>>,
    opportunityId?: string,
    assignedToId?: { _id: string; email: string },
  ) => {
    if (opportunityId) {
      setSelectedOppForAssigning(opportunityId);
      setSelectedAssignedToId(assignedToId);
    }
    if (!selectedCheckboxes.length && !opportunityId) {
      createNotification(
        'error',
        'Please select one or more opportunities to modify',
      );
    } else {
      setOpenFn(true);
    }
  };

  const handleExport = async (onlyExportSelected: boolean) => {
    setLoading(true);
    let data = {
      queryParams,
      bodyParams: {
        ...bodyParamsForfiltering,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    if (onlyExportSelected) {
      data = {
        queryParams,
        bodyParams: {
          ...bodyParamsForfiltering,
          // @ts-ignore
          ids: selectedCheckboxes,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };
    }
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const res = await opportunityOpportunitiesAPI.export(data);
    if (res.data.csvData) {
      const url = window.URL.createObjectURL(new Blob([res?.data?.csvData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Opportunities.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#F4F6F766',
        height: '100%',
        maxHeight: '100%',
        borderRadius: '8px',
        overflow: 'auto',
        backdropFilter: 'blur(20px)',
      }}
    >
      <Stack
        sx={{
          height: '100%',
          maxHeight: '100%',
        }}
      >
        <Filters
          customizationOptions={tableHeadersForCustomization}
          onCustomizationOptionsChange={handleCustomizationOptionsChange}
          fetchAllItems={fetchAllOpportunityOpportunities}
        />

        {/* --Select and menu-- */}
        <Stack
          px={1.5}
          sx={{ backgroundColor: '#ffffff' }}
          borderBottom={'1px solid #C1C1C1'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack
            spacing={2.75}
            py={1.5}
            px={2}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <Checkbox
                icon={<UnCheckAllIcon />}
                checkedIcon={<CheckAllIcon />}
                onClick={handleSelectAllCheckboxes}
                checked={
                  opportunities.length
                    ? selectedCheckboxes.length === opportunities.length
                    : false
                }
              />
              <Typography>{selectedCheckboxes.length || ''}</Typography>
            </Box>

            {selectedCheckboxes.length > 0 && (
              <IconButton
                size="small"
                variant="contained"
                // @ts-ignore
                color="transparent"
                title="Delete"
                onClick={handleOpenDeleteConfirmationModel}
              >
                <DeleteIcon />
              </IconButton>
            )}

            <IconButton
              size="small"
              variant="contained"
              // @ts-ignore
              color="transparent"
              title="Assign To"
              onClick={() => {
                handleOpenDropdownSelectionPopup(setOpenAssignToPopup);
              }}
              disabled={!selectedCheckboxes.length}
            >
              <PersonAddIcon />
            </IconButton>

            <IconButton
              size="small"
              variant="contained"
              // @ts-ignore
              color="transparent"
              title="Export Selected"
              onClick={() => handleExport(true)}
              disabled={!selectedCheckboxes.length}
            >
              <UploadFileIcon />
            </IconButton>

            <IconButton
              size="small"
              variant="contained"
              // @ts-ignore
              color="transparent"
              title="Export All"
              onClick={() => handleExport(false)}
              disabled={selectedCheckboxes.length > 0}
            >
              <OutputIcon />
            </IconButton>

            {/* <IconButton
              size="small"
              variant="contained"
              // @ts-ignore
              color="transparent"
            >
              <OpportunitiesIcon />
            </IconButton> */}

            {/* <IconButton
              size="small"
              variant="contained"
              // @ts-ignore
              color="transparent"
            >
              <CancelIcon />
            </IconButton> */}

            {/* <IconButton
              size="small"
              variant="contained"
              // @ts-ignore
              color="transparent"
            >
              <MoreIcon />
            </IconButton> */}
          </Stack>
          <QuickFilterDisplay moduleName={moduleName} />
        </Stack>

        {/* --table list-- */}
        <Box flexGrow={1} overflow={'auto'} sx={{ backgroundColor: '#ffffff' }}>
          <TableContainer
            sx={{ maxHeight: '100%', height: '100%' }}
            className="data-table"
          >
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="Email List">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ ...tableHeadStyle, width: '44px', pl: 4 }}
                  ></TableCell>
                  {tableHeaders
                    .filter((header) =>
                      visibleColumns?.find(
                        (visibleColumn) =>
                          visibleColumn.id === header.id && visibleColumn.show,
                      ),
                    )
                    .map((header) => (
                      <TableCell
                        key={header.id}
                        sx={{
                          ...tableHeadStyle,
                          // ...(header.maxWidth && { maxWidth: header.maxWidth }),
                        }}
                        onClick={() => handleSorting(header.id)}
                      >
                        <TableSortLabel
                          IconComponent={KeyboardArrowDownIcon}
                          direction={
                            header.id === sortBy
                              ? sortOrder === 'asc'
                                ? 'desc'
                                : 'asc'
                              : undefined
                          }
                          active={header.id === sortBy}
                        >
                          {header.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  {customFields
                    .filter((cf) =>
                      visibleColumns?.find(
                        (visibleColumn) =>
                          visibleColumn.id === cf._id && visibleColumn.show,
                      ),
                    )
                    .map((cf) => {
                      return (
                        <TableCell
                          key={cf._id}
                          sx={{
                            ...tableHeadStyle,
                          }}
                        >
                          {cf.fieldData.fieldName}
                        </TableCell>
                      );
                    })}
                  {visibleColumns?.find(
                    (field) => field.id === 'updatedAt' && field.show,
                  ) && (
                    <TableCell
                      sx={{
                        ...tableHeadStyle,
                      }}
                    >
                      UPDATED AT
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(opportunities) && opportunities.length
                  ? opportunities.map((opportunity) => (
                      <TableRow
                        // @ts-ignore
                        key={opportunity.eijentId}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:hover': { backgroundColor: '#E6FDF4' },
                          px: 2.25,
                        }}
                      >
                        <TableCell sx={{ width: '44px', pl: 4 }}>
                          <Checkbox
                            onClick={(e) =>
                              handleSelectCheckBox(e, opportunity._id)
                            }
                            checked={selectedCheckboxes.includes(
                              opportunity._id,
                            )}
                          />
                        </TableCell>
                        {tableHeaders
                          .filter((header) =>
                            visibleColumns?.find(
                              (visibleColumn) =>
                                visibleColumn.id === header.id &&
                                visibleColumn.show,
                            ),
                          )
                          .map((header) => (
                            <DefaultHeadersContentCell
                              key={header.id}
                              header={header}
                              opp={opportunity}
                              handleOpenDropdownSelectionPopup={
                                handleOpenDropdownSelectionPopup
                              }
                              setOpenAssignToPopup={setOpenAssignToPopup}
                            />
                          ))}
                        {/* {visibleColumns?.find(
                          (field) => field.id === 'assignedTo' && field.show,
                        ) && (
                          <AssignedToCell
                            opportunity={opportunity}
                            handleOpenDropdownSelectionPopup={handleOpenDropdownSelectionPopup}
                            setOpenAssignToPopup={setOpenAssignToPopup}
                          />
                        )} */}
                        {customFields
                          .filter((cf) =>
                            visibleColumns?.find(
                              (visibleColumn) =>
                                visibleColumn.id === cf._id &&
                                visibleColumn.show,
                            ),
                          )
                          .map((cf: CustomField) => {
                            const value1 =
                              opportunity?.customFields?.[cf._id as string];
                            const fieldType = cf.fieldData.fieldType;
                            const isArrayType = ['list', 'user'].includes(
                              fieldType,
                            );
                            return Array.isArray(value1) || isArrayType ? (
                              <React.Fragment key={cf._id as string}>
                                <CustomFieldArrayCell
                                  cf={cf}
                                  opp={opportunity}
                                  value1={Array.isArray(value1) ? value1 : []}
                                  isMultiSelect={
                                    cf.fieldData.isMultiSelect || false
                                  }
                                />
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={cf._id as string}>
                                <CustomFieldCell
                                  cf={cf}
                                  opp={opportunity}
                                  customFields={customFields}
                                  authPermissionValidation={
                                    authPermissionValidation
                                  }
                                />
                              </React.Fragment>
                            );
                          })}
                        {visibleColumns?.find(
                          (field) => field.id === 'updatedAt' && field.show,
                        ) && (
                          <TableCell sx={{ minWidth: '200px' }}>
                            {moment(opportunity.updatedAt).format(
                              'MMM, Do YYYY, h:mm A',
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  : !isOpportunitiesLoading && (
                      <TableRow>
                        <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                          There are no records to display
                        </TableCell>
                      </TableRow>
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ background: '#ffffff' }}>
          <PaginationFunctional
            startCount={opportunities.length === 0 ? 0 : startCount}
            setStartCount={setStartCount}
            endCount={opportunities.length === 0 ? 0 : endCount}
            limit={limit}
            setLimit={setLimit}
            page_no={pageNo}
            setPage_no={setPageNo}
            totalItemsCount={totalCount}
            listItemsFn={() => {}}
          />
        </Box>
      </Stack>
      <DropdownSelectionPopUp
        open={openAssignToPopup}
        setOpen={setOpenAssignToPopup}
        dropdownArray={activeUsersForCompany}
        heading={'Assign To'}
        subheading={
          "Select the user's email from the list to assign the selected opportunities for the necessary actions"
        }
        label={'Assign To'}
        onSaveFn={(value: any) => handleUpdateMultiple('assignedToIds', value)}
        setSelectedOppForAssigning={setSelectedOppForAssigning}
        selectedOppForAssigning={selectedOppForAssigning}
        selectedAssignedToId={selectedAssignedToId}
      />
      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => handleUpdateMultiple('isDeleted', true)}
      />
      {(loading || isOpportunitiesLoading) && <Loader />}
    </Box>
  );
}
