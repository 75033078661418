import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Stack, Stepper, Step, StepLabel, Alert, FormControl, FormLabel, TextField, InputAdornment } from "@mui/material";
import {
  ArrowLeftIcon,
  ReportingIconActive,
  CancelIcon,
  ArrowRightWhiteIcon,
  SettingIcon,
} from "../../../components/CustomIcons";
import { useTheme } from '@mui/material/styles';

const steps = ["Type", "Initial journey data", "Define Workflow", "Confirmation",];

type SendTestMailModalProps = {
    showSendTestMail: boolean;
    setShowSendTestMail: (showSendTestMail: boolean) => void;
}

const SendTestMailModal = ({showSendTestMail, setShowSendTestMail}: SendTestMailModalProps) => {
  const theme = useTheme();

  const PopUpStyle = {
    width: '100%',
    maxWidth: '420px',
    backgroundColor: '#F4F6F7',
    borderRadius: '8px',
    padding: '16px',
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 200px)",
    height: "calc(100vh - 60px)",
    // bgcolor: "#F2F2F2",
    bgcolor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up('xs')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('sm')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('xl')]: {
      width: "calc(100% - 200px)",
      height: "calc(100vh - 60px)",
    },
  };

  return (
    <>
      <Modal
        open={showSendTestMail}
        onClose={()=>setShowSendTestMail(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: { timeout: 200, sx: { backdropFilter: "blur(4px)", backgroundColor: "rgba(255, 255, 255, 0.5)" } },
        }}
      >
        <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} >
          <Box sx={PopUpStyle}>
            <Stack spacing={2}>

                <Box>
                    <Typography variant="subtitle1">
                        Send Mail
                    </Typography>
                    <Typography variant="body2">
                        Please provide a valid email address, and we will send a test email for your review.
                    </Typography>
                </Box>

              <FormControl fullWidth>
                <FormLabel htmlFor="subject" sx={{ fontWeight: '400' }}>Email Address</FormLabel>
                <TextField
                  // error={emailError}
                  // helperText={emailErrorMessage}
                  id="subject"
                  type="text"
                  name="subject"
                  placeholder="Email Subject"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  // color={emailError ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl>

              <Stack direction={'row'} gap={1}>
                <Button size="small"
                  variant="contained"
                  color="primary"
                >
                  Send Test Mail
                </Button>
                <Button size="small"
                  variant="contained"
                  // @ts-ignore
                  color="white"
                  onClick={()=>setShowSendTestMail(false)}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>

          </Box>          
        </Stack>
      </Modal>
    </>
  );
};

export default SendTestMailModal;
