import { createReducer } from 'reduxsauce';
import { Constants } from '../Actions';
import { send } from 'process';

export type TransactionalEmail = {
  _id?: string;
  isDeleted?: boolean;
  templateName: string;
  emailSubject: string;
  senderName: string;
  scope: string;
  replyTo: string;
  html: string;
  json: object;
  userId?: string;
  templateType: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type GlobalSettings = {
  senderName: string; 
  replyTo: string;
};

export type TransactionalEmailsState = {
  transactionalEmails: TransactionalEmail[];
  totalCount: number;
  transactionalEmail: TransactionalEmail | null;
  globalSettings: GlobalSettings
  loading: boolean;
  error: string | null;
  message: string | null;
};

const INITIAL_STATE: TransactionalEmailsState = {
  transactionalEmails: [],
  totalCount: 0,
  transactionalEmail: null,
  globalSettings: {
    senderName: '', 
    replyTo: '',
  },
  loading: false,
  error: null,
  message: null,
};

const getTransactionalEmailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null,
});

const getTransactionalEmailsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  transactionalEmails: action.transactionalEmails,
  totalCount: action.totalCount,
  globalSettings: {
    senderName: action.globalSettings.transactionalEmailSendFrom,
    replyTo: action.globalSettings.transactionalEmailReplyToMailId
  },
  error: null,
});

const getTransactionalEmailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const createTransactionalEmailRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  transactionalEmail: null,
  message: null,
  error: null,
});

const createTransactionalEmailSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  transactionalEmail: action.transactionalEmail,
  message: action.message,
  error: null,
});

const createTransactionalEmailFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const updateTransactionalEmailRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null,
});

const updateTransactionalEmailSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  message: action.message,
  error: null,
});

const updateTransactionalEmailFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const deleteTransactionalEmailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null,
});

const deleteTransactionalEmailsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  transactionalEmails: state.transactionalEmails.filter(
    email => !action.transactionalEmailIds.includes(email._id) 
  ),
  message: action.message,
  error: null,
});

const deleteTransactionalEmailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const updateCompanyGlobalSettingsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null,
});

const updateCompanyGlobalSettingsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  globalSettings: {
    senderName: action.globalSettings.transactionalEmailSendFrom,
    replyTo: action.globalSettings.transactionalEmailReplyToMailId
  },
  message: action.message,
  error: null,
});

const updateCompanyGlobalSettingsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const HANDLERS = {
  [Constants.GET_TRANSACTIONAL_EMAILS_REQUEST]: getTransactionalEmailsRequest,
  [Constants.GET_TRANSACTIONAL_EMAILS_SUCCESS]: getTransactionalEmailsSuccess,
  [Constants.GET_TRANSACTIONAL_EMAILS_FAILURE]: getTransactionalEmailsFailure,

  [Constants.CREATE_TRANSACTIONAL_EMAIL_REQUEST]: createTransactionalEmailRequest,
  [Constants.CREATE_TRANSACTIONAL_EMAIL_SUCCESS]: createTransactionalEmailSuccess,
  [Constants.CREATE_TRANSACTIONAL_EMAIL_FAILURE]: createTransactionalEmailFailure,

  [Constants.UPDATE_TRANSACTIONAL_EMAIL_REQUEST]: updateTransactionalEmailRequest,
  [Constants.UPDATE_TRANSACTIONAL_EMAIL_SUCCESS]: updateTransactionalEmailSuccess,
  [Constants.UPDATE_TRANSACTIONAL_EMAIL_FAILURE]: updateTransactionalEmailFailure,

  [Constants.DELETE_TRANSACTIONAL_EMAILS_REQUEST]: deleteTransactionalEmailsRequest,
  [Constants.DELETE_TRANSACTIONAL_EMAILS_SUCCESS]: deleteTransactionalEmailsSuccess,
  [Constants.DELETE_TRANSACTIONAL_EMAILS_FAILURE]: deleteTransactionalEmailsFailure,

  [Constants.UPDATE_COMPANY_GLOBAL_SETTINGS_REQUEST]: updateCompanyGlobalSettingsRequest,
  [Constants.UPDATE_COMPANY_GLOBAL_SETTINGS_SUCCESS]: updateCompanyGlobalSettingsSuccess,
  [Constants.UPDATE_COMPANY_GLOBAL_SETTINGS_FAILURE]: updateCompanyGlobalSettingsFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
