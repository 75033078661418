import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification, redirectTo } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
import '../../sass/main.scss';
import DashboardLayout from '../../Layouts/DashboardLayout';
import { Button } from '@mui/material';
import SearchIconBar from '../../assets/images/searchIco.png';
import Contact1 from '../../assets/images/MaskGroup2.png';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Pagination from 'react-js-pagination';
import ImportModal from './importModal';
import ContactModal from './contactModal';
import GroupModal from './groupModal';
import UpdateModal from './updateModal';
import AssignGroupModal from '../ContactGroups/assignGroup';
import { DeleteAllContactAPI } from 'ReduxStore/API';
import ImportStatusModal from './importStatusModal';
import { generateCsv, mkConfig, download } from 'export-to-csv';
import { EventsAllAPI } from 'ReduxStore/API';
import { Link } from 'react-router-dom';
import withCustomRouter from '../../Common/withCustomRouter';
import { generateUrl } from 'helpers/common';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false,
      isFilterImports: false,
      title: 'Contact',
      pageTitle: 'Contact',
      open: false,
      errors: {},
      loading: false,
      page_no: 1,
      totalResults: 1,
      groupOpen: false,
      importOpen: false,
      assignGroupOpen: false,
      updateOpen: false,
      singleData: {},
      contacts: [],
      allChecked: false,
      list: [],
      showDelete: false,
      searchText: '',
      firstChar: '',
      contacteditPopup: true,
      filter_by: 'byAll',
      listAll: [],
      imported: 0,
      rejected: 0,
      importOpenModal: false,
      events: [],
    };
  }

  componentDidMount() { 
    if (this.props) {
      this.props.groupRequest({ permission: 'View Audience' });
      const requestbody = {
        page_no: this.state.page_no,
        filter_by: this.state.filter_by,
      };
      this.props.listContactRequest(requestbody);
      this.props.listAllContactRequest(requestbody);
      this.allEventsApiCall();
      this.handleLoading();
    }
  }

  allEventsApiCall = () => {
    EventsAllAPI()
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({ events: result.data.events });
        } else {
          createNotification('error', result.data.message);
        }
      })
      .catch(console.log);
  };

  async componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;

    if (
      this.props.contacts.success === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts.message);
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({ totalResults: this.props.contacts.totalResults });
      this.setState((prevState) => {
        let { list, listAll } = prevState;
        list = list.map((item) => ({ ...item, isChecked: false }));
        listAll = listAll.map((item) => ({ ...item, isChecked: false }));

        return { list, listAll };
      });
      this.setState({ showDelete: false, allChecked: false });
      this.props.listAllContactRequest({
        page_no: this.state.page_no,
        filter_by: this.state.filter_by,
      });
    }

    if (
      this.props.contacts.listSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({ totalResults: this.props.contacts.totalResults });
    }

    if (
      this.props.contacts.importSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        importOpen: false,
        imported: this.props.contacts.imported,
        rejected: this.props.contacts.rejected,
        importOpenModal: true,
      });
      this.props.listContactRequest({
        page_no: this.state.page_no,
        filter_by: this.state.filter_by,
      });
      this.handleLoading();
    }

    if (
      this.props.contacts.contactSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        open: false,
      });
      this.props.listContactRequest({
        page_no: this.state.page_no,
        filter_by: this.state.filter_by,
      });
      this.props.listAllContactRequest({
        page_no: this.state.page_no,
        filter_by: this.state.filter_by,
      });
    }
    if (
      this.props.contacts.groupSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        groupOpen: false,
        imported: this.props.contacts.imported,
        rejected: this.props.contacts.rejected,
        importOpenModal: true,
      });

    }
    if (
      this.props.contacts.updateSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        updateOpen: false,
        assignGroupOpen: false,
        showDelete: false,
        allChecked: false,
      });
      let requestbody = { page_no: this.state.page_no };
      this.props.listContactRequest(requestbody);
      this.props.listAllContactRequest(requestbody);
      createNotification('success', this.props.contacts.message);
    }

    if (
      this.props.contacts.contacts &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      let data = await this.f1();
      this.setState({ list: data });
    }

    if (
      this.props.contacts.listAllSuccess &&
      this.props.contacts.allContactsList &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      let allData = await this.all(this.props.contacts.allContactsList);
      this.setState({ listAll: allData });
    }

    if (
      this.props.contacts.error === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }
    if (
      this.props.contacts.listError === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }

    if (
      this.props.contacts.searchError === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }
  }

  f1 = async () => {
    //var contacts = [];
    let data =
      this.props.contacts.contacts &&
      this.props.contacts.contacts.map((data, index) => {
        let groups = '';
        if (data.groupId) {
          groups = '';
          if (Array.isArray(data.groupId) && data.groupId.length > 0) {
            groups = data.groupId.map((groupData) => {
              return (groups = groupData.groupName);
            });

          }
        }

        return {
          firstName: data.firstName,
          lastName: data.lastName,
          _id: data._id,
          isChecked: false,
          email: data.email,
          company: data.company,
          profilePic: data.profilePic,
          groupName: groups === '' ? 'N/A' : groups.join(),
        };
      });

    return await data;
  };

  all = async () => {
    //var contacts = [];
    let data =
      this.props.contacts.allContactsList &&
      this.props.contacts.allContactsList.map((data, index) => {
        let groups = '';
        if (data.groupId) {
          groups = '';
          if (Array.isArray(data.groupId) && data.groupId.length > 0) {
            groups = data.groupId.map((groupData) => {

              return (groups = groupData.groupName);
            });

          }
        }
        return {
          firstName: data.firstName,
          lastName: data.lastName,
          _id: data._id,
          isChecked: false,
          email: data.email,
          company: data.company,
          profilePic: data.profilePic,
          groupName: groups === '' ? 'N/A' : groups.join(),
        };
      });

    return await data;
  };

  handleChangeCheck = async (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    await this.setState((prevState) => {
      let { allChecked, list, listAll } = prevState;
      if (itemName === 'checkAll') {
        allChecked = checked;
        list = list.map((item) => ({ ...item, isChecked: checked }));
        listAll = listAll.map((item) => ({ ...item, isChecked: checked }));
      } else {
        list = list.map((item) =>
          item.email === itemName ? { ...item, isChecked: checked } : item,
        );
        listAll = listAll.map((item) =>
          item.email === itemName ? { ...item, isChecked: checked } : item,
        );
        //allChecked = list.every(item => item.isChecked);
        allChecked = listAll.every((item) => item.isChecked);
      }

      return { list, listAll, allChecked };
    });

    let data = await this.state.listAll.map((item) => {
      if (true === item.isChecked) {
        return item._id;
      }
    });
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined;
    });

    ids.length > 0
      ? this.setState({ showDelete: true })
      : this.setState({ showDelete: false });
  };

  onOpenImportStatusModal = () => {
    this.setState({ importOpenModal: true });
  };

  onCloseImportStatusModal = () => {
    this.setState({ importOpenModal: false });
  };
  onOpenAssignGroup = () => {
    this.setState({ assignGroupOpen: true });
  };

  onCloseAssignGroup = () => {
    this.setState({ assignGroupOpen: false });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenGroupModal = () => {
    this.setState({ groupOpen: true });
  };

  onCloseGroupModal = () => {
    this.setState({ groupOpen: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handlePageChange = (pageNumber) => {

    this.setState({ page_no: pageNumber });
    this.props.listContactRequest({
      page_no: pageNumber,
      filter_by: this.state.filter_by,
    });
    this.handleLoading();
  };

  FilterOpen = () => {
    this.setState((prevState) => ({
      isFilterVisible: !prevState.isFilterVisible,
    }));
  };
  ImportsOpen = () => {
    this.setState((prevState) => ({
      isFilterImports: !prevState.isFilterImports,
    }));
  };

  handleLoading = () => {

    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  openImportModal = () => {
    this.setState({ importOpen: true });
  };

  onCloseImportModal = () => {
    this.setState({ importOpen: false });
  };

  onCloseUpdateModal = () => {
    this.setState({ updateOpen: false });
  };
  openUpdateModal = (data) => {
    this.setState({ updateOpen: true, singleData: data });
  };

  deleteContact = (id) => {
    this.props.deleteContactRequest({
      page_no: this.state.page_no,
      id: id,
      permission: 'Delete Audience',
    });
    this.setState({ allChecked: false });
    this.handleLoading();
  };
  deleteAll = () => {
    this.handleLoading();
    DeleteAllContactAPI()
      .then((result) => {
        this.handleLoading();
        if (result.data.status === 200) {
          this.setState({
            contacts: [],
            allContactsList: [],
            totalResults: 0,
            list: [],
            listAll: [],
          });
          createNotification('success', result.data.message);
          this.props.listContactRequest({
            page_no: this.state.page_no,
            filter_by: this.state.filter_by,
          });
        } else {
          createNotification('error', result.data.message);
        }
      })
      .catch(console.log);
  };
  deleteCheck = async () => {
    let data = await this.state.listAll.map((item) => {
      if (true === item.isChecked) {
        return item._id;
      }
    });
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined;
    });

    this.props.deleteManyContactRequest({
      ids: ids,
      page_no: this.state.page_no,
      permission: 'Delete Audience',
    });
    this.handleLoading();
  };

  filterBy = (filterBy) => {
    this.props.listContactRequest({
      page_no: this.state.page_no,
      filter_by: filterBy,
    });
    this.setState((prevState) => ({
      isFilterVisible: !prevState.isFilterVisible,
    }));
    this.handleLoading();
  };

  sampleCsv = () => {
    
    var data = [
      {
        firstName: 'value',
        lastName: 'value',
        email: 'value',
        company: 'value',
      },
      {
        firstName: 'value',
        lastName: 'value',
        email: 'value',
        company: 'value',
      },
      {
        firstName: 'value',
        lastName: 'value',
        email: 'value',
        company: 'value',
      },
    ];

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Sample Csv',
      useTextFile: false,
      useBom: false,
      useKeysAsHeaders: true,
      filename: 'sample',
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new generateCsv(options);
    if (data.length > 0) {
      csvExporter.generateCsv(data);
    }
  };

  assignGroup = async () => {
    let data = await this.state.listAll.map((item) => {
      if (true === item.isChecked) {
        return item._id;
      }
    });
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined;
    });


    let requestbody =
      this.state.group_id !== ''
        ? {
          ids: ids,
          page_no: this.state.page_no,
          group_id: this.state.group_id,
        }
        : { ids: ids, page_no: this.state.page_no };
    this.setState({ ids: ids, assignGroupOpen: true });
    //this.props.deleteManyContactRequest(requestbody);
  };

  matchArray = () => {
    let newArray = [];
    let list = this.state.list;
    let listAll = this.state.listAll;
    for (let item of list) {
      for (let each of listAll) {
        if (item.email === each.email && each.isChecked === true) {
          newArray.push(each);
        } else if (item.email === each.email && each.isChecked === false) {
          newArray.push(item);
        }
      }
    }
    return newArray;
  };

  renderList = () => {
    let finalArray = this.matchArray();
    if (finalArray.length > 0) {
      let dataCharArray = [];
      return finalArray.map((data, index) => {
        let dataChar = data.firstName.charAt(0);
        dataCharArray.push(dataChar);
        return (
          <tr key={index}>
            <td className="tableUserInfo">
              <span className="tableAlphabet">
                {index !== 0
                  ? dataCharArray[index].toUpperCase() !==
                    dataCharArray[index - 1].toUpperCase()
                    ? dataCharArray[index]
                    : null
                  : dataCharArray[index]}
              </span>
              <label className="checkBoxColl">
                <input
                  key={data._id}
                  type="checkbox"
                  name={data.email}
                  value={data.email}
                  checked={data.isChecked}
                  onChange={this.handleChangeCheck}
                />
                <span className="checkmark"></span>
              </label>
              {data.profilePic && data.profilePic !== '' ? (
                <img
                  src={process.env.REACT_APP_IMAGE_URL + data.profilePic}
                  alt=""
                />
              ) : (
                <span className="listLetterContact">
                  {data.firstName.charAt(0) + data.lastName.charAt(0)}
                </span>
              )}

              <span className="eventName">
                {data.firstName + ' ' + data.lastName}
              </span>
            </td>
            <td>{data.company}</td>
            <td>{data.email}</td>
            <td className="userStatus">{data.groupName}</td>
            <td className="tableDotMore">
              <button className="tableDots">
                <span className="tableDotsExpo"></span>
                <span className="tableDotsExpo"></span>
                <span className="tableDotsExpo"></span>
              </button>
              <div className="contacteditPopup">
                <p>
                  {' '}
                  <span onClick={() => this.openUpdateModal(data)}>Edit</span>
                </p>
                <p>
                  <span
                    onClick={() => {
                      if (window.confirm('Delete the contact?')) {
                        this.deleteContact(data._id);
                      }
                    }}
                  >
                    Delete
                  </span>
                </p>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (
      this.state.searchText !== '' &&
      this.state.searchText.trim().length > 0
    ) {
      const { searchText, page_no } = this.state;
      const requestbody = { searchText, page_no };

      this.props.searchContactRequest(requestbody);
      this.handleLoading();
    } else {
      this.props.listContactRequest({ page_no: this.state.page_no });
      this.handleLoading();
    }
  };

  exportCsv = (id) => {

    if (
      this.props.contacts.allContactsList &&
      Array.isArray(this.props.contacts.allContactsList)
    ) {
      let data = [];
      const { allContactsList } = this.props.contacts;
      this.props.contacts.allContactsList.map((item) => {
        let groups = '';
        if (item.groupId) {
          groups = '';
          if (Array.isArray(item.groupId) && item.groupId.length > 0) {
            groups = item.groupId.map((groupData) => {

              return (groups = groupData.groupName);
            });
          }
        }

        let d = {
          firstName: item.firstName || 'N/A',
          lastName: item.lastName || 'N/A',
          email: item.email,
          company: item.company || 'N/A',
          group: groups === '' ? 'N/A' : groups.join(),
        };
        data.push(d);
      });

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Contacts Csv',
        useTextFile: false,
        useBom: false,
        useKeysAsHeaders: true,
        filename: 'contacts',
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };

      const csvExporter = new download(options);
      if (data.length > 0) {
        csvExporter.generateCsv(data);
      }
    }
  };

  exportXsl = () => {

  };

  manageGroup = () => {
    this.props.navigate(generateUrl('/audiencebkp'));
  };

  render() {
    const {
      isFilterVisible,
      isFilterImports,
      title,
      pageTitle,
      open,
      loading,
      page_no,
      totalResults,
      groupOpen,
      importOpen,
      updateOpen,
      singleData,
      showDelete,
      searchText,
      errors,
      importOpenModal,
      imported,
      rejected,
      assignGroupOpen,
      ids,
      events,
    } = this.state;

    let exportData = [];
    if (
      this.props.contacts.allContactsList &&
      Array.isArray(this.props.contacts.allContactsList)
    ) {
      const { allContactsList } = this.props.contacts;
      this.props.contacts.allContactsList.map((item) => {
        let groups = '';
        if (item.groupId) {
          groups = '';
          if (Array.isArray(item.groupId) && item.groupId.length > 0) {
            groups = item.groupId.map((groupData) => {

              return (groups = groupData.groupName);
            });
          }
        }
        let d = {
          firstName: item.firstName || 'N/A',
          lastName: item.lastName || 'N/A',
          email: item.email,
          company: item.company || 'N/A',
          group: groups === '' ? 'N/A' : groups.join(),
        };
        exportData.push(d);
      });
    }

    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          <div className="filterSearchBar">
            <div className="filterControler SearchGrid">
              <div className="eventControlSearch mr-2">
                <form onSubmit={this.handleSubmit}>
                  <div className="filterSerchSubmit">
                    <button type="submit">
                      <img src={SearchIconBar} alt="" />
                    </button>
                    <input
                      type="text"
                      placeholder="Search......"
                      name="searchText"
                      value={searchText}
                      onChange={this.handleChange}
                    />
                  </div>
                  <p className="error_mesage"> {errors.searchText} </p>
                </form>
              </div>
              <div
                className={`eventControlfilter ${isFilterVisible ? 'Open' : ''
                  }`}
              >
                <button
                  className="eventControlBtn"
                  type="button"
                  onClick={this.FilterOpen}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.222"
                    height="17.5"
                    viewBox="0 0 19.222 17.5"
                  >
                    <path
                      id="filter"
                      d="M19.222,3H2l6.889,8.146v5.632L12.333,18.5V11.146Z"
                      transform="translate(-1 -2)"
                      fill="none"
                      stroke="#3f3e43"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                  </svg>
                </button>
                <div className="dropdown-menu">
                  <Link
                    className="dropdown-item"
                    onClick={() => this.filterBy('byAll')}
                  >
                    All
                  </Link>
                  <Link
                    className="dropdown-item"
                    onClick={() => this.filterBy('byGroup')}
                  >
                    By Group
                  </Link>
                  <Link
                    className="dropdown-item"
                    onClick={() => this.filterBy('byList')}
                  >
                    By List
                  </Link>
                </div>
              </div>
            </div>
            <div className="filterControler filterImport mobile-tablet-None buttonGroups">
              <div className="eventControlList importExportBtn mr-2">
                <a onClick={this.openImportModal}>Import from CSV</a>
                <a onClick={this.exportCsv}>Export as CSV</a>
                {/*<a onClick={this.exportXsl}>Export as Excel</a>*/}
                {/* {exportData.length > 0 && (
                  <ExcelFile
                    filename="contacts"
                    element={
                      <button className="exportBtn">Export as Excel</button>
                    }
                  >
                    <ExcelSheet data={exportData} name="Contact List">
                      <ExcelColumn label="firstName" value="firstName" />
                      <ExcelColumn label="lastName" value="lastName" />
                      <ExcelColumn label="email" value="email" />
                      <ExcelColumn label="company" value="company" />
                      <ExcelColumn label="audience" value="group" />
                    </ExcelSheet>
                  </ExcelFile>
                )} */}
              </div>
              <Button
                type="button"
                className="outlineButton mr-2"
                onClick={this.manageGroup}
              >
                Manage Audience
              </Button>
              <Button
                type="button"
                className="outlineButton mr-2"
                onClick={this.onOpenGroupModal}
              >
                Add Audience
              </Button>

              <Button
                type="button"
                className="themeBlueButton mr-2"
                onClick={this.onOpenModal}
              >
                Add Contact
              </Button>
              <Button
                type="button"
                className="themeBlueButton"
                onClick={() =>
                  redirectTo(
                    this.props.navigate,
                    'importlogs',
                    this.props.location.pathname,
                  )
                }
              >
                Email Import Logs
              </Button>
            </div>
            <div className="filterControler middleFilterCont OnlyDesktopNone">
              <a
                onClick={this.manageGroup}
                className="exportEvent ctrlBtn mr-2"
              >
                <i className="fa fa-arrow-circle-up"></i>
              </a>
              <a onClick={this.onOpenModal} className="newEvent ctrlBtn">
                <i className="fa fa-plus"></i>
              </a>
            </div>
          </div>
          <div className="sampleCSV">
            <span onClick={this.sampleCsv}>Download Sample Csv to import</span>
            <span>&nbsp; | &nbsp;</span>
            <span
              onClick={() => {
                if (window.confirm('Delete the contacts?')) {
                  this.deleteAll();
                }
              }}
            >
              Delete All
            </span>
          </div>
          <div className="myEventTable contactsTable">
            {this.props.contacts.contacts &&
              this.props.contacts.contacts.length > 0 && (
                <div className="eventTableInner table-responsive contactTableInner">
                  <table className="table contactTableInner">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">Email</th>
                        <th scope="col" colSpan="2">
                          Audience
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tableBodyC">
                      <div className="checkedAllSection">
                        <label className="checkBoxColl">
                          <input
                            type="checkbox"
                            name="checkAll"
                            checked={this.state.allChecked}
                            onChange={this.handleChangeCheck}
                          />
                          <span className="checkmark"></span>
                          <span className="selectAllName">
                            Select All (
                            {this.props.contacts.allContactsList
                              ? this.props.contacts.allContactsList.length
                              : 0}{' '}
                            records)
                          </span>
                        </label>
                        {showDelete ? (
                          <>
                            <button
                              onClick={() => {
                                if (window.confirm('Delete the contact?')) {
                                  this.deleteCheck();
                                }
                              }}
                            >
                              Delete checked
                            </button>
                            <button onClick={this.assignGroup}>
                              Assign Audience
                            </button>
                          </>
                        ) : null}
                        {/* <input
                                        type="checkbox"
                                        name="checkAll"
                                        checked={this.state.allChecked}
                                        onChange={this.handleChangeCheck}
                                        /> 
                                        <span> Check all </span>  */}
                      </div>
                      {this.renderList()}
                    </tbody>
                  </table>
                </div>
              )}

            {totalResults > 50 ? (
              <div className="tablePagination">
                <Pagination
                  activePage={page_no}
                  itemsCountPerPage={50}
                  totalItemsCount={totalResults}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                  prevPageText="Prev"
                  // firstPageText="First"
                  // lastPageText="Last"
                  nextPageText="Next"
                />
              </div>
            ) : null}
          </div>
        </div>

        <Modal open={open} onClose={this.onCloseModal} center>
          <ContactModal page_no={page_no} events={events} />
        </Modal>

        <Modal open={groupOpen} onClose={this.onCloseGroupModal} center>
          <GroupModal page_no={page_no} />
        </Modal>

        <Modal open={importOpen} onClose={this.onCloseImportModal} center>
          <ImportModal page_no={page_no} />
        </Modal>

        <Modal open={updateOpen} onClose={this.onCloseUpdateModal} center>
          <UpdateModal
            page_no={page_no}
            singleData={singleData}
            events={events}
          />
        </Modal>

        <Modal
          open={importOpenModal}
          onClose={this.onCloseImportStatusModal}
          center
        >
          <ImportStatusModal imported={imported} rejected={rejected} />
        </Modal>
        <Modal open={assignGroupOpen} onClose={this.onCloseAssignGroup} center>
          <AssignGroupModal
            page_no={page_no}
            groups={this.props.groups.groups}
            ids={ids}
          />
        </Modal>

        <NotificationContainer />
        {loading ? <Loader /> : null}
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  groups: state.Groups,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      groupRequest: Actions.groupRequest,
      listContactRequest: Actions.listContactRequest,
      listAllContactRequest: Actions.listAllContactRequest,
      deleteContactRequest: Actions.deleteContactRequest,
      deleteManyContactRequest: Actions.deleteManyContactRequest,
      searchContactRequest: Actions.searchContactRequest,
      csvRequest: Actions.csvRequest,
      xslRequest: Actions.xslRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(Contact));
