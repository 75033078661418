import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    eijentNotificationListRequest: ['data'], // Action for initiating the request, passing payload data
    eijentNotificationListSuccess: ['notificationList','totalResults'], // Action for a successful response, passing the notification list
    eijentNotificationListError: ['error'], // Action for handling errors, passing error details
    eijentNotificationListReset: ['action'], // Action to reset the notification list
    markNotificationRequest: ['data'], // Action for initiating the request, passing payload data
    markNotificationSuccess: ['message'], // Action for a successful response, passing the notification list
    markNotificationError: ['error'], // Action for handling errors, passing error details
    toasterNotificationListRequest: ['data'], // Action for initiating the request, passing payload data
    toasterNotificationListSuccess: ['toasterNotificationList','toasterNotificationNormal'], // Action for a successful response, passing the notification list
    toasterNotificationListError: ['error'], // Action for handling errors, passing error details
    markNotificationAsShown: ['payload'],
    setNotificationPopupOpen: ['isOpen'],
    toasterNotificationCloseRequest: ['data'], // Action for initiating the request, passing payload data
    toasterNotificationCloseSuccess: ['message'], // Action for a successful response, passing the notification list
    toasterNotificationCloseError: ['error'], // Action for handling errors, passing error details
});
export const Constants = Types
export default Creators