import React from 'react';
import { IconButton, SelectChangeEvent } from '@mui/material';
import { Stack } from '@mui/material';
import { CancelIcon } from '../../../components/CustomIcons';
import FilterTypeSelect from '../filterComponents/FilterTypeSelect';
import OperatorSelect from '../filterComponents/OperatorSelect';
import ValueSelect from '../filterComponents/ValueSelect';
import Grid from '@mui/material/Grid2';

interface FilterRowProps {
  filterType: { value: string, label: string, type: string, isCustomField: boolean; };
  operator: string;
  value: string[] | string;
  onFilterTypeChange: (event: SelectChangeEvent<unknown>, filterType?: string, isCustomField?: boolean, label?: string) => void;
  onOperatorChange: (event: SelectChangeEvent<unknown>) => void;
  onValueChange: (event: SelectChangeEvent<unknown>, filterType?: string, isCustomField?: boolean, label?: string) => void;
  onDelete: () => void;
}

export default function FilterRow({
  filterType,
  operator,
  value,
  onFilterTypeChange,
  onOperatorChange,
  onValueChange,
  onDelete,
}: FilterRowProps) {
  return (
    <Grid
      container
      spacing={1}
      width={'100%'}
    >
      <Grid size={4}>
        <FilterTypeSelect
          value={filterType.label}
          onChange={onFilterTypeChange}
        />
      </Grid>
      <Grid size={"grow"}>
        <OperatorSelect
          value={operator}
          onChange={onOperatorChange}
          filterType={filterType}
        />
      </Grid>
      <Grid size={4}>
        <ValueSelect
          value={value}
          onChange={onValueChange}
          filterType={filterType}
          operator={operator}
        />
      </Grid>
      <Grid size={"auto"} display={'flex'} alignItems={'center'}>
        <IconButton
          size="small"
          variant="contained"
          // @ts-ignore
          color="transparent"
          onClick={onDelete}
        >
          <CancelIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
} 