//@ts-nocheck
import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../../theme/theme';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import EditBtn from '../../../assets/images/icons/edit.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import {
    OpportunityState,
} from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import moment from 'moment';
import Loader from 'Components/Loader/loader';
import { generateUrl } from 'helpers/common';

type Props = {
    closeTransactionEmailsModal: (transactionEmailsComp: boolean) => void;
};

export default function OpportunityTransactionalEmails({ closeTransactionEmailsModal }: Props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { templates, isLoading } = useSelector(
        (state: any) => state.OpportunityOpportunities,
    ) as OpportunityState;

    const openEditor = () => {
        navigate(generateUrl(`/opportunity/opportunity/update-transactional-email-templates`));
    };

    useEffect(() => {
        listTransactionEmail();
    }, []);

    const listTransactionEmail = () => {
        dispatch(Actions.listTransactionEmailRequest());
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className="tab-container">
                <Grid container spacing={2} mb={3} className="top-filter-area">
                    <Grid >
                        <Grid container alignItems="center">
                            <IconButton size='small' aria-label="back">
                                <KeyboardBackspaceIcon
                                    onClick={() => closeTransactionEmailsModal(false)}
                                    style={{ color: theme.palette.primary.dark }}
                                />
                            </IconButton>
                            <p className='subtitle' style={{ color: theme.palette.grey[800], marginLeft: 10 }}></p>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer className="list-table">
                    <Table className="table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='name-avatar sort-asc'>Template Name</TableCell>
                                <TableCell className='long-text sort-asc'>Subject</TableCell>
                                <TableCell className='long-text sort-asc'>Last Updated On</TableCell>
                                <TableCell className='options'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(templates) && templates.length ? (
                                templates.map((temp) => (
                                    <TableRow key={temp._id}>
                                        <TableCell className='name-avatar'>{temp.templateName}</TableCell>
                                        <TableCell className='long-text'>{temp.emailSubject}</TableCell>
                                        <TableCell className='long-text'>
                                            {moment(temp.updatedAt).format('MMM, Do YYYY, h:mm A')}
                                        </TableCell>
                                        <TableCell className='options'>
                                            <Box sx={{ textAlign: 'center' }}>
                                                    <img alt="Edit" src={EditBtn}
                                                    onClick={() => navigate(generateUrl(`/opportunity/opportunity/update-transactional-email-templates`), { state: { temp } })} 
                                                     />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        There are no records to display
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {isLoading && <Loader />}
        </ThemeProvider>
    );
}
