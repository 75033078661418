import BaseAPI from '../baseAPI';


class OpportunityOpportunitiesAPI extends BaseAPI {
    fetchAllOpportunityOpportunities = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunities/fetchAllOpportunities', {
            method: 'POST',
            params: queryParams,
            data: bodyParams
        })
    }

    fetchOpportunityOpportunity =  async (params: object) => {
        return this.API('opportunityManagement/opportunities/fetchOpportunity', {
            method: 'GET',
            params
        })
    }

    createOpportunityOpportunity =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunities/createOpportunity', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }
    
    updateOpportunityOpportunity =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunities/updateOpportunity', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }

    deleteOpportunityOpportunity =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunities/delete', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }
    updateMultipleOpportunityOpportunities =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunities/updateMultiple', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }

    export = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunities/export', {
            method: 'POST',
            params: queryParams,
            data: bodyParams
        })
    }

    downloadSampleCSVFile = async () => {
        return this.API('opportunityManagement/opportunities/downloadSampleCSVFile', {
            method: 'POST',
        });
    };

    checkValidationForCsvImportFile = async (data: FormData, queryParams : {
        keyFieldForOpportunities: string,
        keyFieldForContacts: string,
        keyFieldForAccounts: string,
      }) => {
        return this.API('opportunityManagement/opportunities/checkValidationForCsvImportFile', {
            method: 'POST',
            data: data,
            params: queryParams,
            headers: {'Content-Type': 'multipart/form-data'},
        });
    }

    importCsvDataAfterValidationIsDone = async (queryParams: {
        keyFieldForOpportunities: string,
        keyFieldForAccounts: string,
        keyFieldForContacts: string,
        skipExistingOpportunities: boolean,
        skipExistingAccounts: boolean,
        skipExistingContacts: boolean,
    }) => {
        return this.API('opportunityManagement/opportunities/importCsvDataAfterValidationIsDone', {
            method: 'POST',
            params: queryParams
        });
    }
    

    listTransactionEmail =  async (params: object) => {
        return this.API('/opportunityManagement/opportunities/listTransactionEmail', {
            method: 'GET',
            params
        })
    }

    updateTransactionEmail =  async (data: object) => {
        return this.API('/opportunityManagement/opportunities/updateTransactionEmail', {
            method: 'POST',
            data: data
        })
    }

    updateContactDataTypeCustomField = async (data: object) => {
        return this.API('/opportunityManagement/opportunities/updateContactDataTypeCustomField', {
            method: 'POST',
            data: data
        });
    }
    checkIfNameAlreadyExists = async (data: object) => {
        return this.API('/opportunityManagement/opportunities/checkIfNameAlreadyExists', {
            method: 'POST',
            data: data
        });
    }

    fetchOpportunityById =  async (params: object) => {
        return this.API('opportunityManagement/opportunity/fetchById', {
            method: 'GET',
            params
        })
    }

    updateOneField =  async (data: object) => {
        return this.API('/opportunityManagement/opportunities/updateOneField', {
            method: 'PATCH',
            data: data
        })
    }
}

export default OpportunityOpportunitiesAPI