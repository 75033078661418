import {
  Box,
  ClickAwayListener,
  Stack,
  IconButton,
  TableCell,
  Button,
  Radio,
  FormControlLabel,
  RadioGroup,
  Tooltip,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { formatDateField } from 'helpers/common';
import {
  AccountCf,
  ContactCf,
  Opportunity,
} from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import { CustomField } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import { permissionsConstants } from 'constants/permissionsConstant';
// @ts-ignore
import { useRef, useState } from 'react';
import OpportunityOpportunitiesAPI from 'ReduxStore/API/opportunity/opportunityOpportunitiesAPI';
import {
  isValidEmail,
  isValidURL,
  validateSocialMediaUrl,
} from '../../../../helpers/common';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhoneInput from 'react-phone-number-input';
import { createNotification } from 'helpers';
import EditableTextField from '../../components/EditableTextField';
import LinkIcon from '@mui/icons-material/Link';
// Interface defining props required for CustomFieldCell component
interface CustomFieldCellProps {
  cf: CustomField; // Custom field configuration
  opp: Opportunity; // Opportunity data
  customFields: CustomField[]; // Array of all custom fields
  authPermissionValidation: (permissions: string[]) => boolean; // Function to check user permissions
}

export const CustomFieldCell: React.FC<CustomFieldCellProps> = ({
  cf,
  opp,
  customFields,
  authPermissionValidation,
}) => {
  const navigate = useNavigate();
  const textFieldRef = useRef('');

  const [opportunity, setOpportunity] = useState(opp);
  const [showInputField, setShowInputField] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [phoneFieldValue, setPhoneFieldValue] = useState<string | null>(null);
  const [error, setError] = useState('');

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    // Ensure click away only closes when clicking outside the TextField container
    const target = event.target as HTMLElement;
    if (target.closest('#textfield-container')) return;
    setShowInputField(false);
  };

  // Handles clicks on field values (e.g., opening links, navigating to details)
  const handleFieldClick = (fieldValue: any) => {
    if (!fieldValue) return;

    switch (cf.fieldData.fieldType) {
      case 'contact':
        // Handle navigation to contact details with permission check
        if (
          authPermissionValidation([`${permissionsConstants.viewContacts}`])
        ) {
          if (fieldValue?.uniqueEijentId) {
            navigate(
              `/xp/opportunity/contactdetail/${fieldValue.uniqueEijentId}`,
            );
          }
        } else {
          createNotification(
            'error',
            "Sorry! You don't have permission to view this contact",
          );
        }
        break;
      case 'account':
        // Handle navigation to account details with permission check
        if (authPermissionValidation([`${permissionsConstants.viewAccount}`])) {
          if (fieldValue?.uniqueEijentId) {
            navigate(
              `/xp/opportunity/accountview/${fieldValue.uniqueEijentId}`,
            );
          }
        } else {
          createNotification(
            'error',
            "Sorry! You don't have permission to view this contact",
          );
        }
        break;
      // Handle various social/web links
      case 'url':
      case 'facebook':
      case 'instagram':
      case 'x':
      case 'pinterest':
      case 'linkedin':
        let url = fieldValue;
        if (!/^https?:\/\//i.test(url)) {
          url = 'https://' + url;
        }
        window.open(url);
        break;
      case 'email':
        window.open(`mailto:${fieldValue}`);
        break;
      case 'phone':
        window.open(`tel:${fieldValue}`);
        break;
      default:
        console.warn(`Unhandled field type: ${cf.fieldData.fieldType}`);
    }
  };

  // Determines CSS classes for clickable fields based on type and permissions
  const getLinkableFieldClass = () => {
    const isLinkableField = [
      'account',
      'contact',
      'url',
      'email',
      'facebook',
      'instagram',
      'x',
      'pinterest',
      'linkedin',
      'phone',
    ].includes(cf.fieldData.fieldType);

    const hasPermission =
      authPermissionValidation([`${permissionsConstants.viewContacts}`]) ||
      authPermissionValidation([`${permissionsConstants.viewAccount}`]);

    const hasValue = opportunity?.customFields?.[cf._id as string];

    return `editable-cell custom-field ${
      isLinkableField && hasPermission && hasValue ? 'link' : ''
    }`;
  };

  // text, number, date, url, email, list, boolean, phone, longText, user, contact, account, linkedIn, facebook, instagram, x, pinterest
  // Renders the field value based on its type with appropriate formatting
  const renderFieldValue = () => {
    const fieldValue = opportunity?.customFields?.[cf._id as string];

    switch (cf.fieldData.fieldType) {
      case 'contact':
        return (fieldValue as ContactCf)?.fullName || '-';
      case 'account':
        return (fieldValue as AccountCf)?.name || '-';
      case 'date':
        return fieldValue
          ? formatDateField(opportunity, cf, customFields)
          : null;
      case 'boolean':
        return fieldValue?.toString() === 'true'
          ? 'True'
          : fieldValue?.toString() === 'false'
          ? 'False'
          : null;
      default:
        // Handle generic field types with basic validation
        if (
          fieldValue === undefined ||
          fieldValue === null ||
          fieldValue === ''
        ) {
          return null;
        }
        if (typeof fieldValue !== 'string' && typeof fieldValue !== 'number') {
          return 'Invalid Data';
        }
        return fieldValue;
    }
  };

  const isValid = () => {
    const validationRules = {
      email: {
        validator: isValidEmail,
        errorMsg: 'Invalid email',
      },
      url: {
        validator: isValidURL,
        errorMsg: 'Invalid url',
      },
      facebook: {
        validator: (value: string) => validateSocialMediaUrl(value, 'facebook'),
        errorMsg: 'Invalid facebook url',
      },
      instagram: {
        validator: (value: string) =>
          validateSocialMediaUrl(value, 'instagram'),
        errorMsg: 'Invalid instagram url',
      },
      x: {
        validator: (value: string) => validateSocialMediaUrl(value, 'x'),
        errorMsg: 'Invalid x url',
      },
      pinterest: {
        validator: (value: string) =>
          validateSocialMediaUrl(value, 'pinterest'),
        errorMsg: 'Invalid pinterest url',
      },
      linkedin: {
        validator: (value: string) => validateSocialMediaUrl(value, 'linkedin'),
        errorMsg: 'Invalid linkedin url',
      },
      number: {
        validator: (value: string) => {
          const numericValue = parseFloat(value);
          return !isNaN(numericValue) && isFinite(numericValue);
        },
        errorMsg: 'Invalid number',
      },
    };

    const rule =
      validationRules[cf.fieldData.fieldType as keyof typeof validationRules];

    if (rule) {
      const isValid = rule.validator(textFieldValue);
      setError(isValid ? '' : rule.errorMsg);
      return isValid;
    }

    // Default case for other field types
    // const isValid = textFieldValue.length > 0;
    // setError(isValid ? '' : 'Invalid text');
    return isValid;
  };

  const handleUpdateOneField = async (e?: any) => {
    if (
      [
        'text',
        'number',
        'url',
        'email',
        'facebook',
        'instagram',
        'x',
        'pinterest',
        'linkedin',
        'longText',
      ].includes(cf.fieldData.fieldType) &&
      textFieldRef.current === textFieldValue
    ) {
      setShowInputField(false);
      setError('');
      return;
    }
    if (
      cf.fieldData.fieldType === 'phone' &&
      textFieldRef.current === phoneFieldValue
    ) {
      setShowInputField(false);
      setError('');
      return;
    }
    if (!isValid()) {
      return;
    }

    const opportunityBeforeUpdate = { ...opportunity };
    try {
      // Optimistic update
      setOpportunity({
        ...opportunity,
        customFields: {
          ...opportunity.customFields,
          [cf._id]:
            cf.fieldData.fieldType === 'boolean'
              ? e.target.value === 'true'
                ? true
                : false
              : cf.fieldData.fieldType === 'phone'
              ? phoneFieldValue
              : cf.fieldData.fieldType === 'date'
              ? e.target.value
              : textFieldValue,
        },
      });
      showInputField && setShowInputField(false);

      const data = {
        opportunityId: opportunity._id,
        field: `customFields.${[cf._id]}`,
        value:
          cf.fieldData.fieldType === 'boolean'
            ? e.target.value === 'true'
              ? true
              : false
            : cf.fieldData.fieldType === 'phone'
            ? phoneFieldValue
            : cf.fieldData.fieldType === 'date'
            ? new Date(e.target.value).toISOString()
            : textFieldValue,
      };
      const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
      const response = await opportunityOpportunitiesAPI.updateOneField(data);
      if (response.status === 200) {
        if (response.data.success) {
          setOpportunity(response.data.opportunity);
          setShowInputField(false);
        }
      }
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setOpportunity(opportunityBeforeUpdate);
      createNotification('error', error.message);
    }
  };

  const handleOpenEditField = (fieldValue: any, fieldType: string) => {
    setShowInputField(true);
    if (fieldType === 'phone') {
      setPhoneFieldValue(fieldValue);
      textFieldRef.current = fieldValue;
    } else {
      setTextFieldValue(fieldValue);
      textFieldRef.current = fieldValue;
    }
  };

  const textField = (
    <EditableTextField
      value={textFieldValue}
      onChange={(e) => setTextFieldValue(e.target.value)}
      onSave={handleUpdateOneField}
      error={error}
      type={cf.fieldData.fieldType === 'number' ? 'number' : 'text'}
      placeholder="Enter here"
      fieldType={cf.fieldData.fieldType}
    />
  );

  const datePicker = (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className="date-picker-with-icon form-control"
        style={{ position: 'relative', width: '100%' }}
      >
        <ReactDatePicker
          selected={
            opportunity?.customFields?.[cf._id as string]
              ? new Date(
                  opportunity?.customFields?.[cf._id as string] as string,
                )
              : null
          }
          onChange={(date: Date) => {
            handleUpdateOneField({ target: { value: date } });
          }}
          id={`date-picker-${[cf._id]}`}
          dateFormat="dd/MM/yyyy"
          placeholderText="DD/MM/YYYY"
          className="form-control small"
          wrapperClassName="datepicker-wrapper"
          popperProps={{
            strategy: 'fixed',
          }}
          popperClassName="custom-datepicker-popper"
          customInput={
            <input
              style={{
                width: '100%',
                height: '36px',
                padding: '8px 32px 8px 8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          }
        />
        <CalendarTodayIcon
          fontSize="small"
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none',
          }}
        />
      </div>
    </ClickAwayListener>
  );

  const booleanField = (
    <ClickAwayListener onClickAway={handleClickAway}>
      <RadioGroup
        // @ts-ignore
        aria-label={cf.fieldData.fieldName}
        name={cf.fieldData.fieldName}
        row
        onChange={(e) => handleUpdateOneField(e)}
        value={opportunity?.customFields?.[cf._id as string]?.toString() || ''}
      >
        <FormControlLabel
          value={'true'}
          control={<Radio color="primary" />}
          label="True"
        />
        <FormControlLabel
          value={'false'}
          control={<Radio color="primary" />}
          label="False"
        />
      </RadioGroup>
    </ClickAwayListener>
  );

  const phoneField = (
    <ClickAwayListener
      onClickAway={handleUpdateOneField}
    >
      <Box
        display={'inline-block'}
        id="textfield-container"
        sx={{ minWidth: '200px', position: 'relative' }}
      >
        <PhoneInput
          initialValueFormat="national"
          defaultCountry="US"
          placeholder="Enter phone number"
          className="form-control small"
          value={phoneFieldValue}
          onChange={(e) => setPhoneFieldValue(e)}
          style={{ width: '100%', paddingRight: '40px' }}
        />
      </Box>
    </ClickAwayListener>
  );

  const editField = () => {
    switch (cf.fieldData.fieldType) {
      case 'text':
      case 'number':
      case 'url':
      case 'email':
      case 'facebook':
      case 'instagram':
      case 'x':
      case 'pinterest':
      case 'linkedin':
      case 'longText':
        return textField;
      case 'date':
        return datePicker;
      case 'boolean':
        return booleanField;
      case 'phone':
        return phoneField;
      default:
        return null;
    }
  };

  // Render the table cell with appropriate styling and click handlers
  return (
    <TableCell sx={{ minWidth: '200px' }} className={getLinkableFieldClass()}>
      <Box>
        {showInputField ? (
          editField()
        ) : (
          <Stack direction={'row'} spacing={1}>
            {renderFieldValue() && (
              <Box
                px={1.5}
                py={'8.2px'}
              sx={
                !['contact', 'account'].includes(cf.fieldData.fieldType) &&
                renderFieldValue()
                  ? {
                      width: '100%',
                      border: '1px solid transparent',
                      '&:hover': { borderColor: '#000', borderRadius: '8px' },
                    }
                  : {}
              }
              onClick={() =>
                handleOpenEditField(
                  opportunity?.customFields?.[cf._id as any],
                  cf.fieldData.fieldType,
                )
              }
            >
              <Tooltip title={renderFieldValue()?.length > 20 ? renderFieldValue() : null} arrow placement="bottom">
                <span>
                  {renderFieldValue()?.length > 20 ? renderFieldValue()?.slice(0, 20) + '...' : renderFieldValue()}
                </span>
              </Tooltip>
            </Box>
            )}
            {!['contact', 'account'].includes(cf.fieldData.fieldType) &&
              (renderFieldValue() ? (
                [
                  'url',
                  'facebook',
                  'instagram',
                  'x',
                  'pinterest',
                  'linkedin',
                  'phone',
                  'email',
                ].includes(cf.fieldData.fieldType) && (
                  <IconButton
                    size="small"
                    // @ts-ignore
                    color="transparent"
                    variant="contained"
                    className="edit-icon"
                    onClick={() =>
                      handleFieldClick(
                        opportunity?.customFields?.[cf._id as any],
                      )
                    }
                  >
                    <LinkIcon />
                  </IconButton>
                )
              ) : (
                <Button
                  variant="text"
                  size="small"
                  sx={{ fontSize: '15px', px: 0 }}
                  onClick={() =>
                    handleOpenEditField('', cf.fieldData.fieldType)
                  }
                >
                  + Click to Add
                </Button>
              ))}
          </Stack>
        )}
      </Box>
    </TableCell>
  );
};
