import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, AvatarGroup, ClickAwayListener, IconButton, InputAdornment, MenuItem, Select, Stack, SxProps, TextField, Theme } from "@mui/material";
import Grid from '@mui/material/Grid';
import { EditIcon, RoundCheckIcon, ArrowDownIcon, ArrowDownDarkIcon, } from "../../components/CustomIcons";
import DoneIcon from '@mui/icons-material/Done';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  backgroundColor: 'transparent',
  padding: theme.spacing(0),
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)<AccordionSummaryProps>(({ theme }) => ({
  borderBottom: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderRadius: '0px',
  gap: 8,
  padding: theme.spacing(3, 0),


  '&:hover': {
    backgroundColor: 'transparent',
  },

  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    padding: 10,
    borderRadius: '50px',
    backgroundColor: '#F4F4F4',
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
  {

    transform: 'rotate(-180deg)',
  },

  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(0),
    alignItems: 'center',
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2, 0),

  borderTop: '0px solid rgba(0, 0, 0, .125)',
}));

const OpportunityView: React.FC = () => {
  const [showTextField, setShowTextField] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleClick = () => {
    setShowTextField(true);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    // Ensure click away only closes when clicking outside the TextField container
    const target = event.target as HTMLElement;
    if (target.closest("#textfield-container")) return;
    setShowTextField(false);
  };

  return (
    <Stack sx={{ backgroundColor: "#fff", height: "100%" }}>
      {/* Header */}
      <Box sx={{ borderBottom: "1px solid #D9D9D9", padding: "24px 60px 20px 80px" }}>
        {/* <Typography variant="h2" >
          Name Of The Opportunity
        </Typography> */}
        <Box ml={-1.5}>
          {showTextField ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box display={"inline-block"} width={'100%'} id="textfield-container">
                <TextField
                  variant="outlined"
                  size="small"
                  // value={"Name Of The Opportunity"}
                  type="text"
                  name="name"
                  fullWidth
                  placeholder="Name Of The Opportunity"
                  autoFocus
                  sx={{
                    height: '38px',
                    '& .MuiOutlinedInput-root': { borderColor: 'transparent', backgroundColor: '#fff' },
                    '& input': { fontSize: "35px", fontWeight: '700' },
                  }}
                  slotProps={{
                    input: {

                      endAdornment: <InputAdornment position="end"  >
                        <IconButton
                          size="small"
                          // @ts-ignore
                          color='transparent'
                          variant='contained'
                        >
                          <DoneIcon />
                        </IconButton>
                      </InputAdornment>,
                    },
                  }}
                />
              </Box>
            </ClickAwayListener>
          ) : (
            <>
              {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
              <Stack direction={'row'} spacing={1}>

                <Button
                  variant="text"
                  size="small"
                  sx={{
                    fontSize: "35px", fontWeight: '700',
                    border: '1px solid transparent',
                    height: '38px',
                    ':hover': {
                      border: '1px solid #000',
                    },
                  }}
                  onClick={handleClick}
                >
                  Name Of The Opportunity
                </Button>
              </Stack>
            </>
          )}
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="caption" color="textDisabled" >
            Assigned to
          </Typography>
          <Avatar sx={{ width: 32, height: 32, fontSize: '16px', backgroundColor: "#FFB5B5", color: "#fff", }}>
            AS
          </Avatar>
          <Typography variant="caption" >
            Ajay Seth
          </Typography>
        </Box>
      </Box>

      {/* Body */}
      <Stack py={2.75} pl={10} pr={8} gap={5} flexGrow={1} overflow={'auto'}>
        {/* --States-- */}
        <Box sx={{
          display: "flex",
          gap: 2,
          backgroundColor: "#F2F2F2",
          borderRadius: 3,
          padding: 2,
          border: "1px solid #D9D9D9",
          justifyContent: "space-between",
          overflow: "auto",
        }}>
          <Button
            variant="text"
            size="small"
            startIcon={<RoundCheckIcon />}
            sx={{ color: "#000", fontSize: "15px" }}
          >
            New
          </Button>
          <Button
            variant="text"
            size="small"
            startIcon={<RoundCheckIcon />}
            sx={{ color: "#000", fontSize: "15px", minWidth: "auto" }}
          >
            Qualifying
          </Button>
          <Button
            variant="text"
            size="small"
            startIcon={<RoundCheckIcon />}
            sx={{
              color: "#000", fontSize: "15px", minWidth: "auto",
              opacity: 0.25,
            }}
          >
            Quote
          </Button>
          <Button
            variant="text"
            size="small"
            startIcon={<RoundCheckIcon />}
            sx={{
              color: "#000", fontSize: "15px", minWidth: "auto",
              opacity: 0.25,
            }}
          >
            Contract
          </Button>
          <Button
            variant="text"
            size="small"
            startIcon={<RoundCheckIcon />}
            sx={{
              color: "#000", fontSize: "15px", minWidth: "auto",
              opacity: 0.25,
            }}
          >
            Invoiced
          </Button>
          <Button
            variant="text"
            size="small"
            startIcon={<RoundCheckIcon />}
            sx={{
              color: "#000", fontSize: "15px", minWidth: "auto",
              opacity: 0.25,
            }}
          >
            Won/Lost
          </Button>
        </Box>

        {/* --Basic Fields-- */}

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="caption" color="textDisabled" >
              BUDGET
            </Typography>
            <Box ml={-1.5}>
              {showTextField ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box display={"inline-block"} id="textfield-container">
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="name"
                      placeholder="enter value"
                      autoFocus
                      slotProps={{
                        input: {
                          startAdornment: <InputAdornment position="start"  >
                            $
                          </InputAdornment>,
                          endAdornment: <InputAdornment position="end"  >
                            <IconButton
                              size="small"
                              // @ts-ignore
                              color='transparent'
                              variant='contained'
                            >
                              <DoneIcon />
                            </IconButton>
                          </InputAdornment>,
                        },
                      }}
                    />
                  </Box>
                </ClickAwayListener>
              ) : (
                <>
                  {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                  <Stack direction={'row'} spacing={1}>
                    {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        fontSize: "15px", border: '1px solid transparent',
                        ':hover': {
                          border: '1px solid #000',
                        },
                      }}
                      onClick={handleClick}
                    >
                      + Click to Add
                    </Button>
                  </Stack>
                </>
              )}
            </Box>

          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption" color="textDisabled" >
              TYPE
            </Typography>
            <Box ml={-1.5}>
              {showTextField ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    size="small"
                    label="Age"
                  // onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </ClickAwayListener>
              ) : (
                <>
                  {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                  <Stack direction={'row'} spacing={1}>
                    {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        fontSize: "15px", border: '1px solid transparent',
                        ':hover': {
                          border: '1px solid #000',
                        },
                      }}
                      onClick={handleClick}
                    >
                      + Click to Add
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption" color="textDisabled" >
              ACCOUNTS
            </Typography>
            <Box ml={-1.5}>
              {showTextField ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    size="small"
                    label="Age"
                  // onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </ClickAwayListener>
              ) : (
                <>
                  <AvatarGroup spacing="small" sx={{ justifyContent: "start" }} >
                    <Avatar alt="+" src="/static/images/avatar/1.jpg"
                      onClick={handleClick}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#000",
                        fontWeight: 400,
                        width: 32,
                        height: 32,

                      }} />
                    <Avatar alt="Travis Howard" sx={{ width: 32, height: 32 }} src="/static/images/avatar/2.jpg" />
                    <Avatar alt="Cindy Baker" sx={{ width: 32, height: 32 }} src="/static/images/avatar/3.jpg" />
                    <Avatar alt="Cindy Baker" sx={{ width: 32, height: 32 }} src="/static/images/avatar/3.jpg" />
                  </AvatarGroup>
                  {/* <Stack direction={'row'} spacing={1}>

                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        fontSize: "15px", border: '1px solid transparent',
                        ':hover': {
                          border: '1px solid #000',
                        },
                      }}
                      onClick={handleClick}
                    >
                      + Click to Add
                    </Button>
                  </Stack> */}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption" color="textDisabled" >
              Invoice
            </Typography>
            <Box ml={-1.5}>
              {showTextField ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box display={"inline-block"} id="textfield-container">
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="name"
                      placeholder="enter value"
                      autoFocus
                      slotProps={{
                        input: {
                          startAdornment: <InputAdornment position="start"  >
                            $
                          </InputAdornment>,
                          endAdornment: <InputAdornment position="end"  >
                            <IconButton
                              size="small"
                              // @ts-ignore
                              color='transparent'
                              variant='contained'
                            >
                              <DoneIcon />
                            </IconButton>
                          </InputAdornment>,
                        },
                      }}
                    />
                  </Box>
                </ClickAwayListener>
              ) : (
                <>
                  {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                  <Stack direction={'row'} spacing={1}>
                    {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        fontSize: "15px", border: '1px solid transparent',
                        ':hover': {
                          border: '1px solid #000',
                        },
                      }}
                      onClick={handleClick}
                    >
                      + Click to Add
                    </Button>
                  </Stack>
                </>
              )}
            </Box>

          </Grid>
        </Grid>

        {/* ---Custom Fields--- */}
        <Box>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ArrowDownDarkIcon />} aria-controls="panel1d-content" id="panel1d-header">

              <Typography component="span">Customized sessions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="caption" color="textDisabled" >
                    BUDGET
                  </Typography>
                  <Box ml={-1.5}>
                    {showTextField ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Box display={"inline-block"} id="textfield-container">
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            name="name"
                            placeholder="enter value"
                            autoFocus
                            slotProps={{
                              input: {
                                startAdornment: <InputAdornment position="start"  >
                                  $
                                </InputAdornment>,
                                endAdornment: <InputAdornment position="end"  >
                                  <IconButton
                                    size="small"
                                    // @ts-ignore
                                    color='transparent'
                                    variant='contained'
                                  >
                                    <DoneIcon />
                                  </IconButton>
                                </InputAdornment>,
                              },
                            }}
                          />
                        </Box>
                      </ClickAwayListener>
                    ) : (
                      <>
                        {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                        <Stack direction={'row'} spacing={1}>
                          {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              fontSize: "15px", border: '1px solid transparent',
                              ':hover': {
                                border: '1px solid #000',
                              },
                            }}
                            onClick={handleClick}
                          >
                            + Click to Add
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Box>

                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" color="textDisabled" >
                    TYPE
                  </Typography>
                  <Box ml={-1.5}>
                    {showTextField ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          size="small"
                          label="Age"
                        // onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </ClickAwayListener>
                    ) : (
                      <>
                        {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                        <Stack direction={'row'} spacing={1}>
                          {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              fontSize: "15px", border: '1px solid transparent',
                              ':hover': {
                                border: '1px solid #000',
                              },
                            }}
                            onClick={handleClick}
                          >
                            + Click to Add
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" color="textDisabled" >
                    ACCOUNTS
                  </Typography>
                  <Box ml={-1.5}>
                    {showTextField ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          size="small"
                          label="Age"
                        // onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </ClickAwayListener>
                    ) : (
                      <>
                        <AvatarGroup spacing="small" sx={{ justifyContent: "start" }} >
                          <Avatar alt="+" src="/static/images/avatar/1.jpg"
                            onClick={handleClick}
                            sx={{
                              cursor: "pointer",
                              backgroundColor: "#000",
                              fontWeight: 400,
                              width: 32,
                              height: 32,

                            }} />
                          <Avatar alt="Travis Howard" sx={{ width: 32, height: 32 }} src="/static/images/avatar/2.jpg" />
                          <Avatar alt="Cindy Baker" sx={{ width: 32, height: 32 }} src="/static/images/avatar/3.jpg" />
                          <Avatar alt="Cindy Baker" sx={{ width: 32, height: 32 }} src="/static/images/avatar/3.jpg" />
                        </AvatarGroup>
                        {/* <Stack direction={'row'} spacing={1}>

                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        fontSize: "15px", border: '1px solid transparent',
                        ':hover': {
                          border: '1px solid #000',
                        },
                      }}
                      onClick={handleClick}
                    >
                      + Click to Add
                    </Button>
                  </Stack> */}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" color="textDisabled" >
                    Invoice
                  </Typography>
                  <Box ml={-1.5}>
                    {showTextField ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Box display={"inline-block"} id="textfield-container">
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            name="name"
                            placeholder="enter value"
                            autoFocus
                            slotProps={{
                              input: {
                                startAdornment: <InputAdornment position="start"  >
                                  $
                                </InputAdornment>,
                                endAdornment: <InputAdornment position="end"  >
                                  <IconButton
                                    size="small"
                                    // @ts-ignore
                                    color='transparent'
                                    variant='contained'
                                  >
                                    <DoneIcon />
                                  </IconButton>
                                </InputAdornment>,
                              },
                            }}
                          />
                        </Box>
                      </ClickAwayListener>
                    ) : (
                      <>
                        {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                        <Stack direction={'row'} spacing={1}>
                          {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              fontSize: "15px", border: '1px solid transparent',
                              ':hover': {
                                border: '1px solid #000',
                              },
                            }}
                            onClick={handleClick}
                          >
                            + Click to Add
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Box>

                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary expandIcon={<ArrowDownDarkIcon />} aria-controls="panel2d-content" id="panel2d-header">
              <Typography component="span">Customized sessions #2</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="caption" color="textDisabled" >
                    BUDGET
                  </Typography>
                  <Box ml={-1.5}>
                    {showTextField ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Box display={"inline-block"} id="textfield-container">
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            name="name"
                            placeholder="enter value"
                            autoFocus
                            slotProps={{
                              input: {
                                startAdornment: <InputAdornment position="start"  >
                                  $
                                </InputAdornment>,
                                endAdornment: <InputAdornment position="end"  >
                                  <IconButton
                                    size="small"
                                    // @ts-ignore
                                    color='transparent'
                                    variant='contained'
                                  >
                                    <DoneIcon />
                                  </IconButton>
                                </InputAdornment>,
                              },
                            }}
                          />
                        </Box>
                      </ClickAwayListener>
                    ) : (
                      <>
                        {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                        <Stack direction={'row'} spacing={1}>
                          {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              fontSize: "15px", border: '1px solid transparent',
                              ':hover': {
                                border: '1px solid #000',
                              },
                            }}
                            onClick={handleClick}
                          >
                            + Click to Add
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Box>

                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" color="textDisabled" >
                    TYPE
                  </Typography>
                  <Box ml={-1.5}>
                    {showTextField ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          size="small"
                          label="Age"
                        // onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </ClickAwayListener>
                    ) : (
                      <>
                        {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                        <Stack direction={'row'} spacing={1}>
                          {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              fontSize: "15px", border: '1px solid transparent',
                              ':hover': {
                                border: '1px solid #000',
                              },
                            }}
                            onClick={handleClick}
                          >
                            + Click to Add
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" color="textDisabled" >
                    ACCOUNTS
                  </Typography>
                  <Box ml={-1.5}>
                    {showTextField ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          size="small"
                          label="Age"
                        // onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </ClickAwayListener>
                    ) : (
                      <Box pl={1.5}>
                        <AvatarGroup spacing="small" sx={{ justifyContent: "start", flexDirection: "row !important" }} >
                          <Avatar alt="+" src="/static/images/avatar/1.jpg"
                            onClick={handleClick}
                            sx={{
                              cursor: "pointer",
                              backgroundColor: "#000",
                              fontWeight: 400,
                              width: 32,
                              height: 32,

                            }} />
                          <Avatar alt="Travis Howard" sx={{ width: 32, height: 32 }} src="/static/images/avatar/2.jpg" />
                          <Avatar alt="Cindy Baker" sx={{ width: 32, height: 32 }} src="/static/images/avatar/3.jpg" />
                          <Avatar alt="Cindy Baker" sx={{ width: 32, height: 32 }} src="/static/images/avatar/3.jpg" />
                        </AvatarGroup>
                        {/* <Stack direction={'row'} spacing={1}>

                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        fontSize: "15px", border: '1px solid transparent',
                        ':hover': {
                          border: '1px solid #000',
                        },
                      }}
                      onClick={handleClick}
                    >
                      + Click to Add
                    </Button>
                  </Stack> */}
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" color="textDisabled" >
                    Invoice
                  </Typography>
                  <Box ml={-1.5}>
                    {showTextField ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Box display={"inline-block"} id="textfield-container">
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            name="name"
                            placeholder="enter value"
                            autoFocus
                            slotProps={{
                              input: {
                                startAdornment: <InputAdornment position="start"  >
                                  $
                                </InputAdornment>,
                                endAdornment: <InputAdornment position="end"  >
                                  <IconButton
                                    size="small"
                                    // @ts-ignore
                                    color='transparent'
                                    variant='contained'
                                  >
                                    <DoneIcon />
                                  </IconButton>
                                </InputAdornment>,
                              },
                            }}
                          />
                        </Box>
                      </ClickAwayListener>
                    ) : (
                      <>
                        {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                        <Stack direction={'row'} spacing={1}>
                          {/* <Box>
                      <Typography variant="body2" >
                        $5000
                      </Typography>
                    </Box> */}
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              fontSize: "15px", border: '1px solid transparent',
                              ':hover': {
                                border: '1px solid #000',
                              },
                            }}
                            onClick={handleClick}
                          >
                            + Click to Add
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

      </Stack>
    </Stack>
  );
};

export default OpportunityView;