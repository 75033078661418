import { Stack, IconButton, Typography } from '@mui/material';
import {
  Reply as ReplyIcon,
  ReplyAll as ReplyAllIcon,
  Forward as ForwardIcon,
  DraftsOutlined as DraftsOutlinedIcon,
  MarkEmailUnreadOutlined as MarkEmailUnreadOutlinedIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  Delete as DeleteIcon,
  StarRateRounded,
} from '@mui/icons-material';
import moment from 'moment';
import {
  xpAccountIntegrationGmailDeleteEmail,
  xpAccountIntegrationGmailMarkEmailAsBookmarked,
  xpAccountIntegrationGmailMarkEmailAsUnread,
  xpAccountIntegrationGmailUnBookmarkEmail,
} from 'ReduxStore/API';
import { useNavigate } from 'react-router';
import { createNotification } from 'helpers';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import React from 'react';
import { MailType, sampleMessage, Message, Gmail } from '../common';


type Props = {
  mail: Message;
  gmailId: string;
  mailType: MailType;
  setGmail: React.Dispatch<React.SetStateAction<Gmail>>;
  setGmailDataAfterUnBookmarkingEmail: (id: string) => void;
  setGmailDataAfterBookmarkingEmail: (id: string) => void;
  i: number;
  setReplyToAll: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function EmailActionButtons({
  mail,
  gmailId,
  mailType,
  setGmail,
  setGmailDataAfterUnBookmarkingEmail,
  setGmailDataAfterBookmarkingEmail,
  i,
  setReplyToAll,
}: Props) {
  const navigate = useNavigate();
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] = React.useState(false);

  const openDeleteConfirmationModelFn = () => setOpenDeleteConfirmationModel(true);
  const closeDeleteConfirmationModel = () => setOpenDeleteConfirmationModel(false);

  const deleteEmail = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailDeleteEmail(data);
      if (res.data.success) {
        setGmail((prev) => {
          let filteredArr = prev.filter((obj) => obj.id !== id);
          if (filteredArr.length === 0) {
            navigate(`/inbox/${mailType.toLowerCase()}`);
            // closeGmailContent();
          }
          return filteredArr;
        });
        // getGmailData(true, undefined);
      }else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const unBookmarkEmail = async (id) => {
    let prevState: Gmail;
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };

      setGmail((prev) => {
        prevState = [...prev];
        return prev.map((mail) => {
          if (mail.id === id) {
            if (mail.labelIds.includes('STARRED')) {
              // Create a new copy of the mail object with "STARRED" removed
              return {
                ...mail,
                labelIds: mail.labelIds.filter((label) => label !== 'STARRED'),
              };
            }
            return mail; // Return the original mail if "STARRED" is not present
          }
          return mail; // Return mail unchanged for non-matching IDs
        });
      });

      setGmailDataAfterUnBookmarkingEmail(id);

      const res = await xpAccountIntegrationGmailUnBookmarkEmail(data);

      if (res.data.success) {
        // getGmailData(false, undefined);
      } else {
        setGmail(prevState);
        createNotification('error', res.data.message);
      }
    } catch (error) {
      setGmail(prevState);
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const markEmailAsBookmarked = async (id) => {
    let prevState: Gmail;
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };

      // Save the previous state to roll back in case of error
      setGmail((prev) => {
        prevState = [...prev]; // Make a shallow copy of the previous state
        return prev.map((mail) => {
          if (mail.id === id) {
            if (!mail.labelIds.includes('STARRED')) {
              return {
                ...mail,
                labelIds: [...mail.labelIds, 'STARRED'],
              };
            }
            return mail;
          }
          return mail;
        });
      });

      setGmailDataAfterBookmarkingEmail(id);

      const res = await xpAccountIntegrationGmailMarkEmailAsBookmarked(data);

      if (res.data.success) {
        // getGmailData(false, undefined); // Refresh the data if the API call is successful
      } else {
        // Rollback to the previous state if the API call fails
        setGmail(prevState);
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      // Rollback to the previous state in case of an error
      setGmail(prevState);
      createNotification('error', error.message);
    }
  };

  const markEmailAsUnread = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkEmailAsUnread(data);
      if (res.data.success) {
        // getGmailData(true, undefined);
        navigate(`/inbox/${mailType.toLowerCase()}`);
        // closeGmailContent();
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const handleOpenReplyCompose = (isReplyingToAll, isForwarding = false) => {
    setReplyToAll(isReplyingToAll);
    
    setGmail((prev) => {
      const isNextMailAlreadyDraft = prev[i + 1]?.labelIds.includes('DRAFT');
      if (isNextMailAlreadyDraft) return prev;

      const newPrev = [...prev];
      const originalHeaders = mail.payload.headers;
      const subject = originalHeaders.find(h => h.name === 'Subject')?.value || '';
      const from = originalHeaders.find(h => h.name === 'From')?.value || '';
      const to = originalHeaders.find(h => h.name === 'To')?.value || '';
      const cc = originalHeaders.find(h => h.name === 'Cc')?.value || '';
      const date = originalHeaders.find(h => h.name === 'Date')?.value || '';

      // Helper function to extract email from string like "Name <email@domain.com>" or "email@domain.com"
      const extractEmail = (str: string): string => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
        const match = str.match(emailRegex);
        return match ? match[0] : str;
      };

      // Process multiple email addresses
      const processEmails = (emailStr: string): string[] => {
        if (!emailStr) return [];
        return emailStr.split(',')
          .map(email => email.trim())
          .map(extractEmail)
          .filter(email => email && !email.includes(gmailId));
      };

      // Check if the original email was sent by the current user
      const isFromMe = extractEmail(from).includes(gmailId);

      // Set up recipients based on reply type and sender
      const recipients = isFromMe
        ? processEmails(to)  // If I sent it, reply to original recipients
        : [extractEmail(from)];  // If someone else sent it, reply to sender

      const ccRecipients = isReplyingToAll
        ? isFromMe
          ? processEmails(cc)  // If I sent it, CC original CC recipients
          : [...processEmails(to), ...processEmails(cc)].filter(email => !email.includes(gmailId))
        : [];
      
      const forwardingHeader = isForwarding 
        ? `---------- Forwarded message ---------\n\
From: ${from}\n\
Date: ${date}\n\
Subject: ${subject}\n\
To: ${to}\n\
\n\
`
        : '';

      // Helper function to safely decode base64
      const safeAtob = (str) => {
        try {
          // Replace URL-safe chars and add padding if needed
          const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
          const padding = '='.repeat((4 - (base64.length % 4)) % 4);
          return atob(base64 + padding);
        } catch (e) {
          console.error('Base64 decode failed:', e);
          return '';
        }
      };

      // Helper function to safely encode base64
      const safeBtoa = (str) => {
        try {
          // Make base64 URL-safe
          return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        } catch (e) {
          console.error('Base64 encode failed:', e);
          return '';
        }
      };

      const newDraft = {
        ...sampleMessage,
        snippet: isForwarding ? `Forwarded message - ${mail.snippet}` : '',
        id: (i + 1).toString(),
        labelIds: isForwarding ? ['DRAFT', 'FORWARD'] : ['DRAFT'],
        recipients: {
          to: isForwarding ? [] : recipients,
          cc: isForwarding ? [] : ccRecipients,
        },
        payload: {
          ...sampleMessage.payload,
          headers: [
            { name: 'Subject', value: `${isForwarding ? 'Fwd: ' : 'Re: '}${subject.replace(/^(Re: |Fwd: )*/, '')}` },
            { name: 'To', value: recipients.join(', ') },
            { name: 'Cc', value: ccRecipients.join(', ') }
          ],
          ...(isForwarding && {
            body: {
              ...mail.payload.body,
              data: safeBtoa(forwardingHeader + (mail.payload.body.data ? safeAtob(mail.payload.body.data) : ''))
            },
            parts: mail.payload.parts?.map(part => ({
              ...part,
              body: {
                ...part.body,
                data: part.mimeType?.includes('text')
                  ? safeBtoa(forwardingHeader + (part.body.data ? safeAtob(part.body.data) : ''))
                  : part.body.data
              }
            })),
            mimeType: mail.payload.mimeType,
          })
        }
      };

      newPrev.splice(i + 1, 0, newDraft);
      return newPrev;
    });
  };

  const canReplyToAll = (originalEmail) => {
    const allRecipients = originalEmail.headers
      .filter(
        (obj) =>
          ['To', 'Cc', 'cC'].includes(obj.name) ||
          (obj.name === 'From' && obj.value !== gmailId),
      )
      .map((obj) => obj.value);

    // Exclude the user's email
    const otherRecipients = allRecipients.filter(
      (email) => email !== gmailId || !email.includes(gmailId),
    );
    let finalArray = [];
    otherRecipients.forEach((email) => {
      email
        .split(',')
        .forEach(
          (el) =>
            el !== gmailId &&
            !el.includes(gmailId) &&
            finalArray.push(el.trim()),
        );
    });
    // Check if there are other recipients
    return finalArray.length > 1;
  };

  return (
    <>
    <Stack spacing={1} direction={'row'} alignItems={'center'}>
      <IconButton
        size="small"
        // @ts-ignore
        color="transparent"
        variant="contained"
        onClick={() => markEmailAsUnread(mail.id)}
        title="Mark as unread"
      >
        <MarkEmailUnreadOutlinedIcon />
      </IconButton>

      <IconButton
        size="small"
        // @ts-ignore
        color="transparent"
        variant="contained"
        onClick={() =>
          mail.labelIds.includes('STARRED')
            ? unBookmarkEmail(mail.id)
            : markEmailAsBookmarked(mail.id)
        }
        title="Bookmark"
      >
        {mail.labelIds.includes('STARRED') ? (
          <StarRateRounded />
        ) : (
          <StarBorderRoundedIcon />
        )}

        {/* <ArchiveOutlinedIcon /> */}
      </IconButton>

      <IconButton
        title="Delete"
        size="small"
        // @ts-ignore
        color="transparent"
        variant="contained"
        onClick={openDeleteConfirmationModelFn}
      >
        <DeleteIcon />
      </IconButton>

      <IconButton
        size="small"
        // @ts-ignore
        color="transparent"
        variant="contained"
        onClick={() => {
          handleOpenReplyCompose(false);
        }}
        title="Reply"
      >
        <ReplyIcon />
      </IconButton>

      {canReplyToAll(mail.payload) && (
        <IconButton
          size="small"
          // @ts-ignore
          color="transparent"
          variant="contained"
          onClick={() => {
            // setShowReplyCompose(true);
            handleOpenReplyCompose(true);
          }}
          title="Reply to all"
        >
          <ReplyAllIcon />
        </IconButton>
      )}

      <IconButton
        size="small"
        // @ts-ignore
        color="transparent"
        variant="contained"
        onClick={() => {
          handleOpenReplyCompose(false, true);
        }}
        title="Forward"
      >
        <ForwardIcon />
      </IconButton>

      {/* --time-- */}
      <Typography variant="body2" sx={{ pl: 2 }}>
        {(() => {
          const emailDate = moment(
            mail.payload.headers.find((g: any) => g.name === 'Date').value,
          );
          const now = moment();
          const diffMinutes = now.diff(emailDate, 'minutes');
          const diffHours = now.diff(emailDate, 'hours');
          const isSameYear = now.year() === emailDate.year();

          if (diffMinutes < 60) {
            // Less than an hour ago
            return `${emailDate.format('HH:mm')} (${diffMinutes} minutes ago)`;
          } else if (diffHours < 24) {
            // Less than 24 hours ago
            return `${emailDate.format('HH:mm')} (${diffHours} hours ago)`;
          } else {
            // More than 24 hours ago
            const dateFormat = isSameYear
              ? 'ddd D MMM, HH:mm'
              : 'ddd D MMM YYYY, HH:mm';
            return `${emailDate.format(dateFormat)} (${emailDate.fromNow()})`;
          }
        })()}
      </Typography>
    </Stack>
    <ConfirmationAlertPopUp
              openModal={openDeleteConfirmationModel}
              closeModalFunc={closeDeleteConfirmationModel}
              title={'Delete Confirmation!'}
              text={'Are you sure you want to delete it?'}
              confirmationButtonText={'Delete'}
              confirmationButtonColor="secondary"
              closeButtonText={'Cancel'}
              functionality={() => {
                deleteEmail(mail.id);
                closeDeleteConfirmationModel();
              }}
            />
    </>
  );
}
