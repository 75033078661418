import { createReducer } from 'reduxsauce';
import { Constants } from '../Actions';

const INITIAL_STATE = {
  success: false,
  data: {},
  error: false,
  message: null,
};

const USER_STATE = {
  isRegister: false,
  registerErr: false,
  registerMsg: null,
  errMessage: null,
  data: {},
};

const userRegisterRequest = (state = USER_STATE, action) => {
  return {
    ...state,
    isRegister: false,
    registerMsg: null,
    errMessage: null,
    registerErr: false,
  };
};

const userRegisterSuccess = (state = USER_STATE, action) => {
  return { ...state, isRegister: true, registerMsg: action.payload, data:action.data };
};

const userRegisterFailure = (state = USER_STATE, action) => {
  return { ...state, registerErr: true, errMessage: action.error };
};

const HANDLERS = {
  [Constants.USER_REGISTER_REQUEST]: userRegisterRequest,
  [Constants.USER_REGISTER_SUCCESS]: userRegisterSuccess,
  [Constants.USER_REGISTER_FAILURE]: userRegisterFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
