import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useEffect, useState } from 'react';
import { FilterType, useFilterTypes } from '../utils/FilterTypesContext';

interface FilterTypeSelectProps {
  value?: string | number;
  onChange?: (
    event: SelectChangeEvent<unknown>,
    filterType?: string,
    isCustomField?: boolean,
    label?: string,
  ) => void;
}

export default function FilterTypeSelect({
  value = 'name',
  onChange,
}: FilterTypeSelectProps) {
  const { filterTypes, selectedFilterTypes } = useFilterTypes();

  const [selectedValue, setSelectedValue] = useState<FilterType | null>(null);
  const [filteredFilterTypes, setFilteredFilterTypes] = useState<FilterType[]>(
    [],
  );

  useEffect(() => {
    const arr = filterTypes.filter((type) => {
      if (type.value === selectedValue?.value) {
        return true;
      }
      return !selectedFilterTypes.includes(type.label);
    });
    setFilteredFilterTypes(arr);
  }, [filterTypes, selectedFilterTypes]);

  return (
    <Select
      variant="outlined"
      size="small"
      value={value}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 300,  // Set your desired max height here
          },
        },
      }}
      onChange={(e) => {
        const selectedType = filterTypes.find(
          (type) => type.value === e.target.value,
        );
        onChange?.(
          e,
          selectedType?.type,
          selectedType?.isCustomField,
          selectedType?.label,
        );
      }}

      fullWidth
      displayEmpty
      renderValue={(selected) => {
        const selectedType = filterTypes.find(
          (type) => type.label === selected,
        );
        setSelectedValue(selectedType || null);
        return selectedType?.label || 'Select a filter type';
      }}
    >
      {filteredFilterTypes?.map((filterType, index) => (
        <MenuItem
          key={index}
          value={filterType.value}
          data-type={filterType.type}
          data-isCustomField={filterType.isCustomField}
          data-label={filterType.label}
        >
          {filterType.label}
        </MenuItem>
      ))}
    </Select>
  );
}
