import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { NotificationContainer } from 'react-notifications';
import { createNotification } from '../../helpers';
import '../../sass/main.scss';
import { GetInvitedUserDetailsAPI } from '../../ReduxStore/API';
//import * as React from 'react';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AuthLayout from '../../Layouts/AuthLayout';
import Card from '../../Layouts/AuthLayout/SignInCard';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Alert, AlertTitle, Collapse, FormLabel, IconButton, InputAdornment, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Loader from '../../Components/Loader/loader';
import Grid from '@mui/material/Grid2';
import { CancelIcon } from '../../eijent/components/CustomIcons';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Divider from '@mui/material/Divider';
import MicrosoftIcon from '../../assets/icons/microsoftIcon.svg';
import GoogleIcon from '../../assets/icons/googleIcon.svg';
import { generateUrl } from 'helpers/common';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';


const label = (
  <span>
    I agree Illumeet &nbsp;
    <a href="/terms_and_conditions" target="_blank">
      Terms & Conditions
    </a>
  </span>
);

const URL = process.env.REACT_APP_API_URL;
const REACT_BACKEND_URL = process.env.REACT_BACKEND_URL;
const REACT_GOOGLE_CLIENT_ID = process.env.REACT_GOOGLE_CLIENT_ID;
const REACT_SSO_REDIRECT_URI = process.env.REACT_SSO_REDIRECT_URI;
const REACT_GOOGLE_AUTH_URL = process.env.REACT_GOOGLE_AUTH_URL;
class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      email: '',
      phoneNumber: '',
      company: '',
      accountName: '',
      errors: {},
      terms: false,
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
      isAccountNameSetSso: false,
      isComponentReady: false,
      invited: false,
      invitedToken: '',
      role:1  
    };
  }

  componentDidMount() {
    const { location } = this.props;
    // Parse query parameters from the location prop
    const searchParams = new URLSearchParams(location.search);
    const isInvited = searchParams.get('invited');
    const invited = isInvited == 1 ? true : false;
    const isSSOAccountSource = searchParams.get('source');
    if (isSSOAccountSource == 'sso' && isInvited !== 1) {
      const email = searchParams.get('email');
      const ssofirstName = searchParams.get('firstName');
      const ssolastName = searchParams.get('lastName');
      this.setState({ email: email });
      this.setState({ firstName: ssofirstName });
      this.setState({ lastName: ssolastName });
      this.setState({ isAccountNameSetSso: true });
    } else if(isSSOAccountSource == 'sso' && isInvited == 1){ 
      const email = searchParams.get('email');
      this.setState({ email: email });
      this.setState({ isAccountNameSetSso: true }); 
    }

    const token = searchParams.get('token');
    this.setState({ invited: invited });
    this.setState({ invitedToken: token });
    if (invited === true && token) {
      this.getInvitedUserDetails(token);
    }

    setTimeout(() => {
      this.setState({ isComponentReady: true });
    }, 300); // Small delay to ensure updates
  }

  componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;
    if (
      this.props.isRegister === true &&
      this.props.registerMsg !== prevProps.registerMsg
    ) { 
      this.handleAlert('success', this.props.registerMsg);
      let st = (prevState.loading ? this.setState({ loading: false }) : null);
      /*   setTimeout(() => {
         // this.props.navigate('/');${account}
        }, 2000); */


      setTimeout(() => {
        const { accountName } = this.props.data; // Assume accountName is passed in props
        let currentHost = window.location.hostname; // Get current hostname

        if (currentHost === "localhost") {
          this.props.navigate('/'); // Normal redirect on localhost
          return;
        }

        let newHost = currentHost;

        if (accountName) {
          // Replace existing subdomain with the new one
          newHost = currentHost.replace(/^([^.]+)\./, `${accountName}.`);
        }

        const newUrl = `${window.location.protocol}//${newHost}/`; 
        window.location.href = newUrl; // Redirect to login with new subdomain
      }, 2000);
    }

    if (this.props.error && this.props.errMessage !== prevProps.errMessage) {
      this.handleAlert('error', this.props.errMessage);
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }
  }


  getInvitedUserDetails = async (token) => {
    try {
      const data = { inviteToken: token };
      const response = await GetInvitedUserDetailsAPI(data); 
      // Prefill email in the form
      this.setState({ email: response?.data?.users?.email });
      if (response?.data?.users?.role > 1) {
        this.setState({ role: response?.data?.users?.role });
        this.setState({ accountName: response?.data?.users?.accountName });
        this.setState({ invited: true });
      } 
    } catch (error) {
      console.error('Error fetching user details:', error);
      this.setState({ loading: false });
    }
  };


  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  isValid = () => {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      company,
      accountName,
      phoneNumber,
    } = this.state;

    let error = {};
    let formIsValid = true;
    let hasMandatoryFieldError = false;

    const regexTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobPattern = /^[0-9]{10}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    //  Mandatory Fields Check
    if (!firstName || firstName.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["firstName"] = "*Required field";
    }
    if (!lastName || lastName.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["lastName"] = "*Required field";
    }


    if (this.state.isAccountNameSetSso == false) {
      if (!password || password.trim() === "") {
        hasMandatoryFieldError = true;
        formIsValid = false;
        error["password"] = "*Required field";
      }
      if (!confirmPassword || confirmPassword.trim() === "") {
        hasMandatoryFieldError = true;
        formIsValid = false;
        error["confirmPassword"] = "*Confrim password not matched";
      }
    }
    if (!email || email.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["email"] = "*Required field";
    }
    if (this.state.invited == false) {
      if (!company || company.trim() === "") {
        hasMandatoryFieldError = true;
        formIsValid = false;
        error["company"] = "*Required field";
      }
      if (!accountName || accountName.trim() === "") {
        hasMandatoryFieldError = true;
        formIsValid = false;
        error["accountName"] = "*Required field";
      }
    }

    if (!phoneNumber || phoneNumber.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["phoneNumber"] = "*Required field";
    }

    // Show Generic Error Message if Any Mandatory Field is Empty
    if (hasMandatoryFieldError) {
      this.handleAlert("error", "Please fill in all mandatory fields before proceeding.");
    }

    // Password Validation  
    if (this.state.isAccountNameSetSso == false) {
      if (!passwordRegex.test(password)) {
        formIsValid = false;
        error["password"] =
          "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8.";
      }

      //  Confirm Password Validation
      if (password && confirmPassword && password !== confirmPassword) {
        formIsValid = false;
        error["confirmPassword"] = "Passwords do not match.";
      }
    }


    //  Email Validation
    if (email && !regexTest.test(email)) {
      formIsValid = false;
      error["email"] = "Please enter a valid email address.";
    }

    // Phone Number Validation
    /*  if (phoneNumber && !mobPattern.test(phoneNumber)) {
       formIsValid = false;
       error["phoneNumber"] = "Phone number must be exactly 10 digits.";
     } */

    //  Show specific validation errors if they exist
    if (!formIsValid && !hasMandatoryFieldError) {
      const errorMessages = Object.values(error).join("\n");
      this.handleAlert("error", errorMessages);
    }

    //  Set `errors` in state so MUI highlights inputs
    this.setState({ errors: error });

    return formIsValid;
  };


  handleChange = (event) => {
    let error = {};
    if (event.target.name === 'phoneNumber') {
      const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
        this.setState({ errors: {} });
      } else {
        error['phoneNumber'] = '*Please enter a valid numeric phone number.';
        this.setState({ errors: error });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }


  };

  handleCheckBox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const {
        firstName,
        lastName,
        password,
        confirmPassword, 
        email,
        phoneNumber,
        company,
        accountName,
        invited,
        invitedToken,
        isAccountNameSetSso,
        role
      } = this.state;
      const requestbody = {
        firstName,
        lastName,
        password,
        email,
        phoneNumber, 
        company,
        role,
        accountName,
        invited,
        invitedToken,
        isAccountNameSetSso,
        
      };
      this.props.userRegister(requestbody);
      this.handleLoading();
      this.props.startLoading();
    }
  };

  handleGoogleLogin = (source) => {
    let originDomain = `${encodeURIComponent(window.location.origin)}`;
    const params = {
      client_id: REACT_GOOGLE_CLIENT_ID,
      redirect_uri: REACT_SSO_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid email profile',
      state: JSON.stringify({ source, originDomain, timestamp: Date.now() }),
      access_type: 'offline',
      prompt: 'consent',
      ssoOrigin: source,
      originDomain: originDomain
    };

    const queryString = qs.stringify(params);
    const authUrl = `${REACT_GOOGLE_AUTH_URL}?${queryString}`;
    // Popup dimensions
    const popupWidth = 600;
    const popupHeight = 600;
    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;

    // Open the popup in the center of the screen
    const popup = window.open(
      authUrl,
      'Google SSO Register',
      `width=${popupWidth},height=${popupHeight},top=${Math.max(top, 0)},left=${Math.max(left, 0)},scrollbars=yes`
    );

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      alert('Popup blocked! Please allow popups for this website.');
      return;
    }

    // Poll the popup to detect when it closes
    const popupTimer = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupTimer);
        console.log(' Popup closed before login completed.');
      }
    }, 5000);

    // Remove existing listener to prevent duplicate actions
    window.removeEventListener('message', this.handleSSOMessage);

    // Add the message listener
    window.addEventListener('message', (event) => {
      this.handleSSOMessage(event);
      // If the message has been acknowledged, close the timer
      if (event.data === 'acknowledged') {
        clearInterval(popupTimer);
      }
    });
  };

  handleSSOMessage = (event) => {
   // console.log("🔄 Received event from backend:", event.data);
    // Validate event origin before processing
    let origin = event?.origin;
    if (!origin.includes("localhost")) {
      origin = origin.endsWith("/") ? origin : `${origin}/`;
    }

   // console.log(" Origin:", origin);
   // console.log(" Expected Backend URL:", REACT_BACKEND_URL);

    /* if (origin !== REACT_BACKEND_URL) {
      console.error(" Invalid origin:", event.origin);
      return;
    } */
    // Handle SSO redirect (e.g., when a new user needs to register)
    if (event.data.type === "sso-redirect") {
     // console.warn("🔄 Redirecting to registration:====", event.data.url);
      this.handleAlert("error", event?.data?.message || "Redirecting to registration...");
      // Redirect the main window
      setTimeout(() => {
        window.location.href = event.data.url;
      }, 2000);
      return; // Stop further execution
    }
    if (event.data.type === "sso-login-success") {
    //  console.log(" SSO Login Successful");
      const userDetails = event?.data?.user;
      const token = event?.data?.token; //  Get token from backend
      const accountName = userDetails?.accountName;

      //  Store token & user details before redirection
      localStorage.setItem("user_role", userDetails?.role);
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      localStorage.setItem("token", token);

     // console.log("🌍 Redirecting to subdomain:", accountName);

      //  Replace "www" or "live" in the subdomain with accountName
      let currentUrl = window.location.origin;
      let updatedFrontendUrl = currentUrl.replace(/\/\/(www)\./, `//${accountName}.`);

      //  Redirect to /dashboard with token
      updatedFrontendUrl = `${updatedFrontendUrl.replace(/\/register$/, "/dashboard")}?token=${token}&ssoOrigin=register`;

     // console.log("🚀 Final Redirect URL:", updatedFrontendUrl);
      window.location.href = updatedFrontendUrl;
    }
  }; 

  handleAlert = (type, message) => {
    this.setState({
      alert: { type, message },
    });
  };
  render() {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      company,
      accountName,
      phoneNumber,
      errors,
      terms,
      loading,
      invited,
      isAccountNameSetSso,
      invitedToken,
    } = this.state;
    return (
      <AuthLayout>
        <Helmet>
          <title>Eijent | Register</title>
        </Helmet>
        <Box component="form" onSubmit={this.handleSubmit}>
          <Card >
            <Stack spacing={2}>
              {this.state.isComponentReady ? (
               isAccountNameSetSso || (invited && isAccountNameSetSso && !invitedToken) ? (
                  <>
                    <Typography component="h1" variant="h1" textAlign="center" sx={{ width: '100%' }}>
                      Complete Registration
                      {loading ? <Loader /> : null}
                    </Typography>
                    <Typography variant="body3">
                      Please fill your company and account details below to complete your registration.
                    </Typography>
                  </>
                ) : (
                  <Typography component="h1" variant="h1" textAlign="center" sx={{ width: '100%' }}>
                    Registration
                    {loading ? <Loader /> : null}
                  </Typography>
                )
              ) : null}

              {/* ---Form--- */}
              <Grid container spacing={2}
              // sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
              >
                <Grid component={FormControl} size={{ md: 6, }}>
                  <FormLabel htmlFor="firstName" error={!!errors.firstName}>First Name</FormLabel>
                  <TextField
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={firstName}
                    placeholder="First Name"
                    autoFocus
                    disabled={this.state.isAccountNameSetSso == true && this.state.invited == false}
                    fullWidth
                    variant="outlined"
                    size="large"
                    onChange={this.handleChange}
                    color={errors.firstName ? 'error' : 'primary'}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }}
                  />
                </Grid>

                <Grid component={FormControl} size={{ md: 6, }}>
                  <FormLabel htmlFor="lastName" error={!!errors.lastName} >Last Name</FormLabel>
                  <TextField
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={this.handleChange}
                    placeholder="Last Name"
                    autoComplete="last name"
                    fullWidth
                    disabled={this.state.isAccountNameSetSso == true && this.state.invited == false}
                    variant="outlined"
                    size="large"
                    color={errors.lastName ? 'error' : 'primary'}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }}
                  />
                </Grid>

                {!(this.state.isAccountNameSetSso) && <Grid component={FormControl} size={{ md: 6, }}>
                  <FormLabel htmlFor="password" error={!!errors.password}>Password</FormLabel>
                  <TextField
                    name="password"
                    id="password"
                    value={password}
                    placeholder="Password"
                    type={this.state.showPassword ? "text" : "password"}
                    //type="password"
                    autoComplete="password"
                    onChange={this.handleChange}
                    fullWidth
                    variant="outlined"
                    size='large'
                    color={errors.password ? 'error' : 'primary'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.togglePasswordVisibility}
                            size='small'
                            color='transparent'
                            variant='contained'
                            edge="end"
                            sx={{
                              backgroundColor: "transparent", // Remove background
                              "&:hover": {
                                backgroundColor: "transparent", // Prevent hover background
                              },
                            }}
                          >
                            {this.state.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>}

                {!(this.state.isAccountNameSetSso) && <Grid component={FormControl} size={{ md: 6, }}>
                  <FormLabel htmlFor="confirmPassword" error={!!errors.confirmPassword}>Confirm Password</FormLabel>
                  <TextField
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Confirm Password"
                    onChange={this.handleChange}
                    autoComplete="confirmPassword"
                    fullWidth
                    variant="outlined"
                    size='large'
                    color={errors.confirmPassword ? 'error' : 'primary'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.toggleConfirmPasswordVisibility}
                            edge="end"
                            size='small'
                            color='transparent'
                            variant='contained'
                            sx={{
                              backgroundColor: "transparent", // Remove background
                              "&:hover": {
                                backgroundColor: "transparent", // Prevent hover background
                              },
                            }}
                          >
                            {this.state.showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /*  slotProps={{
                     input: {
                       endAdornment: <InputAdornment position="end"  >
                         <Typography color='error'>
                           *
                         </Typography>
                       </InputAdornment>,
                     },
                   }} */
                  />
                </Grid>}

                <Grid component={FormControl} size={{ md: 6, }}>
                  <FormLabel htmlFor="email" error={!!errors.email} >E-mail</FormLabel>
                  <TextField
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    placeholder="Work email"
                    autoComplete="email"
                    disabled={this.state.invited == true || this.state.isAccountNameSetSso == true}
                    fullWidth
                    variant="outlined"
                    size="large"
                    color={errors.email ? 'error' : 'primary'}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }}
                  />
                </Grid>

                <Grid component={FormControl} size={{ md: 6, }}>
                  <FormLabel htmlFor="phoneNumber" error={!!errors.phoneNumber} >Phone Number</FormLabel>
                  <PhoneInput
                    defaultCountry="US"
                    value={phoneNumber}
                    onChange={(value) => this.setState({ phoneNumber: value })}
                    placeholder="Phone"
                    autoComplete="phoneNumber"
                    fullWidth
                    variant="outlined"
                    size="large"
                    className={`phoneNumber ${errors.phoneNumber ? 'error' : ''}`}
                    style={{
                      borderRadius: '8px',
                      height: '52px',
                      width: '100%',
                      padding: '0 12px ',
                      fontSize: '16px',
                      border: errors.phoneNumber ? '1px solid #f00' : '1px solid #000',

                      '& input': {
                        border: 'none',
                      }
                    }}

                  />
                  {/*
                    {errors.phoneNumber && (
                      <InputAdornment position="end">
                        <Typography color="error">*</Typography>
                      </InputAdornment>
                    )}
                 */}

                </Grid>
                {this.state.isComponentReady && this.state.invited == false && (
                  <>
                    <Grid component={FormControl} size={{ md: 6, }}>
                      <FormLabel htmlFor="company" error={!!errors.company} >Company</FormLabel>
                      <TextField
                        id="company"
                        type="text"
                        name="company"
                        value={company}
                        onChange={this.handleChange}
                        placeholder="Company"
                        autoComplete="company"
                        fullWidth
                        variant="outlined"
                        size="large"
                        color={errors.company ? 'error' : 'primary'}
                        slotProps={{
                          input: {
                            endAdornment: <InputAdornment position="end"  >
                              <Typography color='error'>
                                *
                              </Typography>
                            </InputAdornment>,
                          },
                        }}
                      />
                    </Grid>

                    <Grid component={FormControl} size={{ md: 6, }}>
                      <FormLabel htmlFor="accountName" error={!!errors.accountName} >Account Name</FormLabel>
                      <TextField
                        id="accountName"
                        type="text"
                        name="accountName"
                        value={accountName}
                        onChange={this.handleChange}
                        placeholder="Account Name"
                        autoComplete="accountName"
                        fullWidth
                        variant="outlined"
                        size="large"
                        color={errors.accountName ? 'error' : 'primary'}
                        slotProps={{
                          input: {
                            endAdornment: <InputAdornment position="end"  >
                              <Typography color='error'>
                                *
                              </Typography>
                            </InputAdornment>,
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}

              </Grid>
              <Collapse in={!!this.state.alert}>
                <Alert
                  severity={this.state.alert?.type} // dynamic severity (error, success, etc.)
                  action={
                    <IconButton
                      aria-label="close"
                      size="small"
                      onClick={() => this.setState({ alert: null })} // Close the alert
                    >
                      <CancelIcon />
                    </IconButton>
                  }
                >
                  {this.state.alert?.message.split('\n').map((msg, index) => (
                    <Box key={index}>{msg}</Box> // Render each message on a new line
                  ))}
                </Alert>
              </Collapse>
              {/* ---Button--- */}
              <Stack spacing={2.5} alignItems={'center'}>
                <Box>
                  <Button size='large' color='primary' type="submit" variant='contained'>CREATE YOUR ACCOUNT</Button>
                </Box>
              </Stack>

            </Stack>
            {this.state.isComponentReady && this.state.invited == false && !this.state.isAccountNameSetSso && (
              <>
                <Divider> <Typography variant="body2" >or</Typography></Divider>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Button
                    fullWidth
                    size='large'
                    variant="outlined"
                    color='secodary'
                    onClick={() => this.handleGoogleLogin('register')}
                    startIcon={<img src={GoogleIcon} alt="Google-icon" />}
                    sx={{
                      justifyContent: 'start'
                    }}
                  >
                    Continue with Google
                  </Button>
                </Box>
              </>
            )}
          </Card>
        </Box>
        <NotificationContainer />
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  isRegister: state.Register.isRegister,
  error: state.Register.registerErr,
  errMessage: state.Register.errMessage,
  registerMsg: state.Register.registerMsg,
  loading: state.Loader.loading,
  data: state.Register.data
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userRegister: Actions.userRegisterRequest,
      startLoading: Actions.startLoading,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(RegisterPage);
