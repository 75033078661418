import { Constants } from 'ReduxStore/Actions';
import { createReducer } from "reduxsauce";

type VisibleColumns = {
    id: string;
    show: boolean;
    order: number;
}[]

type Query = {
    column: string,
    condition: string,
    value: string,
    fieldType: string,
    isCustomField: boolean,
    label?: string,
}

export type Filter = {
    _id?: string;
    name: string;
    color: string;
    sorting: string;
    query: Query[];
}

type ModuleName = 'opportunities' | 'accounts' | 'contacts' | 'inbox';

type UserPreferences = {
    moduleName: ModuleName;
    visibleColumns: VisibleColumns;
    filters: Filter[];
    lastAppliedFilter: Filter;
}

export type UserPreferencesState = {
    isLoading: boolean,
    fetchUserPreferencesSuccess: boolean,
    updateUserPreferencesSuccess: boolean,
    error: boolean,
    message: string,
    userPreferences: UserPreferences[],
    deleteFilterSuccess: boolean,
    appliedQuickFilter: Filter | null,
};

const INITIAL_STATE: UserPreferencesState = {
    isLoading: false,
    fetchUserPreferencesSuccess: false,
    updateUserPreferencesSuccess: false,
    error: false,
    message: '',
    userPreferences: [],
    deleteFilterSuccess: false,
    appliedQuickFilter: null,
};

const fetchUserPreferencesRequest = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

const fetchUserPreferencesSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, fetchUserPreferencesSuccess: true, message: action.message, userPreferences: action.data };
};

const fetchUserPreferencesError = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, error: true, message: action.message };
};

const updateUserPreferencesRequest = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: true, updateUserPreferencesSuccess: false, error: false, message: '', userPreferences: action.data };
};

const updateUserPreferencesSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, updateUserPreferencesSuccess: true, message: action.message, userPreferences: action.data };
};

const updateUserPreferencesError = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, updateUserPreferencesSuccess: false, error: true, message: action.message };
};

const deleteFilterRequest = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: true, deleteFilterSuccess: false, error: false, message: '' };
};

const deleteFilterSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, deleteFilterSuccess: true, message: action.message, 
        userPreferences: action.data 
    };
};

const deleteFilterError = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, deleteFilterSuccess: false, error: true, message: action.message };
};

const setAppliedQuickFilter = (state = INITIAL_STATE, action: any) => {
    const updatedUserPreferences = state.userPreferences.map(preference => 
        preference.moduleName === action.data.moduleName 
            ? { ...preference, lastAppliedFilter: action.data.filter }
            : preference
    );
    
    return { 
        ...state, 
        appliedQuickFilter: action.data.filter,
        userPreferences: updatedUserPreferences
    };
};

export const HANDLERS = {
    [Constants.FETCH_USER_PREFERENCES_REQUEST]: fetchUserPreferencesRequest,
    [Constants.FETCH_USER_PREFERENCES_SUCCESS]: fetchUserPreferencesSuccess,
    [Constants.FETCH_USER_PREFERENCES_ERROR]: fetchUserPreferencesError,
    [Constants.UPDATE_USER_PREFERENCES_REQUEST]: updateUserPreferencesRequest,
    [Constants.UPDATE_USER_PREFERENCES_SUCCESS]: updateUserPreferencesSuccess,
    [Constants.UPDATE_USER_PREFERENCES_ERROR]: updateUserPreferencesError,
    [Constants.DELETE_FILTER_REQUEST]: deleteFilterRequest,
    [Constants.DELETE_FILTER_SUCCESS]: deleteFilterSuccess,
    [Constants.DELETE_FILTER_ERROR]: deleteFilterError,
    [Constants.SET_APPLIED_QUICK_FILTER]: setAppliedQuickFilter,
};

export default createReducer(INITIAL_STATE, HANDLERS);

