import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
} from '@mui/material';
import { Opportunity } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import { useEditableField } from '../Opportunity/List/hooks/useEditableField';
import EditableTextField from '../../components/EditableTextField';

interface EditableNameCellProps {
  opp: Opportunity;
}

export const EditableNameCell: React.FC<EditableNameCellProps> = ({ opp }) => {
  const [opportunity, setOpportunity] = useState(opp);

  const {
    showInputField,
    textFieldValue,
    error,
    handleOpenEditField,
    handleUpdateOneField,
    handleClickAway,
    setTextFieldValue,
    handleValidateDuplicateName,
  } = useEditableField(opportunity, setOpportunity);

  useEffect(() => {
    let timeout: any;
    if (showInputField) {
      timeout = setTimeout(() => {
        handleValidateDuplicateName();
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [textFieldValue]);

  if (showInputField) {
    return (
      <EditableTextField
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        onSave={handleUpdateOneField}
        error={error}
        placeholder="enter name here"
      />
    );
  }

  return (
    <Box
      px={1.5}
      py={'8.2px'}
      sx={{
        border: '1px solid transparent',
        '&:hover': { borderColor: '#000', borderRadius: '8px' },
      }}
    >
      <Stack direction={'row'} spacing={1}>
        <Box onClick={handleOpenEditField}>{opportunity.name}</Box>
      </Stack>
    </Box>
  );
}
