import { call, put, delay } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import OpportunityAccountsCustomFieldsAPI from 'ReduxStore/API/opportunity/opportunityCustomFieldsAPI';
import { removeStorage } from 'helpers/common';

function* createOpportunityCustomField(action) { 
  const opportunityAccountsCustomFieldsAPI = new OpportunityAccountsCustomFieldsAPI();
  const response = yield call(opportunityAccountsCustomFieldsAPI.createOpportunityCustomField, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.createOpportunityCustomFieldSuccess(
          response.data.customFields,
        ),
      );
      yield put(
        Actions.updateFieldGroupSuccess(response.data.updatedGroup) // Modify this according to the required data
      );
    } else if (response.data.status === 403) {
      yield put(Actions.createOpportunityCustomFieldError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.createOpportunityCustomFieldError(response.data.message));
    }
  } else {
    yield put(Actions.createOpportunityCustomFieldError(response.data.message));
  }
}

function* deleteOpportunityCustomField(action) {
  const opportunityAccountsCustomFieldsAPI = new OpportunityAccountsCustomFieldsAPI();
  const response = yield call(opportunityAccountsCustomFieldsAPI.deleteOpportunityCustomField, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.deleteOpportunityCustomFieldSuccess(
          response.data.customFields,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.deleteOpportunityCustomFieldError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteOpportunityCustomFieldError(response.data.message));
    }
  } else {
    yield put(Actions.deleteOpportunityCustomFieldError(response.data.message));
  }
}

function* updateOpportunityCustomFieldOLD(action) { 
  const opportunityAccountsCustomFieldsAPI = new OpportunityAccountsCustomFieldsAPI();
  const response = yield call(opportunityAccountsCustomFieldsAPI.updateOpportunityCustomField, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.updateOpportunityCustomFieldSuccess(
          response.data.customFields,
          response.data.message,
          response.data.updatedCustomField,
        ),
      );
      yield put(
        Actions.updateFieldGroupSuccess(response.data.updatedGroup) // Modify this according to the required data
      );
    } else if (response.data.status === 403) {
      yield put(Actions.updateOpportunityCustomFieldError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateOpportunityCustomFieldError(response.data.message));
    }
  } else {
    yield put(Actions.updateOpportunityCustomFieldError(response.data.message));
  }
}

function* updateOpportunityCustomField(action) { 
  const opportunityAccountsCustomFieldsAPI = new OpportunityAccountsCustomFieldsAPI();
  const response = yield call(opportunityAccountsCustomFieldsAPI.updateOpportunityCustomField, action.data);
 
  if (response.status === 200) {
    if (response.data.success) {
      // **If the field was updated in the same group**
      yield put(
        Actions.updateOpportunityCustomFieldSuccess(
          response.data.customFields,
          response.data.message,
          response.data.updatedCustomField,
        ),
      ); 
      if (response.data?.updatedGroup) {
        yield put(Actions.updateFieldGroupSuccess(response.data.updatedGroup)); //  Update only the modified group
      }

      // **If the field was moved to a new group, update all groups**
      if (response.data?.fieldManagementGroups) {
        yield put(
          Actions.getFieldGroupsListSuccess(response.data.fieldManagementGroups),
        );
      } 
    } else if (response.data.status === 403) {
      yield put(Actions.updateOpportunityCustomFieldError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateOpportunityCustomFieldError(response.data.message));
    }
  } else {
    yield put(Actions.updateOpportunityCustomFieldError(response.data.message));
  }
}

function* fetchAllOpportunityCustomField(action) {
  const opportunityAccountsCustomFieldsAPI = new OpportunityAccountsCustomFieldsAPI();
  const response = yield call(opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.fetchAllOpportunityCustomFieldSuccess(
          response.data.customFields,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.fetchAllOpportunityCustomFieldError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.fetchAllOpportunityCustomFieldError(response.data.message));
    }
  } else {
    yield put(Actions.fetchAllOpportunityCustomFieldError(response.data.message));
  }
}


function* deleteOpportunityCustomFieldSetting(action) { 
  const opportunityAccountsCustomFieldsAPI = new OpportunityAccountsCustomFieldsAPI();
  const response = yield call(opportunityAccountsCustomFieldsAPI.deleteOpportunityCustomFieldSetting, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.deleteOpportunityCustomFieldSettingSuccess(
          response.data.customFields,
        ),
      );

      yield put(
        Actions.updateFieldGroupSuccess(response.data.updatedGroup) // Modify this according to the required data
      );
    } else if (response.data.status === 403) {
      yield put(Actions.deleteOpportunityCustomFieldSettingError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteOpportunityCustomFieldSettingError(response.data.message));
    }
  } else {
    yield put(Actions.deleteOpportunityCustomFieldSettingError(response.data.message));
  }
}

const opportunityCustomFieldsSaga = {
  deleteOpportunityCustomField,
  updateOpportunityCustomField,
  createOpportunityCustomField,
  fetchAllOpportunityCustomField,
  deleteOpportunityCustomFieldSetting
};

export default opportunityCustomFieldsSaga;