import {
  Box,
  Button,
  ClickAwayListener,
  SelectChangeEvent,
} from '@mui/material';
import { rgbToHex2 } from 'helpers/common';
import { useSelector } from 'react-redux';
import { OpportunityStates_State } from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import { useState } from 'react';
import {
  Opportunity,
  StateType,
} from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import StateDropdown from '../components/StateDropdown';
import OpportunityOpportunitiesAPI from 'ReduxStore/API/opportunity/opportunityOpportunitiesAPI';

const StateCell = ({ opp }: { opp: Opportunity }) => {
  const [opportunity, setOpportunity] = useState(opp);
  const [showListField, setShowListField] = useState(false);
  const [listFieldValue, setListFieldValue] = useState<StateType | null>(null);
  const { opportunityStates: opportunityDynamicStates } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const handleOpenEditField = (state: StateType) => {
    setShowListField(true);
    setListFieldValue(state);
  };

  const getStateColor = (opportunity) => {
    // Ensure opportunity and state exist and have at least one element
    if (opportunity?.state?.length > 0) {
      const defaultState = opportunityDynamicStates.find(
        (state) => state.name === opportunity.state[0].name,
      );
      // Return textColor and backgroundColor if found, else return default values
      if (defaultState) {
        return {
          textColor: defaultState.textColor
            ? rgbToHex2(defaultState.textColor)
            : '#000056', // Default text color
          backgroundColor:
            rgbToHex2(defaultState.backgroundColor) || '#ffffff00', // Default background color
        };
      }
    }

    // If state length is 0, return no color for both
    return {
      textColor: '',
      backgroundColor: '',
    };
  };

  const handleUpdateOneField = async (e: SelectChangeEvent<string>) => {
    const selectedState = JSON.parse(e.target.value);
    const opportunityBeforeUpdate = { ...opportunity };
    try {
      // Optimistic update
      setOpportunity({
        ...opportunity,
        // @ts-ignore
        state: [
          {
            _id: selectedState._id,
            name: selectedState.name,
          },
        ],
      });
      setShowListField(false);

      const data = {
        opportunityId: opportunity._id,
        field: `state`,
        value: [
          {
            _id: selectedState._id,
            name: selectedState.name,
          },
        ],
      };
      const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
      const response = await opportunityOpportunitiesAPI.updateOneField(data);
      if (response.status === 200) {
        if (response.data.success) {
          setOpportunity(response.data.opportunity);
          setShowListField(false);
        }
      }
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setOpportunity(opportunityBeforeUpdate);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setShowListField(false)}>
      <Box>
        {showListField ? (
          <Box sx={{ position: 'relative' }}>
            <StateDropdown
              label="State"
              name="dynamicState"
              dynamicStates={opportunityDynamicStates}
              selectedState={listFieldValue}
              handleStateChange={(e) => {
                handleUpdateOneField(e);
                setShowListField(false);
              }}
              showListField={showListField}
              setShowListField={setShowListField}
              onClose={() => setShowListField(false)}
            />
          </Box>
        ) : opportunity?.state[0]?.name ? (
          <Box
            component={'span'}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 0.5,
              '&:hover .editable-status': {
                display: 'block',
              },
              '&:hover .table-status': {
                display: 'none',
              },
            }}
          >
            <Box
              px={1.5}
              py={'8.2px'}
              sx={{
                border: '1px solid transparent',
                display: 'none',
                '&:hover': { borderColor: '#000', borderRadius: '8px' },
              }}
              className="editable-status"
              onClick={() => handleOpenEditField(opportunity?.state[0])}
            >
              {' '}
              {opportunity?.state[0]?.name || '-'}
            </Box>
            <Box
              component={'span'}
              className="table-status"
              sx={{
                color: `${getStateColor(opportunity).textColor} !important`,
                bgcolor: `${getStateColor(opportunity).backgroundColor}`,
                px:
                  getStateColor(opportunity).backgroundColor === '#ffffff00' &&
                  0,
              }}
            >
              {opportunity?.state[0]?.name || '-'}
            </Box>
          </Box>
        ) : (
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: '15px', px: 0 }}
            onClick={() => handleOpenEditField(null)}
          >
            + Click to Add
          </Button>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default StateCell;
