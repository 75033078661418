import {createActions} from "reduxsauce"
 
const {Types, Creators} = createActions({
    createOpportunityCustomFieldRequest: ['data'],
    createOpportunityCustomFieldSuccess: ['customFields', 'message',"actionType"],  
    createOpportunityCustomFieldError: ['message'],
    deleteOpportunityCustomFieldRequest: ['data'],
    deleteOpportunityCustomFieldSuccess: ['customFields', 'message'],
    deleteOpportunityCustomFieldError: ['message'],
    updateOpportunityCustomFieldRequest: ['data'],
    updateOpportunityCustomFieldSuccess: ['customFields', 'message', 'updatedCustomField', "actionType"],  
    updateOpportunityCustomFieldError: ['message'],
    fetchAllOpportunityCustomFieldRequest: ['data'],
    fetchAllOpportunityCustomFieldSuccess: ['customFields'],
    fetchAllOpportunityCustomFieldError: ['message'], 
    deleteOpportunityCustomFieldSettingRequest: ['data'],
    deleteOpportunityCustomFieldSettingSuccess: ['customFields', 'message'],
    deleteOpportunityCustomFieldSettingError: ['message'],
    clearFieldMessage: [],  // Clears success messages
})

export const Constants = Types
export default Creators