import React, { useState } from 'react';
import { Box, Stack, Checkbox, IconButton, Badge, Button, Chip, Tooltip, Typography } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

import {
  StartIconOutlined,
  StartIcon,
  AttachmentIcon,
  BoltIcon,
  BoltOutlinedIcon
} from '../../components/CustomIcons';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LowPriorityIcon from '@mui/icons-material/LowPriority';

import {
  xpAccountIntegrationGmailMarkEmailAsBookmarked,
  xpAccountIntegrationGmailPinUnpinThread,
  xpAccountIntegrationGmailUnBookmarkMultipleEmails,
  xpAccountIntegrationGmailPriorityThread,
} from 'ReduxStore/API';
import { createNotification } from 'helpers';
import { useNavigate } from 'react-router';
import CustomHTMLTooltip from 'Components/CustomHTMLTooltip';
import moment from 'moment';
import Loader from 'Components/Loader/loader';
import ErrorBoundary from 'eijent/components/ErrorBoundary';
import { MailType, Message, GmailData } from './common';

type Gmail = {
  _id: string;
  email: string;
  messages: Message[];
  isPinned: boolean;
  isPriority: boolean;
};

type Props = {
  selectedCheckboxes: string[];
  setSelectedCheckboxes: React.Dispatch<React.SetStateAction<string[]>>;
  gmailData: GmailData;
  getGmailData: (
    limit?: number,
    page_no?: number,
    showLoader?: boolean,
  ) => Promise<void>;
  gmailId: string;
  setGmailData: React.Dispatch<React.SetStateAction<GmailData>>;
  mailType: MailType;
  setLabelIdsArray: React.Dispatch<React.SetStateAction<string[]>>;
  redirectToGmailView: (threadId: string, index: any) => void;
  sideBarType: string;
  internalTab: string;
  limit: number;
  page_no: number;
  setEndCount: React.Dispatch<React.SetStateAction<number>>;
  setPage_no: React.Dispatch<React.SetStateAction<number>>;
  setStartCount: React.Dispatch<React.SetStateAction<number>>;
  setSelectedDraftIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedDraftIds: string[];
};

export default function List({
  selectedCheckboxes,
  setSelectedCheckboxes,
  gmailData,
  getGmailData,
  gmailId,
  setGmailData,
  mailType,
  setLabelIdsArray,
  redirectToGmailView,
  sideBarType,
  internalTab,
  limit,
  page_no,
  setEndCount,
  setPage_no,
  setStartCount,
  setSelectedDraftIds,
  selectedDraftIds,
}: Props) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  // const [
  //   openPermanentDeleteConfirmationModel,
  //   setOpenPermanentDeleteConfirmationModel,
  // ] = useState<boolean>(false);

  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mailId: string,
    labelArray: string[],
    draftId?: string,
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setLabelIdsArray((prev) => [...prev, ...labelArray]);
      setSelectedCheckboxes((prev) => [...prev, mailId]);
      if (mailType === 'DRAFT') {
        setSelectedDraftIds((prev) => [...prev, draftId]);
      }
    } else {
      setLabelIdsArray((prev) => filterOnce(prev, labelArray));
      setSelectedCheckboxes((prev) => prev.filter((id) => id !== mailId));
      if (mailType === 'DRAFT') {
        setSelectedDraftIds((prev) => prev.filter((id) => id !== draftId));
      }
    }
  };

  const filterOnce = (source, toRemove) => {
    const toRemoveCount = {};
    toRemove.forEach((item) => {
      toRemoveCount[item] = (toRemoveCount[item] || 0) + 1;
    });
    return source.filter((item) => {
      if (toRemoveCount[item]) {
        toRemoveCount[item]--;
        return false; // Remove this occurrence
      }
      return true; // Keep this item
    });
  };

  //The following function may be required later

  // const closeDeleteConfirmationModel = () => {
  //   setOpenDeleteConfirmationModel(false);
  // };

  // const closePermanentDeleteConfirmationModel = () => {
  //   setOpenPermanentDeleteConfirmationModel(false);
  // };

  const renderSendersName = (gmail: { messages: Message[]; }) => {
    let existingSendersNames = [];
    let existingSendersEmails = [];
    gmail.messages.forEach((message: any) => {
      const fromHeader = message.payload.headers.find(
        (header: any) => header.name === 'From',
      );

      if (!fromHeader?.value) return null;

      // Extract name and email
      const nameOnly = fromHeader.value.replace(/<.*?>/g, '').trim();
      !existingSendersNames.includes(nameOnly) &&
        existingSendersNames.push(nameOnly);

      const emailOnly = fromHeader.value.match(/<([^>]+)>/)?.[1] || '';
      !existingSendersEmails.includes(emailOnly) &&
        existingSendersEmails.push(emailOnly);
    });

    return (

      <span>
        {existingSendersNames
          .map((name) => (name === gmailId ? 'me' : name))
          .join(', ')}
      </span>
    );
  };

  const markLastEmailAsBookmarked = async (messageId: string) => {
    try {
      setGmailDataAfterBookmarkingEmail(messageId);
      const data = {
        messageId: messageId,
        gmailId: gmailId,
      };

      const res = await xpAccountIntegrationGmailMarkEmailAsBookmarked(data);

      if (res.data.success) {
        getGmailData(undefined, undefined, false);
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const setGmailDataAfterBookmarkingEmail = (messageId) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            message.id === messageId &&
            !message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds
            return {
              ...message,
              labelIds: [...message.labelIds, 'STARRED'],
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const renderAttachmentCount = (gmail) => {
    let count = 0;
    gmail.messages.forEach((message) => {
      if (
        message?.payload.mimeType === 'multipart/mixed' &&
        message?.payload.parts &&
        Array.isArray(message?.payload.parts) &&
        message?.payload?.parts?.length > 1
      ) {
        count += message?.payload?.parts?.length - 1;
      }
    });
    if (count > 0) {
      return (
        <Badge color="primary" badgeContent={count}>
          <AttachmentIcon />
        </Badge>
      );
    } else {
      return null;
    }
  };

  const redirectToCompose = (mail: Message) => {
    if (mail) {
      const subjectDetail = mail?.payload?.headers.find(
        (detail) => detail.name === 'Subject',
      );
      const sendTo = mail?.payload?.headers.find(
        (detail) => detail.name === 'To',
      );
      const { payload } = mail;
      let payloadForDraft = payload;
      let messageIdForDraft = mail.id;
      let draftId = mail.draftId;
      let subject = subjectDetail?.value;
      let recipients = sendTo?.value;
      let editorText = mail?.snippet;

      const composeState = {
        payloadForDraft,
        messageIdForDraft,
        draftId,
        subject,
        recipients,
        editorText,
      };

      navigate(`/inbox/drafts/compose/${mail.draftId}`);
    }
  };

  const unBookmarkMultipleEmails = async (messages) => {
    try {
      const messageIds = messages
        .filter((message) => message.labelIds.includes('STARRED'))
        .map((message) => message.id);

      setGmailDataAfterUnbookmarkingMultipleEmails(messageIds);
      const data = {
        messageIds: messageIds,
        gmailId: gmailId,
      };

      const res = await xpAccountIntegrationGmailUnBookmarkMultipleEmails(data);

      if (res.data.success) {
        getGmailData(undefined, undefined, false);
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const setGmailDataAfterUnbookmarkingMultipleEmails = (messageIds) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            messageIds.includes(message.id) &&
            message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds (removing "STARRED")
            return {
              ...message,
              labelIds: message.labelIds.filter((label) => label !== 'STARRED'),
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const handleMailAttributeToggle = async (
    threadId: string,
    isEnabled: boolean,
    tabType: 'pinned' | 'priority',
    makeApiCall: (threadId: string, isEnabled: boolean) => Promise<void>,
    attributeName: 'isPinned' | 'isPriority',
  ) => {
    // Update local state first
    if (internalTab === tabType && isEnabled && gmailData.data.length === 1) {
      makeApiCall(threadId, isEnabled);
      getGmailData(undefined, page_no === 1 ? 1 : page_no - 1, true);
      setPage_no(page_no === 1 ? 1 : page_no - 1);
      setStartCount(page_no === 1 ? 1 : (page_no - 2) * limit + 1);
    } else {
      setGmailData((prev) => {
        const updatedData = prev.data
          .map((mail) => {
            if (mail.messages[0].threadId === threadId) {
              return {
                ...mail,
                [attributeName]: !isEnabled,
              };
            }
            return mail;
          })
          .filter((mail) => {
            if (internalTab === tabType && isEnabled) {
              return mail.messages[0].threadId !== threadId;
            }
            return true;
          });

        const newTotalCount =
          internalTab === tabType && isEnabled
            ? prev.totalMailCount - 1
            : prev.totalMailCount;

        return {
          ...prev,
          data: updatedData,
          totalMailCount: newTotalCount,
        };
      });

      if (internalTab === tabType && isEnabled) {
        setEndCount((prev) => prev - 1);
      }

      makeApiCall(threadId, isEnabled);
    }
  };

  const handlePinUnpin = async (threadId: string, isPinned: boolean) => {
    handleMailAttributeToggle(
      threadId,
      isPinned,
      'pinned',
      makeApiCallForPinUnpin,
      'isPinned',
    );
  };

  const handlePriorityToggle = async (
    threadId: string,
    isPriority: boolean,
  ) => {
    handleMailAttributeToggle(
      threadId,
      isPriority,
      'priority',
      makeApiCallForPriorityToggle,
      'isPriority',
    );
  };

  const makeApiCallForAttributeToggle = async (
    threadId: string,
    isEnabled: boolean,
    apiFunction: Function,
    attribute: 'isPinned' | 'isPriority',
    errorMessage: string,
  ) => {
    try {
      const data = {
        threadIds: [threadId],
        [attribute]: !isEnabled,
        gmailId: gmailId,
      };
      const res = await apiFunction(data);

      if (!res.data.success) {
        // Revert state if API call fails
        revertGmailDataState(threadId, isEnabled, attribute);
        createNotification('error', errorMessage);
      }else {
        createNotification('success', res.data.message);
      }
    } catch (error) {
      console.log('error=======', error);
      // Revert state on error
      revertGmailDataState(threadId, isEnabled, attribute);
      createNotification('error', errorMessage);
    }
  };

  const revertGmailDataState = (
    threadId: string,
    originalValue: boolean,
    attribute: 'isPinned' | 'isPriority',
  ) => {
    setGmailData((prev) => ({
      ...prev,
      data: prev.data.map((mail) => {
        if (mail.messages[0].threadId === threadId) {
          return {
            ...mail,
            [attribute]: originalValue,
          };
        }
        return mail;
      }),
      totalMailCount: prev.totalMailCount + 1,
    }));
  };

  const makeApiCallForPriorityToggle = async (
    threadId: string,
    isPriority: boolean,
  ) => {
    await makeApiCallForAttributeToggle(
      threadId,
      isPriority,
      xpAccountIntegrationGmailPriorityThread,
      'isPriority',
      'Failed to update priority status',
    );
  };

  const makeApiCallForPinUnpin = async (
    threadId: string,
    isPinned: boolean,
  ) => {
    await makeApiCallForAttributeToggle(
      threadId,
      isPinned,
      xpAccountIntegrationGmailPinUnpinThread,
      'isPinned',
      'Failed to update pin status',
    );
  };

  return (
    <ErrorBoundary>
      <Box flexGrow={1} overflow={'auto'}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="Email List">
            <TableBody>
              {Array.isArray(gmailData?.data) && gmailData?.data.length > 0 ? (
                gmailData?.data.map(
                  (
                    gmail: Gmail,
                    index: number,
                  ) => (
                    <TableRow
                      key={index}
                      sx={{
                        cursor: 'pointer',
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': {
                          backgroundColor: '#F4F6F7',
                        },
                        backgroundColor: selectedCheckboxes.includes(
                          gmail?.messages[0]?.threadId,
                        )
                          ? '#E6FDF4 !important'
                          : '',
                        px: 2.25,
                      }}
                    >
                      <TableCell align='center' sx={{ width: '44px', pl: 4, pr: 1 }}>
                        <Checkbox
                          onClick={(e) => {
                            handleSelectCheckBox(
                              e,
                              gmail?.messages[0]?.threadId,
                              gmail?.messages[0]?.labelIds,
                              gmail?.messages[0]?.draftId,
                            );
                          }}
                          checked={selectedCheckboxes.includes(
                            gmail?.messages[0]?.threadId,
                          )}
                        />
                      </TableCell>
                      {mailType !== 'TRASH' && (
                        <TableCell align='center' sx={{ width: '30px', px: '0px' }}>
                          <IconButton
                            title="Bookmark"
                            onClick={() =>
                              gmail?.messages?.some((message) =>
                                message.labelIds.includes('STARRED'),
                              )
                                ? unBookmarkMultipleEmails(gmail?.messages)
                                : markLastEmailAsBookmarked(
                                  gmail?.messages?.at(-1)?.id,
                                )
                            }
                            size="small"
                            // @ts-ignore
                            color="transparent"
                            variant="contained"
                          >
                            {gmail?.messages?.some((message) =>
                              message?.labelIds?.includes('STARRED'),
                            ) ? (
                              <StartIcon />
                            ) : (
                              <StartIconOutlined />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                      {mailType !== 'TRASH' && (
                        <TableCell align='center' sx={{ width: '30px', px: '0px' }}>
                          {gmail.isPriority ? (
                            <IconButton
                              title="Priority High"
                              size="small"
                              // @ts-ignore
                              color="transparent"
                              variant="contained"
                              onClick={() => {
                                handlePriorityToggle(
                                  gmail?.messages[0]?.threadId,
                                  true,
                                );
                              }}
                            >
                              <BoltIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              title="Priority Low"
                              size="small"
                              // @ts-ignore
                              color="transparent"
                              variant="contained"
                              onClick={() => {
                                handlePriorityToggle(
                                  gmail?.messages[0]?.threadId,
                                  false,
                                );
                              }}
                            >
                              <BoltOutlinedIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                      {mailType !== 'TRASH' && (
                        <TableCell align='center' sx={{ width: '30px', px: '0px' }}>
                          <IconButton
                            title="Pin"
                            onClick={() => {
                              /* Add pin handling logic here */
                            }}
                            size="small"
                            // @ts-ignore
                            color="transparent"
                            variant="contained"
                          >
                            {gmail.isPinned ? (
                              <PushPinIcon
                                onClick={() => {
                                  handlePinUnpin(
                                    gmail?.messages[0]?.threadId,
                                    true,
                                  );
                                }}
                              />
                            ) : (
                              <PushPinOutlinedIcon
                                onClick={() => {
                                  handlePinUnpin(
                                    gmail?.messages[0]?.threadId,
                                    false,
                                  );
                                }}
                              />
                            )}
                          </IconButton>
                        </TableCell>
                      )}

                      <TableCell
                        sx={{
                          fontWeight: gmail.messages.some((message) =>
                            message.labelIds.includes('UNREAD'),
                          )
                            ? '700'
                            : '400',
                          width: 150,
                          maxWidth: 300,
                        }}
                        onClick={() =>
                          mailType === 'DRAFT'
                            ? redirectToCompose(gmail?.messages[0])
                            : redirectToGmailView(
                              gmail?.messages[0]?.threadId,
                              index,
                            )
                        }
                      >
                        {mailType === 'SENT' ? (
                          <>
                            {(() => {
                              const senderNamesArray = gmail?.messages[0]?.payload.headers
                                .find((header: any) => header.name === 'To')
                                ?.value?.split(',')
                                .map(name => name.trim()) || [];

                              return (
                                <>
                                  <span>{senderNamesArray[0]}</span>
                                  {senderNamesArray.length > 1 && (
                                    <Tooltip
                                      arrow
                                      placement="bottom"
                                      title={
                                        <Stack spacing={0.5} alignItems="start">
                                          {senderNamesArray.slice(1).map((senderName, i) => (
                                            <>
                                              {/* <Chip
                                              key={i}
                                              label={senderName}
                                              size="medium"
                                              variant="outlined"
                                            /> */}
                                              <Typography variant='subtitle2' key={i} >
                                                {senderName}
                                              </Typography>
                                            </>
                                          ))}
                                        </Stack>
                                      }
                                    >
                                      <Button
                                        //@ts-ignore
                                        variant="tag"
                                        size="small"
                                        sx={{
                                          ml: 0.5,
                                          backgroundColor: '#FBE5FE',
                                          '&:hover': {
                                            backgroundColor: '#FBE5FE',
                                          },
                                          '&:active': {
                                            backgroundColor: '#FBE5FE',
                                          },
                                        }}
                                      >
                                        +{senderNamesArray.length - 1} more
                                      </Button>
                                    </Tooltip>
                                  )}
                                </>
                              );
                            })()}
                          </>
                        ) : mailType !== 'DRAFT' ? (
                          renderSendersName(gmail)
                        ) : (
                          <span>
                            {
                              gmail?.messages[0]?.payload.headers.find(
                                (header: any) => header.name === 'From',
                              )?.value
                            }
                          </span>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: gmail.messages.some((message) =>
                            message.labelIds.includes('UNREAD'),
                          )
                            ? '700'
                            : '400',
                        }}
                        onClick={() =>
                          mailType === 'DRAFT'
                            ? redirectToCompose(gmail?.messages[0])
                            : redirectToGmailView(
                              gmail?.messages[0]?.threadId,
                              index,
                            )
                        }
                      >
                        <Stack
                          direction={'row'}
                          className="email-title"
                          spacing={1}
                        >
                          <Box
                            // style={{ maxWidth: '100%' }}
                            onClick={() =>
                              mailType === 'DRAFT'
                                ? redirectToCompose(gmail.messages[0])
                                : redirectToGmailView(
                                  gmail?.messages[0]?.threadId,
                                  index,
                                )
                            }
                          >
                            <Box component={'span'}
                              sx={{
                                fontWeight: gmail.messages.some((message) =>
                                  message.labelIds.includes('UNREAD'),
                                )
                                  ? '700'
                                  : '400',
                              }}
                            >
                              {
                                gmail?.messages[0]?.payload?.headers?.find(
                                  (g: any) => g.name === 'Subject',
                                )?.value
                              }{' '}
                            </Box>{' '}
                            -{' '}
                            {gmail?.messages[gmail?.messages?.length - 1]
                              ?.snippet?.length > 80
                              ? `${gmail?.messages[
                                gmail?.messages?.length - 1
                              ]?.snippet?.slice(0, 80)}...`
                              : gmail?.messages[gmail?.messages?.length - 1]
                                ?.snippet}
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ width: '44px', }}>
                        {renderAttachmentCount(gmail)}
                      </TableCell>
                      <TableCell align="right"

                        sx={{
                          fontWeight: gmail.messages.some((message) =>
                            message.labelIds.includes('UNREAD'),
                          )
                            ? '700'
                            : '400',
                          minWidth: '130px', pr: 4
                        }}>
                        {moment(
                          gmail?.messages[0]?.payload?.headers?.find(
                            (g: any) => g.name === 'Date',
                          )?.value,
                        ).format('MMM, DD')}
                      </TableCell>
                    </TableRow>
                  ),
                )
              ) : (
                <TableRow style={{ cursor: 'pointer' }}>
                  <TableCell sx={{ textAlign: 'center' }} colSpan={5}>
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {isLoading && <Loader />}
      </Box>
    </ErrorBoundary >
  );
}
