import * as React from 'react';
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { Alert, Checkbox, ClickAwayListener, Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, MenuItem, Select, Stack, TextField, Tooltip, Typography, } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  CancelIcon,
  InfoIcon,
} from "../../../components/CustomIcons";
import { FieldManagementGroupsCreators } from 'ReduxStore/Actions/fieldManagementGroups.action';
import { toCamelCase } from 'helpers';
import Loader from 'Components/Loader/loader';

type ValidationErrors = {
  groupName?: string;
  internalName?: string;
};

type AlertType = "success" | "error" | "warning" | "info"; // Define alert types

interface AlertState {
  type: AlertType;
  message: string;
}

export default function AddGroup({
  type,
  isEditMode = false,
  existingData = null,
  openDrawer,
  onClose
}: {
  type: "opportunity" | "accounts" | "contacts";
  isEditMode?: boolean;
  existingData?: { _id: any; name: string; internalName: string; } | null;
  openDrawer: boolean;
  onClose: () => void;
}) {
  const location = useLocation();
  const lastPath = location.pathname.split("/").filter(Boolean).pop();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [groupName, setGroupName] = React.useState("");
  const [internalName, setInternalName] = React.useState("");
  const [opportunityGroupType, setOpportunityGroupType] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState<AlertState | null>(null); 
  const [errors, setErrors] = React.useState<ValidationErrors>({});
  const fieldGroupRedux = useSelector((state: any, ) => state.FieldManagementGroups);
  const {loading } = useSelector((state: any) => state.FieldManagementGroups);
  // Pre-fill form fields when in edit mode
  React.useEffect(() => {
    if (isEditMode && existingData) {
      setGroupName(existingData.name || "");
      setInternalName(existingData.internalName || "");
    }
  }, [isEditMode, existingData]);


  // Set opportunity group type from prop
  React.useEffect(() => {
    setOpportunityGroupType(type); // directly from prop
  }, [type]);



  // Function to handle "Save" button click
  const handleSave = () => {
    const validationErrors = validateForm(groupName, internalName);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      handleAlert("error", "Please fill in all mandatory fields before proceeding.");
      return; //   Stop execution if validation fails
    }
    if (isEditMode) {
      //  Call update API when editing an existing group
      dispatch(FieldManagementGroupsCreators.updateFieldGroupRequest({
        groupId: existingData?._id, // Pass the group ID if needed
        name: groupName,
        internalName,
        opportunityGroupType: opportunityGroupType,
      })); 
    } else {
      dispatch(FieldManagementGroupsCreators.createFieldGroupRequest({
        name: groupName,
        internalName,
        opportunityGroupType: opportunityGroupType
      }));
      
    } 
  }; 

  React.useEffect(() => {
    if (openDrawer) {
      setAlertMessage(null); // Clear the alert on UI
      dispatch({ type: "CLEAR_MESSAGE" }); // Clear previous messages 
    }
  }, [openDrawer]);

  React.useEffect(() => {
    if (fieldGroupRedux.error && fieldGroupRedux.message) {
      handleAlert("error", fieldGroupRedux.message); 
      //Highlight group name if it already exists
      if (fieldGroupRedux.message.toLowerCase().includes("already exists")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          groupName: "This group name already exists",
        }));
      } 
      return;
    }
  
    if (!fieldGroupRedux.error && fieldGroupRedux.message) {
      handleAlert("success", isEditMode ? "Group updated successfully!" : "Group created successfully!");
   //Delay drawer close until alert shows
      setTimeout(() => {
        onClose();
        dispatch({ type: "CLEAR_MESSAGE" });
      }, 500);
    }
  }, [
    fieldGroupRedux.message,
    fieldGroupRedux.error,
  ]);
  

  const handleAlert = (type: AlertType, message: string) => {
    setAlertMessage({ type, message });
  };

  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    const formattedInternalName = toCamelCase(name);

    setGroupName(name);
    setInternalName(formattedInternalName);

    //  Validate and update errors dynamically
    setErrors((prevErrors) => {
      const newErrors = validateForm(name, formattedInternalName);
      return Object.keys(newErrors).length === 0 ? {} : newErrors;
    });
  };


  const validateForm = (groupName: string, internalName: string): ValidationErrors => {
    let errors: ValidationErrors = {}; //  Explicitly typed

    if (!groupName.trim()) {
      errors.groupName = "Group name is required";
    }

    if (!internalName.trim()) {
      errors.internalName = "Internal name is required";
    }

    return errors; //  TypeScript now recognizes the structure correctly
  };

  const drawerStyle = {
    width: 690,
    flexGrow: 1,
    overflow: 'auto',
    borderRadius: 1,
    backgroundColor: '#fff',
    paddingY: '30px',
  };

  const checkboxStyle = {
    marginLeft: 0,
    gap: 1,
    "& .MuiTypography-root": {
      fontWeight: 400,
    },
  };

  const DrawerContent = (
    <Stack sx={drawerStyle} id="drawer-container" role="presentation" >

      <Box display={'flex'} px={6} pb={2} alignItems={'start'} justifyContent={'space-between'}
      >
        <Typography variant="h2">{isEditMode ? "Edit Group" : "Add Group"}</Typography>
        <IconButton size="small" variant="contained"
          // @ts-ignore
          color="transparent"
          onClick={onClose}
          sx={{ position: 'absolute', top: 21, right: 21, }}
        >
          <CancelIcon />
        </IconButton>
      </Box>
      {/* --form-- */}
      <Box display={'flex'} flexGrow={1} px={6} flexDirection={'column'} overflow={'auto'} gap={2}>
        <FormControl fullWidth error={!!errors.groupName}>
          <FormLabel htmlFor="fieldLabel">
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Typography>
                Group Name
              </Typography>
              <Typography component={'span'} ml={-1} color='error'>*</Typography>
            </Box>
          </FormLabel>
          <TextField
            // error={emailError}
            // helperText={emailErrorMessage}
            id="fieldLabel"
            type="text"
            name="groupName"
            value={groupName}
            placeholder="Give a name for your field"
            autoFocus
            required
            fullWidth
            variant="outlined"
            //onChange={(e) => setGroupName(e.target.value)}
            onChange={handleGroupNameChange}
            // @ts-ignore
            size="large"
            // error={!!errors.groupName} //   Show red border if error
            //helperText={errors.groupName} //   Show error message
            color={errors.groupName ? 'error' : 'primary'}
          />
          {/* {errors.groupName && <Typography color="error">{errors.groupName}</Typography>} */}
        </FormControl>

        <FormControl fullWidth error={!!errors.internalName}>
          <FormLabel htmlFor="fieldLabel">

            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Typography>
                Internal Name
              </Typography>
              <Typography component={'span'} ml={-1} color='error'>*</Typography> 
              <Tooltip placement="right" title={"This is Internal Name"} arrow><Box><InfoIcon /></Box></Tooltip>
            </Box>
          </FormLabel>
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <Box sx={{
              width: '52px',
              height: '52px',
              background: '#F4F6F7',
              border: '1px solid #D9D9D9',
              borderRadius: '8px',
              py: 1.5,

            }}>
              <Typography color={errors.internalName ? 'error' : 'textDisabled'} align='center'>
                cf_
              </Typography>
            </Box>
            <TextField
              // error={emailError}
              // helperText={emailErrorMessage}
              id="fieldLabel"
              type="text"
              name="internalName"
              value={internalName}
              placeholder="Give a name for your field"
              autoFocus
              required
              fullWidth
              variant="outlined"
              onChange={(e) => setInternalName(e.target.value)}
              // @ts-ignore
              size="large"
              color={errors.internalName ? 'error' : 'primary'}
            // error={!!errors.internalName} //  Show red border if error
            // helperText={errors.internalName} //  Show error message
            />
          </Box>
          {/* {errors.internalName && <Typography color="error">{errors.internalName}</Typography>} */}
        </FormControl>
        <Collapse in={!!alertMessage}>
        <Alert
          severity={alertMessage?.type}
          action={
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => setAlertMessage(null)}
            >
              <CancelIcon />
            </IconButton>
          }
        >
          {alertMessage?.message.split("\n").map((msg, index) => (
            <Box key={index}>{msg}</Box>
          ))}
        </Alert>
      </Collapse>
      </Box>
     

      {/* ---Actions--- */}
      <Box px={6} pt={2} display={'flex'} gap={'6px'} justifyContent={'flex-end'}>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='large'
          onClick={handleSave}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Box>
    </Stack>
  );

  return (
    <div>
      {/* <Button onClick={() => toggleDrawer(true)} variant="contained" color="primary">
        {isEditMode ? "Edit Group" : "Add Group"}
      </Button> */}
      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={onClose}
        slotProps={{
          backdrop: {
            timeout: 200,
            sx: {
              // backdropFilter: "blur(4px)",
              backgroundColor: "rgba(255, 255, 255, 0.5)"
            }
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            border: 'none',
          },
        }}
        sx={{
          zIndex: 1202,
        }}
      >
        {DrawerContent}
        {(loading) && <Loader />}
      </Drawer>
    </div>
  );
}
