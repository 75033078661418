import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  userRegisterRequest: ['data'],
  userRegisterSuccess: ['payload','data'],
  userRegisterFailure: ['error'],
})

export const Constants = Types
export default Creators
