import { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransactionalEmailCreators } from "../../../../ReduxStore/Actions/transactionalEmails.action";
import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup, ButtonGroup, Tab, Checkbox, IconButton, TableSortLabel } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from '@mui/material/styles';
import EijentDashboard from 'eijent';

import {
  ArrowDownIcon,
  AddNewIcon,

  HomeIconActive,
  InboxIconActive,
  TasksIconActive,
  OpportunitiesIcon,
  OpportunitiesIconActive,
  AccountsIconActive,
  ContactsIconActive,
  StartIconOutlined,
  StartIcon,
  AttachmentIcon,
  UnCheckedIcon,
  CheckedIcon,
  CheckAllIcon,
  UnCheckAllIcon,
  DeleteIcon,
  CancelIcon,
  MoreIcon,
  SettingIcon

} from '../../../components/CustomIcons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from "react";
import Settings from '../index';
import Loader from "Components/Loader/loader";
import { TransactionalEmail, TransactionalEmailsState } from "ReduxStore/Reducers/transactionalEmails.reducer";
import { useNavigate } from "react-router";
import PaginationFunctional, { handleEndCountForPagination } from 'eijent/components/Pagination/Pagination';
import moment from "moment";
import TransactionalEmailSettingModal from "./TransactionalEmailSettingModal";
import TransactionalEmailsAPI from "ReduxStore/API/transactionalEmailsAPI";

const tableHeadStyle: React.CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '10px',
  fontSize: '12px',
  lineHeight: 1,
  color: '#c1c1c1',
  borderColor: '#C1C1C1',
  backgroundColor: '#ffffff',
};

export default function TransactionalEmailList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { transactionalEmails, totalCount, transactionalEmail, globalSettings, loading } = useSelector((state: any) => state.TransactionalEmails);
  const prevTransactionalEmail = useRef(transactionalEmail)
  const [templates, setTemplates] = useState<TransactionalEmail[]>([]);
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false);
  const [senderName, setSenderName] = useState<string>('');
  const [replyTo, setReplyTo] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [sortOrder, setSortOrder] = useState<"asc"|"desc">('asc');
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [limit, setLimit] = useState<number>(10);
  const [page_no, setPage_no] = useState<number>(1);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [allCustomFields, setAllCustomFields] = useState<any[]>([]);

  useEffect(() => {
    getTransactionalEmailsData(limit, page_no);
    getAllCustomFields()
  }, []);

  const getTransactionalEmailsData = (limit: number, page_no: number) => {
    dispatch(TransactionalEmailCreators.getTransactionalEmailsRequest({sortBy, sortOrder, limit, page_no}));
  };

  const getAllCustomFields = () => {
    const transactionalEmailsAPI = new TransactionalEmailsAPI();
    transactionalEmailsAPI.getAllCustomFieldsAPI().then((response) => {
      if (response.status === 200) {
        if (response.data.success) {
          setAllCustomFields(response.data.customFields);
        }
      }
    })
  }

  useEffect(() => {
    setTemplates(transactionalEmails);
    handleEndCountForPagination(
      totalCount,
      limit,
      page_no,
      setEndCount,
    );
    setStartCount((page_no - 1) * limit + 1);
  }, [transactionalEmails]);

  const editTemplate = (transactionalEmail: TransactionalEmail) => {
    navigate(`/settings/transactional-emails/template/${transactionalEmail._id}`, { state: { transactionalEmail, allCustomFields } });
  };

  const createBlankTemplate = () => {
    let data = {
      templateName: '',
      emailSubject: '',
      senderName: '',
      replyTo: '',
      html: "",
      json: {},
    };
    dispatch(TransactionalEmailCreators.createTransactionalEmailRequest(data));
  };

  useEffect(() => {  
    if (transactionalEmail != null && prevTransactionalEmail.current != transactionalEmail) {
      prevTransactionalEmail.current = transactionalEmail;
      navigate(`/settings/transactional-emails/template/${transactionalEmail._id}`, { state: { transactionalEmail, allCustomFields } });
    }
  }, [transactionalEmail]);  

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedCheckboxes(transactionalEmails.filter((mail: TransactionalEmail)=>mail.templateType !== 'default').map((mail: TransactionalEmail) => mail._id));
    } else {
      setSelectedCheckboxes([]);
    }
  };

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [page_no]);

  const handleSelectCheckBox = (id: string) => {
    if (selectedCheckboxes.includes(id)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((mailId) => mailId !== id));
    } else {  
      setSelectedCheckboxes([...selectedCheckboxes, id]);
    }
  }

  const deleteSelectedTemplates = () => {
    dispatch(TransactionalEmailCreators.deleteTransactionalEmailsRequest(selectedCheckboxes));
    setSelectedCheckboxes([]);
  };

  const saveGlobalSettings = (senderName:string, replyTo:string) => {
    setSenderName(senderName);
    setReplyTo(replyTo);
    setShouldUpdate(true);
  }

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
      dispatch(TransactionalEmailCreators.updateCompanyGlobalSettingsRequest({senderName, replyTo}));
      showSettingModal && setShowSettingModal(false);
    }
  }, [shouldUpdate]);

  console.log("globalSettings in list", globalSettings);

  return (
      <Box
        sx={{
          backgroundColor: '#ffffff',
          height: '100%',
          maxHeight: '100%',
          borderRadius: '8px',
          overflow: 'auto'
        }}
      >       
        <Stack sx={{
          height: '100%',
          maxHeight: '100%',
        }}>
          {/* --Select and menu-- */}
          <Stack px={1.5} borderBottom={'1px solid #C1C1C1'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack spacing={2.75} py={1.5} px={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <Checkbox
                  icon={<UnCheckAllIcon />}
                  checkedIcon={<CheckAllIcon />}
                  onClick={handleSelectAllCheckboxes}
                />
                <Typography>
                  {selectedCheckboxes.length ? selectedCheckboxes.length: ''}
                </Typography>
              </Box>

              {selectedCheckboxes.length > 0 && (
                <>
                  <IconButton size="small" variant='contained'
                    // @ts-ignore
                    color="transparent"
                    onClick={deleteSelectedTemplates}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>)
              }
              
            </Stack>
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <IconButton size="small" variant='contained'
                // @ts-ignore
                color="transparent"
                onClick={() => setShowSettingModal(true)}
              >
                <SettingIcon />
              </IconButton> 
              <Button variant="contained" color="primary" size="small" onClick={createBlankTemplate}>
                Create
              </Button>
            </Box>
          </Stack>

          {/* --table list-- */}
          <Box flexGrow={1} overflow={'auto'}>
            <TableContainer sx={{ maxHeight: '100%' }} >
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="Email List">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ ...tableHeadStyle, width: '44px', pl: 4 }} ></TableCell>                    
                    <TableCell sx={{ ...tableHeadStyle, }} onClick={() => { setSortBy('templateName'); setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); getTransactionalEmailsData(limit, page_no); }}>
                      <TableSortLabel
                        IconComponent={KeyboardArrowDownIcon}
                        direction={sortOrder}
                        active={sortBy === 'templateName'}
                      >
                        Template Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ ...tableHeadStyle, }} onClick={() => { setSortBy('emailSubject'); setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); getTransactionalEmailsData(limit, page_no); }}>
                      <TableSortLabel
                        IconComponent={KeyboardArrowDownIcon}
                        direction={sortOrder}
                        active={sortBy === 'emailSubject'}
                      >
                        Subject
                      </TableSortLabel>
                    </TableCell>

                    <TableCell sx={{ ...tableHeadStyle, }} onClick={() => { setSortBy('updatedAt'); setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); getTransactionalEmailsData(limit, page_no); }}>
                      <TableSortLabel
                        IconComponent={KeyboardArrowDownIcon}
                        direction={sortOrder}
                        active={sortBy === 'updatedAt'}
                      >
                        Last Updated On
                      </TableSortLabel>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.map((template) => (
                    <TableRow
                      // @ts-ignore
                      key={template._id}
                      onClick={() => editTemplate(template)}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { backgroundColor: '#E6FDF4' },
                        px: 2.25,
                        cursor: 'pointer'
                      }}
                    >
                      <TableCell sx={{ width: '44px', pl: 4 }} onClick={(e) => e.stopPropagation()}>
                        <Checkbox onClick={()=>handleSelectCheckBox(template._id)} checked={selectedCheckboxes.includes(template._id)} disabled={template.templateType==='default'} />
                      </TableCell>
                      <TableCell >{template.templateName != "" ? template.templateName : 'Untitled'}</TableCell>
                      <TableCell >{template.emailSubject}</TableCell>
                      <TableCell >{moment(template.updatedAt).format('MMM DD YYYY hh:mm a')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box py={2}>
            <PaginationFunctional
              startCount={startCount}
              setStartCount={setStartCount}
              endCount={endCount}
              limit={limit}
              setLimit={setLimit}
              page_no={page_no}
              setPage_no={setPage_no}
              listItemsFn={getTransactionalEmailsData}
              totalItemsCount={totalCount}
            />
          </Box>
        </Stack >
        <TransactionalEmailSettingModal
          openedFrom={'list'}
          showSettingModal={showSettingModal} 
          setShowSettingModal={setShowSettingModal}
          senderName_={globalSettings.senderName}
          replyTo_={globalSettings.replyTo}
          saveSettings={saveGlobalSettings}
        />
        {loading && <Loader />}
      </Box >      
  );
}