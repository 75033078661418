import React from 'react';
import { Select, MenuItem, SelectChangeEvent, Checkbox } from '@mui/material';
import { FilterType } from '../../utils/FilterTypesContext';

interface DropdownForSourceProps {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  filterType: FilterType;
}

const DropdownForSource: React.FC<DropdownForSourceProps> = ({
  value,
  onChange,
  filterType,
}) => {
  const sourceOptions = [
    { value: 'imported', label: 'Imported' },
    { value: 'manuallyAdded', label: 'Manually Added' },
    { value: 'aiAgent', label: 'AI Agent' },
  ];

  return (
    <Select
      multiple
      variant="outlined"
      size="small"
      value={value}
      onChange={onChange}
      fullWidth
      displayEmpty
      renderValue={(selected) => {
        const selectedArray = selected as string[];
        if (selectedArray.length === 0) return 'Select';

        const selectedItems = selectedArray
          .map((value) => sourceOptions.find((option) => option.value === value)?.label)
          .join('\n');

        return (
          <div title={selectedItems}>
            {`${selectedArray.length} ${
              selectedArray.length === 1 ? 'Source' : 'Sources'
            } Selected`}
          </div>
        );

        // (selected as string[]).length === 0 ? 'Select source' : (selected as string[]).map((value) => sourceOptions.find((option) => option.value === value)?.label).join(', ')
      }}
    >
      {sourceOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={value.includes(option.value)} />
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DropdownForSource;
