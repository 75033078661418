import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { OpportunityStates as OpportunityDynamicStates } from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import { StateType } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';

interface StateDropdownProps {
  label: string;
  name: string;
  dynamicStates: OpportunityDynamicStates;
  selectedState: StateType;
  handleStateChange: (e: SelectChangeEvent<string>) => void;
  errors?: {
    [key: string]: boolean | string;
  };
  showListField: boolean;
  setShowListField: (show: boolean) => void;
  onClose?: () => void;
}

const StateDropdown: React.FC<StateDropdownProps> = ({
  name,
  dynamicStates,
  selectedState,
  handleStateChange,
  errors,
  showListField,
  setShowListField,
  onClose,
}) => {
  return (
    <FormControl
      size="medium"
      sx={{ minWidth: 120, bgcolor: 'background.paper' }}
    >
      <Select
        open={showListField}
        type="text"
        className="form-control select medium"
        sx={{
          '& .MuiSelect-select': {
            padding: '8px 14px',
          },
        }}
        displayEmpty
        name={name}
        onChange={handleStateChange}
        onClose={onClose}
        value={
          selectedState
            ? JSON.stringify({
                _id: selectedState._id,
                name: selectedState.name,
              })
            : ''
        }
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300,  // Set your desired max height here
            },
          },
        }}
      >
        <MenuItem value={JSON.stringify({})}>Select</MenuItem>
        {dynamicStates
          .filter((state) => state.stateType !== 'Draft')
          .map((state) => (
            <MenuItem
              key={state._id}
              value={JSON.stringify({ _id: state._id, name: state.name })}
            >
              {state.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default StateDropdown;
