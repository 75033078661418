// QuillToolbar.js
import { Box } from "@mui/material";
import React from "react";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { createNotification } from "helpers";
// Inline styles for the toolbar and editor
const toolbarStyle = {
    '&.ql-toolbar': {
        border: "none",
        display: "flex",
        gap: "2px",
        alignItems: "center"
    }
};

const blockedExtensions = [
    'ade', 'adp', 'apk', 'appx', 'appxbundle', 'bat', 'cab', 'chm', 'cmd', 'com',
    'cpl', 'dll', 'dmg', 'exe', 'hta', 'ins', 'isp', 'jar', 'js', 'jse', 'lib',
    'lnk', 'mde', 'msc', 'msi', 'msix', 'msixbundle', 'msp', 'mst', 'nsh', 'pif',
    'ps1', 'scr', 'sct', 'shb', 'sys', 'vb', 'vbe', 'vbs', 'vxd', 'wsc', 'wsf', 'wsh'
  ];

const QuillToolbar = ({ onUploadAttachment, toolbarId }) => {

    const handleFileChange = (e) => {
        console.log('File input event triggered');
        
        if (!e.target.files || !e.target.files[0]) {
            console.log('No file selected or file selection cancelled');
            return;
        }

        const file = e.target.files[0];
        
        // Check file size first
        if (file.size >= 26214400) {
            createNotification('error', 'Please attach files less than 25MB');
            e.target.value = ""; // Reset the file input
            return;
        }

        // Check file extension
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (blockedExtensions.includes(fileExtension)) {
            console.log('File extension check failed:', fileExtension);
            createNotification('error', 'This file type is not allowed for security reasons');
            e.target.value = ""; // Reset the file input
            return;
        }

        onUploadAttachment(file); // Pass the file to the upload handler
        e.target.value = ""; // Reset the file input value
    };

    return (
        <Box id={toolbarId} sx={toolbarStyle} className="ql-toolbar ql-snow">
            <span className="ql-formats">
                <select className="ql-font"></select>
                <select className="ql-size"></select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <button className="ql-strike"></button>
            </span>
            <span className="ql-formats">
                <select className="ql-color"></select>
                <select className="ql-background"></select>
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
            <span className="ql-formats">
                <button className="ql-link"></button>
                <button className="ql-image"></button>
                <button className="ql-attachment">
                    <label style={{ cursor: "pointer" }}>
                        <AttachFileIcon sx={{ fontSize: '18px' }} />
                        <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                    </label>
                </button>
            </span>
            <span className="ql-formats">
                <button className="ql-clean"></button>
            </span>
        </Box>
    );
};

export default QuillToolbar;
