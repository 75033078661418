import React, { useEffect, useState, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Alert,FormControl, FormLabel, IconButton, Stack, TextField } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
// @ts-ignore
import EijentButtonLogo from "../assets/images/eijent-logo-compressed.gif";
import { CancelIcon } from "eijent/components/CustomIcons";
import ListLoader from "eijent/components/ListLoader";


const aiInputStyle: SxProps<Theme> = {
  position: "relative",
  transformStyle: "preserve-3d",

  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    transform: "translate3d(1px, 0px, -1px)",
    background:
      "transparent linear-gradient(114deg, #5578FA 0%, #CBFFE3 100%) 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    filter: "blur(10px)",
  },
};


interface InitialDataProps {
  name: string;
  description: string;
  setName: (value: string) => void;
  setDescription: (value: string) => void;
  errors: { generic?:string; name?: boolean; description?: boolean }; // New errors prop
  clearErrors: () => void; // Function to clear errors
  isModifiedManually: { name: boolean; description: boolean }; // Track modifications for both fields
}
const InitialData: React.FC<InitialDataProps> = ({ name, description, setName, setDescription,errors,clearErrors, isModifiedManually  }) => {

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);  
  };

  return (
    <Stack flexGrow={1} py={2} display="flex" alignItems="center" justifyContent="center">
      {/* Eijent Logo & Title */}
      <Box display="flex" my={2} gap={1} justifyContent="center" alignItems="center">
        <img
          src={EijentButtonLogo}
          width={36}
          height={36}
          alt="Eijent logo"
          style={{
            mixBlendMode: "multiply",
            filter: "contrast(1)",
          }}
        />
        <Typography>Eijent</Typography>
      </Box>

      {/* <Typography variant="body2" align="center">
        If none of these suggestions fit what you are looking for, please <br />
        describe it to me and I’ll do my best to create one custom <br />
        made for you.
      </Typography> */}

      {/* Input Form */}
      <Stack
        spacing={3}
        mt={4}
        sx={{
          backgroundColor: "#ffffff",
          boxShadow: "0px 15px 30px #00000022",
          borderRadius: "16px",
          p: 6,
          width: "100%",
          maxWidth: "50%",
        }}
      >
        {/* Name Input */}
        <FormControl>
          <FormLabel htmlFor="name" sx={{ fontWeight: "400", color: "#525252" }}>
            Name
          </FormLabel>
          <Box sx={!isModifiedManually?.name ? aiInputStyle : {}}>
            <TextField
              id="name"
              type="text"
              name="name"
              placeholder="Add name here"
              autoComplete="name"
              required
              fullWidth
              variant="outlined"
              //@ts-ignore
              size="large"
              value={name}
              onChange={handleNameChange}
              color={errors.name ? 'error' : 'info'}
             
            />
          </Box>
        </FormControl>

        {/* Description Input */}
        <FormControl
          sx={{
            "& .MuiInputBase-root": {
              minHeight: "20ch",
              maxHeight: "20ch",
              overflow: "auto",
              alignItems: "start",
            },
          }}
        >
          <FormLabel htmlFor="description" sx={{ fontWeight: "400", color: "#525252" }}>
            Description
          </FormLabel>
          <Box sx={!isModifiedManually?.description ? aiInputStyle : {}}>
            <TextField
              id="description"
              multiline
              name="description"
              placeholder="Add description here"
              autoComplete="off"
              required
              fullWidth
              variant="outlined"
              value={description}
              onChange={handleDescriptionChange}
              color={errors.description ? 'error' : 'info'}
            />
          </Box>

        </FormControl>
        {/* Combined Error Alert */}
         {errors.generic && (
          <Alert
          severity="error"
          sx={{ mt: 2, whiteSpace: "pre-line" }}
          action={
            <IconButton aria-label="close" size="small" onClick={clearErrors}>
              <CancelIcon />
            </IconButton>
          }
        >
          {errors.generic}
        </Alert>
        )}
      </Stack>
    </Stack>
  );
};

export default InitialData;
