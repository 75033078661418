import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  //#region Transactional Emails
  getTransactionalEmailsRequest: ['data'],
  getTransactionalEmailsSuccess: ['transactionalEmails', 'totalCount', 'globalSettings'],
  getTransactionalEmailsFailure: ['error'],

  createTransactionalEmailRequest: ['data'],
  createTransactionalEmailSuccess: ['transactionalEmail', 'message'],
  createTransactionalEmailFailure: ['error'],

  updateTransactionalEmailRequest: ['data'],
  updateTransactionalEmailSuccess: ['transactionalEmail', 'message'],
  updateTransactionalEmailFailure: ['error'],

  deleteTransactionalEmailsRequest: ['transactionalEmailIds'],
  deleteTransactionalEmailsSuccess: ['transactionalEmailIds', 'message'],
  deleteTransactionalEmailsFailure: ['error'],
  //#endregion

  updateCompanyGlobalSettingsRequest: ['data'],
  updateCompanyGlobalSettingsSuccess: ['globalSettings', 'message'],
  updateCompanyGlobalSettingsFailure: ['error'],
});

const Constants = Types;
export {
  Creators as TransactionalEmailCreators,
  Constants as TransactionalEmailConstants,
};
