import { SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { CustomFields_State } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import { FilterType } from '../../utils/FilterTypesContext';
import CommonMultiSelect from './CommonMultiSelect';

export default function DropdownForListType({
  value,
  onChange,
  filterType,
}: {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  filterType: FilterType;
}) {
  const { customFields } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const filteredCustomFields = customFields?.filter((field: any) => field._id === filterType.value)|| [];
  const listValues = filteredCustomFields[0]?.fieldData?.dropdownValues || [];
 
  return (
    <CommonMultiSelect
      value={value}
      onChange={onChange}
      options={listValues?.map((item: string) => ({
        id: item,
        label: item
      }))}
      singularLabel="Item"
      pluralLabel="Items"
    />
  );
}
