import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Stack, Stepper, Step, StepLabel, Alert } from "@mui/material";
import {
    ArrowLeftIcon,
    ReportingIconActive,
    CancelIcon,
    ArrowRightWhiteIcon,
    SettingIcon,
} from "../../../components/CustomIcons";
import { useTheme } from '@mui/material/styles';

const steps = ["Type", "Initial journey data", "Define Workflow", "Confirmation",];
interface ConfirmationModalProps {
    isOpenModal: boolean;
    onClose: () => void;
    onConfirm: () => void;
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpenModal, onClose, onConfirm }) => {
    const theme = useTheme();
    return ( 
        <>
            <Modal
                open={isOpenModal}
                onClose={onClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                slotProps={{
                    backdrop: { timeout: 200, sx: { backdropFilter: "blur(4px)", backgroundColor: "rgba(255, 255, 255, 0.5)" } },
                }}
            >
                <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} >

                    <Alert
                        // @ts-ignore
                        severity="system"
                        action={
                            <IconButton
                                aria-label="close"
                                variant="contained"
                                // @ts-ignore
                                color="transparent"
                                size="small"
                                onClick={onClose}
                            >
                                <CancelIcon />
                            </IconButton>
                        }
                        sx={{ width: '420px' }}
                    >
                        <Stack spacing={2} justifyContent={'space-between'} height={'100%'}>
                            <Typography variant="body2" >
                                Are you sure you want to delete this item?
                                <br />
                                This action cannot be undone.
                            </Typography>


                            <Stack direction={'row'} pt={5} gap={1}>
                                <Button size="small"
                                    variant="contained"
                                    color="primary"
                                    sx={{ borderRadius: '4px' }}
                                    onClick={onClose}
                                >
                                    No
                                </Button>
                                <Button size="small"
                                    variant="contained"
                                    // @ts-ignore
                                    color="white"
                                    sx={{ borderRadius: '4px' }}

                                    onClick={() => { 
                                        onConfirm(); // Ensure it calls the delete function
                                    }}
                                >
                                    Yes
                                </Button>
                                
                            </Stack>
                        </Stack>
                    </Alert>
                </Stack>
            </Modal>
        </>
    );
};

export default ConfirmationModal;
