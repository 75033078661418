import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, FormControlLabel, Checkbox, Button, Tooltip, TableHead } from "@mui/material";
import AddState from "./AddState";
import { Actions } from 'ReduxStore/Actions';
import { DeleteIcon } from "../../../components/CustomIcons.js";
import Loader from "Components/Loader/loader";
import ConfirmationModal from "./ConfirmationModal";

import {
  OpportunityStates,
  OpportunityStates_State
} from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import { rgbToHex2 } from "helpers/common";

const tableHeadStyle: React.CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '10px',
  fontSize: '12px',
  lineHeight: 1,
  color: '#c1c1c1',
  borderColor: '#C1C1C1',
  backgroundColor: '#ffffff',
};

const checkboxStyle = {
  marginLeft: 0,
  gap: 1,
  "& .MuiTypography-root": {
    fontWeight: 400,
  },
};

type StateManagementListProps = {
  type: 'opportunity' | 'accounts' | 'contacts';
};
export default function StateManagementList({ type }: StateManagementListProps) {
  const customStateTypeVar = type;

  const dispatch = useDispatch();
  //custom states 
  const { opportunityStates: opportunityStatesFromRedux, loading, fetchAllOpportunityStateSuccess } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;
  const [opportunityStates, setOpportunityStates] = useState<OpportunityStates>([] as OpportunityStates);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openStateDrawer, setOpenStateDrawer] = React.useState(false);
  const [isEditMode, setEditMode] = React.useState(false);
  const [deleteStateId, setDeleteStateId] = useState<string | null>(null);


  const [selectedStateField, setSelectedStateField] = useState(null);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    fetchAllOpportunityStates();
  }, [type]);

  const fetchAllOpportunityStates = () => {
    const data = {
      opportunityStateType: customStateTypeVar,
    };
    dispatch(Actions.fetchAllOpportunityStateRequest(data));
  };

  useEffect(() => {
    let states = opportunityStatesFromRedux.filter(state => state.stateType !== 'Draft');
    setOpportunityStates(states);
  }, [opportunityStatesFromRedux]);


  const handleAddStateClick = () => {
    setEditMode(false); // 
    dispatch({ type: "CLEAR_OPPORTUNITY_STATE_MESSAGE" });
    setSelectedStateField({
      name: "",
      stateType: "",
      description: "",
      isDefault: false,
      textColor: "",
      backgroundColor: "",
      opportunityStateType: customStateTypeVar,
      orderIndex: null
    });
    setOpenStateDrawer(true); //  Open drawer
  };


  const handleEditFieldClick = (state) => {
    dispatch({ type: "CLEAR_OPPORTUNITY_STATE_MESSAGE" });
    setEditMode(true); // Set edit mode 
    setSelectedStateField({
      _id: state?._id,
      name: state?.name,
      stateType: state?.stateType,
      description: state?.description,
      isDefault: state?.isDefault,
      textColor: state?.textColor,
      backgroundColor: state?.backgroundColor,
      opportunityStateType: customStateTypeVar,
      orderIndex: state?.orderIndex
    });
    setOpenStateDrawer(true); // Open drawer
  };

  //  Open confirmation modal
  const handleOpenDelete = (id) => {
    setDeleteStateId(id);
    setConfirmModalOpen(true);
  };

  // Handle delete action based on type
  const handleDelete = () => {
    const queryParams = {
      opportunityStateType: customStateTypeVar
    };
    let data = {
      bodyParams: {
        _id: deleteStateId
      },
      queryParams: queryParams
    };
    if (deleteStateId) {
      dispatch(Actions.deleteOpportunityStateRequest(data));
    }
    setConfirmModalOpen(false); // Close modal after delete
    setDeleteStateId(null); // Reset ID 
  };

  return (
    <Box
      sx={{
        backgroundColor: '#F4F6F7',
        height: '100%',
        maxHeight: '100%',
        borderRadius: '8px',
        overflow: 'auto'
      }}
    >
      <Stack sx={{ height: '100%', maxHeight: '100%' }}>
        <Stack py={2.5} px={3.75} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack spacing={2.75} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h2">
              State Management
            </Typography>
          </Stack>
        </Stack>

        <Stack className={'field-header'} px={3.75} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack spacing={2.75} py={1.5} px={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>  </Stack>

          <Button onClick={handleAddStateClick} variant="contained" color="primary">
            Add State
          </Button>

        </Stack>

        {/* --Table List-- */}
        <Box className="field-managment" flexGrow={1} overflow={'auto'} px={1.25}>
          <TableContainer sx={{ maxHeight: '100%' }}>
            <Table className="field-managment-table" sx={{ minWidth: 650 }} stickyHeader aria-label="Field Management List">
              <TableHead className="table-head">
                <TableRow sx={{ px: 2.25 }}>
                  <TableCell sx={{ width: 250, maxWidth: 250 }}>Name</TableCell>
                  <TableCell sx={{ width: 250, maxWidth: 250 }}>Type</TableCell>
                  <TableCell sx={{ minWidth: 420 }}>Description</TableCell>
                  <TableCell sx={{ maxWidth: 70 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {opportunityStates && opportunityStates.length > 0 ? (
                  opportunityStates.map((state) => (
                    <TableRow sx={{ px: 2.25 }} key={state._id}>
                      <TableCell sx={{ width: 250, maxWidth: 250 }}>
                        <Button
                          // @ts-ignore
                          variant="tag"
                          size="small"
                          onClick={() => handleEditFieldClick(state)}
                          sx={{
                            borderRadius: '4px',
                            backgroundColor: `${rgbToHex2(state?.backgroundColor)}`,
                            fontWeight: '400',
                            '&:hover': {
                              backgroundColor: `${rgbToHex2(state?.backgroundColor)}`,
                            },
                          }}
                        >
                          {state.name}
                        </Button>
                      </TableCell>
                      <TableCell sx={{ width: 250, maxWidth: 250 }}>
                        {state.stateType}
                      </TableCell>
                      <TableCell sx={{ minWidth: 420 }}>
                        {state.description && state.description.length > 100 ? (
                          <Tooltip placement="bottom-start" title={state.description} arrow>
                            <span>{state.description.substring(0, 100) + "..."}</span>
                          </Tooltip>
                        ) : (
                          <span>{state.description || ''}</span>
                        )}
                      </TableCell>
                      <TableCell sx={{ maxWidth: 70 }} align="right">
                        <IconButton
                          size="small"
                          variant="contained"
                          // @ts-ignore
                          color="transparent"
                          onClick={() => handleOpenDelete(state._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : ( 
                  <Typography sx={{ p: 3, textAlign: "center" }}>
                    No states available
                  </Typography>  
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {(loading) && <Loader />}
        </Box>
      </Stack>

      {openStateDrawer && (<AddState
        type={customStateTypeVar}
        isEditMode={isEditMode}
        existingStateData={selectedStateField}
        openStateDrawer={openStateDrawer} //  Ensure it matches the correct prop name
        onClose={() => setOpenStateDrawer(false)} //  Ensure correct function name 
      />)}

      {/* Confirmation Modal */}
      {isConfirmModalOpen && (<ConfirmationModal
        isOpenModal={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleDelete}
      />)}
    </Box>
  );
}
