import { Checkbox, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface CommonMultiSelectProps {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  options: Array<{
    id: string;
    label: string;
  }>;
  singularLabel?: string;
  pluralLabel?: string;
  selectedItemsToString?: (selectedIds: string[]) => string;
}

export default function CommonMultiSelect({
  value,
  onChange,
  options,
  singularLabel = 'Item',
  pluralLabel = 'Items',
  selectedItemsToString,
}: CommonMultiSelectProps) {
  return (
    <Select
      variant="outlined"
      size="small"
      value={value}
      onChange={onChange}
      fullWidth
      multiple
      displayEmpty
      // placeholder="Select"
      renderValue={(selected) => {
        const selectedArray = selected as string[];
        if (selectedArray.length === 0) return "Select";
        
        const selectedItems = selectedItemsToString 
          ? selectedItemsToString(selectedArray)
          : selectedArray.join('\n');
        
        return (
          <div title={selectedItems}>
            {`${selectedArray.length} ${
              selectedArray.length === 1 ? singularLabel : pluralLabel
            } Selected`}
          </div>
        );
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          <Checkbox checked={value.includes(option.id)} />
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
} 