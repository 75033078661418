import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Stack, Stepper, Step, StepLabel, Alert } from "@mui/material";
import {
  ArrowLeftIcon,
  ReportingIconActive,
  CancelIcon,
  ArrowRightWhiteIcon,
  SettingIcon,
} from "../CustomIcons";
import { useTheme } from '@mui/material/styles';

const steps = ["Type", "Initial journey data", "Define Workflow", "Confirmation",];

type ConfirmationModalProps = {
  showConfirmationModal: boolean;
  setShowConfirmationModal: (showConfirmationModal: boolean) => void;
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,  
  onCancel
}: ConfirmationModalProps) => {
  const theme = useTheme();

  return (
    <>
      <Modal
        open={showConfirmationModal}
        onClose={onCancel}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: { timeout: 200, sx: { backdropFilter: "blur(4px)", backgroundColor: "rgba(255, 255, 255, 0.5)" } },
        }}
      >
        <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} >
     
          <Alert
            // @ts-ignore
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                variant="contained"
                // @ts-ignore
                color="transparent"
                size="small"
                onClick={() => setShowConfirmationModal(false)}
              >
                <CancelIcon />
              </IconButton>
            }
            sx={{  width: '420px' }}
          >
            <Stack spacing={2} justifyContent={'space-between'} height={'100%'}>
              <Typography variant="body2" >
                {title}
                <br />
                {message}
              </Typography>


              <Stack direction={'row'} pt={5} gap={1}>
                <Button size="small"
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: '4px' }}
                  onClick={onCancel}
                >
                  {cancelButtonText}
                </Button>
                <Button size="small"
                  variant="contained"
                  // @ts-ignore
                  color="white"
                  sx={{ borderRadius: '4px' }}
                  onClick={onConfirm}
                >
                  {confirmButtonText}
                </Button>
              </Stack>
            </Stack>
          </Alert>
        </Stack>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
