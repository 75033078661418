import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Actions } from 'ReduxStore/Actions';

import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup, ButtonGroup, Tab, Checkbox, IconButton, TableSortLabel, ClickAwayListener, TextField, InputAdornment, RadioGroup, FormControlLabel, Radio, Switch } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from '@mui/material/styles';


import {
  MyInboxIcon,
  SettingIcon,
  EditIcon
} from '../../../components/CustomIcons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React from "react";
import { useNavigate } from "react-router-dom";
import getTransactionalEmailTemplateUnlayerConfig from "eijent/internals/Settings/TransactionalEmail/UnlayerConfig/TransactionalEmailTemplateUnlayerConfig";
import { useDispatch } from "react-redux";
import SendTestMailModal from "./sendTestMailModal";
import TransactionalEmailSettingModal from "./TransactionalEmailSettingModal";
import DoneIcon from '@mui/icons-material/Done';
import Loader from "Components/Loader/loader";
import { useSelector } from "react-redux";
import ConfirmationModal from "eijent/components/Modals/ConfirmationModal";
import { UserData } from "helpers/common";

export default function TransactionalEmailView() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { transactionalEmail, allCustomFields } = location.state || {};

  const { loading, globalSettings } = useSelector((state: any) => state.TransactionalEmails);

  const [html, setHtml] = useState<string>('');
  const [json, setJson] = useState<object>({});
  const [templateName, setTemplateName] = useState<string>('');
  const [senderName, setSenderName] = useState<string>('');
  const [replyTo, setReplyTo] = useState<string>('');
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [showSendTestMail, setShowSendTestMail] = useState<boolean>(false);
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false);
  const [showTemplateNameInput, setShowTemplateNameInput] = useState<boolean>(false);
  const [showEmailSubjectInput, setShowEmailSubjectInput] = useState<boolean>(false);
  const [scope, setScope] = useState<string>('private');
  const [isDesignChanged, setIsDesignChanged] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    loadUnlayer();
    const userDetails = UserData();
    if(userDetails){
      const userId = userDetails._id;
      setUserId(userId);
    };    
  }, []);

  const loadUnlayer = () => {
    window.scrollTo(0, 0);
    window.unlayer.init(getTransactionalEmailTemplateUnlayerConfig(allCustomFields));
    window.unlayer.addEventListener('design:updated', function (updates) { 
      window.unlayer.exportHtml(function (data) {  
        if(updates.type != 'design:migrated'){
          setIsDesignChanged(true); 
        }     
        setJson(data.design);
        setHtml(data.html);
      });
    });
    if (transactionalEmail) {
      window.unlayer.loadDesign(transactionalEmail.json);
      setTemplateName(transactionalEmail.templateName ? transactionalEmail.templateName : '');
      setSenderName(transactionalEmail.senderName ? transactionalEmail.senderName : '');
      setReplyTo(transactionalEmail.replyTo ? transactionalEmail.replyTo : '');
      setEmailSubject(transactionalEmail.emailSubject ? transactionalEmail.emailSubject : '');
      setScope(transactionalEmail.scope);
    }
  };

  const updateTransactionEmail = () => {
    const data = {
      id: transactionalEmail._id,
      json: json,
      html: html,
      templateName: templateName,
      emailSubject: emailSubject,
      replyTo: replyTo != "" ? replyTo : globalSettings.replyTo,
      senderName: senderName != "" ? senderName : globalSettings.senderName,
      scope: scope
    };

    dispatch(Actions.updateTransactionalEmailRequest(data));
    setShowSettingModal(false);
    setIsDesignChanged(false);
  };

  const updateAndExit = () => {
    updateTransactionEmail();
    setShowConfirmationModal(false);
    goBack();
  };

  const discardAndExit = () => {
    setShowConfirmationModal(false);
    goBack();
  };

  const goBack = () => {
    navigate(-1);
  };

  const saveSettings = (senderName: string, replyTo: string) => {
    console.log('saveSettings', senderName, replyTo);
    setSenderName(senderName);
    setReplyTo(replyTo);
    setShowSettingModal(false);
  };

  const handleClickAwayFromTemplateNameInput = (event: MouseEvent | TouchEvent) => {
    // Ensure click away only closes when clicking outside the TextField container
    const target = event.target as HTMLElement;
    if (target.closest("#textfield-container")) return;
    setShowTemplateNameInput(false);
  };

  const handleClickAwayFromEmailSubjectInput = (event: MouseEvent | TouchEvent) => {
    // Ensure click away only closes when clicking outside the TextField container
    const target = event.target as HTMLElement;
    if (target.closest("#textfield-container")) return;
    setShowEmailSubjectInput(false);
  };

  const checkChangesBeforeExit = () => {
if (templateName != transactionalEmail.templateName || emailSubject != transactionalEmail.emailSubject || senderName != transactionalEmail.senderName || replyTo != transactionalEmail.replyTo || scope != transactionalEmail.scope || isDesignChanged) {
      setShowConfirmationModal(true);
    }else{
      goBack()
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        height: '100%',
        maxHeight: '100%',
        borderRadius: '8px',
        overflow: 'auto'
      }}
    >
      <Stack sx={{
        height: '100%',
        maxHeight: '100%',
      }}>
        {/* --Select and menu-- */}
        <Stack px={1.5} borderBottom={'1px solid #C1C1C1'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack flexGrow={1} spacing={2.75} py={0.5} px={2} direction={'row'} justifyContent={'start'} alignItems={'center'}>


            <IconButton size="small" variant='contained'
              // @ts-ignore
              color="transparent"
              onClick={checkChangesBeforeExit}
            >
              <KeyboardBackspaceIcon />
            </IconButton>

            <Box flexGrow={1} display={'flex'} gap={1} alignItems={'center'}>
              <MyInboxIcon />
              <Box flexGrow={1}>
                {showTemplateNameInput ? (
                  <ClickAwayListener onClickAway={handleClickAwayFromTemplateNameInput}>

                      <TextField
                        variant="outlined"
                        size="small"
                        type="text"
                        name="templateName"
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        placeholder="enter name here"
                        autoFocus
                        fullWidth
                        multiline
                        maxRows={2}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            minHeight: "2.25rem",
                            height: "auto",
                          },
                        }}
                        slotProps={{
                          input: {
                            endAdornment: <InputAdornment position="end"  >
                              <IconButton
                                size="small"
                                // @ts-ignore
                                color='transparent'
                                variant='contained'
                                onClick={() => { setShowTemplateNameInput(false); }}
                              >
                                <DoneIcon />
                              </IconButton>
                            </InputAdornment>,
                          },
                        }}
                      />

                  </ClickAwayListener>
                ) : (
                  <>
                    {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                    <Stack direction={'row'} spacing={1}>
                      <Box px={1.5} py={'8.2px'} sx={{ border: '1px solid transparent', '&:hover': { borderColor: '#000', borderRadius: '8px' } }}>
                        <Typography variant="body1"  onClick={() => setShowTemplateNameInput(true)} >
                          {templateName != "" ? templateName : 'Template Name'}
                        </Typography>
                      </Box>
                    </Stack>
                  </>
                )}
              </Box>
            </Box>
          </Stack>
          <Stack spacing={1} py={0.5} px={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Box px={2} display={'flex'} gap={4} alignItems={'center'}>
              <FormControlLabel
                sx={{ marginBottom: 0 }}
                control={
                  <Switch
                    sx={{ ml: 1 }}
                    checked={scope === 'public'}
                    onChange={(e) => setScope(e.target.checked ? 'public' : 'private')}
                    disabled={transactionalEmail.userId != userId}
                  />
                }
                label="Make it public"
                labelPlacement="start"
              />
              <IconButton size="small" variant='contained' mr={2}
                // @ts-ignore
                color="transparent"
                onClick={() => setShowSettingModal(true)}
              >
                <SettingIcon />
              </IconButton>
            </Box>

            {/* <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={()=>setShowSendTestMail(true)}
            >
              Test
            </Button> */}
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => updateTransactionEmail()}
            >
              Save
            </Button>
          </Stack>
        </Stack>

        {/**for subject input */}
        <Stack borderBottom={'1px solid #C1C1C1'} spacing={1} py={0.5} px={2} direction={'row'} justifyContent={'start'} alignItems={'center'}>
          <Typography variant="body2" >
            Subject:
          </Typography>
          <Box flexGrow={1}>
            {showEmailSubjectInput ? (
              <ClickAwayListener onClickAway={handleClickAwayFromEmailSubjectInput}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  name="emailSubject"
                  fullWidth
                  value={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                  placeholder="enter name here"
                  autoFocus
                  multiline
                  maxRows={2}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      minHeight: "2.25rem",
                      height: "auto",
                    },
                  }}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <IconButton
                          size="small"
                          // @ts-ignore
                          color='transparent'
                          variant='contained'
                          onClick={() => {
                            setShowEmailSubjectInput(false);
                            updateTransactionEmail();
                          }}
                        >
                          <DoneIcon />
                        </IconButton>
                      </InputAdornment>,
                    },
                  }}
                />
              </ClickAwayListener>
            ) : (
              <>
                {/* <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }} onClick={handleClick}>+ Click to Add</Button> */}
                <Stack direction={'row'} spacing={1}>
                  <Box px={1.5} py={'8.2px'} sx={{ border: '1px solid transparent', '&:hover': { borderColor: '#000', borderRadius: '8px' } }}>
                    <Typography variant="body2" onClick={() => setShowEmailSubjectInput(true)} >
                      {emailSubject != "" ? emailSubject : 'Template Subject'}
                    </Typography>
                  </Box>
                </Stack>
              </>
            )}
          </Box>
        </Stack>
        {/* subject end */}

        {/* --TransactionalEmailView-- */}
        <Box flexGrow={1} overflow={'auto'}>
            <div className="editingDashboard" style={{height:'100%'}}>
              <div id="transactionalEmailTemplateEditor" style={{ height: 'calc(100% - 7px)' }}>
                {''}
              </div>
            </div>
        </Box>
      </Stack >
      <TransactionalEmailSettingModal
        openedFrom="view"
        showSettingModal={showSettingModal}
        setShowSettingModal={setShowSettingModal}
        senderName_={senderName}
        replyTo_={replyTo}
        globalSettings={globalSettings}
        saveSettings={saveSettings}
      />
      <SendTestMailModal showSendTestMail={showSendTestMail} setShowSendTestMail={setShowSendTestMail}/>
      <ConfirmationModal 
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        title="Save Confirmation" 
        message="Do you want to exit without saving the changes?" 
        confirmButtonText="Save & Exit" 
        cancelButtonText="Discard" 
        onConfirm={updateAndExit} 
        onCancel={discardAndExit}
      />
      {loading && <Loader />}
    </Box >
  );
}