import { createContext, useContext, useState, useMemo, Dispatch, SetStateAction, useRef, useEffect } from 'react';
import { CustomFields } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';

import { useSelector } from 'react-redux';
import { Filter, UserPreferencesState } from 'ReduxStore/Reducers/userPreferences.reducer';
import { useDispatch } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { useLocation } from 'react-router-dom';

const defaultFilterTypes = [
  {
    value: 'name',
    label: 'Opportunity Name',
    type: 'text',
    isCustomField: false,
  },
  {
    value: 'uniqueEijentId',
    label: 'Eijent ID',
    type: 'uniqueEijentId',
    isCustomField: false,
  },
  {
    value: 'source',
    label: 'Source',
    type: 'source',
    isCustomField: false,
  },
  {
    value: 'assignedTo',
    label: 'Assigned to',
    type: 'assignedTo',
    isCustomField: false,
  },
  {
    value: 'states',
    label: 'States',
    type: 'states',
    isCustomField: false,
  },
  {
    value: 'updatedAt',
    label: 'Last updated',
    type: 'date',
    isCustomField: false,
  },
  {
    value: 'lastImported',
    label: 'Last imported',
    type: 'date',
    isCustomField: false,
  },
];

export type FilterType = {
  value: string;
  label: string;
  type: string;
  isCustomField: boolean;
}

export type FilterRow = {
  id: number;
  filterType: {value: string, label: string, type: string, isCustomField: boolean};
  operator: string;
  value: string[] | string;
}

const FilterTypesContext = createContext<{
  filterTypes: FilterType[];
  selectedFilterTypes: string[];
  setSelectedFilterTypes: Dispatch<SetStateAction<string[]>>;
  appliedQuickFilter: Filter;
  setAppliedQuickFilter: Dispatch<SetStateAction<Filter>>;
  filterRows: FilterRow[];
  setFilterRows: Dispatch<SetStateAction<FilterRow[]>>;
  handleApplyQuickFilter: (filter: Filter) => void;
  noFilterAppliedManuallyRef: React.MutableRefObject<boolean>;
  handleDeleteFilter: (moduleName: string, filterId: string) => void;
}>({
  filterTypes: [...defaultFilterTypes],
  selectedFilterTypes: [],
  setSelectedFilterTypes: () => {},
  appliedQuickFilter: {} as Filter,
  setAppliedQuickFilter: () => {},
  filterRows: [],
  setFilterRows: () => {},
  handleApplyQuickFilter: () => {},
  noFilterAppliedManuallyRef: { current: false },
  handleDeleteFilter: () => {},
});

export const FilterTypesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const moduleName = location.pathname.split('/')[1];
  const noFilterAppliedManuallyRef = useRef(false);
  const dispatch = useDispatch();
  const {appliedQuickFilter} = useSelector((state: any) => state.UserPreferences) as UserPreferencesState;
  const [selectedFilterTypes, setSelectedFilterTypes] = useState<string[]>([]);
  const [filterRows, setFilterRows] = useState<FilterRow[]>([]);
  const [deletedFilterId, setDeletedFilterId] = useState<string>('');

  const userPreferencesState = useSelector((state: any) => state.UserPreferences) as UserPreferencesState;

  const handleApplyQuickFilter = (filter: Filter) => {
    setAppliedQuickFilter(filter);
    setFilterRows(filter.query.map((query, index) => ({
      id: index,
      filterType: {value: query.column, label: query.label, type: query.fieldType, isCustomField: query.isCustomField},
      operator: query.condition,
      value: query.value,
    })));
  }

  useEffect(() => {
    if (userPreferencesState?.deleteFilterSuccess && deletedFilterId) {
      if (appliedQuickFilter._id === deletedFilterId) {
        setFilterRows([]);
        setAppliedQuickFilter({} as Filter);
        setDeletedFilterId('');
      }
    }
  }, [userPreferencesState?.deleteFilterSuccess, deletedFilterId]);  

  const setAppliedQuickFilter = (filter: Filter) => {
    const data = {
      moduleName: moduleName,
      filter: filter,
    }
    dispatch(Actions.setAppliedQuickFilter(data));
  }

  const customFields = useSelector(
    (state: any) => state.OpportunityCustomFields.customFields,
  ) as CustomFields;

  const filterTypes = useMemo(() => {
    const customFieldsList = customFields.map((cf) => ({
      value: cf._id,
      label: cf.fieldData.fieldName,
      type: cf.fieldData.fieldType,
      isCustomField: true,
    }));
    return [...defaultFilterTypes, ...customFieldsList];
  }, [customFields]);

  const handleDeleteFilter = (moduleName: string, filterId: string) => {
    const data = {
      moduleName: moduleName,
      filterId: filterId,
    }
    dispatch(Actions.deleteFilterRequest(data));
    setDeletedFilterId(filterId);
  }

  return (
    <FilterTypesContext.Provider
      value={{ filterTypes, selectedFilterTypes, setSelectedFilterTypes, appliedQuickFilter, setAppliedQuickFilter, filterRows, setFilterRows, handleApplyQuickFilter, noFilterAppliedManuallyRef,handleDeleteFilter }}
    >
      {children}
    </FilterTypesContext.Provider>
  );
};

export const useFilterTypes = () => {
  return useContext(FilterTypesContext);
};
