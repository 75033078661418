import React from 'react';
import { Avatar, Button, TableCell, Typography, Stack } from '@mui/material';
import { Opportunity } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';

interface AssignedToCellProps {
  opportunity: Opportunity;
  handleOpenDropdownSelectionPopup: (
    setOpenFn: React.Dispatch<React.SetStateAction<boolean>>,
    opportunityId?: string,
    assignedToId?: {_id: string, email: string}
  ) => void;
  setOpenAssignToPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssignedToCell: React.FC<AssignedToCellProps> = ({
  opportunity,
  handleOpenDropdownSelectionPopup,
  setOpenAssignToPopup,
}) => {
  return (
    <TableCell
      sx={{ py: '1px' }}
      onClick={() => {
        handleOpenDropdownSelectionPopup(
          setOpenAssignToPopup,
          opportunity._id,
          opportunity?.assignedToIds?.[0]
        );
      }}
    >
      {opportunity?.assignedToIds?.[0]?.email ? (
        <>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              sx={{ width: 44, height: 44 }}
              alt={`${opportunity?.assignedToIds?.[0]?.email}`}
              src="/static/images/avatar/1.jpg"
            />
            <Typography variant="body2" sx={{ fontSize: '15px' }}>
              {opportunity?.assignedToIds?.[0]?.email}
            </Typography>
          </Stack>
        </>
      ) : (
        <Button
          variant="text"
          size="small"
          sx={{ fontSize: '15px', px: 0 }}
          onClick={() => {
            handleOpenDropdownSelectionPopup(
              setOpenAssignToPopup,
              opportunity._id
            );
          }}
        >
          + Click to Add
        </Button>
      )}
    </TableCell>
  );
}; 