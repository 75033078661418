import React, { useEffect, useState } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Button, Box, Typography, Stack, IconButton } from "@mui/material";
import "./styles.css"; // CSS file to define boundaries
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NotificationState } from "ReduxStore/Reducers/eijentNotification.reducer";
import { toasterDuration } from "constants/constants";
import { generateUrl } from "helpers/common";
import { Actions } from "ReduxStore/Actions";
import StyledToasterContent from "./StyledToasterContent";
import { SuggetionIcon, CancelIcon } from "../CustomIcons";
import { createNotification } from "helpers";
import { authPermissionValidation } from "helpers/permissionsHelper";
import { permissionsConstants } from "constants/permissionsConstant";
import AppTheme from "eijentTheme/AppTheme";


const HighPriorityNotifications = ({ close, getNotificationCount }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigation = (link: string, notificationId: string): void => {
    if (authPermissionValidation([permissionsConstants.viewOpportunity])) {
      if (link === null || link === undefined || link === "") {
        console.error("Navigation failed: link is undefined or empty.");
        return;
      }
      const firstSegment = link.startsWith("/") ? link.split("/")[1] : "";
      dispatch(Actions.markNotificationRequest({ id: notificationId, isRead: true }));
      if (firstSegment === "opportunity") {
        navigate(generateUrl(link));
      } else {
        navigate(link);
      }
    } else {
      createNotification('error', 'You are not authorized to access this opportunity');
    }
  };

  // Use default empty arrays if any value is null/undefined
  const {
    toasterNotificationhigh,
    shownNotifications,
    isPopupOpen
  } = (useSelector((state: any) => state.eijentNotification) as NotificationState) || {
    toasterNotificationhigh: [],
    shownNotifications: [],
    isPopupOpen: false
  };

  // Filter safely
  const notificationsToDisplay = (toasterNotificationhigh || []).filter((notification) =>
    notification && notification._id && !(shownNotifications || []).includes(notification._id)
  );

 // Close all snackbars and update state when `close === 'all'`
    useEffect(() => {
      if (close === 'all') {
        closeSnackbar(); // Closes all snackbars
        // notificationsToDisplay.forEach((notification) => {
          dispatch(Actions.toasterNotificationCloseRequest({
            id: shownNotifications,
            close: true,
            type: 'smartNudges'
          }));
        // });
      }
    }, [close]);


    useEffect(() => {
      getNotificationCount(notificationsToDisplay.length);
    }, [notificationsToDisplay.length]);

  useEffect(() => {
    if (notificationsToDisplay && notificationsToDisplay.length > 0) {
      notificationsToDisplay.forEach((notification) => {
        // Check if notification and its required fields exist
        if (!notification || !notification._id) return;
        const title: string = notification.title ? notification.title : "";
        const truncatedTitle =
          title.length > 28 ? title.substring(0, 28) + "..." : title;

        const message: string = notification.message ? notification.message : "";
        const truncatedMessage =
          message.length > 80 ? message.substring(0, 80) + "..." : message;

        const priority: string = notification.priority ? notification.priority : "";
        const autoHideDuration = priority === "high" ? null : toasterDuration;

        enqueueSnackbar(
          <Box
            sx={{ maxWidth: "275px" }}
            onClick={(e) => {
              handleNavigation(notification.link, notification._id);
            }}
          >
            <Typography sx={{ fontWeight: "700", wordBreak: "break-word" }}>
              {truncatedTitle ? truncatedTitle.charAt(0).toUpperCase() + truncatedTitle.slice(1) : ""}
            </Typography>
            <Typography sx={{ fontWeight: "400", fontStyle: "italic", wordBreak: "break-word" }}>
              {truncatedMessage}
            </Typography>
            <Stack
              alignItems="end"
              justifyContent="space-between"
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <IconButton
                size="small"
                variant="contained"
                // @ts-ignore
                color="transparent"
                aria-label="close"
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch(Actions.toasterNotificationCloseRequest({ id: [notification._id], close: true, type: 'smartNudges' }));
                  closeSnackbar(notification._id);
                  // Decrease the count in the parent component
                  if (getNotificationCount) {
                    getNotificationCount(-1);
                  }
                }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
          </Box>,
          {
            key: notification._id,
            variant: notification?.type !== "eijentNotification" ? "success" : null,
            autoHideDuration: autoHideDuration,
            onClose: (_, reason) => {
              if (reason === "timeout" && priority !== "high") {
                // Optionally remove notification on timeout
              }
            },
          }
        );
        // Mark notification as shown in Redux  
        dispatch(Actions.markNotificationAsShown(notification._id));
      });
    }
  }, [notificationsToDisplay, closeSnackbar, dispatch, navigate]);

  return null;
};

const MediumPriorityNotifications = ({ close }: { close?: string }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigation = (link: string, notificationId: string): void => {
    if (authPermissionValidation([permissionsConstants.viewOpportunity])) {
      if (link === null || link === undefined || link === "") {
        console.error("Navigation failed: link is undefined or empty.");
        return;
      }
      const firstSegment = link.startsWith("/") ? link.split("/")[1] : "";
      dispatch(Actions.markNotificationRequest({ id: notificationId, isRead: true }));
      if (firstSegment === "opportunity") {
        navigate(generateUrl(link));
      } else {
        navigate(link);
      }
    } else {
      createNotification('error', 'You are not authorized to access this opportunity');
    }
  };

  const {
    toasterNotificationMedium,
    shownNotifications,
    isPopupOpen
  } = (useSelector((state: any) => state.eijentNotification) as NotificationState) || {
    toasterNotificationMedium: [],
    shownNotifications: [],
    isPopupOpen: false
  };

  const notificationsToDisplay = (toasterNotificationMedium || []).filter(
    (notification) =>
      notification && notification._id && !(shownNotifications || []).includes(notification._id)
  );

  useEffect(() => {
    if (close === 'all') {
      closeSnackbar(); // Closes all snackbars
    }
  }, [close]);

  useEffect(() => {
    if (notificationsToDisplay && notificationsToDisplay.length > 0) {
      notificationsToDisplay.forEach((notification) => {
        if (!notification || !notification._id) return;
        const title: string = notification.title ? notification.title : "";
        const truncatedTitle =
          title.length > 28 ? title.substring(0, 28) + "..." : title;

        const message: string = notification.message ? notification.message : "";
        const truncatedMessage =
          message.length > 80 ? message.substring(0, 80) + "..." : message;

        enqueueSnackbar(
          <Box
            sx={{ maxWidth: "275px" }}
            onClick={(e) => {
              handleNavigation(notification.link, notification._id);
            }}
          >
            <Typography sx={{ fontWeight: "700", wordBreak: "break-word" }}>
              {truncatedTitle ? truncatedTitle.charAt(0).toUpperCase() + truncatedTitle.slice(1) : ""}
            </Typography>
            <Typography sx={{ fontWeight: "400", fontStyle: "italic", wordBreak: "break-word" }}>
              {truncatedMessage}
            </Typography>
            <Stack
              alignItems="end"
              justifyContent="space-between"
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <IconButton
                size="small"
                variant="contained"
                // @ts-ignore
                color="transparent"
                aria-label="close"
                onClick={(event) => {
                  event.stopPropagation();
                  closeSnackbar(notification._id);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
          </Box>,
          {
            key: notification._id,
            variant: notification?.type !== "eijentNotification" ? "success" : null,
            autoHideDuration: toasterDuration,
            onClose: (_, reason) => {
              if (reason === "timeout") {
                // Optionally remove notification on timeout
              }
            },
          }
        );
        dispatch(Actions.markNotificationAsShown(notification._id));
      });
    }
  }, [notificationsToDisplay, closeSnackbar, dispatch, navigate]);

  return null;
};

const LowPriorityNotifications  = ({ close }: { close?: string }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigation = (link: string, notificationId: string): void => {
    if (authPermissionValidation([permissionsConstants.viewOpportunity])) {
      if (link === null || link === undefined || link === "") {
        console.error("Navigation failed: link is undefined or empty.");
        return;
      }
      const firstSegment = link.startsWith("/") ? link.split("/")[1] : "";
      dispatch(Actions.markNotificationRequest({ id: notificationId, isRead: true }));
      if (firstSegment === "opportunity") {
        navigate(generateUrl(link));
      } else {
        navigate(link);
      }
    } else {
      createNotification('error', 'You are not authorized to access this opportunity');
    }
  };

  const {
    toasterNotificationLow,
    shownNotifications,
    isPopupOpen
  } = (useSelector((state: any) => state.eijentNotification) as NotificationState) || {
    toasterNotificationLow: [],
    shownNotifications: [],
    isPopupOpen: false
  };

  const notificationsToDisplay = (toasterNotificationLow || []).filter(
    (notification) =>
      notification && notification._id && !(shownNotifications || []).includes(notification._id)
  );

  useEffect(() => {
    if (close === 'all') {
      closeSnackbar(); // Closes all snackbars
    }
  }, [close]);

  useEffect(() => {
    if (notificationsToDisplay && notificationsToDisplay.length > 0) {
      notificationsToDisplay.forEach((notification) => {
        if (!notification || !notification._id) return;
        const title: string = notification.title ? notification.title : "";
        const truncatedTitle =
          title.length > 28 ? title.substring(0, 28) + "..." : title;

        const message: string = notification.message ? notification.message : "";
        const truncatedMessage =
          message.length > 80 ? message.substring(0, 80) + "..." : message;

        const priority: string = notification.priority ? notification.priority : "";
        const autoHideDuration = priority === "high" ? null : toasterDuration;

        enqueueSnackbar(
          <Box
            sx={{ maxWidth: "275px" }}
            onClick={(e) => {
              handleNavigation(notification.link, notification._id);
            }}
          >
            <Typography sx={{ fontWeight: "700", wordBreak: "break-word" }}>
              {truncatedTitle ? truncatedTitle.charAt(0).toUpperCase() + truncatedTitle.slice(1) : ""}
            </Typography>
            <Typography sx={{ fontWeight: "400", fontStyle: "italic", wordBreak: "break-word" }}>
              {truncatedMessage}
            </Typography>
            <Stack
              alignItems="end"
              justifyContent="space-between"
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <IconButton
                size="small"
                variant="contained"
                // @ts-ignore
                color="transparent"
                aria-label="close"
                onClick={(event) => {
                  event.stopPropagation();
                  closeSnackbar(notification._id);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
          </Box>,
          {
            key: notification._id,
            variant: notification?.type !== "eijentNotification" ? "success" : null,
            autoHideDuration: autoHideDuration,
            onClose: (_, reason) => {
              if (reason === "timeout") {
                // Optionally remove notification on timeout
              }
            },
          }
        );
        dispatch(Actions.markNotificationAsShown(notification._id));
      });
    }
  }, [notificationsToDisplay, closeSnackbar, dispatch, navigate]);

  return null;
};

const HighNormalNotifications = ({ close, getNotificationCount }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigation = (link: string, notificationId: string): void => {
    if (authPermissionValidation([permissionsConstants.viewOpportunity])) {
      if (link === null || link === undefined || link === "") {
        console.error("Navigation failed: link is undefined or empty.");
        return;
      }
      const firstSegment = link.startsWith("/") ? link.split("/")[1] : "";
      dispatch(Actions.markNotificationRequest({ id: notificationId, isRead: true }));
      if (firstSegment === "opportunity") {
        navigate(generateUrl(link));
      } else {
        navigate(link);
      }
    } else {
      createNotification('error', 'You are not authorized to access this opportunity');
    }
  };

  const {
    toasterNotificationNormalHigh,
    shownNotifications,
    isPopupOpen
  } = (useSelector((state: any) => state.eijentNotification) as NotificationState) || {
    toasterNotificationNormalHigh: [],
    shownNotifications: [],
    isPopupOpen: false
  };

  const notificationsToDisplay = (toasterNotificationNormalHigh || []).filter(
    (notification) =>
      notification && notification._id && !(shownNotifications || []).includes(notification._id)
  );

  
  useEffect(() => {
    getNotificationCount(notificationsToDisplay.length);
  }, [notificationsToDisplay.length]);

    // Close all snackbars and update state when `close === 'all'`
    useEffect(() => {
      if (close === 'all') {
        closeSnackbar(); // Closes all snackbars
          dispatch(Actions.toasterNotificationCloseRequest({
            id: shownNotifications,
            close: true,
            type: 'normal'
          }));
      }
    }, [close]);


  useEffect(() => {
    if (notificationsToDisplay && notificationsToDisplay.length > 0) {
      notificationsToDisplay.forEach((notification) => {
        if (!notification || !notification._id) return;
        const title: string = notification.title ? notification.title : "";
        const truncatedTitle =
          title.length > 28 ? title.substring(0, 28) + "..." : title;

        const message: string = notification.message ? notification.message : "";
        const truncatedMessage =
          message.length > 80 ? message.substring(0, 80) + "..." : message;

        const priority: string = notification.priority ? notification.priority : "";
        const autoHideDuration = priority === "high" ? null : toasterDuration;

        enqueueSnackbar(
          <Box
            sx={{ maxWidth: "275px" }}
            onClick={(e) => {
              handleNavigation(notification.link, notification._id);
            }}
          >
            <Typography sx={{ fontWeight: "700", wordBreak: "break-word" }}>
              {truncatedTitle ? truncatedTitle.charAt(0).toUpperCase() + truncatedTitle.slice(1) : ""}
            </Typography>
            <Typography sx={{ fontWeight: "400", fontStyle: "italic", wordBreak: "break-word" }}>
              {truncatedMessage}
            </Typography>
            <Stack
              alignItems="end"
              justifyContent="space-between"
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <IconButton
                size="small"
                variant="contained"
                // @ts-ignore
                color="transparent"
                aria-label="close"
                onClick={(event) => {
                  event.stopPropagation();
                  if (priority === "high") {
                    dispatch(Actions.toasterNotificationCloseRequest({ id: [notification._id], close: true, type: 'normal' }));
                  }
                  closeSnackbar(notification._id);
                  if (getNotificationCount) {
                    getNotificationCount(-1);
                  }
                }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
          </Box>,
          {
            key: notification._id,
            variant: notification?.type !== "eijentNotification" ? "success" : null,
            autoHideDuration: autoHideDuration,
            onClose: (_, reason) => {
              if (reason === "timeout") {
                // Optionally remove notification on timeout
              }
            },
          }
        );
        dispatch(Actions.markNotificationAsShown(notification._id));
      });
    }
  }, [notificationsToDisplay, closeSnackbar, dispatch, navigate]);

  return null;
};

const MediumNormalNotifications  = ({ close }: { close?: string }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigation = (link: string, notificationId: string): void => {
    if (authPermissionValidation([permissionsConstants.viewOpportunity])) {
      if (link === null || link === undefined || link === "") {
        console.error("Navigation failed: link is undefined or empty.");
        return;
      }
      const firstSegment = link.startsWith("/") ? link.split("/")[1] : "";
      dispatch(Actions.markNotificationRequest({ id: notificationId, isRead: true }));
      if (firstSegment === "opportunity") {
        navigate(generateUrl(link));
      } else {
        navigate(link);
      }
    } else {
      createNotification('error', 'You are not authorized to access this opportunity');
    }
  };

  const {
    toasterNotificationNormalMedium,
    shownNotifications,
    isPopupOpen
  } = (useSelector((state: any) => state.eijentNotification) as NotificationState) || {
    toasterNotificationNormalMedium: [],
    shownNotifications: [],
    isPopupOpen: false
  };

  const notificationsToDisplay = (toasterNotificationNormalMedium || []).filter(
    (notification) =>
      notification && notification._id && !(shownNotifications || []).includes(notification._id)
  );

  useEffect(() => {
    if (close === 'all') {
      closeSnackbar(); // Closes all snackbars
    }
  }, [close]);

  useEffect(() => {
    if (notificationsToDisplay && notificationsToDisplay.length > 0) {
      notificationsToDisplay.forEach((notification) => {
        if (!notification || !notification._id) return;
        const title: string = notification.title ? notification.title : "";
        const truncatedTitle =
          title.length > 28 ? title.substring(0, 28) + "..." : title;

        const message: string = notification.message ? notification.message : "";
        const truncatedMessage =
          message.length > 80 ? message.substring(0, 80) + "..." : message;

        enqueueSnackbar(
          <Box
            sx={{ maxWidth: "275px" }}
            onClick={(e) => {
              handleNavigation(notification.link, notification._id);
            }}
          >
            <Typography sx={{ fontWeight: "700", wordBreak: "break-word" }}>
              {truncatedTitle ? truncatedTitle.charAt(0).toUpperCase() + truncatedTitle.slice(1) : ""}
            </Typography>
            <Typography sx={{ fontWeight: "400", fontStyle: "italic", wordBreak: "break-word" }}>
              {truncatedMessage}
            </Typography>
            <Stack
              alignItems="end"
              justifyContent="space-between"
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <IconButton
                size="small"
                variant="contained"
                // @ts-ignore
                color="transparent"
                aria-label="close"
                onClick={(event) => {
                  event.stopPropagation();
                  closeSnackbar(notification._id);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
          </Box>,
          {
            key: notification._id,
            variant: notification?.type !== "eijentNotification" ? "success" : null,
            autoHideDuration: toasterDuration,
            onClose: (_, reason) => {
              if (reason === "timeout") {
                // Optionally remove notification on timeout
              }
            },
          }
        );
        dispatch(Actions.markNotificationAsShown(notification._id));
      });
    }
  }, [notificationsToDisplay, closeSnackbar, dispatch, navigate]);

  return null;
};

const LowNormalNotifications  = ({ close }: { close?: string }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigation = (link: string, notificationId: string): void => {
    if (authPermissionValidation([permissionsConstants.viewOpportunity])) {
      if (link === null || link === undefined || link === "") {
        console.error("Navigation failed: link is undefined or empty.");
        return;
      }
      const firstSegment = link.startsWith("/") ? link.split("/")[1] : "";
      dispatch(Actions.markNotificationRequest({ id: notificationId, isRead: true }));
      if (firstSegment === "opportunity") {
        navigate(generateUrl(link));
      } else {
        navigate(link);
      }
    } else {
      createNotification('error', 'You are not authorized to access this opportunity');
    }
  };

  const {
    toasterNotificationNormalLow,
    shownNotifications,
    isPopupOpen
  } = (useSelector((state: any) => state.eijentNotification) as NotificationState) || {
    toasterNotificationNormalLow: [],
    shownNotifications: [],
    isPopupOpen: false
  };

  const notificationsToDisplay = (toasterNotificationNormalLow || []).filter(
    (notification) =>
      notification && notification._id && !(shownNotifications || []).includes(notification._id)
  );

  useEffect(() => {
    if (close === 'all') {
      closeSnackbar(); // Closes all snackbars
    }
  }, [close]);

  useEffect(() => {
    if (notificationsToDisplay && notificationsToDisplay.length > 0) {
      notificationsToDisplay.forEach((notification) => {
        if (!notification || !notification._id) return;
        const title: string = notification.title ? notification.title : "";
        const truncatedTitle =
          title.length > 28 ? title.substring(0, 28) + "..." : title;

        const message: string = notification.message ? notification.message : "";
        const truncatedMessage =
          message.length > 80 ? message.substring(0, 80) + "..." : message;

        enqueueSnackbar(
          <Box
            sx={{ maxWidth: "275px" }}
            onClick={(e) => {
              handleNavigation(notification.link, notification._id);
            }}
          >
            <Typography sx={{ fontWeight: "700", wordBreak: "break-word" }}>
              {truncatedTitle ? truncatedTitle.charAt(0).toUpperCase() + truncatedTitle.slice(1) : ""}
            </Typography>
            <Typography sx={{ fontWeight: "400", fontStyle: "italic", wordBreak: "break-word" }}>
              {truncatedMessage}
            </Typography>
            <Stack
              alignItems="end"
              justifyContent="space-between"
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <IconButton
                size="small"
                variant="contained"
                // @ts-ignore
                color="transparent"
                aria-label="close"
                onClick={(event) => {
                  event.stopPropagation();
                  closeSnackbar(notification._id);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
          </Box>,
          {
            key: notification._id,
            variant: notification?.type !== "eijentNotification" ? "success" : null,
            autoHideDuration: toasterDuration,
            onClose: (_, reason) => {
              if (reason === "timeout") {
                // Optionally remove notification on timeout
              }
            },
          }
        );
        dispatch(Actions.markNotificationAsShown(notification._id));
      });
    }
  }, [notificationsToDisplay, closeSnackbar, dispatch, navigate]);

  return null;
};


const NotificationToaster = (props) => {
 const [clearAll, setClearAll] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  // Function to update notification count
  const getNotificationCount = (change) => {
    setNotificationCount((prev) => Math.max(0, prev + change));
  };

  // Handle Clear All button click
  const handleClearAll = () => {
    if (notificationCount > 0) {
      setClearAll(true);
      setNotificationCount(0); // Reset count when clearing all notifications
    }
  };

  return (
    <AppTheme {...props} >
      <div className="notification-container">
         {notificationCount > 1 && (
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              variant="contained"
              // @ts-ignore
              color="white"
              size="small"
              onClick={handleClearAll}
            >
              Clear All 
            </Button>
          </Box>
        )}
        <div className="high-priority-notifications">
          <SnackbarProvider
            iconVariant={{ success: <SuggetionIcon /> }}
            maxSnack={5}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            Components={{
              default: StyledToasterContent,
              success: StyledToasterContent,
            }}
          >
            <HighPriorityNotifications close={clearAll ? 'all' :null} getNotificationCount={getNotificationCount} />
          </SnackbarProvider>
        </div>

        <div className="medium-priority-notifications">
          <SnackbarProvider
            iconVariant={{ success: <SuggetionIcon /> }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            Components={{
              default: StyledToasterContent,
              success: StyledToasterContent,
            }}
          >
            <MediumPriorityNotifications close={clearAll ? 'all' :null}/>
          </SnackbarProvider>
        </div>

        <div className="low-priority-notifications">
          <SnackbarProvider
            iconVariant={{ success: <SuggetionIcon /> }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            Components={{
              default: StyledToasterContent,
              success: StyledToasterContent,
            }}
          >
            <LowPriorityNotifications close={clearAll ? 'all' :null}/>
          </SnackbarProvider>
        </div>

        <div className="normal-high-toast-notifications">
          <SnackbarProvider
            maxSnack={50}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            Components={{
              default: StyledToasterContent,
              success: StyledToasterContent,
            }}
          >
            <HighNormalNotifications close={clearAll ? 'all' :null} getNotificationCount={getNotificationCount} />
          </SnackbarProvider>
        </div>

        <div className="normal-medium-toast-notifications">
          <SnackbarProvider
            maxSnack={50}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            Components={{
              default: StyledToasterContent,
              success: StyledToasterContent,
            }}
          >
            <MediumNormalNotifications close={clearAll ? 'all' :null}/>
          </SnackbarProvider>
        </div>

        <div className="normal-low-toast-notifications">
          <SnackbarProvider
            maxSnack={50}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            Components={{
              default: StyledToasterContent,
              success: StyledToasterContent,
            }}
          >
            <LowNormalNotifications close={clearAll ? 'all' :null}/>
          </SnackbarProvider>
        </div>
      </div>
    </AppTheme>
  );
};

export default NotificationToaster;
