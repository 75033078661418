import axios from 'axios';
import BaseAPI from '../baseAPI';


class JourneyAPI extends BaseAPI {


    getJourney = async (params: object) => {
        return this.API('/journey/get', {
            method: 'GET',
            params
        });
    };

    clrearWorkflowHistory = async (data: object) => {
        return this.API('/journey/clearHistory', {
            method: 'POST',
            data
        });
    };

    updateStatus = async (data: object) => {
        return this.API('/journey/updateStatus', {
            method: 'POST',
            data
        });
    };


    testJourney = async (params: object) => {
        return this.API('/journey/test', {
            method: 'POST',
            data:params
        });
    };

    getJourneyList = async (params: object) => {
        return this.API('/journey/list', {
            method: 'GET',
            params
        });
    };

    deleteJourney = async (params: object) => {
        return this.API('/journey/delete', {
            method: 'DELETE',
            params
        });
    };

    generateBasicInfo = async (params: object) => {
        return this.API('/journey/generateBasicInfo', {
            method: 'POST',
            data:params
        });
    };
}

export const createJourneyAPI = async (data: object) => {
    try {
        const URL = process.env.REACT_APP_API_URL;
      const users = JSON.parse(localStorage.getItem('user_details'));
      if (!users) {
        throw new Error('User data is null');
      }
  
      // Call the API with page and limit
      const res = await axios.post(`${URL}/journey/create`, data,{
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  };
export default JourneyAPI;