import React, { useState, useEffect, useCallback } from 'react';
import { Select, MenuItem, SelectChangeEvent, TextField, Checkbox } from '@mui/material';
import { FilterType } from '../../utils/FilterTypesContext';

interface Entity {
  _id: string;
  [key: string]: any;
}

interface BaseSearchDropdownProps<T extends Entity> {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  filterType: FilterType;
  placeholder: string;
  displayField: keyof T;
  fetchItems: (search: string) => Promise<T[]>;
}

function BaseSearchDropdown<T extends Entity>({
  value,
  onChange,
  placeholder,
  displayField,
  fetchItems,
}: BaseSearchDropdownProps<T>) {
  const [items, setItems] = useState<T[]>([]);
  const [selectedItems, setSelectedItems] = useState<Record<string, string>>({});
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const getItemDisplay = useCallback((id: string) => {
    return selectedItems[id] || placeholder;
  }, [selectedItems, placeholder]);

  const handleSearch = useCallback(async (search: string) => {
    try {
      const fetchedItems = await fetchItems(search);
      setItems(fetchedItems);
      
      // Update selectedItems with any new items that are selected
      setSelectedItems(prevSelectedItems => {
        const newSelectedItems = { ...prevSelectedItems };
        fetchedItems.forEach(item => {
          if (value.includes(item._id)) {
            newSelectedItems[item._id] = String(item[displayField]);
          }
        });
        return newSelectedItems;
      });
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  }, [fetchItems, value, displayField]);

  // Update selectedItems when a selection changes
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value as string[];
    
    // Store display values for newly selected items
    const newSelectedItems = { ...selectedItems };
    items.forEach(item => {
      if (newValue.includes(item._id)) {
        newSelectedItems[item._id] = String(item[displayField]);
      }
    });
    setSelectedItems(newSelectedItems);
    
    onChange(event);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchText) {
        handleSearch(searchText);
      } else {
        setItems([]);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText, handleSearch]);

  return (
    <Select
      multiple
      variant="outlined"
      size="small"
      value={value}
      onChange={handleChange}
      fullWidth
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      displayEmpty
      renderValue={(selected) => {
        const selectedId = selected as string[];
        const selectedNames = selectedId.map(id => getItemDisplay(id)).join('\n');
        return (
          <div title={selectedNames}>
            {selectedId.length === 0 
              ? placeholder 
              : `${selectedId.length} ${placeholder}${selectedId.length > 1 ? 's' : ''} selected`}
          </div>
        );
      }}
    >
      <MenuItem style={{ padding: '8px' }}>
        <TextField
          size="small"
          fullWidth
          placeholder={`Search ${placeholder.toLowerCase()}...`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </MenuItem>
      {items.map((item) => (
        <MenuItem key={item._id} value={item._id}>
          <Checkbox checked={value.includes(item._id)} />
          {String(item[displayField])}
        </MenuItem>
      ))}
    </Select>
  );
}

export default BaseSearchDropdown; 