import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography
} from '@mui/material';
import EijentDashboard from 'eijent';
import Loader from 'Components/Loader/loader';

export default function Home() {
    // const [page_no, setPage_no] = useState(1); 

    const getGreetingMessage = () => {
        const currentHour = new Date().getHours(); // Get the current hour (0-23)
        let user = JSON.parse(localStorage.getItem('user_details'));
        let firstName1 = user?.firstName;
        let lastName1 = user?.lastName;
        let firstName = firstName1 ? firstName1.charAt(0).toUpperCase() + firstName1.slice(1).toLowerCase() : '';
        let lastName = lastName1 ? lastName1.charAt(0).toUpperCase() + lastName1.slice(1).toLowerCase() : '';
        if (currentHour < 12) {
            return `Good Morning, ${firstName} ${lastName}`;
        } else if (currentHour < 17) {
            return `Good Afternoon, ${firstName} ${lastName}`;
        } else {
            return `Good Evening, ${firstName} ${lastName}`;
        }
    };


    return (
        <>
        <EijentDashboard>
            <Box>
                <Typography variant='h1' fontWeight={'400'} textAlign={'center'} sx={{ marginBottom: 2, paddingTop: 4 }}>
                    {getGreetingMessage()}
                </Typography>
            </Box>
        </EijentDashboard>
        </>
    );
}
