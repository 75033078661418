import React from 'react';

interface SourceCellProps {
  source: string;
}

export const SourceCell: React.FC<SourceCellProps> = ({ source }) => {
  const getSourceDisplay = () => {
    switch (source) {
      case 'aiAgent':
        return 'AI Agent';
      case 'imported':
        return 'Imported';
      default:
        return 'Manually Added';
    }
  };

  return <>{getSourceDisplay()}</>;
}; 