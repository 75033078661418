import * as React from "react";
import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Stack, Stepper, Step, StepLabel } from "@mui/material";
import {
  ArrowLeftIcon,
  ReportingIconActive,
  CancelIcon,
  ArrowRightWhiteIcon,
  MeetingIcon,
  TasksIcon,
  TaskBlueIcon,
  CancelRedIcon,
  RoundCheckIcon,
} from "../../components/CustomIcons";
import { useTheme } from '@mui/material/styles';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import Grid from '@mui/material/Grid2';
import OpportunityView from "./OpportunityView";
import OpportunityTabs from "./OpportunityTabsContent/OpportunityTabs";



const steps = ["Type", "Initial journey data", "Define Workflow", "Confirmation",];

const NewOpportunity: React.FC<{ open: boolean; onClose: () => void; }> = ({ open, onClose }) => {
  const theme = useTheme();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 200px)",
    height: "calc(100vh - 60px)",
    // bgcolor: "#F2F2F2",
    bgcolor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up('xs')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('sm')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('xl')]: {
      width: "calc(100% - 200px)",
      height: "calc(100vh - 60px)",
    },
  };
  // const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [skipped, setSkipped] = React.useState<Set<number>>(new Set());

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const isStepOptional = (step: number) => {
    return step === 0;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={{
        backdrop: { timeout: 200, sx: { backdropFilter: "blur(4px)", backgroundColor: "rgba(255, 255, 255, 0.5)" } },
      }}
    >
      <Box sx={modalStyle}>
        {/* Header */}
        <Stack pl={2.75} pr={1.75} py={1} borderBottom="1px solid #D9D9D9" direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">
            🧠KB / 🤓CCXP
          </Typography>

          <Box display="flex" alignItems="center" gap={0.75}>
            <Typography variant="body2" pr={3}>
              Created on 3 Oct
            </Typography>
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<RoundCheckIcon />}
              sx={{ pl: 1 }}
            >
              Commit Opportunity
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ backgroundColor: "#E6EFFF", color: '#5578fa', "&:hover": { backgroundColor: "#E6EFFF" } }}
              startIcon={<TaskBlueIcon />}
            >
              Task
            </Button>



            <Button
              variant="contained"
              size="small"
              sx={{ backgroundColor: "#E6EFFF", color: '#5578fa', "&:hover": { backgroundColor: "#E6EFFF" } }}
              startIcon={<MeetingIcon />}
            >
              Meeting
            </Button>

            {/* --divider-- */}
            <Box sx={{ borderLeft: "1px solid #D9D9D9", height: "27px", mx: 2, }} ></Box>


            <IconButton size="small"
              sx={{ backgroundColor: "#E6EFFF", "&:hover": { backgroundColor: "#E6EFFF" } }}
            >
              <NorthEastRoundedIcon color="primary" />
            </IconButton>

            <IconButton size="small"
              sx={{ backgroundColor: "#E6EFFF", "&:hover": { backgroundColor: "#E6EFFF" } }}
            >
              <MoreHorizRoundedIcon color="primary" />
            </IconButton>

            <IconButton size="small"
              sx={{ backgroundColor: "#E6EFFF", "&:hover": { backgroundColor: "#E6EFFF" } }}
            >
              <StarRoundedIcon color="primary" />
            </IconButton>

            {/* Close Button */}
            <IconButton size="small"
              sx={{ backgroundColor: "#FFB5B5", "&:hover": { backgroundColor: "#FFB5B5" } }}
              aria-label="close"
              onClick={onClose}>
              <CancelRedIcon />
            </IconButton>
          </Box>
        </Stack>

        {/* Step Content */}
        <Box flexGrow={1} sx={{ overflow: 'auto', }} >
          <Grid container
            height="100%"
            width="100%">
            {/* Left Panel - Opportunity View */}
            <Grid size={{ xs: "grow", }}  >
              <OpportunityView />
            </Grid>

            {/* Right Panel - Tabs */}
            <Grid size={{ xs: 4, lg: 5, xl: 5 }} py={'5px'} maxHeight={'100%'}>
              <OpportunityTabs />
            </Grid>
          </Grid>
        </Box>;

      </Box >
    </Modal >
  );
};

export default NewOpportunity;
