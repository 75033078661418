import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import { FilterType } from '../../utils/FilterTypesContext';
import OpportunityContactsAPI from 'ReduxStore/API/opportunity/opportunityContactsAPI';
import BaseSearchDropdown from './BaseSearchDropdown';

interface Contact {
  _id: string;
  fullName: string;
}

interface DropdownForContactProps {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  filterType: FilterType;
}

const DropdownForContact: React.FC<DropdownForContactProps> = (props) => {
  const fetchContacts = async (search: string) => {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const data = {
      queryParams: {
        searchOnlyName: true,
        from: '',
      },
      bodyParams: { 
        filterArray: [], 
        searchText: search, 
        getAllContacts: true 
      },
    };
    const res = await opportunityContactsAPI.fetchAllOpportunityContacts(data);
    return res.data.success ? res.data.contacts : [];
  };

  return (
    <BaseSearchDropdown<Contact>
      {...props}
      placeholder="Select Contact"
      displayField="fullName"
      fetchItems={fetchContacts}
    />
  );
};

export default DropdownForContact;
