import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
function DropdownSelectionPopUp({
  open,
  setOpen,
  dropdownArray,
  heading,
  subheading,
  label,
  onSaveFn,
  setSelectedOppForAssigning,
  selectedOppForAssigning,
  selectedAssignedToId
}) {
  const [selected, setSelected] = useState(selectedAssignedToId || null);

  useEffect(() => {
    setSelected(selectedAssignedToId || null);
  }, [selectedAssignedToId]);

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
    selectedOppForAssigning && setSelectedOppForAssigning(null);
  };

  const handleSave = () => {
    onSaveFn(selected);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      classNames={{
        overlay: '',
        modal: 'Invite-User-Modal',
      }}
      center
    >
      <Grid component="form" container className="modal-wrapper">
        <Grid size={{xs: 12,}} pb={3}>
          <Box mb={1} className="subsecondary">
            {heading}
          </Box>
          <Box className="small-subtitle">{subheading}</Box>
        </Grid>
        <Grid size={{xs: 12,}} pb={3}>
          <Box component="div" className="inputField" pb={2}>
            <label>{label}</label>

            <Autocomplete
              options={dropdownArray}
              getOptionLabel={(option) => option?.name || option?.email || ''}
              value={selected}
              onChange={(event, newValue) => {
                setSelected(newValue);
              }}
              renderInput={(params) => {
                return <TextField 
                  {...params}  
                  variant="outlined"  
                  placeholder="Select" 
                  value={selected?.name || selected?.email || ''}
                />
              }}
            />
          </Box>
        </Grid>
        <Grid size={{xs: 12,}}>
          <Grid container justifyContent="end">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleSave}
              disabled={selected?false:true}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default DropdownSelectionPopUp;