
import React, { useEffect, useState } from 'react';
import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup, ButtonGroup, Tab, Badge, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';

import {
  ArrowDownDarkIcon,
  AddNewIcon,

  HomeIconActive,
  InboxIconActive,
  TasksIconActive,
  OpportunitiesIconActive,
  AccountsIconActive,
  ContactsIconActive,
} from '../../components/CustomIcons';

import SettingsMenuContent from "./SettingsMenuContent";
import TransactionalEmailList from "./TransactionalEmail/TransactionalEmailList.tsx";
import MyAgents from "./MyAgents/MyAgentsList.tsx";
import EijentDashboard from "eijent";

export default function Settings(props) {

  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("user_details");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  return (
    <Box display={'flex'} gap={1.5} flexGrow='1' overflow={'auto'}>
      <Box className={'setting-menu'}
        sx={{
          backgroundColor: '#fff',
          borderRadius: '8px',
          width: 288,
          minWidth: 288,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <Stack
          direction="row"
          sx={{
            p: 3,
            gap: 1,
            alignItems: 'center',
            borderColor: 'divider',
          }}
        >
          <Avatar
            sizes="small"
            alt="Riley Carter"
            src="/static/images/avatar/7.jpg"
            sx={{ width: 42, height: 42, fontSize: '12px', fontWeight: 400, }}

          >
            {user?.firstName
              ?.charAt(0)
              .toUpperCase()}
            {user?.lastName
              ?.charAt(0)
              .toUpperCase()}
          </Avatar>
          <Box sx={{ mr: 'auto' }}>
            <Typography variant="body2" sx={{ fontWeight: 700, lineHeight: '16px', textTransform: 'capitalize' }}>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 400, lineHeight: '16px' }}>
              {user?.email}
            </Typography>
          </Box>
        </Stack>
        <Divider />
        <SettingsMenuContent />
      </Box>

      <Box className={'setting-pannel'} flexGrow='1'
        sx={{
          backgroundColor: '#F4F6F7',
          overflow: 'auto',
          borderRadius: '8px',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
