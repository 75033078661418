import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Chip,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import {
  DeleteIcon,
  CancelIcon,
  SentWhiteIcon,
} from '../../components/CustomIcons';
import DraftsIcon from '@mui/icons-material/Drafts';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './QuillToolbar/quill.scss';
import QuillToolbar from './QuillToolbar/QuillToolbar';
import EijentDashboard from 'eijent';
import PageTabs from '../Tabs';
import SideTabs from './SideTabs';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  xpAccountIntegrationDeleteDraft,
  xpAccountIntegrationDownloadAttachment,
  xpAccountIntegrationGmailGetDraftsDetails,
  xpAccountIntegrationMailSaveAsDraft,
  xpAccountIntegrationMailSend,
  xpAccountIntegrationMailSendDraft,
  xpAccountIntegrationMailUpdateDraft,
} from 'ReduxStore/API';
import { createNotification } from 'helpers';
import Loader from 'Components/Loader/loader';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ClearIcon from '@mui/icons-material/Clear';
import { decodeBase64Content, isValidEmail } from 'helpers/common';
//@ts-ignore
import DocIcon from '../../../../src/assets/images/icons/Doc-icon.svg';
import ErrorBoundary from 'eijent/components/ErrorBoundary';
import { useGmail } from './context/GmailContext';

//@ts-ignore
const modules: QuillToolbarModules = {
  toolbar: {
    container: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }], // Text alignment
      ['link', 'image'], // Default image handler
      ['attachment'], // Custom attachment button
      ['clean'], // Removes formatting
    ],
  },
};

type Errors = {
  emailBody?: string;
  subject?: string;
  recipients?: string;
  cc?: string;
  bcc?: string;
  general?: string;
  fieldName?: string;
};

export default function ComposeEmail() {
  const ccRef = useRef<HTMLInputElement>(null);
  const recipientsRef = useRef<HTMLInputElement>(null);
  const bccRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [payload, setPayload] = useState({});

  const [recipients, setRecipients] = useState('');
  const [subject, setSubject] = useState('');
  const [cC, setCc] = useState('');
  const [isCcOpen, setIsCcOpen] = useState(false);
  const [bcC, setBcc] = useState('');
  const [isBccOpen, setIsBccOpen] = useState(false);
  const [removedAttachments, setRemovedAttachments] = useState([]);
  const [editorText, setEditorText] = useState('');
  const [fileNames, setFileNames] = useState<File[]>([]);
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageIdForDraft, setMessageIdForDraft] = useState('');
  const [recipientsChips, setRecipientsChips] = useState<string[]>([]);
  const [ccChips, setCcChips] = useState<string[]>([]);
  const [bccChips, setBccChips] = useState<string[]>([]);

  const params = useParams();
  const { gmailId } = useGmail();
  // useEffect(() => {
  //   if(!location.pathname.includes('compose')){
  //     if (params.draftId) {
  //       getDraftDetails(params.draftId);
  //     } else {
  //       resetStates();
  //     }
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    if (params.draftId) {
      getDraftDetails(params.draftId);
    } else {
      resetStates();
    }
  }, []);


  const resetStates = () => {
    setRecipients('');
    setSubject('');
    setCc('');
    setIsCcOpen(false);
    setBcc('');
    setIsBccOpen(false);
    setRemovedAttachments([]);
    setEditorText('');
    setFileNames([]);
    setErrors({});
    setIsLoading(false);
    setMessageIdForDraft('');
    setPayload({});
    setRecipientsChips([]);
    setCcChips([]);
    setBccChips([]);
  };

  const handleOpenCC = () => {
    setIsCcOpen(true);
  };
  const handleCloseCC = () => {
    setIsCcOpen(false);
    setCc('');
    setCcChips([]);
  };
  const handleOpenBCC = () => {
    setIsBccOpen(true);
    setBccChips([]);
    setBcc('');
  };
  const handleCloseBCC = () => {
    setIsBccOpen(false);
  };

  const modules = {
    toolbar: {
      container: '#toolbar', // Attach the toolbar using the ID
    },
  };

  const extractValidAndInvalidEmails = (inputString) => {
    // Regex to match emails (both in "<...>" and as plain emails)
    const emailRegex =
      /(?:<([^<>]+@[^\s@]+\.[^\s@]+)>)|([^<>\s,]+@[^\s@]+\.[^\s@]+)/g;

    const result = [];
    let match;

    // Iterate through all matches in the input string
    while ((match = emailRegex.exec(inputString)) !== null) {
      // Select the correct email from the capture groups
      const email = match[1] || match[2];

      // Only add valid emails
      if (email && !result.includes(email.trim())) {
        result.push(email.trim()); // Add email to result (avoiding duplicates)
      }
    }

    // Return the final comma-separated string (empty if no emails are found)
    return result.join(' ');
  };

  const getDraftDetails = async (draftId) => {
    try {
      setIsLoading(true);
      const data = {
        draftId,
      };
      const res = await xpAccountIntegrationGmailGetDraftsDetails(data);
      if (res.data.success && res.status === 200) {
        const message = res.data.data.message;
        const subjectDetail = message?.payload?.headers.find(
          (detail) => detail.name === 'Subject',
        );
        const sendTo = message?.payload?.headers.find(
          (detail) => detail.name === 'To',
        );

        const { payload } = message;
        const decodedContent = payload.parts?.[0]?.body?.data
          ? decodeBase64Content(payload.parts[0].body.data)
          : decodeBase64Content(payload.body?.data || '');

        setEditorText(decodedContent || message?.snippet || '');
        setPayload(payload);
        setMessageIdForDraft(message.id);

        // setDraftId(draftId);
        subjectDetail?.value && setSubject(subjectDetail?.value);
        sendTo?.value && setRecipientsChips(
          extractValidAndInvalidEmails(sendTo.value).split(', '),
        );
        setRecipients('');
      } else {
        createNotification('error', 'Something went wrong');
      }
    } catch (error) {
      createNotification('error', error.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMailStatus = async (status: string) => {
    try {
      if (isValid(status)) {
        const formattedRecipients = recipientsChips.join(',');
        const formattedCcMails = ccChips.join(',');
        const formattedBCcMails = bccChips.join(',');
        const formData = new FormData();
        formData.append('from', gmailId);
        formData.append('to', formattedRecipients);
        formData.append('cC', formattedCcMails);
        formData.append('bcC', formattedBCcMails);
        formData.append('subject', subject);
        formData.append('htmlContent', editorText);

        fileNames.forEach((file: File) => {
          formData.append('attachments', file);
        });

        if (status === 'send') {
          setIsLoading(true);
          let response: any = {};
          if (params.draftId) {
            formData.append('gmailId', gmailId);
            formData.append('draftId', params.draftId);
            removedAttachments.forEach((el) => {
              formData.append('removedAttachmentsPartId', el);
            });
            response = await xpAccountIntegrationMailSendDraft(formData);
          } else {
            response = await xpAccountIntegrationMailSend(formData);
          }
          setIsLoading(false);
          if (response?.data?.status == 200) {
            createNotification('success', response?.data?.message);
            setRecipients('');
            setSubject('');
            setPayload({});
            setCc('');
            setBcc('');
            setEditorText('');
            setFileNames([]);
            setErrors({});
            setRecipientsChips([]);
            setCcChips([]);
            setBccChips([]);
            navigate(-1);
          }else{
            createNotification('error', response?.data?.message);
          }
        } else {
          setIsLoading(true);
          let response: any = {};
          if (params.draftId) {
            formData.append('messageId', messageIdForDraft);
            formData.append('draftId', params.draftId);
            formData.append('gmailId', gmailId);
            removedAttachments.forEach((el) => {
              formData.append('removedAttachmentsPartId', el);
            });
            response = await xpAccountIntegrationMailUpdateDraft(formData);
          } else {
            response = await xpAccountIntegrationMailSaveAsDraft(formData);
            navigate(`/inbox/compose/${response.data.draftId}`);
          }
          setIsLoading(false);
          if (response?.data?.status == 200 && response?.data?.success) {
            setPayload(response.data.draftMessage.payload);
            createNotification('success', response?.data?.message);
            setFileNames([]);

            // trying start
            const message = response.data.draftMessage;
            const subjectDetail = message?.payload?.headers.find(
              (detail) => detail.name === 'Subject',
            );
            const sendTo = message?.payload?.headers.find(
              (detail) => detail.name === 'To',
            );

            const { payload } = message;
            const decodedContent = payload.parts?.[0]?.body?.data
              ? decodeBase64Content(payload.parts[0].body.data)
              : decodeBase64Content(payload.body?.data || '');

            setEditorText(decodedContent || message?.snippet || '');
            setPayload(payload);
            setMessageIdForDraft(message.id);

            // setDraftId(draftId);
            subjectDetail?.value && setSubject(subjectDetail?.value);
            sendTo?.value && setRecipientsChips(
              extractValidAndInvalidEmails(sendTo.value).split(', '),
            );
            setRecipients('');
            // trying end

            // setRecipients('');
            // setSubject('');
            // setPayload({});
            // setCc('');
            // setBcc('');
            // setEditorText('');
          }else{
            createNotification('error', response?.data?.message);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      createNotification('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecipientsChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== ',') {
      setRecipients(event.target.value);
    }
  };
  const handleCcChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== ',') {
      setCc(event.target.value);
    }
  };
  const handleBccChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== ',') {
      setBcc(event.target.value);
    }
  };

  const processInput = (handlingFor: 'recipients' | 'cC' | 'bcC') => {
    let input = '';

    if (handlingFor === 'recipients') {
      input = recipients;
    } else if (handlingFor === 'cC') {
      input = cC;
    } else if (handlingFor === 'bcC') {
      input = bcC;
    }

    const isInputValid = isValidEmail(input.trim());

    if (!isInputValid) {
      setErrors({
        ...errors,
        [handlingFor === 'cC' ? 'cc' : handlingFor === 'bcC' ? 'bcc' : 'recipients']: 'Please enter a valid email address'
      });
      return;
    }

    if (input && isInputValid) {
      setErrors(prev => {
        const obj = { ...prev };
        delete obj[handlingFor === 'cC' ? 'cc' : handlingFor === 'bcC' ? 'bcc' : 'recipients'];
        return obj;
      });
      const callBackFn = (prev) => [input.toLowerCase().trim(), ...prev];

      if (handlingFor === 'recipients') {
        setRecipientsChips(callBackFn);
        setRecipients('');
        recipientsRef?.current?.focus();
      } else if (handlingFor === 'cC') {
        setCcChips(callBackFn);
        setCc('');
        ccRef?.current?.focus();
      } else if (handlingFor === 'bcC') {
        setBccChips(callBackFn);
        setBcc('');
        bccRef?.current?.focus();
      }
    }
  };

  const handleKeyDown = (event, handlingFor: 'recipients' | 'cC' | 'bcC') => {
    if (['Tab', ',', 'Enter'].includes(event.key)) {
      // Get the current input value based on handlingFor
      let currentInput = '';
      if (handlingFor === 'recipients') currentInput = recipients;
      else if (handlingFor === 'cC') currentInput = cC;
      else if (handlingFor === 'bcC') currentInput = bcC;

      // Only prevent default if we have a valid email
      if (currentInput.trim() && isValidEmail(currentInput.trim())) {
        event.preventDefault();
        processInput(handlingFor);
      }
    }
  };

  const handleBlur = (handlingFor: 'recipients' | 'cC' | 'bcC') => {
    processInput(handlingFor);
  };

  const handleRemoveChip = (
    chip: string,
    handlingFor: 'recipients' | 'cC' | 'bcC',
  ) => {
    const callBackFn = (prev) => prev.filter((el) => el !== chip);
    if (handlingFor === 'recipients') {
      setRecipientsChips(callBackFn);
    }
    if (handlingFor === 'cC') {
      setCcChips(callBackFn);
    }
    if (handlingFor === 'bcC') {
      setBccChips(callBackFn);
    }
  };

  const handleSubChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleEditorText = (val: string) => {
    setEditorText(val);
  };

  // Custom handler to upload attachments
  const handleAttachment = (file) => {
    if (file) {
      setFileNames([...fileNames, file]);
    }
  };

  const handleDelete = (fileToDelete: File) => {
    setFileNames((prevFileNames) =>
      prevFileNames.filter((file) => file.name !== fileToDelete.name),
    );
  };

  const isValid = (status: string) => {
    const error: Errors = {};
    let isValid = true;

    const validateEmailField = (
      field: string | undefined,
      fieldName: string,
    ): boolean => {
      if (!field) {
        return false; // Immediately return false if field is undefined or empty
      }

      const allMails = field.split(',').map((mail) => mail.trim()); // Split and trim all emails
      const invalidMail = allMails.find(
        (mail) => mail.length > 0 && !isValidEmail(mail),
      ); // Find the first invalid email

      if (invalidMail) {
        error.fieldName = `The field is not valid. Please enter a valid email address for ${fieldName.toUpperCase()}.`;
        return false;
      }

      return true; // All emails are valid
    };

    // Condition to check if editorText is empty or only contains placeholder HTML
    const isBodyEmpty = !editorText || editorText === '<p><br></p>';

    // Validate mandatory fields based on status
    if (status === 'send') {
      if (recipientsChips.length === 0) {
        error.recipients = 'Please add at least one recipient';
        isValid = false;
      }
      if (recipientsChips.length > 0 && !validateEmailField(recipientsChips.join(','), 'recipients')) {
        error.recipients = 'Please enter the recipients email again';
        isValid = false;
      }
      if (!subject || subject.trim().length === 0) {
        error.subject = 'Please enter the subject';
        isValid = false;
      }
      if (isBodyEmpty) {
        error.emailBody = 'Please enter the email body';
        isValid = false;
      }
    } else if (status === 'draft') {
      // Check if at least one field is valid in "draft"
      const hasValidField =
        (recipientsChips.length > 0 && validateEmailField(recipientsChips.join(','), 'recipients')) ||
        (subject && subject.trim().length > 0) ||
        fileNames.length > 0 ||
        validateEmailField(cC, 'cc') ||
        validateEmailField(bcC, 'bcc') ||
        !isBodyEmpty;

      if (!hasValidField) {
        error.recipients =
          'For a draft, at least one of recipients, subject, cc, bcc, email body or file must be filled in and valid.';
        isValid = false;
      }
    }

    //Additional validation for cc and bcc fields, if provided
    if (isCcOpen && ccChips.length > 0 && !validateEmailField(ccChips.join(','), 'cc')) {
      error.cc = 'CC field is not valid. Please enter a valid email address.';
      error.general = '';
      isValid = false;
    }
    if (isBccOpen && bccChips.length > 0 && !validateEmailField(bccChips.join(','), 'bcc')) {
      error.bcc = 'BCC field is not valid. Please enter a valid email address.';
      error.general = '';
      isValid = false;
    }

    if (recipients !== '') {
      error.recipients = 'Please enter the recipients email properly';
      isValid = false;
    }
    if (isCcOpen && cC !== '') {
      error.cc = 'Please enter the cc email properly';
      isValid = false;
    }
    if (isBccOpen && bcC !== '') {
      error.bcc = 'Please enter the bcc email properly';
      isValid = false;
    }


    setErrors(error);
    return isValid;
  };

  const handleRemovedAttachments = (partId) => {
    setRemovedAttachments((prev) => [...prev, partId]);
  };

  const renderAttachments = () => {
    if (!payload) return null;
    const { parts }: any = payload;

    if (
      //@ts-ignore
      payload.mimeType === 'multipart/mixed' &&
      Array.isArray(parts) &&
      parts.length > 1
    ) {
      return parts.map((part, i) => {
        if (i > 0 && !removedAttachments.includes(part.partId)) {
          return (
            <Grid>
              <Box className="attachment-file" display={'flex'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box className="file-icon">
                    <img src={DocIcon} alt="file-icon" />
                  </Box>
                  <Box>
                    <Typography variant='body2' className="file-name">{part.filename}</Typography>
                    <Typography variant='caption' className="file-size">{part.body.size}</Typography>
                  </Box>
                </Box>
                <FileDownloadIcon
                  onClick={() =>
                    downloadAttachment(
                      gmailId,
                      part.filename,
                      part.body.attachmentId,
                    )
                  }
                  style={{ fontSize: '18px' }}
                  className="options-button"
                />
                <Box ml={1} className="options">
                  <ClearIcon
                    onClick={() => handleRemovedAttachments(part.partId)}
                    style={{ fontSize: '18px' }}
                  />
                </Box>
              </Box>
            </Grid>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  };

  const downloadAttachment = async (messageId, filename, attachmentId) => {
    try {
      const data = {
        messageId,
        gmailId,
        filename,
        attachmentId,
      };
      await xpAccountIntegrationDownloadAttachment(data);
    } catch (error) {
      createNotification('error', error.message);
    }
  };

  const handleDiscard = async () => {
    try {
      setIsLoading(true);
      if (params.draftId) {
        const data = {
          draftId: params.draftId,
          gmailId: gmailId,
        };
        const res = await xpAccountIntegrationDeleteDraft(data);
        if (res.data.status === 200 && res.data.success) {
          resetStates();
          navigate('/inbox/drafts');
        } else {
          createNotification('error', res.data.message);
        }
      } else {
        resetStates();
      }
    } catch (error) {
      createNotification('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const mainContent = <SideTabs>
    <ErrorBoundary>
      <Stack px={2.5} py={1} height={'100%'}>
        {/* --To-- */}
        <TextField
          name="Recipients"
          placeholder="Add recipients"
          variant="standard"
          fullWidth
          // @ts-ignore
          size="large"
          inputRef={recipientsRef}
          slotProps={{
            input: {
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Box gap={1} display={'flex'} alignItems={'center'}>
                      <Stack direction="row" spacing={1}>
                        {recipientsChips.map((chip, i) => {
                          return (
                            i < 3 && (
                              <Chip
                                key={chip}
                                label={chip}
                                size="medium"
                                variant="outlined"
                                onDelete={() =>
                                  handleRemoveChip(chip, 'recipients')
                                }
                              />
                            )
                          );
                        })}

                        {/* <Chip
                      label="soumyakhatri@softobiz.com"
                      size="medium"
                      variant="outlined"
                      onDelete={handleDelete}
                    /> */}
                      </Stack>
                      {recipientsChips.length > 3 && (
                        <Tooltip
                          arrow
                          placement="bottom"
                          title={
                            <Stack spacing={0.5} alignItems={'start'}>
                              {recipientsChips.map((chip, i) => {
                                return (
                                  i >= 3 && (
                                    <Chip
                                      label={chip}
                                      size="medium"
                                      variant="outlined"
                                      onDelete={() =>
                                        handleRemoveChip(chip, 'recipients')
                                      }
                                    />
                                  )
                                );
                              })}
                            </Stack>
                          }
                        >
                          <Button
                            // @ts-ignore
                            variant="tag"
                            size="small"
                            sx={{
                              backgroundColor: '#FBE5FE',
                              '&:hover': {
                                backgroundColor: '#FBE5FE',
                              },
                              '&:active': {
                                backgroundColor: '#FBE5FE',
                              },
                            }}
                          >
                            +{recipientsChips.length - 3} more
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </InputAdornment>

                </>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Box gap={0.75} display={'flex'}>
                    {!isCcOpen && (
                      <Button
                        onClick={handleOpenCC}
                        //@ts-ignore
                        variant="tag"
                        size="small"
                        sx={{
                          backgroundColor: '#B8F3F5',
                          '&:hover': {
                            backgroundColor: '#B8F3F5',
                          },
                          '&:active': {
                            backgroundColor: '#B8F3F5',
                          },
                        }}
                      >
                        CC
                      </Button>
                    )}

                    {!isBccOpen && (
                      <Button
                        onClick={handleOpenBCC}
                        //@ts-ignore
                        variant="tag"
                        size="small"
                        sx={{
                          backgroundColor: '#E6FDF3',
                          '&:hover': {
                            backgroundColor: '#E6FDF3',
                          },
                          '&:active': {
                            backgroundColor: '#E6FDF3',
                          },
                        }}
                      >
                        BCC
                      </Button>
                    )}
                  </Box>
                </InputAdornment>
              ),
            },
          }}
          value={recipients}
          onChange={handleRecipientsChange}
          onKeyDown={(e) => handleKeyDown(e, 'recipients')}
          onBlur={() => handleBlur('recipients')}
          color={errors?.recipients ? 'error' : 'primary'}
          helperText={errors?.recipients}
          error={Object.keys(errors)?.includes('recipients')}
        />
        {isCcOpen && (
          <TextField
            name="CC"
            placeholder="CC"
            variant="standard"
            fullWidth
            //@ts-ignore
            size="large"
            slotProps={{
              input: {
                ref: ccRef,
                startAdornment: (
                  <InputAdornment position="start">
                    <Box gap={1} display={'flex'} alignItems={'center'}>
                      <Stack direction="row" spacing={1}>
                        {ccChips.map((chip, i) => {
                          return (
                            i < 5 && (
                              <Chip
                                key={chip}
                                label={chip}
                                size="medium"
                                variant="outlined"
                                onDelete={() =>
                                  handleRemoveChip(chip, 'cC')
                                }
                              />
                            )
                          );
                        })}
                      </Stack>
                      {ccChips.length > 3 && (
                        <Tooltip
                          arrow
                          placement="bottom"
                          title={
                            <Stack spacing={0.5} alignItems={'start'}>
                              {ccChips.map((chip, i) => {
                                return (
                                  i >= 3 && (
                                    <Chip
                                      key={chip}
                                      label={chip}
                                      size="medium"
                                      variant="outlined"
                                      onDelete={() =>
                                        handleRemoveChip(chip, 'cC')
                                      }
                                    />
                                  )
                                );
                              })}
                            </Stack>
                          }
                        >
                          <Button
                            // @ts-ignore
                            variant="tag"
                            size="small"
                            sx={{
                              backgroundColor: '#FBE5FE',
                              '&:hover': {
                                backgroundColor: '#FBE5FE',
                              },
                              '&:active': {
                                backgroundColor: '#FBE5FE',
                              },
                            }}
                          >
                            +{ccChips.length - 3} more
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleCloseCC}
                      //@ts-ignore
                      color="transparent"
                      size="small"
                      variant="contained"
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            value={cC}
            onChange={handleCcChange}
            onKeyDown={(e) => handleKeyDown(e, 'cC')}
            onBlur={() => handleBlur('cC')}
            color={errors?.cc ? 'error' : 'primary'}
            helperText={errors?.cc}
            error={Object.keys(errors)?.includes('cc')}
          />
        )}
        {isBccOpen && (
          <TextField
            name="BCC"
            placeholder="BCC"
            variant="standard"
            fullWidth
            //@ts-ignore
            size="large"
            slotProps={{
              input: {
                ref: bccRef,
                startAdornment: (
                  <InputAdornment position="start">
                    <Box gap={1} display={'flex'} alignItems={'center'}>
                      <Stack direction="row" spacing={1}>
                        {bccChips.map((chip, i) => {
                          return (
                            i < 5 && (
                              <Chip
                                key={chip}
                                label={chip}
                                size="medium"
                                variant="outlined"
                                onDelete={() =>
                                  handleRemoveChip(chip, 'bcC')
                                }
                              />
                            )
                          );
                        })}
                      </Stack>
                      {bccChips.length > 3 && (
                        <Tooltip
                          arrow
                          placement="bottom"
                          title={
                            <Stack spacing={0.5} alignItems={'start'}>
                              {bccChips.map((chip, i) => {
                                return (
                                  i >= 3 && (
                                    <Chip
                                      key={chip}
                                      label={chip}
                                      size="medium"
                                      variant="outlined"
                                      onDelete={() =>
                                        handleRemoveChip(chip, 'bcC')
                                      }
                                    />
                                  )
                                );
                              })}
                            </Stack>
                          }
                        >
                          <Button
                            // @ts-ignore
                            variant="tag"
                            size="small"
                            sx={{
                              backgroundColor: '#FBE5FE',
                              '&:hover': {
                                backgroundColor: '#FBE5FE',
                              },
                              '&:active': {
                                backgroundColor: '#FBE5FE',
                              },
                            }}
                          >
                            +{bccChips.length - 3} more
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleCloseBCC}
                      //@ts-ignore
                      color="transparent"
                      size="small"
                      variant="contained"
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            value={bcC}
            onKeyDown={(e) => handleKeyDown(e, 'bcC')}
            onBlur={() => handleBlur('bcC')}
            onChange={handleBccChange}
            color={errors?.bcc ? 'error' : 'primary'}
            helperText={errors?.bcc}
            error={Object.keys(errors)?.includes('bcc')}
          />
        )}
        {/* ---subject--- */}
        <TextField
          name="Subject"
          placeholder="Subject"
          variant="standard"
          fullWidth
          // @ts-ignore
          size="large"
          value={subject}
          onChange={handleSubChange}
          color={errors?.subject ? 'error' : 'primary'}
          helperText={errors?.subject}
          error={Object.keys(errors)?.includes('subject')}
        />
        {/* ---Email body--- */}
        <Stack
          py={2}
          borderBottom={'1px solid #F4F6F7'}
          flexGrow={1}
          overflow={'auto'}
        >
          <ReactQuill
            theme="snow"
            value={editorText}
            onChange={handleEditorText}
            modules={modules}
            placeholder="Write something amazing..."
          // style={editorContainerStyle}
          />
        </Stack>
        <span style={{ color: 'red', fontSize: '8px' }}>
          {errors?.emailBody}
        </span>

        {/* ---attachments--- */}
        <Grid container spacing={1} my={1} className="attachments">
          {fileNames?.map((file) => {

            const imageSizeInBytes = file?.size;
            let convertedFileSizeKbMb;
            if (imageSizeInBytes > 1024 * 1024) {
              // Convert to MB if file size is larger than 1024 KB
              convertedFileSizeKbMb = `${(
                imageSizeInBytes /
                (1024 * 1024)
              ).toFixed(2)} MB`;
            } else {
              convertedFileSizeKbMb = `${(
                imageSizeInBytes / 1024
              ).toFixed(2)} KB`;
            }
            return (
              <Grid size={'auto'} key={file.name} >
                <Box className="attachment-file" display={'flex'} alignItems={'center'}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Box className="file-icon">
                      <img src={DocIcon} alt="file-icon" />
                    </Box>
                    <Box>
                      <Typography variant='body2'>{file.name}</Typography>
                      <Typography variant='caption'>
                        Size: {convertedFileSizeKbMb}
                      </Typography>
                    </Box>
                  </Box>
                  <Box ml={1} className="options">
                    <ClearIcon
                      onClick={() => handleDelete(file)}
                      style={{ fontSize: '18px' }}
                    />
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <Box className={'attachment-container'} mt={1}>
          <Grid container spacing={1} className="attachments">
            {renderAttachments()}
          </Grid>
        </Box>

        {/* ---Buttons--- */}
        <Stack
          py={1.5}
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Button
              sx={{ borderRadius: '6px' }}
              variant="contained"
              color="primary"
              endIcon={<SentWhiteIcon />}
              onClick={() => handleMailStatus('send')}
            >
              Send
            </Button>
            <Button
              sx={{ borderRadius: '6px' }}
              variant="outlined"
              color="primary"
              endIcon={<DraftsIcon />}
              onClick={() => handleMailStatus('draft')}
            >
              Draft
            </Button>

            <QuillToolbar
              toolbarId="toolbar"
              onUploadAttachment={handleAttachment}
            />
          </Box>

          <IconButton
            size="small"
            // @ts-ignore
            color="transparent"
            variant="contained"
            onClick={() => handleDiscard()}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Stack>
    </ErrorBoundary>
  </SideTabs>;

  const contentForWorkspace = () => {
    return <EijentDashboard>
      <PageTabs>{mainContent}</PageTabs>
      {isLoading && <Loader />}
    </EijentDashboard>;
  };

  const contentIndependentOfWorkspace = () => {
    return <EijentDashboard>
      {mainContent}
      {isLoading && <Loader />}
    </EijentDashboard>;
  };

  return (
    <>
      {contentIndependentOfWorkspace()}
      {/* {contentForWorkspace()} */}
    </>
  );
}
