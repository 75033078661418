import React, { useRef, useState } from "react";
import { IconButton, Badge, Popover, Box, Typography, Avatar, Stack, Button, Switch, TableContainer, Table, TableBody, TableRow, TableCell, TableBodyOwnProps, TableRowOwnProps, Theme, TableHead } from "@mui/material";
import { CancelIcon, CustomizeIcon } from '../../components/CustomIcons';


// import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { CommonProps } from '@mui/material/OverridableComponent';
import { JSX } from 'react/jsx-runtime';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { BoxOwnProps, styled } from "@mui/system";

type Props = {
  options: {
    id: string;
    label: string;
    order: number;
    show: boolean;
  }[];
  onOptionsChange?: (updatedOptions: Props['options']) => void;
}

export default function Customize({ options, onOptionsChange }: Props) {
  const [anchorEl, setAnchorEl] = useState(null);


  // Reorders the list when an item is dragged and dropped
  const reorder = (list: States[], startIndex: number, endIndex: number): States[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((obj, i) => ({ ...obj, orderIndex: i }));
  };

  // Styles for the draggable item
  const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
    background: isDragging ? "#fafafa" : "white",
    border: isDragging ? "1px dashed #5141e7" : "none",
    ...draggableStyle
  });

  const getItemCellStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
    background: isDragging ? "#fafafa" : "white",
    border: isDragging ? "1px dashed #5141e7" : "none",
    ...draggableStyle
  });
  const handleClick = (event: { currentTarget: React.SetStateAction<null>; }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  interface States {
    stateName: string;
    type: string;
    description: string;
    id: number;
  }

  function createData(fieldLabel: string, fieldType: string) {
    return { fieldLabel, fieldType };
  }


  const [states, setStates] = useState<States[]>([
    { id: 1, stateName: "name1", type: "state1", description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum" },
    { id: 2, stateName: "name2", type: "state2", description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum" },
    { id: 3, stateName: "name3", type: "state3", description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum" },
    { id: 4, stateName: "name4", type: "state4", description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum" },
    { id: 5, stateName: "name5", type: "state5", description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum" }
  ]);


  const onDragEnd = (result: DropResult) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    // Reorder the list
    const movedItems = reorder(
      states, // Array to reorder
      result.source.index, // Source index
      result.destination.index // Destination index
    );
    setStates(movedItems);
  };
  const handleSwitchChange = (optionId: string) => {
    const updatedOptions = options.map(option => 
      option.id === optionId ? { ...option, show: !option.show } : option
    );
    onOptionsChange?.(updatedOptions);
  };

  return (
    <>

      <Button variant="contained"
        onClick={handleClick}
        aria-describedby={id}
        sx={{
          backgroundColor: open ? '#F4F6F7' : '#ffffff',
          borderBottomLeftRadius: open ? "0px" : 8,
          borderBottomRightRadius: open ? "0px" : 8,
          transitionDuration: '200ms',
          "&::before": {
            content: '""',
            position: "absolute",
            bottom: open ? "-4px" : 0,
            left: "0",
            right: '0',
            // width: "52px",
            height: open ? "4px" : 0,
            transitionDuration: '200ms',
            backgroundColor: "#F4F6F7",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",

          },
        }}
        // @ts-ignore
        color="white" size="medium" 
        startIcon={<CustomizeIcon />}
        >Customize</Button>

      {/* Notification Popup */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '4px',
              backgroundColor: '#F4F6F7',
              boxShadow: '0px 3px 6px #00000029',
              py: 1,
              borderTopRightRadius: '0px',
            },
          },
        }}
      >
        <Box display={'flex'} mx={2} pb={2} alignItems={'center'} justifyContent={'space-between'}
          borderBottom={'1px solid #D9D9D9'}
        >
          <Typography variant="h2" >
            Customize
          </Typography>
          <IconButton size="small" variant="contained"
            // @ts-ignore
            color="transparent"
            onClick={handleClose}
          >
            <CancelIcon />
          </IconButton>
        </Box>
        <Stack
          sx={{
            // overflowY: 'auto',
            maxHeight: 'calc(100vh - 270px)',
            width: '478px',
            // maxWidth: '478px',
            px: 2,
            py: 1,
          }}
        >
          <TableContainer
            className="custom-fields-table"
          >
            <Table size="small" stickyHeader aria-label="Email List">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided: any, snapshot: any) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {options.filter(option => !['name', 'uniqueEijentId'].includes(option.id)).map((option, index) => (
                        // <Draggable
                        //   key={option.id}
                        //   draggableId={"q-" + option.id}
                        //   index={index}
                        // >
                          // {(provided: any, snapshot: { isDragging: boolean; }) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}

                              // style={getItemStyle(
                              //   snapshot.isDragging,
                              //   provided.draggableProps.style
                              // )}
                              className={`${(snapshot.isDragging ? 'dragging' : '')}`}
                            >
                              <TableCell component="th" scope="row" sx={{py: 1.25, px: 0, borderBottom: '1px solid #D9D9D9' }} >
                                <Stack direction="row" spacing={1.5} alignItems={'center'}>
                                  {/* <Box sx={{ marginLeft: '-12px !important' }} {...provided.dragHandleProps}>
                                    <DragIndicatorIcon fontSize='inherit' />
                                  </Box> */}
                                  <Box>
                                    {option.label}
                                  </Box>
                                </Stack>
                              </TableCell>
                              <TableCell align="right" sx={{ py: 1.25, px: 0, width: 28, borderBottom: '1px solid #D9D9D9' }}>
                                <Switch 
                                  size="small"
                                  checked={option.show}
                                  disabled={['name', 'eijentId'].includes(option.id)}
                                  onChange={() => handleSwitchChange(option.id)}
                                />
                              </TableCell>
                            </TableRow>
                          // )}
                        ///Draggable> 
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>

        </Stack>
      </Popover>
    </ >
  );
}
