import { useRef, useState } from 'react';
import { Opportunity } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import OpportunityOpportunitiesAPI from 'ReduxStore/API/opportunity/opportunityOpportunitiesAPI';

export const useEditableField = (opportunity: Opportunity, setOpportunity: React.Dispatch<React.SetStateAction<Opportunity>>) => {
  
  const [textFieldValue, setTextFieldValue] = useState('');
  const [showInputField, setShowInputField] = useState(false);
  const [error, setError] = useState('');
  const textFieldRef = useRef('');
  
  const handleOpenEditField = () => {
    setShowInputField(true);
    setTextFieldValue(opportunity?.name);
    textFieldRef.current = opportunity?.name;
  };

  const isValid = () => {
    if (textFieldValue.length === 0) {
      setError('Name is required');
      return false;
    }
    error && setError('');
    return true;
  };

  const handleUpdateOneField = async () => {
    const opportunityBeforeUpdate = { ...opportunity };
    try {
      if (textFieldRef.current === textFieldValue) {
        setError('');
        setShowInputField(false);
        return;
      }
      if ( !isValid()) {
        return;
      }
      
      // Optimistic update
      setOpportunity({
        ...opportunity,
        name: textFieldValue,
      });
      setShowInputField(false);

      const api = new OpportunityOpportunitiesAPI();
      const response = await api.updateOneField({
        opportunityId: opportunity._id,
        field: 'name',
        value: textFieldValue,
      });

      if (response.status === 200 && response.data.success) {
        setOpportunity(response.data.opportunity);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setOpportunity(opportunityBeforeUpdate);
    }
  };

  const handleValidateDuplicateName = async () => {
    const bodyParams = {
      name: textFieldValue?.trim(),
      id: opportunity._id,
    };
    const api = new OpportunityOpportunitiesAPI();
    const response = await api.checkIfNameAlreadyExists(bodyParams);
    if (response.status === 200 && response.data) {
      setError('Name already exists');
    } else {
      setError('');
    }
  }

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement;
    if (target.closest('#textfield-container')) return;
    setShowInputField(false);
    setError('');
  };

  return {
    opportunity,
    showInputField,
    textFieldValue,
    error,
    handleOpenEditField,
    handleUpdateOneField,
    handleClickAway,
    setTextFieldValue,
    handleValidateDuplicateName,
  };
}; 