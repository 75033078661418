import { ReactNode, useState } from 'react';
import React from 'react';

import {
  Box,
  Stack,
} from '@mui/material';

import Customize from './Customize';
import Filter from './Filter';
import { FilterTypesProvider } from './utils/FilterTypesContext';
type Props = {
  customizationOptions: {
    id: string;
    label: string;
    order: number;
    show: boolean;
  }[];
  onCustomizationOptionsChange: (updatedOptions: Props['customizationOptions']) => void;
  fetchAllItems: () => void;
};

export default function Filters({ customizationOptions, onCustomizationOptionsChange, fetchAllItems }: Props) {

  return (
    <Box className={'filters'} p={0.5}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        {/* --for workspace-- */}

        <Box gap={0.5} display={'flex'}>
          {/* <IconButton variant="contained"
            // @ts-ignore
            color="white"
            size="medium" ></IconButton>
          <Button variant="contained"
            // @ts-ignore
            color="white" size="medium" >Comic Con Experience</Button> */}
        </Box>

        <Box gap={0.5} display={'flex'}>
          {/* <FilterTypesProvider> */}
            <Filter fetchAllItems={fetchAllItems} />
          {/* </FilterTypesProvider> */}

          <Customize
            options={customizationOptions}
            onOptionsChange={onCustomizationOptionsChange}
          />
        </Box>
      </Stack>
    </Box>
  );
}
