import {
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Checkbox,
  OutlinedInput,
  SelectChangeEvent,
  ClickAwayListener,
} from '@mui/material';
import { IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TableCell } from '@mui/material';
import { createNotification } from 'helpers';
import {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
  useMemo,
} from 'react';
import OpportunityOpportunitiesAPI from 'ReduxStore/API/opportunity/opportunityOpportunitiesAPI';
import { Opportunity } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import { CustomField } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import CustomDropdownWithActiveUsers from 'eijent/components/commonComponents/CustomDropdownWithActiveUsers';

interface CustomFieldArrayCellProps {
  cf: CustomField;
  value1: any[];
  opp: Opportunity;
  isMultiSelect: boolean;
}

// Add new types/interfaces
type CustomFieldValue = string | { email: string };

interface HandleUpdateFieldData {
  opportunityId: string;
  field: string;
  value: string[];
}

// Extract PopupContent component
const PopupContent: React.FC<{
  values: CustomFieldValue[];
  isUserField: boolean;
}> = ({ values, isUserField }) => {
  return (
    <div className="info">
      {values.map((cfValue, i) => {
        const isObject = typeof cfValue === 'object' && cfValue !== null;
        const displayValue = isObject
          ? (cfValue as { email: string }).email
          : cfValue;

        return (
          <div
            key={i}
            className={isUserField ? 'link' : ''}
            style={{ zIndex: 999999 }}
            onClick={() => {
              if (isUserField && isObject) {
                window.open(`mailto:${displayValue}`);
              }
            }}
          >
            <p>{String(displayValue)}</p>
          </div>
        );
      })}
    </div>
  );
};

// Extract CellContent component
const CellContent: React.FC<{
  value: CustomFieldValue;
  isUserField: boolean;
  remainingValues: CustomFieldValue[];
  isMultiSelect: boolean;
  cf: CustomField;
}> = ({ value, isUserField, remainingValues, isMultiSelect, cf }) => {
  const displayValue = typeof value === 'object' ? value.email : value;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <span
        className={`long-text ${isUserField ? 'link' : ''}`}
        style={{ maxWidth: isMultiSelect ? '80%' : '100%' }}
        onClick={() => {
          if (isUserField) {
            window.open(`mailto:${displayValue}`);
          }
        }}
      >
        {displayValue}
      </span>
      {(cf.fieldData.fieldType === 'list' ? cf.fieldData.isMultiSelect : true) && remainingValues.length > 0 && (
        <div className="detail-popup">
          <span className="count">+{remainingValues.length}</span>
          <PopupContent values={remainingValues} isUserField={isUserField} />
        </div>
      )}
    </Stack>
  );
};

export const CustomFieldArrayCell: React.FC<CustomFieldArrayCellProps> = ({
  cf,
  value1,
  opp,
  isMultiSelect,
}) => {
  const initialRender = useRef(true);
  const setFieldValueInitially = () => {
    return value1;
  };

  const [opportunity, setOpportunity] = useState(opp);
  const [showListField, setShowListField] = useState(false);
  const [showUsersListField, setShowUsersListField] = useState(false);
  const [listFieldValue, setListFieldValue] = useState<string[] | { _id: string; email: string }[]>(
    setFieldValueInitially,
  );

  const listFieldValueStringifies = useMemo(() => {
    return JSON.stringify(listFieldValue);
  }, [listFieldValue]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    } else {
      const timeout = setTimeout(() => {
        handleUpdateOneField();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [listFieldValueStringifies]);

  const handleOpenEditField = (fieldValue: any, fieldType: string) => {
    if (fieldType === 'list') {
      setShowListField(true);
      setListFieldValue(fieldValue);
    }
    if (fieldType === 'user') {
      setShowUsersListField(true);
      setListFieldValue(fieldValue);
    }
  };

  const handleUpdateOneField = async () => {
    const opportunityBeforeUpdate = { ...opportunity };
    try {
      // Optimistic update
      setOpportunity({
        ...opportunity,
        customFields: {
          ...opportunity.customFields,
          [cf._id]: listFieldValue as any,
        },
      });

      const data: HandleUpdateFieldData = {
        opportunityId: opportunity._id,
        field: `customFields.${cf._id}`,
        value: listFieldValue as string[],
      };

      const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
      const response = await opportunityOpportunitiesAPI.updateOneField(data);

      if (response.status === 200 && response.data.success) {
        setOpportunity(response.data.opportunity);
      } else if (response.status !== 200) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setOpportunity(opportunityBeforeUpdate);
      createNotification('error', error.message);
    }
  };

  if (showUsersListField) {
    return (
      <ClickAwayListener 
        onClickAway={() => {
          setShowUsersListField(false);
        }}
        mouseEvent="onMouseDown"
      >
        <div>
          <CustomDropdownWithActiveUsers
            open={showUsersListField}
            handleChange={(option: { _id: string; email: string }) => {
              if(listFieldValue.find((item) => typeof item === 'object' && '_id' in item && item._id === option._id)) {
                setListFieldValue((prev) => {
                  return prev.filter((item) => typeof item === 'object' && '_id' in item && item._id !== option._id) as { _id: string; email: string; }[];
                });
              } else {
                setListFieldValue((prev) => {
                  return [...prev as { _id: string; email: string; }[], option];
                });
              }
            }}
            listOfSelectedIds={listFieldValue || []}
            dropdownFor="users"
            disabled={false}
            handleClearAll={(checked: boolean) => {}}
            handlePageChange={() => {}}
            handleAllCheckbox={() => {}}
          />
        </div>
      </ClickAwayListener>
    );
  }

  if (showListField) {
    return (
      <TableCell sx={{ minWidth: '200px', position: 'relative', zIndex: 1 }}>
        <ClickAwayListener onClickAway={() => setShowListField(false)}>
          <div onClick={(e) => e.stopPropagation()}>
            <DropdownForListDataTypeCF
              cf={cf}
              opportunity={opportunity}
              setListFieldValue={setListFieldValue}
              isOpen={showListField}
              setIsOpen={setShowListField}
            />
          </div>
        </ClickAwayListener>
      </TableCell>
    );
  }

  return (
    <TableCell sx={{ minWidth: '200px' }}>
      {listFieldValue.length > 0 ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          px={1.5}
          py={'8.2px'}
          sx={{
            border: '1px solid transparent',
            '&:hover': { borderColor: '#000', borderRadius: '8px' },
            '& .arrow-icon': { 
              display: 'none' 
            },
            '&:hover .arrow-icon': { 
              display: 'flex' 
            }
          }}
        >
          <CellContent
            value={listFieldValue[0]}
            isUserField={cf.fieldData.fieldType === 'user'}
            remainingValues={listFieldValue.slice(1)}
            isMultiSelect={isMultiSelect}
            cf={cf}
          />
          <IconButton
            size="small"
            // @ts-ignore
            color="transparent"
            variant="contained"
            className="arrow-icon"
            onClick={() =>
              handleOpenEditField(listFieldValue, cf.fieldData.fieldType)
            }
          >
            <ArrowDropDownIcon sx={{ fontSize: 'large' }}/>
          </IconButton>
        </Stack>
      ) : (
        <Box>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: '15px', px: 0 }}
            onClick={() => handleOpenEditField([], cf.fieldData.fieldType)}
          >
            + Click to Add
          </Button>
        </Box>
      )}
    </TableCell>
  );
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
      zIndex: 9999,
    },
  },
  MenuListProps: {
    style: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const,
  },
};

interface DropdownForListDataTypeCFProps {
  cf: CustomField;
  opportunity: Opportunity;
  setListFieldValue: Dispatch<SetStateAction<string[]>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Improve DropdownForListDataTypeCF component
const DropdownForListDataTypeCF = ({
  cf,
  opportunity,
  setListFieldValue,
  isOpen,
  setIsOpen,
}: DropdownForListDataTypeCFProps) => {

  const isMultiSelect = cf.fieldData.isMultiSelect;

  const extractSelectedValue = () => {
    const alreadySelectedValues = opportunity?.customFields?.[
      cf._id as string
    ] as string[];

    if(isMultiSelect) {
      return Array.isArray(alreadySelectedValues) &&
        alreadySelectedValues.length > 0
      ? alreadySelectedValues
      : [];
    } else {
      return alreadySelectedValues || [];
    }
  };

  const [selectedValue, setSelectedValue] =
    useState<string[] | string>(extractSelectedValue);

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    try {
      setSelectedValue(e.target.value as string[]);
      if(isMultiSelect) {
        setListFieldValue(e.target.value as string[]);
      } else {
        setListFieldValue([e.target.value] as string[]);
      }
      // handleUpdateOneField(e);
    } catch (error) {
      console.error('Error handling custom fields:', error);
    }
  };

  // Ensure that selectedValue is treated as an array for multi-select
  const valueToDisplay = isMultiSelect
    ? Array.isArray(selectedValue)
      ? selectedValue
      : selectedValue
      ? [selectedValue]
      : []
    : selectedValue || '';

  return (
    <FormControl size="small" fullWidth sx={{ zIndex: 9999 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Select
          type="text"
          className="form-control select small"
          value={valueToDisplay}
          name="fieldType"
          onChange={handleChange}
          style={{ padding: '4px 0px' }}
          MenuProps={MenuProps}
          displayEmpty
          multiple={isMultiSelect}
          input={<OutlinedInput />}
          sx={{ flex: 1 }}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          renderValue={(selected) => {
            if (isMultiSelect) {
              return (selected as string[])?.length === 0
                ? 'Select'
                : (selected as string[])?.join(', ') || '';
            }
            return (selected as unknown as string) || 'Select';
          }}
        >
          {!isMultiSelect && (
            <MenuItem value="" disabled hidden>
              Select
            </MenuItem>
          )}
          {Array.isArray(cf.fieldData.dropdownValues) &&
            cf.fieldData.dropdownValues.length > 0 &&
            cf.fieldData.dropdownValues.map((dropdownValue) => (
              <MenuItem key={dropdownValue} value={dropdownValue}>
                {isMultiSelect ? (
                  <>
                    <Checkbox
                      color="primary"
                      checked={
                        Array.isArray(selectedValue) &&
                        selectedValue.includes(dropdownValue)
                      }
                    />
                    {dropdownValue}
                  </>
                ) : (
                  dropdownValue
                )}
              </MenuItem>
            ))}
        </Select>
      </Box>
    </FormControl>
  );
};
