import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Box,
  Collapse
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import {
  SettingIcon,
  SettingIconActive,
  OrganizationSettingsIcon,
  OrganizationSettingsIconActive,
  TasksIcon,
  TasksIconActive,
  MyInboxIcon,
  MyInboxIconActive,
  ReportingIcon,
  ReportingIconActive,
  OpportunitiesIcon,
  OpportunitiesIconActive,
  AccountsIcon,
  AccountsIconActive,
  ContactsIcon,
  ContactsIconActive,
  FieldManagementIcon,
  StateManagementIcon
} from "../../components/CustomIcons";

const mainListItems = [
  { text: "Settings", icon: <SettingIcon />, activeIcon: <SettingIconActive />, route: "/settings/my-settings" },
  { text: "Organization Settings", icon: <OrganizationSettingsIcon />, activeIcon: <OrganizationSettingsIconActive /> },
  { text: "Field Management", icon: <FieldManagementIcon />, isExpandable: true }, //  No selection, just expandable
  { text: "State Management", icon: <StateManagementIcon />, isExpandable: true }, 
  { text: "Change Password", icon: <TasksIcon />, activeIcon: <TasksIconActive /> },
  { text: "Transactional Email", icon: <MyInboxIcon />, activeIcon: <MyInboxIconActive />, route: "/settings/transactional-emails" },
  { text: "My Eijents", icon: <ReportingIcon />, activeIcon: <ReportingIconActive />, route: "/settings/my-eijents" }
];

const workspaceListItems = [
  { text: "Opportunities", icon: <OpportunitiesIcon />, activeIcon: <OpportunitiesIconActive />, route: "/settings/field-management/opportunities" },
  { text: "Accounts", icon: <AccountsIcon />, activeIcon: <AccountsIconActive />, route: "/settings/field-management/accounts" },
  { text: "Contacts", icon: <ContactsIcon />, activeIcon: <ContactsIconActive />, route: "/settings/field-management/contacts" }
];

const stateManagementListItems = [
  { text: "Opportunities", icon: <OpportunitiesIcon />, activeIcon: <OpportunitiesIconActive />, route: "/settings/state-management/opportunities" },
  { text: "Accounts", icon: <AccountsIcon />, activeIcon: <AccountsIconActive />, route: "/settings/state-management/accounts" },
  { text: "Contacts", icon: <ContactsIcon />, activeIcon: <ContactsIconActive />, route: "/settings/state-management/contacts" }
];

export default function SettingsMenuContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [expandFieldManagement, setExpandFieldManagement] = useState(false);
  const [expandStateManagement, setExpandStateManagement] = useState(false);
  const [selectedMainListIndex, setSelectedMainListIndex] = useState(null);
  const [selectedWorkSpaceListIndex, setSelectedWorkSpaceListIndex] = useState(null);
  const [selectedStateManagementIndex, setSelectedStateManagementIndex] = useState(null);

  // **Determine selected menu based on URL**
  useEffect(() => {
    const mainIndex = mainListItems.findIndex(item => item.route && location.pathname.startsWith(item.route));
    const workspaceIndex = workspaceListItems.findIndex(item => item.route && location.pathname.startsWith(item.route));
    const stateIndex = stateManagementListItems.findIndex(item => item.route && location.pathname.startsWith(item.route));

    if (mainIndex !== -1) {
      setSelectedMainListIndex(mainIndex);
      setSelectedWorkSpaceListIndex(null);
      setSelectedStateManagementIndex(null);
      setExpandFieldManagement(false);
      setExpandStateManagement(false);
    } else if (workspaceIndex !== -1) {
      setSelectedMainListIndex(null);
      setSelectedWorkSpaceListIndex(workspaceIndex);
      setSelectedStateManagementIndex(null);
      setExpandFieldManagement(true);
      setExpandStateManagement(false);
    } else if (stateIndex !== -1) {
      setSelectedMainListIndex(null);
      setSelectedWorkSpaceListIndex(null);
      setSelectedStateManagementIndex(stateIndex);
      setExpandFieldManagement(false);
      setExpandStateManagement(true);
    }
  }, [location.pathname]);

  const handleOnClickOfMainList = (index) => {
    const selectedItem = mainListItems[index];

    if (selectedItem.text === "Field Management") {
      setExpandFieldManagement(prev => !prev);
      return;
    }

    if (selectedItem.text === "State Management") {
      setExpandStateManagement(prev => !prev);
      return;
    }

    setSelectedMainListIndex(index);
    setSelectedWorkSpaceListIndex(null);
    setSelectedStateManagementIndex(null);
    setExpandFieldManagement(false);
    setExpandStateManagement(false);

    if (selectedItem.route) {
      navigate(selectedItem.route);
    }
  };

  const handleOnClickOfWorkSpaceList = (index) => {
    const selectedRoute = workspaceListItems[index]?.route;
    setSelectedMainListIndex(null);
    setSelectedWorkSpaceListIndex(index);
    setSelectedStateManagementIndex(null);
    setExpandFieldManagement(true);
    setExpandStateManagement(false);

    if (selectedRoute) navigate(selectedRoute);
  };

  const handleOnClickOfStateManagementList = (index) => {
    const selectedRoute = stateManagementListItems[index]?.route;
    setSelectedMainListIndex(null);
    setSelectedWorkSpaceListIndex(null);
    setSelectedStateManagementIndex(index);
    setExpandFieldManagement(false);
    setExpandStateManagement(true);

    if (selectedRoute) navigate(selectedRoute);
  };


  const logout = () => {
    ["user_role", "user_details"].forEach(item => localStorage.removeItem(item));
    window.location.href = "/";
  };

  return (
    <>
      <Box flexGrow={1} overflow="auto">
        <Stack>
          <List disablePadding>
            {mainListItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={index === selectedMainListIndex && !item.isExpandable} //  Prevent selection for Field Management
                    onClick={() => handleOnClickOfMainList(index)}
                    sx={{ padding: "7px 35px !important" }}
                  >
                    <ListItemIcon>{index === selectedMainListIndex ? item.activeIcon || item.icon : item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>

                {/*  Expandable Workspace (For Field Management) */}
                {item.isExpandable && item.text === "Field Management" && (
                  <Collapse in={expandFieldManagement} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      {workspaceListItems.map((subItem, subIndex) => (
                        <ListItem key={subIndex} disablePadding>
                          <ListItemButton
                            sx={{ pl: "50px !important", py: "7px !important" }}
                            selected={subIndex === selectedWorkSpaceListIndex}
                            onClick={() => handleOnClickOfWorkSpaceList(subIndex)}
                          >
                            <ListItemIcon>{subIndex === selectedWorkSpaceListIndex ? subItem.activeIcon : subItem.icon}</ListItemIcon>
                            <ListItemText primary={subItem.text} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}

                  {/* Expandable Section - State Management */}
                  {item.isExpandable && item.text === "State Management" && (
                  <Collapse in={expandStateManagement} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      {stateManagementListItems.map((subItem, subIndex) => (
                        <ListItem key={subIndex} disablePadding>
                          <ListItemButton
                            sx={{ pl: "50px !important", py: "7px !important" }}
                            selected={subIndex === selectedStateManagementIndex}
                            onClick={() => handleOnClickOfStateManagementList(subIndex)}
                          >
                            <ListItemIcon>{subIndex === selectedStateManagementIndex ? subItem.activeIcon : subItem.icon}</ListItemIcon>
                            <ListItemText primary={subItem.text} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Stack>
      </Box>

      <Box mb={1}>
        <List component="div" disablePadding>
          <ListItem sx={{ p: 0 }}>
            <ListItemButton onClick={logout} sx={{ padding: "7px 35px !important" }}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );
}
