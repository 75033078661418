import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { SearchIcon } from "./CustomIcons";

const SearchWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  marginRight: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: { width: "auto" },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2.5),
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer", // Clickable search icon
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 0, 1.5, 2.5),
    width: "100%",
    [theme.breakpoints.up("md")]: { width: "40ch" },
  },
}));

export default function Search({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  //  Trigger search on Enter key
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch(searchTerm);
    }
  };

  //  Trigger search on clicking the search icon
  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  return (
    <SearchWrapper>
      <StyledInputBase
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleKeyDown} //  Calls search on Enter
      />
      <SearchIconWrapper onClick={handleSearchClick}>
        <SearchIcon />
      </SearchIconWrapper>
    </SearchWrapper>
  );
}
