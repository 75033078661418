import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    createJourneyRequest: ['data'], // Action for initiating the request, passing payload data
    createJourneySuccess: ['workflow'], // Action for a successful response, passing the notification list
    createJourneyError: ['error'], // Action for handling errors, passing error details
    getJourneyRequest: ['data'], // Action for initiating the request, passing payload data
    getJourneySuccess: ['workflow'], // Action for a successful response, passing the notification list
    getJourneyError: ['error'], // 
    updateCopilotStatus:['status'],
    getJourneyListRequest: ['data'], // Action for initiating the request, passing payload data
    getJourneyListSuccess: ['journeyList','totalResult'], // Action for a successful response, passing the notification list
    getJourneyListError: ['error'], // 
    deleteJourneyRequest: ['data'], // Action for initiating the request, passing payload data
    deleteJourneySuccess: ['message'], // Action for a successful response, passing the notification list
    deleteJourneyError: ['error'], 
    testJourney: ['data'], 
    updateStateMachine:["stateMachine"],
    updateExplanation:["explanation"],
    clearWorkflowHistory:['data'],
    clearWorkflowHistorySuccess:['success'],
    updateStatusRequest:["data"],
    updateStatusSuccess:["data"],
    generateBasicInfoRequest: ['data'], 
    generateBasicInfoSuccess: ['workflow'],
    generateBasicInfoError: ['error'], 
     resetState: [],  // resetState action
});
export const Constants = Types
export default Creators