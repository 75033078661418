import { useSelector } from "react-redux";
import { useMemo } from "react";
import { UserPreferencesState } from "ReduxStore/Reducers/userPreferences.reducer";

const useCustomizedPreference = (
  moduleName: string, 
  defaultVisibleColumns: {id: string, show: boolean, order: number}[]
) => {
    const { userPreferences } = useSelector(
        (state: any) => state.UserPreferences
    ) as UserPreferencesState;

    const sortedColumns = useMemo(() => {
        const visibleColumns = userPreferences?.find(
            preference => preference.moduleName === moduleName
        )?.visibleColumns;

        return visibleColumns ? 
            [...visibleColumns].sort((a, b) => a.order - b.order) : 
            [...defaultVisibleColumns].sort((a, b) => a.order - b.order);
    }, [userPreferences, moduleName, defaultVisibleColumns]);
    
    return sortedColumns;
}

export default useCustomizedPreference;
