import { Select, MenuItem, SelectChangeEvent } from '@mui/material';

interface OperatorSelectProps {
  value: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  filterType: { label: string; type: string; isCustomField: boolean; };
}

const getOperator = (filterType: {
  label: string;
  type: string;
  isCustomField: boolean;
}) => {
  // customFieldTypes: text, number, date, url, email, list, boolean, phone, longText, user, linkedIn, x, facebook, instagram, pinterest
  switch (filterType.type) {
    case 'non-cf-text':
      return [{ value: 'exactMatch', label: 'Exact Match' }, { value: 'contains', label: 'Contains' }, { value: 'doesNotContain', label: 'Does not contain' }];
    case 'non-cf-date':
    case 'date':
      return [{ value: 'beforeDate', label: 'Before Date' }, { value: 'afterDate', label: 'After Date' }, { value: 'fromToDate', label: 'Custom Range' }];
    case 'user':
    case 'list':
    case 'contact':
    case 'account':
    case 'source':
    case 'states':
    case 'assignedTo':
      return [{ value: 'in', label: 'In' }, { value: 'notIn', label: 'Not in' }];
    case 'boolean':
      return [{ value: 'equals', label: 'Equal To' }];
    case 'number':
    case 'uniqueEijentId':
      return [{ value: 'equals', label: 'Equal To' }, { value: 'notEquals', label: 'Not Equal To' }, { value: 'greaterThan', label: 'Greater Than' }, { value: 'lessThan', label: 'Less Than' }, { value: 'lessThanOrEquals', label: 'Less Than or Equal To' }, { value: 'greaterThanOrEquals', label: 'Greater Than or Equal To' }];
    default:
      return [{ value: 'exactMatch', label: 'Exact Match' }, { value: 'contains', label: 'Contains' }, { value: 'doesNotContain', label: 'Does not contain' }];
  }
};

const OperatorSelect = ({
  value,
  onChange,
  filterType,
}: OperatorSelectProps) => {
  return (
    <Select
      variant="outlined"
      size="small"
      value={value}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 300,  // Set your desired max height here
          },
        },
      }}
      onChange={onChange}
      fullWidth
      displayEmpty
      renderValue={(selected: unknown) => {
        const operator = getOperator(filterType).find((operator) => operator.value === selected);
        return operator?.label || 'Select Condition';
      }}
    >
      {getOperator(filterType).map((operator) => (
        <MenuItem value={operator.value}>{operator.label}</MenuItem>
      ))}
    </Select>
  );
};

export default OperatorSelect;
