import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Divider, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { EventTypes, RoutKeys } from '../../constants/enums/EventTypes';
import {
  CustomField,
  CustomFields_State,
} from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import OpportunityOpportunitiesAPI from 'ReduxStore/API/opportunity/opportunityOpportunitiesAPI';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
const REQUEST_URL = process.env.REACT_APP_API_URL;
import TableWidget from './TableWidget';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ThemeProvider,
} from '@mui/material';
import {
  ArrowDownIcon,
  AddNewIcon,
  HomeIconActive,
  InboxIconActive,
  TasksIconActive,
  OpportunitiesIconActive,
  AccountsIconActive,
  ContactsIconActive,
  ReportingIconActive,
  CancelIcon,
  AttachmentIcon,
  SentWhiteIcon,
  SuggetionIcon,
} from "../components/CustomIcons";


const style: React.CSSProperties = {
  height: "100%",
  maxHeight: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
};

const tablestyles: {
  table: React.CSSProperties;
  row: React.CSSProperties;
  cell: React.CSSProperties;
} = {
  table: {
    border: "1px solid #5578FA",
    borderStyle: "dotted",
    display: "table",
    backgroundColor: "rgb(255,255,255,0.5)",
    borderRadius: "8px",
    color: "#000",
    fontFamily: "Urbanist, serif",
    fontSize: "12px",
    fontWeight: 400,
  },
  row: {
    border: "1px solid #5578FA",
    borderStyle: "dotted",
    display: "table-row",
    backgroundColor: "rgb(255,255,255,0.5)",
    color: "#000",
    fontFamily: "Urbanist, serif",
    fontSize: "12px",
    fontWeight: 400,
  },
  cell: {
    border: "1px solid black",
    borderStyle: "dotted",
    display: "table-cell",
    backgroundColor: "rgb(255,255,255,0.5)",
    color: "#000",
    fontFamily: "Urbanist, serif",
    fontSize: "12px",
    fontWeight: 400,
  },
};
const userMessage: React.CSSProperties = {
  padding: '14px',
  borderRadius: '8px',
  backgroundColor: '#FFF7EE',
  border: '1px solid #BCBCBC45',
  display: 'flex',
  gap: 1.5,
  alignItems: 'baseline',
  alignSelf: 'flex-end',
  maxWidth: '80%',
};

interface Message {
  _id?: string;
  sender: 'user' | 'assistant';
  text: any;
  timestamp?: string;
  index?: number;
  initialMessage?: boolean;
  datatype?: string;
}

interface EijentCopilotGlobalProps {
  userId: any;
}



const EijentCopilotGlobal: React.FC<EijentCopilotGlobalProps> = ({ userId }) => {
  const dispatch = useDispatch();
  const {
    customFields,
    isLoading: isCustomFieldsLoading,
    fetchAllOpportunityCustomFieldSuccess,
  } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const extractedFields = customFields?.map(field => ({
    _id: field._id,
    fieldName: field.fieldData.fieldName
  })) || [];


  const [socket, setSocket] = useState<WebSocket>();
  //const widgetRef = useRef<any>(null);
  const location = useLocation();
  const messageInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [userconnectionid, setConnectionId] = useState<string>('');
  const [usershorthand, setusershortHand] = useState<string>();
  const [userFirstName, setuserFirstName] = useState<string>();
  const [apiResponseData, setApiResponseData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    initializeGlobalSocketService();
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    setusershortHand(userDetails.firstName[0] + userDetails.lastName[0]);
    let firstName = userDetails.firstName;
    setuserFirstName(firstName.charAt(0).toUpperCase() + firstName.slice(1));

  }, []);

  const fetchAllOpportunityOpportunities = async (res) => {
    const queryString = res.endpoint.split('?')[1]; // Get part after '?'
    const params = new URLSearchParams(queryString);
    const paramsObject = Object.fromEntries(params.entries());
    const data = {
      queryParams: paramsObject,
      bodyParams: res.payload,
    };
    const opportunityAPI = new OpportunityOpportunitiesAPI();
    const response = await opportunityAPI.fetchAllOpportunityOpportunities(data);
    //console.log("fetchAllOpportunityOpportunities",response);
    return response;
    //dispatch(Actions.fetchAllOpportunityOpportunitiesRequest(data));
  };

  const initializeGlobalSocketService = async () => {
    //const chatbotId = agentId;

    let webSocketUrl = process.env.REACT_APP_CHATBOT_WEBSOCKET_URL || '';
    const socket: WebSocket = new WebSocket(webSocketUrl);
    //console.log("socket",webSocketUrl,socket)
    // if (socket) {
    //@ts-ignore
    // socket.onopen = (e) => {
    //   onConnect(e, socket);
    // };
    socket.onmessage = onMessage;
    setSocket(socket);

  };

  const onMessage = async (event: any) => {
    const agentResponse = JSON.parse(event?.data);
    //console.log("WEbSOCKET DATA :", agentResponse.data.message.response.replace(/^[^{]+/, ''));
    const apiendpoints = JSON.parse(agentResponse.data.message.response.replace(/^[^{]+/, '').replace(/(\w+):/g, '"$1":').replace(/\/\/.*$/gm, '').replace('```', ''));
    //console.log("apiendpoints",apiendpoints);
    //setLoading(!loading);
    //console.log(messages);
    const {Error,Message} = JSON.parse(agentResponse.data.message.response);
    if(Error && Error.length > 0){
      setMessages((previousMessages) => [
        ...previousMessages,
        { text: Message, sender: "assistant", datatype: "text" }
      ]);
      setLoading(false);
      return;
    }else{
      const response = await fetchAllOpportunityOpportunities(apiendpoints);
     
      if(response.data.opportunities?.length === 0){
        //console.log("response", response.data.opportunities);
        const Messagetext="No opportunities found for the given criteria.";
        if (agentResponse?.data?.type === 'message') {
          setMessages((previousMessages) => {
            const updatedMessages: Message[] = [
              ...previousMessages,
              { text: Messagetext, sender: "assistant", datatype: "Message", index: agentResponse.data.index }
            ];
            // Sort by index in ascending order
            return updatedMessages.sort((a, b) => a.index - b.index);
          });
          setLoading(false);
        };
      }else{
        const transformedData = response.data.opportunities?.map(item => ({
          name: item.name,
          source: item.source,
          assignedToEmails: item.assignedToIds?.map(assigned => assigned.email),
          stateNames: item.state?.map(state => state.name),
          uniqueEijentIdStr: item.uniqueEijentIdStr,
        }));
        //console.log("transformedData",transformedData);
        if (agentResponse?.data?.type === 'message') {
          setMessages((previousMessages) => {
            const updatedMessages: Message[] = [
              ...previousMessages,
              { text: transformedData, sender: "assistant", datatype: "table", index: agentResponse.data.index }
            ];
            // Sort by index in ascending order
            return updatedMessages.sort((a, b) => a.index - b.index);
          });
          setLoading(false);
        };
      }
      
    }
    
    scrollToBottom();
    //setLoading(!loading);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]); // Ensure to update when messages change

  const handleUserMessage = () => {
    //widgetRef.current.innerHTML = 'loading...'
    //setLoading(!loading);
    setMessages((previousMessages) => [
      ...previousMessages,
      {
        sender: 'user',
        text: messageInputRef.current?.value || '',
        _id: "669fd3faf065540008875e2a",
        timestamp: '',
        initialMessage: false,
      },
    ]);
    const messageText = messageInputRef.current?.value;
    if (messageText?.trim().length) {
      const id = '_' + Math.random().toString(36).substr(2, 9);
      onUssrMessage(messageText, 'user', id);
    }
    if (messageInputRef.current) {
      messageInputRef.current.value = '';
    }

  };
  const getfilepathcontext = () => {
    const locationPath = location.pathname;
    switch (true) {
      case locationPath.includes("inbox/my-inbox/all/view"):
        return "inbox-email";
      case locationPath.includes("inbox/my-inbox/all"):
        return "email";
      default:
        return "journey";
    }
  };

  const onUssrMessage = (
    text: string,
    sender: 'user' | 'assistant',
    id: string = '',
  ) => {
    const locationPath = location.pathname;
    //console.log(locationPath.includes("/inbox/my-inbox/all/view"),locationPath.includes("/inbox/my-inbox/all"))
    const data = JSON.stringify({
      action: RoutKeys.CHATBOT,
      eventType: EventTypes.MESSAGE,
      connectionID: userconnectionid ? userconnectionid : '',
      service: locationPath.includes("/inbox/my-inbox/all/view") ? "EmailHelpercopilot" : RoutKeys.EIJENTCOPILOT,
      message: text,
      userId: userId ? userId : '6544a5cca6485a0007526874',
      context: locationPath.includes(RoutKeys.INBOX) ? RoutKeys.INBOX : 'journey',
      html: locationPath.includes("/inbox/my-inbox/all/view") ? localStorage.getItem('HTML_Content') || null : '',
      customFields: extractedFields
    });
    //console.log("socket.readyState",socket.readyState,socket.OPEN);
    // console.log("data",data)
    if (socket && socket.readyState) {

      //console.log('Socket Data', data);
      socket.send(data);
    } else {
      console.error('WebSocket connection not open or available.');
      // Handle the scenario when the socket is not open
    }
    setLoading(true);
  };

  return (
    <Box sx={style}>
      {/* ---Welcome message--- */}
      <Box pr={2}>
        <Typography variant="body1" >
          Co-Eijent
        </Typography>

        <Typography variant="h4" >
          {userFirstName}, how can I help you today?
        </Typography>
      </Box>

      {/* ---Suggestions--- */}
      <Box pr={2}>
        <Typography variant="body2">
          I have some suggestions for you:
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          // justifyContent="center"
          flexWrap="wrap"
          mt={1}
          gap={0.75}
          sx={{ maxWidth: "800px" }}
        >
          {[
            "Create a summary",
            "Ask about this opportunity",
            "Generate tasks",
          ].map((text, index) => (
            <Button key={index}
              //@ts-ignore
              variant="dotted"
              color="primary"
              size="medium" startIcon={<SuggetionIcon />}
              sx={{ backgroundColor: 'transparent' }}
            >
              {text}
            </Button>
          ))}
        </Stack>
      </Box>
      <Divider sx={{ borderColor: 'white', my: 2, mr: 2 }} />
      <Stack flexGrow={1} gap={1} overflow={'auto'}
        justifyContent="start"
        pr={2}

      >

        {/* --chat started-- */}
        <Stack spacing={2} maxWidth={'500px'}>

          {/* --user message-- */}
          {messages.sort().map((message, index) => (

            <>
              {message.sender == "user" && <Box sx={userMessage} key={index}>
                <Avatar
                  sx={{ width: 31, height: 31, fontSize: '11px', fontWeight: 400, }}
                > {usershorthand.toUpperCase()}</Avatar>
                <Typography variant="body2" >
                  {message.text}
                </Typography>
              </Box>}

              {message.sender != "user" && <>
                {message.datatype == "table" ?
                  <>
                    <Typography variant="body2" align="left">
                      Here is the list of top 10 data that you requested for
                    </Typography>
                    <Box sx={{ maxHeight: '240px', }}>
                      <TableWidget data={message.text} />
                    </Box>
                  </>
                  : message.text}
              </>}
            </>
          ))}
          {/* Scroll to bottom anchor */}
          <Box mt={'0 !important'} ref={messagesEndRef} />
        </Stack>
      </Stack>
      {loading && (
        <Typography variant="body2" sx={{ color: "#757575", mr: 2 }}>
          Loading...
        </Typography>
      )}
      {/* --Eijent Input-- */}
      <form onSubmit={handleUserMessage} action="">
        <Box
          sx={{
            // backgroundColor: "#F8F8F8",
            pt: 1,
            pr: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >


          <TextField
            name="BCC"
            placeholder="Type your message here…"
            variant="outlined"
            inputRef={messageInputRef}
            fullWidth
            multiline
            maxRows={3}
            sx={{
              maxWidth: "500px",
              "& .MuiOutlinedInput-root": {
                pr: "4px !important",
                borderColor: "#D9D9D9 !important",
                minHeight: "3.25rem",
                height: "auto",
              },
            }}
            //@ts-ignore
            size="large"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction={'row'} spacing={1.5}>
                      <IconButton
                        size='small'
                        variant="contained"
                        //@ts-ignore
                        color="transparent"
                      >
                        <AttachmentIcon />
                      </IconButton>

                      {/* @ts-ignore */}
                      <IconButton
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={handleUserMessage}
                      >
                        <SentWhiteIcon />
                      </IconButton>
                    </Stack>
                  </InputAdornment>
                ),
              },
            }}
          />

        </Box>
      </form>
    </Box>
  );
};

export default EijentCopilotGlobal;