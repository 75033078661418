import React, { useState } from "react";
import { Stack, Typography} from "@mui/material";
import AppTheme from '../../eijentTheme/AppTheme';

export default function PageNotFound() {
  return (
    <AppTheme> 
      <Stack textAlign="center" alignItems={"center"} justifyContent={"center"} height={"100vh"}>
        <Typography fontSize={"72px"} lineHeight={'1'} mb={3} fontWeight="700" color="textPrimary">
          404
        </Typography>
        <Typography variant="h4" color="textPrimary">
          Oops, Sorry we can’t find that page! 
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Either something went wrong or the page does not exist anymore.
        </Typography>
      </Stack>  
    </AppTheme>
  );
}
