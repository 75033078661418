import { FieldManagementGroup } from 'ReduxStore/Reducers/fieldManagementGroups.reducer';
import NewBaseAPI from './newBaseAPI';

class FieldManagementGroupsAPI extends NewBaseAPI { 
  getFieldGroupsListAPI = async (queryParams = {}) => {
    const queryString = new URLSearchParams(queryParams).toString(); //  Convert object to query string
    return this.API(`/opportunityManagement/customFields/getCustomFieldGroupsList?${queryString}`, {
      method: 'GET'
    });
  };
  

  createFieldGroupAPI = async (data: FieldManagementGroup) => {
    return this.API('/opportunityManagement/customFields/createCustomFieldGroup', {
      method: 'POST',
      data: data,
    });
  };

  updateFieldGroupAPI = async (data: FieldManagementGroup) => {
    return this.API('/opportunityManagement/customFields/updateFieldMangementGroup', {
      method: 'POST',
      data: data,
    });
  }; 

  deleteFieldGroupAPI = async (id: string, customFieldType: string) => {
    return this.API('/opportunityManagement/customFields/deleteFieldGroup', {
      method: 'POST',
      data: {
        id,
        customFieldType, // Send the entity type to the backend
      },
    });
  };
  
}
export default FieldManagementGroupsAPI;
