import { Avatar, Box, Button, Checkbox, ClickAwayListener, IconButton, InputAdornment, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { CheckAllIcon, DeleteIcon, EditIcon, UnCheckAllIcon } from "../components/CustomIcons";
import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
//import FullScreenWidget from "./FullScreenWidget.tsx";


const tableHeaders = [
    { id: 'uniqueEijentId', label: 'EIJENT ID' },
    { id: 'name', label: 'NAME', width: '44px', pl: 4, active: true },
    { id: 'state', label: 'STATE' },
    { id: 'assignedTo', label: 'ASSIGNED TO' },
    { id: 'source', label: 'SOURCE' },
    // { id: 'updatedAt', label: 'UPDATED AT' },
];

const tableHeadStyle: React.CSSProperties = {
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '12px',
    lineHeight: 1,
    color: '#c1c1c1',
    borderColor: '#C1C1C1',
    backgroundColor: '#ffffff',
};

export default function TableWidget({ data }) {


    return (
        <>
            {/* --Select and menu-- */}
            <Stack px={1.5} sx={{ backgroundColor: '#FFF7EE', }} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack spacing={2.75} py={1.5} px={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box display={'flex'} gap={1} alignItems={'center'}>
                        <Checkbox
                            icon={<UnCheckAllIcon />}
                            checkedIcon={<CheckAllIcon />}
                        />
                        <Typography>
                            3X
                        </Typography>
                    </Box>

                    <IconButton size="small" variant='contained'
                        // @ts-ignore
                        color="transparent">
                        <DeleteIcon />
                    </IconButton>

                </Stack>
            </Stack>
            <TableContainer className="data-table" sx={{ maxHeight: '100%', backgroundColor: "#fff" }} >
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="Email List">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...tableHeadStyle, width: '44px', pl: 4 }} ></TableCell>
                            {tableHeaders.map((header) => (
                                <TableCell sx={{ ...tableHeadStyle, }} key={header.id}>
                                    <TableSortLabel
                                        IconComponent={KeyboardArrowDownIcon}
                                        direction='desc'
                                        active={true}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (

                            <TableRow
                                // @ts-ignore
                                key={row.name}

                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    '&:hover': { backgroundColor: '#E6FDF4' },
                                    px: 2.25,
                                }}
                            >
                                <TableCell sx={{ width: '44px', pl: 4 }} >
                                    <Checkbox />
                                </TableCell>
                                {row.state}
                                <TableCell className="editable-cell" sx={{ minWidth: '200px', py: '1px' }}>
                                    {row.uniqueEijentIdStr || 'NA'}
                                </TableCell>
                                <TableCell sx={{ minWidth: '200px' }}>{row.name || 'NA'}</TableCell>
                                <TableCell sx={{ minWidth: '200px' }}>{row.stateNames?.[0] || 'NA'}</TableCell>
                                <TableCell sx={{ minWidth: '200px' }}>{row.assignedToEmails?.[0] || 'NA'}</TableCell>
                                <TableCell sx={{ minWidth: '200px' }}>{row.source || 'NA'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
