import React from 'react';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { FilterType } from '../utils/FilterTypesContext';
import DropdownForListType from './dropdowns/DropdownForListType';
import DropdownForUserType from './dropdowns/DropdownForUserType';
import DropdownForStates from './dropdowns/DropdownForStates';
import DropdownForAssignedTo from './dropdowns/DropdownForAssignedTo';
import DropdownForContact from './dropdowns/DropdownForContact';
import DropdownForAccount from './dropdowns/DropdownForAccount';
import DatePickers from './DatePickers';
import DropdownForSource from './dropdowns/DropdownForSource';

interface ValueSelectProps {
  value: string[] | string;
  onChange: (event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  filterType: FilterType;
  operator: string;
}

const ValueSelect: React.FC<ValueSelectProps> = ({
  value,
  onChange,
  filterType,
  operator,
}) => {

  if (filterType.type === 'list') {
    return <DropdownForListType value={value as string[]} onChange={onChange} filterType={filterType} />;
  }

  if (filterType.type === 'user') {
    return (
      <DropdownForUserType value={value as string[]} onChange={onChange} />
    );
  }

  if (filterType.type === 'states') {
    return <DropdownForStates value={value as string[]} onChange={onChange} filterType={filterType} />;
  }

  if (filterType.type === 'assignedTo') {
    return <DropdownForAssignedTo value={value as string[]} onChange={onChange} />;
  }

  if (filterType.type === 'boolean') {
    return (
      <Select
        variant="outlined"
        size="small"
        value={value}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300,  // Set your desired max height here
            },
          },
        }}
        onChange={onChange}
        fullWidth
        displayEmpty
        renderValue={(selected) => (selected as string) === '' ? 'Select' : selected as string}
      >
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </Select>
    );
  }

  if (filterType.type === 'contact') {
    return (
      <DropdownForContact value={value as string[]} onChange={onChange} filterType={filterType} />
    );
  }

  if (filterType.type === 'account') {
    return (
      <DropdownForAccount value={value as string[]} onChange={onChange} filterType={filterType} />
    );
  }

  if (filterType.type === 'date') {
    return (
      <DatePickers value={value as string[]} onChange={onChange} filterType={filterType} condition={operator} />
    );
  }

  if (filterType.type === 'source') {
    return (
      <DropdownForSource value={value as string[]} onChange={onChange} filterType={filterType} />
    );
  }

  return (
    <TextField
      variant="outlined"
      size="small"
      value={value}
      onChange={(e) => onChange(e)}
      placeholder="Enter a value"
      fullWidth
      type={['uniqueEijentId', 'number'].includes(filterType.type) ? 'number' : 'text'}
    />
  );
};

export default ValueSelect;

// TextField : non-cf-text, text, number, url, email, phone, longText, linkedIn, x, facebook, instagram, pinterest
// Select : list, user
// Select : boolean
// Date : date, non-cf-date
