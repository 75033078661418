import React, { useEffect, useState } from 'react';
import { Typography, Stack, Button, IconButton } from '@mui/material';
// import { CancelIcon } from '../../../../../components/CustomIcons';
import { CancelIcon } from '../../../../components/CustomIcons';

import {
  Filter,
  UserPreferencesState,
} from 'ReduxStore/Reducers/userPreferences.reducer';
import { useFilterTypes } from 'eijent/internals/Filters/utils/FilterTypesContext';
import { useSelector } from 'react-redux';
import ConfirmationAlertPopUp from 'eijent/components/commonComponents/ConfirmationAlertPopUp';

interface QuickFilterDisplayProps {
  moduleName: string;
}

const QuickFilterDisplay: React.FC<QuickFilterDisplayProps> = ({
  moduleName,
}) => {
  const [quickFilters, setQuickFilters] = useState<Filter[]>([]);
  const [openDeleteFilterPopup, setOpenDeleteFilterPopup] = useState(false);
  const [filterIdToDelete, setFilterIdToDelete] = useState<string>('');

  const userPreferencesState = useSelector(
    (state: any) => state.UserPreferences,
  ) as UserPreferencesState;

  useEffect(() => {
    if (
      userPreferencesState?.fetchUserPreferencesSuccess ||
      userPreferencesState?.updateUserPreferencesSuccess
    ) {
      setQuickFilters(
        userPreferencesState.userPreferences.find(
          (preference) => preference.moduleName === moduleName,
        )?.filters,
      );
      setAppliedQuickFilter(
        userPreferencesState.userPreferences.find(
          (preference) => preference.moduleName === moduleName,
        )?.lastAppliedFilter,
      );
    }
  }, [
    userPreferencesState?.fetchUserPreferencesSuccess,
    userPreferencesState?.updateUserPreferencesSuccess,
  ]);

  useEffect(() => {
    if (userPreferencesState?.deleteFilterSuccess) {
      setQuickFilters(
        userPreferencesState.userPreferences.find(
          (preference) => preference.moduleName === moduleName,
        )?.filters,
      );
    }
  }, [userPreferencesState?.deleteFilterSuccess]);

  const handleOpenDeleteFilterPopup = (filterId: string) => {
    setOpenDeleteFilterPopup(true);
    setFilterIdToDelete(filterId);
    // handleDeleteFilter(moduleName, filterId);
  };

  const handleCloseDeleteFilterPopup = () => {
    setOpenDeleteFilterPopup(false);
    setFilterIdToDelete('');
  };

  const {
    setAppliedQuickFilter,
    appliedQuickFilter,
    handleApplyQuickFilter,
    setFilterRows,
    noFilterAppliedManuallyRef,
    handleDeleteFilter,
  } = useFilterTypes();
  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'end'}
        alignItems={'center'}
        spacing={1}
      >
        {appliedQuickFilter?.name && (
          <>
            <Typography variant="body2">Filters:</Typography>
            <Typography variant="body1">
              "{appliedQuickFilter?.name}"
            </Typography>
            <IconButton
              size="small"
              variant="contained"
              // @ts-ignore
              color="transparent"
              onClick={() => {
                noFilterAppliedManuallyRef.current = true;
                setFilterRows([]);
                setAppliedQuickFilter({} as Filter);
              }}
            >
              <CancelIcon />
            </IconButton>
          </>
        )}
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          gap={0.5}
          alignItems={'center'}
          ml={2}
        >
          {Array.isArray(quickFilters) &&
            quickFilters?.length > 0 &&
            quickFilters?.map((filter, index) => (
              <Button
                key={filter._id}
                // @ts-ignore
                variant="tag"
                size="small"
                sx={{
                  borderRadius: '4px',
                  backgroundColor: filter.color,
                  fontWeight: '400',
                  '&:hover': {
                    backgroundColor: filter.color,
                  },
                  '& .MuiButton-endIcon': {
                    opacity: 0,
                    transition: 'opacity 0.2s',
                  },
                  '&:hover .MuiButton-endIcon': {
                    opacity: 1,
                  },
                }}
                endIcon={<CancelIcon />}
                onClick={() => {
                  handleApplyQuickFilter(filter);
                }}
                onClickCapture={(e) => {
                  // Check if click was on the icon
                  if (
                    e.target instanceof Element &&
                    e.target.closest('.MuiButton-endIcon')
                  ) {
                    e.stopPropagation();
                    handleOpenDeleteFilterPopup(filter._id);
                  }
                }}
              >
                {filter.name}
              </Button>
            ))}
        </Stack>
      </Stack>
      {openDeleteFilterPopup && (
        <ConfirmationAlertPopUp
          openModal={openDeleteFilterPopup}
          closeModalFunc={handleCloseDeleteFilterPopup}
          title={'Delete Confirmation!'}
          text={'Are you sure you want to delete it?'}
          confirmationButtonText={'Delete'}
          confirmationButtonColor="secondary"
          closeButtonText={'Cancel'}
          functionality={() => {
            handleDeleteFilter(moduleName, filterIdToDelete);
            handleCloseDeleteFilterPopup();
          }}
        />
      )}
    </>
  );
};

export default QuickFilterDisplay;
