import { jwtDecode } from "jwt-decode";

/**
 * Restores user session from the token in the URL.
 * Ensures it runs only once after redirection.
 */
export function restoreSessionFromToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token"); 
    const invited = urlParams.get("invited"); 
    const code = urlParams.get("code"); 

    if (token && !invited && !code) {
       // console.log("🔓 Token found in URL. Checking if already processed...");
        //  Check if the request comes from the registration page
        const ssoOrigin = urlParams.get("ssoOrigin") || "unknown";
       // console.log(" ssoOrigin from helper function====", ssoOrigin);
        const isFromRegister = ssoOrigin === "register";

        if (isFromRegister) { 
           // console.log("🛑 Request is from registration. Resetting sessionRestored flag.");
            sessionStorage.removeItem("sessionRestored"); // Allow new session restoration
        }
        //  Ensure it only runs **once per session** using `sessionStorage`
        if (sessionStorage.getItem("sessionRestored")) {
            console.log(" Session already restored, skipping...");
            return;
        }

        //  Decode the token
        const decodedUserData = jwtDecode(token);
        //  Store all required details inside `user_details` in localStorage
        const userDetails = {
            acceptSt: decodedUserData?.acceptSt,
            accessToken: decodedUserData?.accessToken,
            accountName: decodedUserData?.accountName,
            companyOwnerId: decodedUserData?.companyOwnerId,
            email: decodedUserData?.email,
            firstName: decodedUserData?.firstName,
            isGoogleSSOCustomJWT: decodedUserData?.isGoogleSSOCustomJWT,
            language: decodedUserData?.language,
            lastName: decodedUserData?.lastName,
            member_id: decodedUserData?.member_id,
            name: decodedUserData?.name,
            permissions: decodedUserData?.permissions || [],
            refreshToken: decodedUserData?.refreshToken,
            role: decodedUserData?.role,
            servicePlanId: decodedUserData?.servicePlanId,
            ssoOrigin: decodedUserData?.ssoOrigin,
            systemLevelPermissions: decodedUserData?.systemLevelPermissions || [],
            token: decodedUserData?.accessToken, // Store the raw token separately
            _id: decodedUserData?._id
        };

        // 🔹 Store everything inside `user_details` as a **stringified JSON**
        localStorage.setItem("user_details", JSON.stringify(userDetails));
        localStorage.setItem("user_role", JSON.stringify(decodedUserData?.role));
       // console.log(" Session restored successfully!");

        //  Mark as processed to avoid running multiple times
        sessionStorage.setItem("sessionRestored", "true");

        //  Remove token from URL for cleanup
        window.history.replaceState({}, document.title, window.location.pathname);
    }

}
