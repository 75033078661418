import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'


// Define types for NotificationItem and State
export interface NotificationItems {
    type: string;
    createdAt: string | number | Date;
    _id: string;
    title?: string;
    message: string;
    name: string;
    updatedAt?:string;
    profile?:string;
    isRead?:boolean;
    link?:string;
    priority:string;
  }

  export interface ToasterNotificationItems {
    type: string;
    _id: string;
    title?: string;
    message: string;
    name: string;
    updatedAt?:string;
    createdAt?:string;
    profile?:string;
    // isRead?:boolean;
    link?:string;
    usersReadedThisMessage:object;
    priority:string;
  }
  
  export interface NotificationState {
    error: boolean;
    message: string | null;
    eijentNotificationListSuccess: boolean;
    toasterNotificationListSuccess:boolean;
    markNotificationSuccess:boolean,
    toasterNotificationCloseSuccess:boolean,
    notificationList: NotificationItems[];
    toasterNotificationList: ToasterNotificationItems[];
    toasterNotificationhigh: ToasterNotificationItems[];
    toasterNotificationMedium: ToasterNotificationItems[];
    toasterNotificationLow: ToasterNotificationItems[];
    toasterNotificationNormalHigh: NotificationItems[];
    toasterNotificationNormalMedium: NotificationItems[];
    toasterNotificationNormalLow: NotificationItems[];
    loading: boolean,
    totalResults:number,
    totalToasterNotification:number,
    type:string,
    shownNotifications: string[]; // Track shown notifications
    isPopupOpen: boolean; //  New state to track popup visibility
  }

// Initial state with proper type
const INITIAL_STATE: NotificationState = {
    type:null,
    error: false,
    message: null,
    eijentNotificationListSuccess: false,
    toasterNotificationListSuccess:false,
    markNotificationSuccess:false,
    toasterNotificationCloseSuccess:false,
    notificationList: [],
    toasterNotificationList:[],
    toasterNotificationhigh: [],
    toasterNotificationMedium: [],
    toasterNotificationLow: [],
    toasterNotificationNormalHigh:[],
    toasterNotificationNormalMedium:[],
    toasterNotificationNormalLow:[],
    loading: false,
    totalResults :0,
    totalToasterNotification :0,
    shownNotifications: [], // Initialize as empty array
    isPopupOpen: false, //  Initialize as false
  };


// Reducer handlers with type annotations
const eijentNotificationListRequest = (
    state: NotificationState = INITIAL_STATE,
    action: any
  ): NotificationState => {
    return {
      ...state,
      loading: true,
      eijentNotificationListSuccess: false,
      error: false,
      type:null,
      message: null,
      totalResults:0
    };
  };

const eijentNotificationListSuccess = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  if (action && action.notificationList.type && (action.notificationList.type === 'eijentNotification' || action.notificationList.type === 'smartNudges')) {
    const newNotificationList = [
      action?.notificationList.data,
      ...state.notificationList,
    ];
    newNotificationList.sort((a, b) => Number(b.isRead) - Number(a.isRead));
    return {
      ...state,
      loading: false,
      eijentNotificationListSuccess: true,
      error: false,
      type:"eijentNotification",
      notificationList: newNotificationList,
      totalResults: state.totalResults + 1
    };
  }
  else  if (action && action.notificationList.type && action.notificationList.type === 'notificationMark') {
    const updatedNotificationList = state.notificationList.map((item) =>
      item._id === action?.notificationList.data.id ? { ...item, isRead:  action?.notificationList.data.isRead } : item
    );
    return {
      ...state,
      notificationList: updatedNotificationList, // Apply the updated notification
    };
  }
   else {
    let previousData = [
      ...state.notificationList,
      ...action?.notificationList,
    ];

    const mergedArray = [...previousData, ...action?.notificationList];

    // Use Set to track unique _id values
    const uniqueArray = mergedArray.filter((item, index, self) =>
      index === self.findIndex(obj => obj._id === item._id)
    );
    uniqueArray.sort((a, b) => Number(a.isRead) - Number(b.isRead));
    return {
      ...state,
      loading: false,
      eijentNotificationListSuccess: true,
      error: false,
      type:null,
      notificationList: uniqueArray,
      totalResults: action?.totalResults
    };
  }
};

const eijentNotificationListReset = (state: NotificationState = INITIAL_STATE, action: any) => {
  const {resetQuery, resetType, notification } = action.action || {};
    if (resetQuery == 'reset') {
      return {
        ...state,
        notificationList: [], // Reset notification list to empty
        totalResults: 0, // Reset totalResults to 0
      };
    }

    if (resetQuery === 'update' && notification) {
      if(resetType === 'notification'){
      const updatedNotificationList = state.notificationList.map((item) =>
        item._id === notification.id ? { ...item, isRead: notification.isRead } : item
      );

      return {
        ...state,
        notificationList: updatedNotificationList, // Apply the updated notification
      };
    }else if(resetType === 'toaster'){
      const updatedToasterNotificationList = state.toasterNotificationList.filter(
        (noti) => noti._id !== notification.id
      );
      return {
        ...state,
        toasterNotificationList: updatedToasterNotificationList, // Apply the updated notification
      };
    }
    }
  return state;
};

const eijentNotificationListError = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    loading: false,
    eijentNotificationListSuccess: false,
    error: true,
    type:null,
    message: action.error,
    notificationList: [],
    totalResults:0,
  };
};

// Reducer handlers with type annotations
const markNotificationRequest = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    markNotificationSuccess: false,
    error: false,
  };
};

const markNotificationSuccess = (
state: NotificationState = INITIAL_STATE,
action: any
): NotificationState => {
  return {
    ...state,
    loading: false,
    markNotificationSuccess: true,
    error: false,
  };
};

const markNotificationError = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    markNotificationSuccess: false,             
    error: true,
    message: action.error,
  };
}; 

// Reducer handlers with type annotations
const toasterNotificationCloseRequest = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    toasterNotificationCloseSuccess: false,
    error: false,
  };
};

const toasterNotificationCloseSuccess = (
state: NotificationState = INITIAL_STATE,
action: any
): NotificationState => {
  return {
    ...state,
    loading: false,
    toasterNotificationCloseSuccess: true,
    error: false,
  };
};

const toasterNotificationCloseError = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    toasterNotificationCloseSuccess: false,             
    error: true,
    message: action.error,
  };
}; 

// Reducer handlers with type annotations
const toasterNotificationListRequest = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    loading: true,
    toasterNotificationListSuccess: false,
    error: false,
    message: null,
    totalToasterNotification:0
  };
};

const toasterNotificationListSuccess = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  if (
    action &&
    action.toasterNotificationList &&
    action.toasterNotificationList.data
  ) {
    const newNotification = action.toasterNotificationList.data;
    // Get current arrays (or default to empty arrays)
    let high = state.toasterNotificationhigh || [];
    let medium = state.toasterNotificationMedium || [];
    let low = state.toasterNotificationLow || [];
    let normalHigh = state.toasterNotificationNormalHigh || [];
    let normalMedium = state.toasterNotificationNormalMedium || [];
    let normalLow = state.toasterNotificationNormalLow || [];

    if(newNotification.type === "smartNudges"){
    // Add new notification to the appropriate array based on its priority
    if (newNotification.priority === "high") {

      high = [newNotification, ...high];
    } else if (newNotification.priority === "medium") {
      medium = [newNotification, ...medium];
    } else if (newNotification.priority === "low") {
      low = [newNotification, ...low];
    }
  }  else if(newNotification.type === "eijentNotification") {
    if (newNotification.priority === "high") {

      normalHigh = [newNotification, ...normalHigh];
    } else if (newNotification.priority === "medium") {
      normalMedium = [newNotification, ...normalMedium];
    } else if (newNotification.priority === "low") {
      normalLow = [newNotification, ...normalLow];
    }
  }


    // Optionally, if the action type is "smartNudges", you can sort each array by creation time (newest first)
    if (action.toasterNotificationList.type === "smartNudges") {
      high.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      medium.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      low.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    }else{
      normalHigh.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      normalMedium.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      normalLow.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    }
    return {
      ...state,
      loading: false,
      toasterNotificationListSuccess: true,
      error: false,
      toasterNotificationhigh: high,
      toasterNotificationMedium: medium,
      toasterNotificationLow: low,
      toasterNotificationNormalHigh: normalHigh,
      toasterNotificationNormalMedium: normalMedium,
      toasterNotificationNormalLow: normalLow,
    };
  }
  return {
    ...state,
    loading: false,
    toasterNotificationListSuccess: true,
    error: false,
    toasterNotificationhigh: action.toasterNotificationList,
    toasterNotificationNormalHigh:action.toasterNotificationNormal
  };
  // return state;
};

const toasterNotificationListError = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    loading: false,
    toasterNotificationListSuccess: false,
    error: true,
    message: action.error,
    toasterNotificationList: [],
    // totalToasterNotification:0,
  };
};

const markNotificationAsShown = (state: NotificationState = INITIAL_STATE, action: any): NotificationState => {
  return {
    ...state,
    shownNotifications: [...state.shownNotifications, action.payload], // Add the notification ID to the shown list
  };
};

const setNotificationPopupOpen = (state: NotificationState = INITIAL_STATE, action: any): NotificationState => {
  return {
    ...state,
    isPopupOpen: action.isOpen, // Update the popup state
  };
};
  

const HANDLERS = {
    [Constants.TOASTER_NOTIFICATION_LIST_REQUEST]: toasterNotificationListRequest,
    [Constants.TOASTER_NOTIFICATION_LIST_SUCCESS]: toasterNotificationListSuccess,
    [Constants.TOASTER_NOTIFICATION_LIST_ERROR]: toasterNotificationListError,
    [Constants.EIJENT_NOTIFICATION_LIST_REQUEST]: eijentNotificationListRequest,
    [Constants.EIJENT_NOTIFICATION_LIST_SUCCESS]: eijentNotificationListSuccess,
    [Constants.EIJENT_NOTIFICATION_LIST_ERROR]: eijentNotificationListError,
    [Constants.EIJENT_NOTIFICATION_LIST_RESET]: eijentNotificationListReset, // Handle reset action here
    [Constants.MARK_NOTIFICATION_REQUEST]: markNotificationRequest,
    [Constants.MARK_NOTIFICATION_SUCCESS]: markNotificationSuccess,
    [Constants.MARK_NOTIFICATION_ERROR]: markNotificationError,
    [Constants.MARK_NOTIFICATION_AS_SHOWN]: markNotificationAsShown, // Handle the action to mark notifications as shown
    [Constants.SET_NOTIFICATION_POPUP_OPEN]: setNotificationPopupOpen,
    [Constants.TOASTER_NOTIFICATION_CLOSE_REQUEST]: toasterNotificationCloseRequest,
    [Constants.TOASTER_NOTIFICATION_CLOSE_SUCCESS]: toasterNotificationCloseSuccess,
    [Constants.TOASTER_NOTIFICATION_CLOSE_ERROR]: toasterNotificationCloseError,
}

export default createReducer(INITIAL_STATE, HANDLERS)