import { Constants } from 'ReduxStore/Actions';
import { createReducer } from "reduxsauce";

export type CustomFields_State = {
    isLoading: boolean,
    customFields: CustomFields,
    error: boolean,
    loading: boolean,
    createOpportunityCustomFieldSuccess: boolean,
    message: '',
    deleteOpportunityCustomFieldSuccess: boolean,
    updateOpportunityCustomFieldSuccess: boolean,
    updatedCustomField: CustomField,
    fetchAllOpportunityCustomFieldSuccess: boolean,
    isUpdatingField: boolean,
};

export type CustomFields = CustomField[];

export type CustomField = {
    fieldData: FieldData,
    _id?: string,
    isMandatory: boolean;
    orderIndex?: number;
};

export type FieldData = {
    fieldName: string;
    fieldType: string;
    isMultiSelect?: boolean;
    checkboxValues?: string[],
    dropdownValues?: string[],
    checkboxString?: string,
    dropdownString?: string;
    dateFormat?: string;
};

export type CustomFieldType = 'accounts' | 'opportunity' | 'contacts';

const INITIAL_STATE: CustomFields_State = {
    isLoading: false,
    customFields: [],
    error: false,
    loading: false,
    createOpportunityCustomFieldSuccess: false,
    message: '',
    deleteOpportunityCustomFieldSuccess: false,
    updateOpportunityCustomFieldSuccess: false,
    updatedCustomField: {} as CustomField,
    fetchAllOpportunityCustomFieldSuccess: false,
    isUpdatingField: false,
};

const createOpportunityCustomFieldRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        createOpportunityCustomFieldSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const createOpportunityCustomFieldSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        createOpportunityCustomFieldSuccess: true,
        error: false,
        loading: false,
        customFields: action.customFields,
        message: action.message,
        lastAction: action.actionType || "createField", 
    };
};
const createOpportunityCustomFieldError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        createOpportunityCustomFieldSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        lastAction: action.actionType || "createField", 
    };
};

const deleteOpportunityCustomFieldRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        deleteOpportunityCustomFieldSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const deleteOpportunityCustomFieldSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityCustomFieldSuccess: true,
        error: false,
        loading: false,
        customFields: action.customFields,
        message: action.message,
    };
};
const deleteOpportunityCustomFieldError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityCustomFieldSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const updateOpportunityCustomFieldRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        updateOpportunityCustomFieldSuccess: false,
        error: false,
        loading: true,
        message: '',
        isUpdatingField: true, //  Set flag when updating a field
        
    };
};
const updateOpportunityCustomFieldSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityCustomFieldSuccess: true,
        error: false,
        loading: false,
        customFields: action.customFields,
        message: action.message,
        updatedCustomField: action.updatedCustomField,
        lastAction: action.actionType || "updateField",
    };
};
const updateOpportunityCustomFieldError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityCustomFieldSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        lastAction: action.actionType || "updateField",
    };
};

const fetchAllOpportunityCustomFieldRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        fetchAllOpportunityCustomFieldSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const fetchAllOpportunityCustomFieldSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchAllOpportunityCustomFieldSuccess: true,
        error: false,
        loading: false,
        customFields: action.customFields,
    };
};
const fetchAllOpportunityCustomFieldError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchAllOpportunityCustomFieldSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};
 

const clearFieldMessage = (state) => ({
    ...state,
    message: null,
    fieldMessage: null,
    lastAction: null,
    error: false,
    createOpportunityCustomFieldSuccess: false,
    updateOpportunityCustomFieldSuccess: false,
  });

const deleteOpportunityCustomFieldSettingRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        deleteOpportunityCustomFieldSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const deleteOpportunityCustomFieldSettingSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityCustomFieldSuccess: true,
        error: false,
        loading: false, 
        customFields: action.customFields,
        message: action.message,
    };
};
const deleteOpportunityCustomFieldSettingError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityCustomFieldSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const HANDLERS = {
    [Constants.CREATE_OPPORTUNITY_CUSTOM_FIELD_REQUEST]: createOpportunityCustomFieldRequest,
    [Constants.CREATE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS]: createOpportunityCustomFieldSuccess,
    [Constants.CREATE_OPPORTUNITY_CUSTOM_FIELD_ERROR]: createOpportunityCustomFieldError,
    [Constants.DELETE_OPPORTUNITY_CUSTOM_FIELD_REQUEST]: deleteOpportunityCustomFieldRequest,
    [Constants.DELETE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS]: deleteOpportunityCustomFieldSuccess,
    [Constants.DELETE_OPPORTUNITY_CUSTOM_FIELD_ERROR]: deleteOpportunityCustomFieldError,
    [Constants.UPDATE_OPPORTUNITY_CUSTOM_FIELD_REQUEST]: updateOpportunityCustomFieldRequest,
    [Constants.UPDATE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS]: updateOpportunityCustomFieldSuccess,
    [Constants.UPDATE_OPPORTUNITY_CUSTOM_FIELD_ERROR]: updateOpportunityCustomFieldError,
    [Constants.FETCH_ALL_OPPORTUNITY_CUSTOM_FIELD_REQUEST]: fetchAllOpportunityCustomFieldRequest,
    [Constants.FETCH_ALL_OPPORTUNITY_CUSTOM_FIELD_SUCCESS]: fetchAllOpportunityCustomFieldSuccess,
    [Constants.FETCH_ALL_OPPORTUNITY_CUSTOM_FIELD_ERROR]: fetchAllOpportunityCustomFieldError,

    [Constants.DELETE_OPPORTUNITY_CUSTOM_FIELD_SETTING_REQUEST]: deleteOpportunityCustomFieldSettingRequest,
    [Constants.DELETE_OPPORTUNITY_CUSTOM_FIELD_SETTING_SUCCESS]: deleteOpportunityCustomFieldSettingSuccess,
    [Constants.DELETE_OPPORTUNITY_CUSTOM_FIELD_SETTING_ERROR]: deleteOpportunityCustomFieldSettingError,
    [Constants.CLEAR_FIELD_MESSAGE]: clearFieldMessage,
};

export default createReducer(INITIAL_STATE, HANDLERS);