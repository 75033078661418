import * as React from 'react';

import EijentButtonLogo from '../assets/images/eijent-logo-compressed.gif';
import { Button } from '@mui/material';

export default function Eijent({ onEijentClick }) {
  return (
    <Button
      onClick={onEijentClick}
      color='secondary'
      variant="contained"
      size='large'
      sx={{
        paddingLeft: 2,
        borderRadius: '50px',
        fontSize: '21px',
        fontWeight: 600,
        position: 'relative',
        transformStyle: 'preserve-3d',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
        '&:active': {
          backgroundColor: '#FFFFFF',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '125px',
          height: '74px',
          transform: 'translate3d(1px, 0px, -1px)',
          background: 'transparent linear-gradient(114deg, #5578FA 0%, #CBFFE3 100%) 0% 0% no-repeat padding-box',
          borderRadius: '26px',
          filter: 'blur(10px)',
        }

      }}
      startIcon={
        <img src={EijentButtonLogo} width={36} height={36} alt="eijent logo"
          style={{
            mixBlendMode: "multiply",
            filter: "contrast(1)",
          }}
        />
      }
    >
      Eijent
    </Button>
  );
}
