import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  Button,
  Collapse,
} from '@mui/material';

import EijentDashboard from 'eijent';
import PageTabs from '../Tabs';
import SideTabs from './SideTabs';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  xpAccountIntegrationDeleteDraft,
  xpAccountIntegrationDownloadAttachment,
  xpAccountIntegrationGmailArchiveThread,
  xpAccountIntegrationGmailDeleteThread,
  xpAccountIntegrationGmailGetList,
  xpAccountIntegrationGmailMarkEmailAsUnread,
  xpAccountIntegrationGmailMarkThreadAsRead,
  xpAccountIntegrationGmailMarkThreadAsUnread,
  xpAccountIntegrationThreadCreateDraft,
  xpAccountIntegrationThreadSendDraft,
  xpAccountIntegrationThreadSendReply,
  xpAccountIntegrationThreadUpdateDraft,
} from 'ReduxStore/API';
import { createNotification } from 'helpers';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
//@ts-ignore
import DocIcon from '../../../../src/assets/images/icons/Doc-icon.svg';
import EmailBody from './components/EmailBody';
import EmailActionButtons from './components/EmailActionButtons';
import SendersReceiversInfo from './components/SendersReceiversInfo';
import ComposeThreadReply from './components/ComposeThreadReply';
import Loader from 'Components/Loader/loader';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import ErrorBoundary from 'eijent/components/ErrorBoundary';
import EmailViewThreadActionsButtons from './components/EmailViewThreadActionsButtons';
import { useGmail } from './context/GmailContext';
import { MailType, Message, sampleMessage, setMailTypeFromUrl, GmailData, Gmail } from './common';
import {
  SuggetionIcon,
} from "../../components/CustomIcons";
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import { useSelector } from 'react-redux';


declare global {
  interface Window {
    myFunction?: () => void;
  }
}
interface AutoResizeIframeProps {
  getHtmlOfContent: () => string;
}

type DraftIds = string[];

export default function EmailView() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { gmailId } = useGmail();

  const { message: xpUsersWebSocketMessage } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;

  // State to control the collapse
  const [openSummary, setOpenSummary] = useState<boolean>(false);

  // Function to handle click and toggle the collapse
  const handleToggleSummary = (): void => {
    setOpenSummary(prevOpen => !prevOpen);
  };

  //@ts-ignore
  const modules: QuillToolbarModules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }], // Text alignment
        ['link', 'image'], // Default image handler
        ['attachment'], // Custom attachment button
        ['clean'], // Removes formatting
      ],
    },
  };

  const [replyToAll, setReplyToAll] = useState<boolean>(false);
  const [gmail, setGmail] = useState<Gmail>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);
  const [mailType, setMailType] = useState<MailType>('');
  const [tempStart, setTempStart] = useState<number>(0);
  const [page_no, setPage_no] = useState<number>(1);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState<number | null>(
    null,
  );
  const [gmailData, setGmailData] = useState<GmailData>({});
  const [totalCount, setTotalCount] = useState<number>(null as number);
  const [limit, setLimit] = useState<number>(10);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [sideBarType, setSideBarType] = useState<String>('');
  const [internalTab, setInternalTab] = useState<string>('');
  const [canReplyToAll, setCanReplyToAll] = useState<boolean>(false);
  useEffect(() => {
    const { mailType: type, currentPath, internalTab } = setMailTypeFromUrl();
    setMailType(type);
    setSideBarType(currentPath);
    setInternalTab(internalTab || '');
    getGmailData(true, type, internalTab);

    const mailNo = searchParams.get('mailNo');
    const pageNo = searchParams.get('pageNo');
    const totalCount = searchParams.get('totalCount');
    const limit = searchParams.get('limit');

    setPage_no(parseInt(pageNo));
    setSelectedEmailIndex(parseInt(mailNo) - 1);
    setTempStart(parseInt(mailNo) + (parseInt(pageNo) - 1) * parseInt(limit));
    setTotalCount(parseInt(totalCount));
    setLimit(parseInt(limit));
  }, []);

  useEffect(() => {
    if (gmail && Array.isArray(gmail) && gmail.length > 0) {
      const lastEmail = gmail[gmail.length - 1];
      setCanReplyToAll(canReplyToAllFn(lastEmail.payload));
    }
  }, [gmail]);

  useEffect(() => {
    console.log('xpUsersWebSocketMessage', xpUsersWebSocketMessage);
    if (xpUsersWebSocketMessage.message == 'Gmail inbox list is updated') {
      // if (page_no === 1) {
      getGmailData(false, mailType, internalTab);
      // }
    }
  }, [xpUsersWebSocketMessage]);

  const getGmailData = async (showLoader = true, type: MailType, filterBy: string) => {
    const pageNo = searchParams.get('pageNo') || 1;
    const limit = searchParams.get('limit') || 10;

    if (showLoader) {
      setIsLoading(true);
    }
    const queryParams = {
      page_no: pageNo,
      limit: limit,
      type: type || mailType,
      filterBy: filterBy || internalTab || '',
    };
    const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
      queryParams,
    );
    setGmail(
      gmailDataResult.data.data.find((msg) => msg._id === params.threadId)
        ?.messages,
    );
    gmailDataResult.data.data
      .find((msg) => msg._id === params.threadId)
      ?.messages.some((message) => message.labelIds.includes('UNREAD')) &&
      markThreadAsRead();

    setIsLoading(false);
    if (gmailDataResult?.data) {
      setGmailData(gmailDataResult.data);
    }
  };

  const handleNextMail = async () => {
    try {
      if (selectedEmailIndex === limit - 1 && totalCount > tempStart) {
        // If the mail reaches the last mail of the current list
        setIsLoading(true);

        const queryParams = {
          page_no: page_no + 1,
          limit: limit,
          type: mailType,
        };
        const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
          queryParams,
        );
        if (gmailDataResult?.data) {
          const threadId = gmailData.data[0]._id;
          setPage_no((prev) => prev + 1);
          setSelectedEmailIndex(0);
          // viewGmailContent(gmailDataResult.data.data[0].messages, 0, page_no + 1);
          setGmailData(gmailDataResult.data);
          setTotalCount(gmailDataResult.data.totalMailCount);
          setGmail(gmailDataResult.data.data[0].messages);
          setIsLoading(false);
          setTempStart((prev) => prev + 1);
          navigate(
            `/inbox/my-inbox/all/view/${threadId}?pageNo=${page_no + 1
            }&limit=${limit}&mailNo=${1}&totalCount=${gmailData?.totalMailCount
            }`,
          );
        }
      } else if (
        selectedEmailIndex !== null &&
        gmailData.data &&
        selectedEmailIndex < gmailData.data.length &&
        totalCount > tempStart
      ) {
        const nextIndex = selectedEmailIndex + 1;
        const nextThreadId = gmailData.data[nextIndex]._id;
        setGmail(gmailData.data[nextIndex].messages);
        gmailData.data
          .find((msg) => msg._id === nextThreadId)
          .messages.some((message) => message.labelIds.includes('UNREAD')) &&
          markThreadAsRead();
        setSelectedEmailIndex(nextIndex);
        setTempStart((prev) => prev + 1);
        navigate(
          `/inbox/my-inbox/all/view/${nextThreadId}?pageNo=${page_no}&limit=${limit}&mailNo=${parseInt(searchParams.get('mailNo')) + 1
          }&totalCount=${gmailData?.totalMailCount}`,
        );
      }
    } catch (error) {
      console.log(error);
      createNotification('error', error.message);
    }
  };

  const handlePreviousMail = async () => {
    try {
      if (selectedEmailIndex === 0 && page_no > 1) {
        setIsLoading(true); // If the mail reaches the first mail of the current list
        const queryParams = {
          page_no: page_no - 1,
          limit: limit,
          type: mailType,
        };
        const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
          queryParams,
        );
        if (gmailDataResult?.data) {
          const threadId = gmailDataResult.data.data[limit - 1]._id;
          setPage_no((prev) => prev - 1);
          setSelectedEmailIndex(limit - 1);
          setGmailData(gmailDataResult.data);
          setTotalCount(gmailDataResult.data.totalMailCount);
          setGmail(gmailDataResult.data.data[limit - 1].messages);
          setIsLoading(false);
          setTempStart((prev) => prev - 1);
          navigate(
            `/inbox/my-inbox/all/view/${threadId}?pageNo=${page_no - 1
            }&limit=${limit}&mailNo=${limit - 1}&totalCount=${gmailData?.totalMailCount
            }`,
          );
        }
      } else if (
        selectedEmailIndex !== null &&
        gmailData.data &&
        selectedEmailIndex > 0
      ) {
        const previousIndex = selectedEmailIndex - 1;
        const previousThreadId = gmailData.data[previousIndex]._id;
        setGmail(gmailData.data[previousIndex].messages);
        gmailData.data
          .find((msg) => msg._id === previousThreadId)
          .messages.some((message) => message.labelIds.includes('UNREAD')) &&
          markThreadAsRead();
        setSelectedEmailIndex(previousIndex);
        setTempStart((prev) => prev - 1);

        navigate(
          `/inbox/my-inbox/all/view/${previousThreadId}?pageNo=${page_no}&limit=${limit}&mailNo=${parseInt(searchParams.get('mailNo')) - 1
          }&totalCount=${gmailData?.totalMailCount}`,
        );
      }
    } catch (error) {
      console.log(error);
      createNotification('error', error.message);
    }
  };

  const setGmailDataAfterUnBookmarkingEmail = (messageId) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            message.id === messageId &&
            message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds (removing "STARRED")
            return {
              ...message,
              labelIds: message.labelIds.filter((label) => label !== 'STARRED'),
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const setGmailDataAfterBookmarkingEmail = (messageId) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            message.id === messageId &&
            !message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds
            return {
              ...message,
              labelIds: [...message.labelIds, 'STARRED'],
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const markEmailAsUnread = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkEmailAsUnread(data);
      if (res.data.success) {
        navigate(`/inbox/${sideBarType}/${internalTab}`);
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const markThreadAsRead = async () => {
    try {
      const data = {
        threadId: params.threadId,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailMarkThreadAsRead(data);
      if (res.data.success) {
      }else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };
  const markThreadAsUnread = async () => {
    try {
      const data = {
        threadId: params.threadId,
        gmailId: gmailId,
      };
      setIsLoading(true);
      const res = await xpAccountIntegrationGmailMarkThreadAsUnread(data);
      if (res.data.success) {
        navigate(`/inbox/${sideBarType}/${internalTab}`, { replace: true });
      }else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const archiveThread = async () => {
    try {
      const data = {
        threadId: params.threadId,
        gmailId: gmailId,
      };
      setIsLoading(true);
      const res = await xpAccountIntegrationGmailArchiveThread(data);
      if (res.data.success) {
        navigate(`/inbox/${sideBarType}/${internalTab}`);
      }else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteThread = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadId: params.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailDeleteThread(data);
      if (res.data.success) {
        navigate(`/inbox/${sideBarType}/${internalTab}`);
      }else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setIsLoading(false);
    }
  };

  const downloadAttachment = async (messageId, filename, attachmentId) => {
    try {
      const data = {
        messageId,
        gmailId,
        filename,
        attachmentId,
      };
      await xpAccountIntegrationDownloadAttachment(data);
    } catch (error) {
      createNotification('error', error.message);
    }
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const renderAttachments = (
    gmail,
    showRemoveAttachmentIcon = false,
    indexFromGmail,
  ) => {
    const { payload } = gmail;
    const { parts } = payload;

    if (
      payload.mimeType === 'multipart/mixed' &&
      Array.isArray(parts) &&
      parts.length > 1
    ) {
      return parts.map((part, i) => {
        if (
          i > 0 &&
          !removedAttachments[indexFromGmail]?.includes(part.partId)
        ) {
          return (
            <Box display={'flex'} alignItems={'center'} className="attachment-file" key={part.partId}>
              <Box display={'flex'} alignItems={'center'}>
                <Box className="file-icon">
                  <img src={DocIcon} alt="file-icon" />
                </Box>
                <Box>
                  <Typography variant='body2' className="file-name">{part.filename}</Typography>
                  <Typography variant='caption' className="file-size">{part.body.size}</Typography>
                </Box>
              </Box>
              <FileDownloadIcon
                onClick={() =>
                  downloadAttachment(
                    gmail.id,
                    part.filename,
                    part.body.attachmentId,
                  )
                }
                style={{ fontSize: '18px' }}
                className="options-button"
              />
              {showRemoveAttachmentIcon && (
                <Box ml={1} className="options">
                  <ClearIcon
                    onClick={() =>
                      handleRemovedAttachments(part.partId, indexFromGmail)
                    }
                    style={{ fontSize: '18px' }}
                  />
                </Box>
              )}
            </Box>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  };

  const handleRemovedAttachments = (partId, indexFromGmail) => {
    setRemovedAttachments((prev) => [...prev, partId]);
  };

  const handleSendOrDraft = async (
    status: string,
    i,
    mail: Message,
    editorText: string,
    fileNames: File[],
    removedAttachments: string[],
    recipientsChips?: string[],
    ccChips?: string[],
    bccChips?: string[],
  ) => {
    try {
      setIsLoading(true);
      const lastMail = gmail[i - 1];
      let sendTo;
      const formData = new FormData();
      if (mail.labelIds?.includes('FORWARD')) {
        sendTo = recipientsChips?.join(', ') || '';
        if (ccChips?.length) {
          formData.append('cC', ccChips.join(', '));
        }
        if (bccChips?.length) {
          formData.append('bcC', bccChips.join(', '));
        }
      } else if (replyToAll) {
        sendTo = lastMail?.payload?.headers
          .filter(
            (obj) =>
              ['To', 'Cc', 'cC', 'CC'].includes(obj.name) ||
              (obj.name === 'From' && obj.value !== gmailId),
          )
          .map((obj) => obj.value)
          .join(', ');
      } else {
        sendTo = lastMail?.payload?.headers.find(
          (obj) => obj.name === 'From',
        ).value;
      }

      formData.append('threadId', lastMail.threadId);
      formData.append('to', sendTo);
      formData.append('from', gmailId);
      formData.append(
        'subject',
        lastMail?.payload?.headers.find((obj) => obj.name === 'Subject').value,
      );
      formData.append('htmlContent', editorText);

      if (
        lastMail?.payload?.headers.find((obj) => obj.name === 'From').value !==
        gmailId
      ) {
        // checking if user is not replying to self
        lastMail?.payload?.headers.find((obj) => obj.name === 'Message-ID')
          ?.value &&
          formData.append(
            'inReplyTo',
            lastMail?.payload?.headers.find((obj) => obj.name === 'Message-ID')
              .value,
          );
      } else {
        // checking if user is replying to self
        // the first mail of thread do not have In-Reply-To, therefore we append
        // Message-ID'a value to  In-Reply-To
        lastMail?.payload?.headers.find((obj) => obj.name === 'In-Reply-To')
          ?.value
          ? formData.append(
            'inReplyTo',
            lastMail?.payload?.headers.find(
              (obj) => obj.name === 'In-Reply-To',
            ).value,
          )
          : formData.append(
            'inReplyTo',
            lastMail?.payload?.headers.find(
              (obj) => obj.name === 'Message-ID' || obj.name === 'Message-Id',
            ).value,
          );
      }

      lastMail?.payload?.headers.find((obj) => obj.name === 'Cc')?.value &&
        formData.append(
          'cC',
          lastMail?.payload?.headers.find((obj) => obj.name === 'Cc').value,
        );
      lastMail?.payload?.headers.find((obj) => obj.name === 'Bcc')?.value &&
        formData.append(
          'bcC',
          lastMail?.payload?.headers.find((obj) => obj.name === 'Bcc').value,
        );

      Array.isArray(fileNames) &&
        fileNames.forEach((file: File) => {
          formData.append('attachments', file);
        });

      Array.isArray(removedAttachments) &&
        removedAttachments.forEach((el) => {
          formData.append('removedAttachmentsPartId', el);
        });

      if (status === 'send') {
        let response: any = {};
        if (mail.draftId) {
          formData.append('draftId', mail.draftId);
          Array.isArray(removedAttachments) &&
            removedAttachments.forEach((el) => {
              formData.append('removedAttachments', el);
            });
          response = await xpAccountIntegrationThreadSendDraft(formData);
        } else {
          response = await xpAccountIntegrationThreadSendReply(formData);
        }
        if (response?.data?.status == 200) {
          createNotification('success', response?.data?.message);
          setGmail((prev) => {
            let prevCopy = [...prev];
            prevCopy.splice(i, 1);
            return [...prevCopy, response.data.data];
          });
        }else {
          createNotification('error', response.data.message);
        }
      } else {
        let response: any = {};
        if (mail.draftId) {
          formData.append('draftId', mail.draftId);
          response = await xpAccountIntegrationThreadUpdateDraft(formData);
          if (response.data.success) {
          }else {
            createNotification('error', response.data.message);
          }
        } else {
          response = await xpAccountIntegrationThreadCreateDraft(formData);
          if (response.data.success) {
          }else {
            createNotification('error', response.data.message);
          }
        }
        if (response.data.success) {
          setGmail((prev) => {
            const updatedGmail = [...prev]; // Create a shallow copy of prev
            updatedGmail.splice(i, 1, response.data.data); // Replace the element at index `i`
            return updatedGmail; // Return the updated array
          });
        }
      }
    } catch (error) {
      createNotification('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDiscard = async (i, mail) => {
    if (mail.draftId) {
      const data = {
        draftId: mail.draftId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationDeleteDraft(data);
      if (res.data.status === 200 && res.data.success) {
      }else {
        createNotification('error', res.data.message);
      }
    }
    setGmail((prev) => {
      const updatedGmail = [...prev];
      updatedGmail.splice(i, 1); // Remove the item at index `i`
      return updatedGmail;
    });
  };

  const handleOpenComposeForReplyFromLastMail = (isReplyingToAll: boolean) => {
    if (gmail.length > 0) {
      const lastEmail = gmail[gmail.length - 1];
      if (!lastEmail.labelIds.includes('DRAFT')) {
        setReplyToAll(false);

        setGmail((prev) => {
          const newPrev = [...prev];
          const originalHeaders = lastEmail.payload.headers;
          const subject = originalHeaders.find(h => h.name === 'Subject')?.value || '';
          const from = originalHeaders.find(h => h.name === 'From')?.value || '';
          const to = originalHeaders.find(h => h.name === 'To')?.value || '';
          const cc = originalHeaders.find(h => h.name === 'Cc')?.value || '';

          // Helper function to extract email from string like "Name <email@domain.com>" or "email@domain.com"
          const extractEmail = (str: string): string => {
            const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
            const match = str.match(emailRegex);
            return match ? match[0] : str;
          };

          // Process multiple email addresses
          const processEmails = (emailStr: string): string[] => {
            if (!emailStr) return [];
            return emailStr.split(',')
              .map(email => email.trim())
              .map(extractEmail)
              .filter(email => email && !email.includes(gmailId));
          };

          // Set up recipients based on reply type
          const recipients = isReplyingToAll ? [extractEmail(from)] : [extractEmail(from)];
          const ccRecipients = isReplyingToAll
            ? [...processEmails(to), ...processEmails(cc)]
            : [];


          const newDraft = {
            ...sampleMessage,
            snippet: '',
            id: prev.length.toString(),
            labelIds: ['DRAFT'],
            recipients: {
              to: recipients,
              cc: ccRecipients,
            },
            payload: {
              ...sampleMessage.payload,
              headers: [
                { name: 'Subject', value: `${'Re: '}${subject.replace(/^(Re: |Fwd: )*/, '')}` },
                { name: 'To', value: recipients.join(', ') },
                { name: 'Cc', value: ccRecipients.join(', ') }
              ],
            }
          };

          return [...newPrev, newDraft];
        });
      }
    }
  };

  const handleForwardFromLastMail = () => {
    if (gmail.length > 0) {
      const lastEmail = gmail[gmail.length - 1];

      // Get the original subject
      const originalSubject = lastEmail.payload.headers.find(
        (obj) => obj.name === 'Subject',
      ).value;
      const subject = originalSubject.startsWith('Fwd:')
        ? originalSubject
        : `Fwd: ${originalSubject}`;

      // Get the email content
      let htmlContent = '';
      if (lastEmail.payload.mimeType === 'text/html') {
        htmlContent = Buffer.from(
          lastEmail.payload.body.data,
          'base64',
        ).toString();
      } else if (lastEmail.payload.parts) {
        const htmlPart = lastEmail.payload.parts.find(
          (part) => part.mimeType === 'text/html',
        );
        if (htmlPart) {
          htmlContent = Buffer.from(htmlPart.body.data, 'base64').toString();
        }
      }

      // Format the forwarded content
      const forwardedContent = `
        <br/><br/>
        ---------- Forwarded message ---------<br/>
        From: ${lastEmail.payload.headers.find((h) => h.name === 'From')?.value
        }<br/>
        Date: ${lastEmail.payload.headers.find((h) => h.name === 'Date')?.value
        }<br/>
        Subject: ${originalSubject}<br/>
        To: ${lastEmail.payload.headers.find((h) => h.name === 'To')?.value
        }<br/>
        <br/>
        ${htmlContent}
      `;

      // Add the draft to the gmail state
      setGmail((prev) => [
        ...prev,
        {
          ...sampleMessage,
          id: prev.length.toString(),
          snippet: forwardedContent,
          labelIds: ['DRAFT', 'FORWARD'],
          payload: {
            ...sampleMessage.payload,
            headers: [{ name: 'Subject', value: subject }],
            originalContent: lastEmail.payload,
          },
        },
      ]);
    }
  };

  const canReplyToAllFn = (lastEmailPayload) => {
    const allRecipients = lastEmailPayload.headers
      .filter(
        (obj) =>
          ['To', 'Cc', 'cC'].includes(obj.name) ||
          (obj.name === 'From' && obj.value !== gmailId),
      )
      .map((obj) => obj.value);

    // Exclude the user's email
    const otherRecipients = allRecipients.filter(
      (email) => email !== gmailId || !email.includes(gmailId),
    );
    let finalArray = [];
    otherRecipients.forEach((email) => {
      email
        .split(',')
        .forEach(
          (el) =>
            el !== gmailId &&
            !el.includes(gmailId) &&
            finalArray.push(el.trim()),
        );
    });
    // Check if there are other recipients
    return finalArray.length > 1;
  };

  const handleOpenComposeForReplyAllFromLastMail = () => {
    if (gmail.length > 0) {
      const lastEmail = gmail[gmail.length - 1];

      if (!lastEmail.labelIds.includes('DRAFT')) {
        setReplyToAll(true);
        setGmail((prev) => {
          const isNextMailAlreadyDraft =
            prev[prev.length - 1]?.labelIds.includes('DRAFT');
          if (isNextMailAlreadyDraft) return prev;

          return [
            ...prev,
            {
              ...sampleMessage,
              snippet: '',
              id: prev.length.toString(),
              labelIds: ['DRAFT'],
            },
          ];
        });
      }
    }
  };
  const mainContent = <SideTabs>
    <ErrorBoundary>
      <Stack px={2.5} py={1} height={'100%'}>
        <EmailViewThreadActionsButtons
          mailType={mailType}
          archiveThread={archiveThread}
          markThreadAsUnread={markThreadAsUnread}
          setOpenDeleteConfirmationModel={setOpenDeleteConfirmationModel}
          tempStart={tempStart}
          totalCount={totalCount}
          handlePreviousMail={handlePreviousMail}
          handleNextMail={handleNextMail}
        />
        {/* ---subject--- */}
        <Stack
          py={1.5}
          borderBottom={'1px solid #F4F6F7'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h4" fontWeight="400">
            {/* Lorem ipsum sit amet */}
            {
              gmail?.[0]?.payload?.headers.find(
                (g: any) => g.name === 'Subject',
              ).value
            }
          </Typography>
        </Stack>
        {/* ---Email body--- */}
        {Array.isArray(gmail) &&
          gmail.length > 0 &&
          gmail.map((mail, i) => {
            return !mail.labelIds.includes('DRAFT') ? (
              <Fragment key={mail.id}>
                {/* ---from--- */}

                <Stack
                  py={1.5}
                  borderBottom={'1px solid #F4F6F7'}
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <SendersReceiversInfo mail={mail} />

                  <EmailActionButtons
                    i={i}
                    mail={mail}
                    gmailId={gmailId}
                    mailType={mailType}
                    setGmail={setGmail}
                    setGmailDataAfterUnBookmarkingEmail={
                      setGmailDataAfterUnBookmarkingEmail
                    }
                    setGmailDataAfterBookmarkingEmail={
                      setGmailDataAfterBookmarkingEmail
                    }
                    setReplyToAll={setReplyToAll}
                  />
                </Stack>
                {/* Email Summary */}
                {i === 0 && <Stack
                  spacing={1}
                  mb={2}
                  p={1}
                  borderRadius={'8px'}
                  sx={{ minWidth: '600px', backgroundColor: '#ffffff', border: '1px dotted #5f6ef6' }}
                >
                  <Box display={'flex'} sx={{ cursor: 'pointer' }} alignItems={'center'} gap={1} onClick={handleToggleSummary}>
                    <SuggetionIcon />
                    <Typography variant='body1' color='primary' fontWeight={'600'}>Email Summary</Typography>
                  </Box>
                  <Collapse in={openSummary} sx={{ marginTop: '0 !important' }}>
                    <Typography variant='body2' mt={1} className="small-subtitle">{mail?.summary} </Typography>
                  </Collapse>
                </Stack>}

                <EmailBody mail={mail} mailCount={gmail.length}/>
                <Stack
                  spacing={1}
                  direction={'row'}
                  mt={1}
                  className="attachments"
                >
                  {renderAttachments(mail, false, i)}
                </Stack>
              </Fragment>
            ) : (
              <Fragment key={i}>
                <ComposeThreadReply
                  i={i}
                  mail={mail}
                  handleSendOrDraft={handleSendOrDraft}
                  handleDiscard={handleDiscard}
                  downloadAttachment={downloadAttachment}
                />
              </Fragment>
            );
          })}
        {/* Only show action buttons if the last email is not a draft */}
        {gmail && gmail?.length > 0 &&
          !gmail[gmail.length - 1].labelIds.includes('DRAFT') && (
            <Stack
              py={1.5}
              direction={'row'}
              alignItems={'center'}
              spacing={1}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenComposeForReplyFromLastMail(false)}
              >
                Reply
              </Button>
              {canReplyToAll && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleOpenComposeForReplyFromLastMail(true)}
                >
                  Reply All
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleForwardFromLastMail}
              >
                Forward
              </Button>
            </Stack>
          )}
      </Stack>
      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={deleteThread}
      />
      {isLoading && <Loader />}
    </ErrorBoundary>
  </SideTabs>;

  const contentForWorkspace = () => {
    return <EijentDashboard>
      <PageTabs>{mainContent}</PageTabs>
      {isLoading && <Loader />}
    </EijentDashboard>;
  };

  const contentIndependentOfWorkspace = () => {
    return <EijentDashboard>
      {mainContent}
      {isLoading && <Loader />}
    </EijentDashboard>;
  };

  return (
    <>
      {contentIndependentOfWorkspace()}
      {/* {contentForWorkspace()} */}
    </>
  );
}
