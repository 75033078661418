import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Filter, UserPreferencesState } from 'ReduxStore/Reducers/userPreferences.reducer';
import { useLocation } from 'react-router-dom';
import { CancelIcon } from 'eijent/components/CustomIcons';
import { FilterRow, useFilterTypes } from '../utils/FilterTypesContext';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';


const QuickFilters: React.FC<{setFilterRows: (filterRows: FilterRow[]) => void}> = ({setFilterRows}) => {
  const userPreferencesState = useSelector((state: any) => state.UserPreferences) as UserPreferencesState;
  const [quickFilters, setQuickFilters] = useState<Filter[]>([]);
  const  [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [filterIdToDelete, setFilterIdToDelete] = useState<string>('');
  const { handleApplyQuickFilter, handleDeleteFilter } = useFilterTypes();

  const location = useLocation();
  const moduleName = location.pathname.split('/')[1];

  useEffect(() => {
    if(userPreferencesState?.fetchUserPreferencesSuccess || userPreferencesState?.updateUserPreferencesSuccess){
      setQuickFilters(userPreferencesState.userPreferences.find(preference => preference.moduleName === moduleName)?.filters);
    }
  }, [userPreferencesState?.fetchUserPreferencesSuccess, userPreferencesState?.updateUserPreferencesSuccess, userPreferencesState?.deleteFilterSuccess]);


  const handleOpenDeletePopup = (filterId: string) => {
    setOpenDeletePopup(true);
    setFilterIdToDelete(filterId);
    // handleDeleteFilter(moduleName, filterId);
  }

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
    setFilterIdToDelete('');
  }

  return (
    <>
    <Stack gap={1}>
      <Typography variant="body2" fontWeight={'700'}>
        Quick filters
      </Typography>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        gap={0.5}
        alignItems={'center'}
      >
        {Array.isArray(quickFilters) && quickFilters?.length > 0 && quickFilters?.map((filter, index) => (
          <Button
            key={filter._id}
            // @ts-ignore
            variant="tag"
            size="small"
            sx={{
              borderRadius: '4px',
              backgroundColor: filter.color,
              fontWeight: '400',
              '&:hover': {
                backgroundColor: filter.color,
              },
              '& .MuiButton-endIcon': {
                opacity: 0,
                transition: 'opacity 0.2s',
              },
              '&:hover .MuiButton-endIcon': {
                opacity: 1,
              }
            }}
            endIcon={<CancelIcon />}
            onClick={() => {
              handleApplyQuickFilter(filter);
            }}
            // onClickCapture={(e) => {
            //   // Check if click was on the icon
            //   if (e.target instanceof Element && e.target.closest('.MuiButton-endIcon')) {
            //     e.stopPropagation();
            //     handleDeleteFilter(moduleName, filter._id);
            //   }
            // }}
            onClickCapture={(e) => {
              // Check if click was on the icon
              if (e.target instanceof Element && e.target.closest('.MuiButton-endIcon')) {
                e.stopPropagation();
                handleOpenDeletePopup(filter._id);
              }
            }}
          >
            {filter.name}
          </Button>
        ))}
      </Stack>
    </Stack>
    {openDeletePopup && (
      <ConfirmationAlertPopUp
        openModal={openDeletePopup}
        closeModalFunc={handleCloseDeletePopup}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => {
          handleDeleteFilter(moduleName, filterIdToDelete)
          handleCloseDeletePopup()
        }}
      />
    )}
    </>
  );
};

export default QuickFilters; 