
import { ReactNode, useState } from "react";


import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup, ButtonGroup, Tab, Checkbox, IconButton, TableSortLabel, TextField, ClickAwayListener, InputAdornment } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from '@mui/material/styles';

import {
  ArrowDownIcon,
  AddNewIcon,

  HomeIconActive,
  InboxIconActive,
  TasksIcon,
  TasksIconActive,
  OpportunitiesIcon,
  OpportunitiesIconActive,
  AccountsIcon,
  ContactsIcon,
  DriveIcon,
  ContactsIconActive,
  StartIconOutlined,
  StartIcon,
  AttachmentIcon,
  UnCheckedIcon,
  CheckedIcon,
  CheckAllIcon,
  UnCheckAllIcon,
  DeleteIcon,
  CancelIcon,
  MoreIcon,
  EditIcon,
  EmailIcon,
  ProductIcon,
  ChatIcon,

} from '../../../components/CustomIcons.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from "react";
import DoneIcon from '@mui/icons-material/Done';
// @ts-ignore
import EijentButtonLogo from "../../../assets/images/eijent-logo-compressed.gif";
import EijentCopilot from "../../../EijentCopilot";
import ActivityTab from "./ActivityTab";
import FilesTab from "./FilesTab";
import AccountsTab from "./AccountsTab";


const tabButtonStyle = {
  flexDirection: 'column',
  gap: 0.5,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
  height: '81px',
  minWidth: '58px',
  fontSize: '11px',
  transition: "margin 0.05s ease-in-out",
  borderRadius: "0 8px 8px 0",

  '& .MuiButton-startIcon': {
    margin: 0,
  },
  '&.active': {
    backgroundColor: '#F8F8F8',
  },
  ' &:hover, &.active': {
    marginLeft: -3.4,
    transition: "margin 0.1s ease-in-out",


  }
};

const aiInputStyle = {
  position: "relative",
  transformStyle: "preserve-3d",

  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    transform: "translate3d(1px, 0px, -1px)",
    background:
      "transparent linear-gradient(114deg, #5578FA 0%, #CBFFE3 100%) 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    filter: "blur(10px)",
  },
};


const tabListItems = [
  { text: '', icon: <img src={EijentButtonLogo} width={32} height={32} alt="eijent logo" />, class: '' },
  { text: 'Actives', icon: <TasksIcon />, class: '' },
  { text: 'Files', icon: <DriveIcon />, class: '' },
  { text: 'Accounts', icon: <AccountsIcon />, class: 'active' },
  { text: 'Contacts', icon: <ContactsIcon />, class: '' },
  { text: 'Products', icon: <ProductIcon />, class: '' },
  { text: 'Email', icon: <EmailIcon />, class: '' },
  { text: 'Chat', icon: <ChatIcon />, class: '' },
];
export default function OpportunityTabs() {


  return (

    <Stack
      direction={"row"}
      sx={{
        height: '100%',
        maxHeight: '100%',
      }}>
      <Box flexGrow={1} sx={{ overflow: 'auto', borderRadius: '8px', backgroundColor: '#F8F8F8', }} >
        {/* <EijentCopilot /> */}
        {/* <ActivityTab /> */}
        {/* <FilesTab /> */}
        <AccountsTab />
      </Box>

      <Stack alignItems={'center'} px={1.7}>
        {tabListItems.map((item, index) => (
          <Button
            variant="text"
            startIcon={item.icon}
            sx={tabButtonStyle}
            className={item.class}
          >
            {item.text}
          </Button>
        ))}
      </Stack>

    </Stack >

  );
}