import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import { FilterType } from '../../utils/FilterTypesContext';
import OpportunityAccountsAPI from 'ReduxStore/API/opportunity/opportunityAccountsAPI';
import BaseSearchDropdown from './BaseSearchDropdown';

interface Account {
  _id: string;
  name: string;
}

interface DropdownForAccountProps {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  filterType: FilterType;
}

const DropdownForAccount: React.FC<DropdownForAccountProps> = (props) => {
  const fetchAccounts = async (search: string) => {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const res = await opportunityAccountsAPI.fetchAllAccountsWhileSearchInDropdown({ searchText: search });
    return res.data.success ? res.data.accounts : [];
  };

  return (
    <BaseSearchDropdown<Account>
      {...props}
      placeholder="Select Account"
      displayField="name"
      fetchItems={fetchAccounts}
    />
  );
};

export default DropdownForAccount; 