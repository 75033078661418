import React, { useState, useEffect } from 'react';
import { confirmAccountAPI, resendConfirmLinkAPI } from '../../ReduxStore/API';
import { NotificationContainer } from 'react-notifications';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../sass/main.scss';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AuthLayout from '../../Layouts/AuthLayout';
import Card from '../../Layouts/AuthLayout/SignInCard';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import SignInButtonIcon from '../../assets/icons/SignInButtonIcon.svg';
import Loader from '../../Components/Loader/loader';
import { generateUrl } from 'helpers/common';
import { Alert, Collapse, IconButton, Stack } from '@mui/material';
import { CancelIcon } from '../../eijent/components/CustomIcons';

const ConfirmAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [resendLink, setResentLink] = useState(false);
    const [tokenToConfirm, setToken] = useState('');
    const [alert, setAlert] = useState(null);

    // Extract email and code from query parameters
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const queryEmail = searchParams.get('email');
        const queryCode = searchParams.get('code');
        const queryToken = searchParams.get('token');

        if (queryEmail) setEmail(queryEmail);
        if (queryCode) setConfirmationCode(queryCode);
        if (queryToken) setToken(queryToken);
    }, [location.search]);

    const isValidForm = () => {
        let error = {};
        let formIsValid = true;
        const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email === '' || email.trim().length === 0) {
            formIsValid = false;
            error['email'] = '*Please enter email.';
        }
        if (email && regexTest.test(email) === false) {
            formIsValid = false;
            error['email'] = '*Please enter a valid email address.';
        }
        if (confirmationCode === '' || confirmationCode.trim().length === 0) {
            formIsValid = false;
            error['code'] = '*Please enter confirmation code.';
        }
        setErrors(error);

        // Trigger alert if there are errors
        if (!formIsValid) {
            const errorMessages = Object.values(error).join('\n'); // Combine error messages into a single string
            handleAlert('error', errorMessages); // Use handleAlert to display errors
        }
        return formIsValid;
    };

    const handleConfirm = async () => {
        try {
            if (isValidForm()) {
                setLoading(true); // Start loading
                const data = {
                    email: email,
                    code: confirmationCode,
                    token: tokenToConfirm
                };
                const res = await confirmAccountAPI(data);
                if (res.data.status === 200) {
                    setLoading(false); // Stop loading 
                    handleAlert('success', 'Account confirmed successfully. You can login now.');
                    navigate('/');
                } else {
                    console.log("res?.data?.message", res?.data?.message);
                    setResentLink(true); // show resen link button
                    setLoading(false); // Stop loading 
                    handleAlert('error', res?.data?.message);
                }
            }
        } catch (error) {
            setLoading(false); // Stop loading 
            handleAlert('error', 'Failed to confirm account. Please try again.');
        }
    };

    const handleResendConfirmLink = async () => {
        try {
            if (isValidForm()) {
                setLoading(true); // Start loading
                const data = {
                    email: email
                };
                const res = await resendConfirmLinkAPI(data);
                if (res.data.status === 200) {
                    setLoading(false); // Stop loading 
                    handleAlert('success', 'Verification link resend successfully');
                    setTimeout(() => {
                        navigate('/');
                    }, 2000); // 2-second delay 
                } else {
                    console.log("res?.data?.message", res?.data?.message);
                    handleAlert('error', res?.data?.message);
                    setResentLink(true); // show resend link button
                    setLoading(false); // Stop loading 
                } 
            }
        } catch (error) {
            setLoading(false); // Stop loading 
            handleAlert('error', 'Failed to confirm account. Please try again.');
        }

    };


    const handleAlert = (type, message) => {
        setAlert({ type, message }); // Update the alert state
    };


    return (
        <AuthLayout>
            <Helmet>
                <title>Eijent | Confirm Account</title>
            </Helmet>
            <Box component="form" >
                <Card >
                    <Typography
                        component="h1"
                        variant="h1"
                        textAlign={'center'}
                        sx={{ width: '100%', }}
                    >
                        Confirm Account
                        {loading ? <Loader /> : null}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
                    >
                        <Typography variant='body2'>
                            Please enter the code we have sent on your email to Confirm your account.
                        </Typography>
                        <FormControl>
                            <TextField
                                id="email"
                                type="email"
                                name="email"
                                placeholder="Email"
                                autoComplete="email"
                                disabled={true}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                variant="outlined"
                                size="large"
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="code"
                                type="text"
                                name="confirmationCode"
                                placeholder="Confirmation Code"
                                value={confirmationCode}
                                disabled={true}
                                onChange={(e) => setConfirmationCode(e.target.value)}
                                fullWidth
                                variant="outlined"
                                size="large"
                            />
                        </FormControl>
                    </Box>

                    <Collapse in={!!alert}>
                        <Alert
                            severity={alert?.type} // Dynamic severity (error, success, etc.)
                            action={
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    onClick={() => setAlert(null)} // Close the alert
                                >
                                    <CancelIcon />
                                </IconButton>
                            }
                        >
                            {alert?.message.split('\n').map((msg, index) => (
                                <Box key={index}>{msg}</Box> // Render each message on a new line
                            ))}
                        </Alert>
                    </Collapse>



                    <Stack spacing={2.5} alignItems={'center'}>
                        {resendLink &&
                            <Box>
                                <Button
                                    size='large'
                                    color='primary'
                                    variant='contained'
                                    onClick={handleResendConfirmLink}
                                    endIcon={<img src={SignInButtonIcon} alt="arrow-icon" />}
                                    sx={{
                                        justifyContent: 'space-between',
                                        marginTop: '16px',
                                        minWidth: '222px',
                                    }}>Resend Confirm account link </Button>
                            </Box>

                        }
                        {!resendLink && <Box>
                            <Button
                                size='large'
                                color='primary'
                                variant='contained'
                                onClick={handleConfirm}
                                endIcon={<img src={SignInButtonIcon} alt="arrow-icon" />}
                                sx={{
                                    justifyContent: 'space-between',
                                    marginTop: '16px',
                                    minWidth: '222px',
                                }}>Confirm</Button>
                        </Box>
                        }
                    </Stack>
                </Card>
            </Box>
            <NotificationContainer />
        </AuthLayout>
    );
};

export default ConfirmAccount;
