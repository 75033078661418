import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import {
  HomeIcon,
  HomeIconActive,
  InboxIcon,
  InboxIconActive,
  TasksIcon,
  TasksIconActive,
  OpportunitiesIcon,
  OpportunitiesIconActive,
  AccountsIcon,
  AccountsIconActive,
  ContactsIcon,
  ContactsIconActive,
  DriveIcon,
  DriveIconActive,
  ReportingIcon,
  ReportingIconActive,
} from './CustomIcons';

import { Box, Collapse, Divider, Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const workspaceListItems = [
  { text: 'Bookmarks', icon: <HomeIcon /> },
  { text: 'Workspace', icon: <InboxIcon /> },
  { text: 'Bookmarks', icon: <HomeIcon /> },
  { text: 'Workspace', icon: <InboxIcon /> },
  { text: 'Bookmarks', icon: <HomeIcon /> },
  { text: 'Workspace', icon: <InboxIcon /> },
];


export default function MenuContent() {
  const navigate = useNavigate();
  const location = useLocation();
  //const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [hoveredIndex, setHoveredIndex] = React.useState(null);
  const [openBookmarks, setOpenBookmarks] = React.useState(false);
  const [openWorkspace, setOpenWorkspace] = React.useState(false);

  //To disable/enable the Inbox tab based on gmail connection status
  const xpGmailIntegrationState = useSelector(store => store.XpGmailIntegration);

  const mainListItems = React.useMemo(() => {
    return [
      { text: 'Home', icon: <HomeIcon />, activeIcon: <HomeIconActive />, route: "/dashboard" },
      // { text: 'Inbox', icon: <InboxIcon />, activeIcon: <InboxIconActive /> },
      //commented route as i have to push code on prod 
      { 
        text: 'Inbox', icon: <InboxIcon />, activeIcon: <InboxIconActive />, route: "/inbox/my-inbox/all", 
        disabled: xpGmailIntegrationState.isGmailConnected ? false: true,
        toolTipText: xpGmailIntegrationState.isGmailConnected ? '': 'Email is not connected!',
      },
      // { text: 'Tasks', icon: <TasksIcon />, activeIcon: <TasksIconActive /> },
      { text: 'Opportunities', icon: <OpportunitiesIcon />, activeIcon: <OpportunitiesIconActive />, route: "/opportunities" },
      // { text: 'Accounts', icon: <AccountsIcon />, activeIcon: <AccountsIconActive /> },
      // { text: 'Contacts', icon: <ContactsIcon />, activeIcon: <ContactsIconActive /> },
      // { text: 'Drive', icon: <DriveIcon />, activeIcon: <DriveIconActive /> },
      // { text: 'Reporting', icon: <ReportingIcon />, activeIcon: <ReportingIconActive /> },
    ];
  }, [xpGmailIntegrationState.isGmailConnected]);

  const selectedIndex = React.useMemo(() => {
    return mainListItems.findIndex((item) => location.pathname.startsWith(item.route));
  }, [location.pathname]);

  const handleClickBookmarks = () => {
    setOpenBookmarks(!openBookmarks);
    setOpenWorkspace(false);
  };

  const handleClickWorkspace = () => {
    setOpenWorkspace(!openWorkspace);
    setOpenBookmarks(false);
  };

  /*   const handleOnClick = (index) => {
      setSelectedIndex(index)
      navigate('/inbox/my-inbox/all')
    }
   */


  const handleOnClick = (index) => {
    // setSelectedIndex(index);
    const selectedRoute = mainListItems[index]?.route; // Get the route from mainListItems
    if (selectedRoute) {
      navigate(selectedRoute); // Navigate to the dynamic route
    }
  };

  return (
    <>
      <Stack>
        <List disablePadding>
          {mainListItems.map((item, index) => (
            <Tooltip title={item.toolTipText ? item.toolTipText: ""} arrow placement='right'>
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  selected={index === selectedIndex}
                  onMouseEnter={() => setHoveredIndex(index)} // Handle hover logic
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleOnClick(index)} // Handle selection logic
                  disabled={item.disabled ? item.disabled : false}
                >
                  <ListItemIcon>
                  {/* hoveredIndex === index || */}
                    { selectedIndex === index ? (
                      item.activeIcon // Active/Selected Icon
                    ) : (
                      item.icon // Default Icon
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>

        <Divider />
      </Stack>
      <Stack
        sx={{
          overflow: 'auto',
          minHeight: 100,
          flexGrow: 1,
        }}
      >
        {/* ---Bookmarks--- */}
        {/* ---commented for now as its not functional--- */}

        {/* <Box>
          <List component="div" disablePadding >
            <ListItem sx={{ p: 0 }}>
              <ListItemButton onClick={handleClickBookmarks}>
                <ListItemIcon>
                  <BookmarksOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Bookmarks" />
              </ListItemButton>
            </ListItem>
          </List>
          <Collapse in={openBookmarks} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {workspaceListItems.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ display: 'block', }}>
                  <ListItemButton sx={{ pl: '40px !important', py: '4px !important', gap: '8px !important' }} >
                    <ListItemIcon ><FiberManualRecordIcon /></ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box> */}
        {/* ---Workspace--- */}
        {/* ---commented for now as its not functional--- */}

        {/* <Box>
          <List component="div" disablePadding >
            <ListItem sx={{ p: 0 }}>
              <ListItemButton onClick={handleClickWorkspace}>
                <ListItemIcon>
                  <FolderOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Workspace" />
              </ListItemButton>
            </ListItem>
          </List>
          <Collapse in={openWorkspace} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding

            >
              {workspaceListItems.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton sx={{ pl: '40px !important', py: '4px !important', gap: '8px !important' }} >
                    <ListItemIcon><FiberManualRecordIcon /></ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box> */}
      </Stack>
    </>
  );
}
