import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Avatar, Button, AvatarGroup, Checkbox, Tooltip } from "@mui/material";
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationItems } from "ReduxStore/Reducers/eijentNotification.reducer";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from "react-redux";
import { Actions } from "ReduxStore/Actions";
import { generateUrl } from "helpers/common";
import { permissionsConstants } from "constants/permissionsConstant";
import { authPermissionValidation } from "../../../helpers/permissionsHelper";
import { createNotification } from "../../../helpers";
import { SuggetionIcon, SuggetionIconLarge } from "../CustomIcons";

interface NotificationItemProps {
  notification: NotificationItems;
}

export default function NotificationItem({ notification }: NotificationItemProps): JSX.Element {
  const dispatch = useDispatch();
  const { name, title, updatedAt, profile, _id, message, link, isRead: initialIsRead, type } = notification;
  const [isRead, setIsRead] = useState<boolean>(initialIsRead);

  const truncatedTitle =
    title.length > 40
      ? title.substring(0, 40) + "..."
      : title;


  const truncatedMessage =
    message.length > 105
      ? message.substring(0, 105) + "..."
      : message;

  const navigate = useNavigate();
  const handleNavigation = (): void => {
    if (authPermissionValidation([permissionsConstants.viewOpportunity])) {
      if (!link) {
        console.error("Navigation failed: link is undefined or empty.");
        return;
      }
      const firstSegment = link.startsWith("/") ? link.split("/")[1] : "";
      if (firstSegment === "opportunity") {
        markNotificationRequest(true);
        navigate(generateUrl(link));
      } else {
        markNotificationRequest(true);
        navigate(link);
      }
    } else {
      createNotification('error', 'You are not authorized to access this opportunity');
    }
  };

  const handleUnreadChange = (): void => {
    const newIsRead = !isRead;
    markNotificationRequest(newIsRead);
  };

  const markNotificationRequest = (isRead: boolean) => {
    setIsRead(!isRead);
    dispatch(Actions.markNotificationRequest({ id: _id, isRead }));
    dispatch(Actions.eijentNotificationListReset({
      resetQuery: 'update',
      resetType: 'notification',
      notification: { id: notification._id, isRead: isRead }
    }));
  };

  return (
    <Box
      // key={index}
      sx={{
        width: 537,
        minWidth: 300,
        // borderRadius:'4px',
        borderBottom: '1px solid #c2cccc',
        // backgroundColor: !isRead ? '#DCE6F7' : 'transparent',
        opacity: isRead ? "0.4" : "1",
        '&:hover ': {
          opacity: '1',
          background: "#e6fdf4 "
        },

        '&:hover .read-checkbox': {
          visibility: 'visible',
        },

      }}
      py={2}
      px={2}
      className={'eijent-notification'}
    >
      <Stack
        direction="row"
        sx={{
          gap: 1,
          alignItems: 'center',
        }}
      >
        <AvatarGroup max={2}>

          <Avatar
            sizes="small"
            alt="Riley Carter"
            sx={{ width: 32, 
              height: 32, 
              fontSize: '10px',
               fontWeight: 400,
                border: 'none !important',
              backgroundColor: type === 'smartNudges' ? 'transparent' : '#94a0b8'
              }}
          >
            {type === 'smartNudges' ?
              <SuggetionIconLarge />
              :
              profile ? (
                <img src={profile} alt="profile" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
              ) : (
                name
                  ? name
                    .split(' ')
                    .slice(0, 2)
                    .map((n) => n.charAt(0).toUpperCase())
                    .join(' ')
                  : ''
              )
            }
          </Avatar>
        </AvatarGroup>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Typography color={ type === 'smartNudges' ? 'primary' : 'textPrimary'} variant="body2">
            <Box component="span" sx={{ fontWeight: 700 }}>
              {name ? name : ""}{" "}
            </Box>
          </Typography>
          <Box
            display="flex"
            gap={1}
          >
            <Typography color={ type === 'smartNudges' ? 'primary' : 'textPrimary'} variant="caption">{moment(updatedAt).format("DD MMM YYYY")}</Typography>
            <Tooltip arrow placement="left"
              title={!isRead ? 'Mark as read' : 'Mark as Unread'}
            >
              <Checkbox
                className="read-checkbox"
                sx={{
                  visibility: 'hidden',
                }}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                checked={isRead}
                onChange={handleUnreadChange}
              />
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
      <Stack spacing={1.4} alignItems={'start'} sx={{ pl: 5, }}>
        <Typography color={ type === 'smartNudges' ? 'primary' : 'textPrimary'} variant="body2" sx={{ fontWeight: 700, lineHeight: '16px', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={handleNavigation}
        >
          {truncatedTitle ? truncatedTitle.charAt(0).toUpperCase() + truncatedTitle.slice(1) : ""}
        </Typography>
        <Typography color={ type === 'smartNudges' ? 'primary' : 'textPrimary'} sx={{ fontSize: '13px', fontWeight: 400, }} >
          {truncatedMessage ? truncatedMessage : ''}
        </Typography>
      </Stack>
    </Box>
  );
}
