import React, { useState, useEffect } from 'react';
import './assets/style.scss';
import { alpha } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// import Header from './components/Header';
import AppTheme from '../eijentTheme/AppTheme';

import { styled, useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
// import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';

import MenuContent from './components/MenuContent';
import { Badge, Button, ButtonGroup, Tab, Tabs } from '@mui/material';
import OptionsMenu from './components/OptionsMenu';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useLocation, useNavigate } from 'react-router';

// ---Header components---

//@ts-ignore
import BlueprintLogo from './assets/images/blueprint-logo.png';
import {
  ArrowRightIcon,
  ArrowLeftIcon,
} from './components/CustomIcons';

import Search from './components/Search';
import NewPopup from './components/New';
import DraftsPopup from './components/Drafts';
import Eijent from './components/Eijent';
import { UserData } from 'ReduxStore/API';

// ---tab---
import PageTabs from './internals/Tabs';
import NotificationPopup from './components/NotificationPopup/NotificationPopup';
import ErrorBoundary from './components/ErrorBoundary';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import { useSelector } from 'react-redux';
import './assets/style.scss';
import EijentCopilotGlobal from './EijentCopilot/EijentCopilotGlobal';
const drawerWidth = 198;
const EijentCopilotWidth = 316;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: `calc(100% - ${drawerWidth}px - ${EijentCopilotWidth}px)`, // Adjust width based on right drawer
    flexGrow: 1,
    display: 'flex',
    zIndex: 1,
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(0.5),
      paddingTop: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
      paddingTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
      paddingTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3.75),
      paddingTop: 0,
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
          paddingLeft: '0 !Important',
        },
      },
    ],
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,


  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: `${drawerWidth}px`,

        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 52,
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(0.5),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(3.75),
  },
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const EijentDashboard = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openEijent, setOpenEijent] = React.useState(false);
  const [delayedOpen, setDelayedOpen] = React.useState(false);
  const [userId, setUserId] = useState(null);
  const handleEijentToggle = () => {
    if (!openEijent) {
      setOpenEijent(true);
      setTimeout(() => setDelayedOpen(true), 100); // 200ms delay
    } else {
      setDelayedOpen(false);
      setTimeout(() => setOpenEijent(false), 30); // Small delay for closing
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // Close the drawer whenever the route changes
  React.useEffect(() => {
    setOpen(true); // Or setOpen(true) if you want to keep it open by default
  }, [location.pathname]);

  React.useEffect(() => {
    getuserdetails();
  });
  const getuserdetails = async () => {
    const userDetails = await UserData();
    const userId = userDetails._id;
    setUserId(userId);
  };

  const getGreetingMessage = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0-23)
    let user = JSON.parse(localStorage.getItem('user_details'));
    let firstName1 = user?.firstName;
    let lastName1 = user?.lastName;
    let firstName = firstName1 ? firstName1.charAt(0).toUpperCase() + firstName1.slice(1).toLowerCase() : '';
    let lastName = lastName1 ? lastName1.charAt(0).toUpperCase() + lastName1.slice(1).toLowerCase() : '';
    if (currentHour < 12) {
      return `Good Morning, ${firstName} ${lastName}`;
    } else if (currentHour < 17) {
      return `Good Afternoon, ${firstName} ${lastName}`;
    } else {
      return `Good Evening, ${firstName} ${lastName}`;
    }
  };

  const getAvtarName = () => {
    let user = JSON.parse(localStorage.getItem('user_details'));
    let firstName = user?.firstName;
    let lastName = user?.lastName;

    let FisrtName = firstName ? firstName?.charAt(0).toUpperCase() : '';
    let LastName = lastName ? lastName?.charAt(0).toUpperCase() : '';
    return `${FisrtName} ${LastName}`;
  };


  const getLoginUserName = () => {
    let user = JSON.parse(localStorage.getItem('user_details'));
    let firstName1 = user?.firstName;
    let lastName1 = user?.lastName;
    let firstName = firstName1 ? firstName1.charAt(0).toUpperCase() + firstName1.slice(1).toLowerCase() : '';
    let lastName = lastName1 ? lastName1.charAt(0).toUpperCase() + lastName1.slice(1).toLowerCase() : '';
    return `${firstName} ${lastName}`;

  };

  const logout = () => {
    let store = ['user_role', 'user_details'];
    store.forEach((item) => localStorage.removeItem(item));

    //window.location.href = Url.wixUrl;
    window.location.href = '/';
  };



  const toggleDesign = () => {
    navigate('/xp/my-events');  // Navigate to the new design route
  };

  const goToSettings = () => {
    navigate('/settings/my-settings');  // Navigate to the new design route
  };

  return (
    <ErrorBoundary>
      <AppTheme {...props} >
        <CssBaseline enableColorScheme />
        <Box sx={{
          display: 'flex',
          backgroundColor: '#DBE0E4',
          height: '100vh',
          // position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(226deg, rgba(85, 121, 250, 0.70) 0%, #CBFFE300 22%) 0% 0% no-repeat padding-box',
            opacity: openEijent ? 1 : 0, // Toggle opacity
            transition: 'opacity 0.3s ease-in-out', // Smooth fade effect
            pointerEvents: 'none', // Ensures clicks pass through
          },

        }} >

          <AppBar position="fixed" color='transparent' elevation={0} open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar
              sx={{
                justifyContent: 'space-between',
                p: {
                  xs: 0.5,
                  sm: 1,
                  md: 2,
                  lg: 3,
                  xl: 3.75,
                },
              }} >
              <Box display={'flex'} alignItems={'center'}>
                <IconButton
                  color="white"
                  variant="contained"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  size='large'
                  sx={[
                    {
                      mr: 4,
                    },
                    open && { mr: `${drawerWidth - 76}px`, },
                    {
                      transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                    }
                  ]}
                >
                  {open ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                </IconButton>

                <img alt="logo" src={BlueprintLogo} />
              </Box>

              <Search />

              <Stack direction={'row'} spacing={1.5} alignItems={'center'}>

                <NewPopup />

                <Stack direction={'row'} spacing={3} alignItems={'center'}>

                  <DraftsPopup />
                  <NotificationPopup />

                  <Eijent onEijentClick={handleEijentToggle} />

                </Stack>
              </Stack>
            </Toolbar>
          </AppBar>

          <Drawer
            PaperProps={{
              sx: {
                backgroundColor: 'transparent',
                border: 'none',
              }
            }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader />

            <MenuContent />

            <Stack
              direction="row"
              sx={{
                py: 2,
                px:3,
                gap: 1,
                alignItems: 'center',
                borderTop: '1px solid',
                borderColor: 'divider',
                cursor: 'pointer',
              }}
              onClick={() => toggleDesign()}
            >
              <SwapHorizIcon
                color='transparent'
                size='small'
                variant='contained'

              ></SwapHorizIcon>
              <Typography variant="body2">
                Go to old design
              </Typography>
              

            </Stack>

            <Stack
              direction="row"
              sx={{
                p: 2,
                gap: 1,
                alignItems: 'center',
                borderTop: '1px solid',
                borderColor: 'divider',
                cursor: 'pointer',
              }}
            >
              <Avatar
                sizes="small"
                alt="Riley Carter"
                src="/static/images/avatar/7.jpg"
                sx={{ width: 36, height: 36, fontSize: '12px', fontWeight: 400, }}
                onClick={() => goToSettings()}
              > {getAvtarName()}
              </Avatar>
              <Box sx={{ mr: 'auto' }} onClick={() => goToSettings()}>
                <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
                  {getLoginUserName()}
                </Typography>
              </Box>
            </Stack>
          </Drawer>

          <Main open={open}
            sx={{
              marginRight: !openEijent ? `-${EijentCopilotWidth}px` : 0, // Adjust width based on right drawer
            }}
          >
            <DrawerHeader />
            {props.children}
          </Main>
          {/* add right drawer here */}
          <Drawer
            PaperProps={{
              sx: {
                backgroundColor: 'transparent', // Adjust color as needed
                border: 'none',
                width: EijentCopilotWidth, // Adjust width
              },
            }}
            sx={{
              width: EijentCopilotWidth,
              flexShrink: 0,
              transitionDelay: '1s',
              '& .MuiDrawer-paper': {
                width: EijentCopilotWidth,
                paddingRight: 1.25,
                paddingBottom: 3.75,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="right"
            // open={openEijent}
            open={delayedOpen}
          >
            <DrawerHeader />
            {/* Add your right drawer content here */}
            <EijentCopilotGlobal userId={userId} />

          </Drawer>
        </Box>
      </AppTheme>
    </ErrorBoundary>
  );
};

export default EijentDashboard;

