
import { useEffect, useState } from "react";


import { Box, Typography, Stack, Button, Checkbox, IconButton, TableSortLabel, Tooltip } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from "react-redux";

import {

  CheckAllIcon,
  UnCheckAllIcon,
  DeleteIcon,
  InfoIcon
} from '../../../components/CustomIcons.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from "react";
import { Actions } from "ReduxStore/Actions/index.js";
import { JourneyState } from "ReduxStore/Reducers/journey.reducer.js";
// import PaginationFunctional from "eijent/components/Pagination/Pagination.jsx";
import PaginationFunctional, { handleEndCountForPagination } from 'eijent/components/Pagination/Pagination';
import Loader from "Components/Loader/loader.js";
import ConfirmationAlertPopUp from "Common/ConfirmationAlertPopUp.js";
import { generateUrl } from "helpers/common.js";
import { useNavigate } from "react-router";
import moment from 'moment';
import CustomTooltip from 'Components/CustomHTMLTooltip';


function createData(agentName: string,
  description: string,
  createdOn: string,) {
  return { agentName, description, createdOn, };
}
const rows = [
  createData('Registration Email', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eleifend vestibulum rhoncus.', 'February 12th 2025, 1:36 am',),
  createData('Password Reset', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'January 20th 2025, 10:22 pm',),

];

const tableHeadStyle: React.CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '10px',
  fontSize: '12px',
  lineHeight: 1,
  color: '#c1c1c1',
  borderColor: '#C1C1C1',
  backgroundColor: '#ffffff',
};

export default function MyAgents() {
  const { journeyList, totalResult, getJourneyListSuccess, loading, deleteJourneySuccess } = useSelector((state: any) => state.journey) as JourneyState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [limit, setLimit] = useState<number>(10);
  const [pageNo, setPageNo] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [selected, setSelected] = useState<string[]>([]);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const [confirmationJourney, setConfirmationJourney] = useState<null | { workflowId: string; status: string }>(null);


  // Determine if all rows are selected
  const allSelected =
    journeyList && journeyList.length > 0 && selected.length === journeyList.length;

  const getJourneyList = () => {
    let params = {
      pageNo: pageNo,
      limit,
      sortBy,
      order,
    };
    dispatch(Actions.getJourneyListRequest(params));

  };

  // Fetch journey list when component mounts or when page, limit, sortBy, or order changes
  useEffect(() => {
    getJourneyList();
    if (deleteJourneySuccess) {
      setSelected([]);
    }
  }, [pageNo, limit, sortBy, order, deleteJourneySuccess]);

  // Handler for sorting by a given field
  const handleSort = (field: string) => {
    if (sortBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setOrder("asc");
    }
  };

  // Update pagination endCount and startCount when journeyList loads
  useEffect(() => {
    if (getJourneyListSuccess) {
      handleEndCountForPagination(totalResult, limit, pageNo, setEndCount);
      setStartCount((pageNo - 1) * limit + 1);
    }
  }, [getJourneyListSuccess, totalResult, limit, pageNo]);

  // Handler for toggling select all
  const handleSelectAll = () => {
    if (allSelected) {
      setSelected([]);
    } else {
      const allIds = journeyList.map((journey: any) => journey._id);
      setSelected(allIds);
    }
  };

  // Handler for selecting individual rows
  const handleSelect = (id: string) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  // Close the delete confirmation modal
  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  // Delete the selected journeys and close the modal
  const handleDeleteMultiple = () => {
    if (selected.length > 0) {
      dispatch(Actions.deleteJourneyRequest({ journeyId: selected }));
      // setSelected([]);
    }
    setOpenDeleteConfirmationModel(false);
  };

  const handleNavigation = (journey: any) => {
    if (journey.status === "published") {
      setShowConfirmationModal(true);
      setPendingNavigation(`/settings/my-eijents/${journey.uniqueEijentId}`);
    } else {
      navigate(`/settings/my-eijents/${journey.uniqueEijentId}`);
    }
  };

  const updateAndExit = () => {
    setShowConfirmationModal(false);
    if (pendingNavigation) {
      navigate(pendingNavigation);
      setPendingNavigation(null);
    }
  };

  const discardAndExit = () => {
    setShowConfirmationModal(false);
    setPendingNavigation(null);
  };

  const handlePublishUnpublishClick = (workflowId: string, status: string) => {
    setConfirmationJourney({ workflowId, status });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        height: '100%',
        maxHeight: '100%',
        borderRadius: '8px',
        overflow: 'auto'
      }}
    >
      <Stack sx={{
        height: '100%',
        maxHeight: '100%',
      }}>
        {/* --Select and menu-- */}
        {selected.length && selected.length > 0 ? (
          <Stack px={1.5} borderBottom={'1px solid #C1C1C1'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack spacing={2.75} py={1.5} px={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

              <>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                  <Checkbox
                    checked={allSelected}
                    onChange={handleSelectAll}
                    icon={<UnCheckAllIcon />}
                    checkedIcon={<CheckAllIcon />}
                  />
                  <Typography>
                    {selected.length > 0
                      ? `${selected.length}`
                      : `${journeyList?.length || ''}`}
                  </Typography>
                </Box>

                <IconButton size="small" variant='contained'
                  // @ts-ignore
                  color="transparent"
                  onClick={() => setOpenDeleteConfirmationModel(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </>



              {/* 
            <IconButton size="small" variant='contained'
              // @ts-ignore
              color="transparent">
              <OpportunitiesIcon />
            </IconButton>

            <IconButton size="small" variant='contained'
              // @ts-ignore
              color="transparent">
              <CancelIcon />
            </IconButton>

            <IconButton size="small" variant='contained'
              // @ts-ignore
              color="transparent">
              <MoreIcon />
            </IconButton> */}

            </Stack>
          </Stack>
        ) : ''}

        {/* --table list-- */}
        <Box flexGrow={1} overflow={'auto'}>
          <TableContainer sx={{ maxHeight: '100%' }} >
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="Email List">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ ...tableHeadStyle, width: '44px', pl: 4 }} ></TableCell>
                  <TableCell sx={{ ...tableHeadStyle, }}>
                    <TableSortLabel
                      IconComponent={KeyboardArrowDownIcon}
                      active={sortBy === "uniqueEijentId"}
                      direction={sortBy === "uniqueEijentId" ? order : "asc"}
                      onClick={() => handleSort("uniqueEijentId")}
                    >
                      Eijent ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ ...tableHeadStyle, }}>
                    <TableSortLabel
                      IconComponent={KeyboardArrowDownIcon}
                      active={sortBy === "title"}
                      direction={sortBy === "title" ? order : "asc"}
                      onClick={() => handleSort("title")}
                    >
                      Eijent Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ ...tableHeadStyle, }}>
                    <TableSortLabel
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      Description
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ ...tableHeadStyle, }}>
                    <TableSortLabel
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      Updated On
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ ...tableHeadStyle, }}>
                  </TableCell>
                  <TableCell sx={{ ...tableHeadStyle, }}>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(journeyList) && journeyList.length > 0 ?
                  (journeyList.map((journey) => (
                    <TableRow
                      // @ts-ignore
                      key={journey._id}

                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { backgroundColor: '#E6FDF4' },
                        px: 2.25,
                      }}
                    >
                      <TableCell sx={{ width: '44px', pl: 4 }} >
                        <Checkbox
                          checked={selected.includes(journey._id)}
                          onChange={() => handleSelect(journey._id)}
                        />
                      </TableCell>
                      <TableCell

                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleNavigation({ status: journey.status, uniqueEijentId: journey.uniqueEijentId })}
                      >
                        <Typography color="primary">{journey.uniqueEijentId || ''}
                        </Typography>
                      </TableCell>

                      {/* <TableCell
                        onClick={() => handleNavigation(journey.uniqueEijentId)}
                      >
                        <Box display="flex" alignItems="" gap={1}>
                          <Tooltip placement="bottom-start" title={journey.title} arrow>
                            <Box>
                              <InfoIcon />

                            </Box>
                          </Tooltip>
                          {journey.title && journey.title.length > 25 ? (

                            <Typography color="primary" sx={{ cursor: 'pointer' }}>{journey.title.substring(0, 25) + "..."}</Typography>

                          ) : (
                            <Typography color="primary" sx={{ cursor: 'pointer' }}>{journey.title || ''}</Typography>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box display="flex" alignItems="" gap={1}>
                          <Tooltip placement="bottom-start" title={journey.description} arrow>
                            <Box>
                              <InfoIcon />

                            </Box>
                          </Tooltip>
                          {journey.description && journey.description.length > 25 ? (

                            <span>{journey.description.substring(0, 25) + "..."}</span>

                          ) : (
                            <span>{journey.description || ''}</span>
                          )}
                        </Box>
                      </TableCell> */}

                      <TableCell onClick={() => handleNavigation({ status: journey.status, uniqueEijentId: journey.uniqueEijentId })}>
                        <Box display="flex" alignItems="center" gap={1}>
                          {journey.title && journey.title.length > 25 ? (
                            <Tooltip placement="bottom-start" title={journey.title} arrow>
                              <Typography color="primary" sx={{ cursor: 'pointer' }}>
                                {journey.title.substring(0, 25) + "..."}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography color="primary" sx={{ cursor: 'pointer' }}>
                              {journey.title || ''}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box display="flex" alignItems="center" gap={1}>
                          {journey.description && journey.description.length > 25 ? (
                            <Tooltip placement="bottom-start" title={journey.description} arrow>
                              <span>{journey.description.substring(0, 25) + "..."}</span>
                            </Tooltip>
                          ) : (
                            <span>{journey.description || ''}</span>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {' '}
                        {journey.updatedAt
                          ? moment(journey.updatedAt).format('MMM, Do YYYY, h:mm A')
                          : ''}
                      </TableCell>
                      <TableCell align="right"><Button variant="contained" onClick={() =>
                        dispatch(Actions.testJourney({ workflowId: journey._id }))
                      } size="small" color="primary">Run</Button></TableCell>
                      <TableCell align="left">
                        <Button variant="contained"
                          onClick={() => handlePublishUnpublishClick(journey._id, journey.status === "published" ? "unpublished" : "published")}
                          size="small" color="primary">
                          {journey.status === "published" ? "Unpublish" : "Publish"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                  )
                  ) : (
                    <TableRow style={{ cursor: 'pointer' }}>
                      <TableCell sx={{ textAlign: 'center' }} colSpan={5}>
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box py={2}>
          {journeyList?.length !== 0 && (

            <PaginationFunctional
              startCount={startCount}
              setStartCount={setStartCount}
              endCount={endCount}
              limit={limit}
              setLimit={setLimit}
              page_no={pageNo}
              setPage_no={setPageNo}
              listItemsFn={getJourneyList}
              totalItemsCount={totalResult}
            />
          )}

          <ConfirmationAlertPopUp
            openModal={openDeleteConfirmationModel}
            closeModalFunc={closeDeleteConfirmationModel}
            title={'Delete Confirmation!'}
            text={'Are you sure you want to delete it?'}
            confirmationButtonText={'Delete'}
            confirmationButtonColor="secondary"
            closeButtonText={'Cancel'}
            functionality={() => handleDeleteMultiple()}
          />

          <ConfirmationAlertPopUp
            openModal={showConfirmationModal}
            closeModalFunc={discardAndExit}
            title={'Confirmation Unpublish'}
            text={'Are you sure you want to Unpublish the Eijent before editing? A Published Eijent cannot be edited'}
            confirmationButtonText={'Unpublish'}
            confirmationButtonColor="secondary"
            closeButtonText={'Cancel'}
            functionality={() => updateAndExit()}
          />
          {/* Publish/Unpublish Confirmation Modal */}
    <ConfirmationAlertPopUp
      openModal={!!confirmationJourney}
      closeModalFunc={() => setConfirmationJourney(null)}
      title={`Confirmation ${confirmationJourney?.status !== "published" ? "Unpublish" : "Publish"}`}
      text={`Are you sure you want to ${confirmationJourney?.status !== "published" ? "Unpublish" : "Publish"} this Eijent?`}
      confirmationButtonText={confirmationJourney?.status !== "published" ? "Unpublish" : "Publish"}
      confirmationButtonColor="secondary"
      closeButtonText="Cancel"
      functionality={() => {
        if (confirmationJourney) {
          dispatch(Actions.updateStatusRequest({
            workflowId: confirmationJourney.workflowId,
            status: confirmationJourney.status
          }));
        }
        setConfirmationJourney(null);
      }}
    />
        </Box>
      </Stack >
      {loading && <Loader />}
    </Box >
  );
}