//@ts-nocheck
import React from 'react'
import { CustomField } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, OutlinedInput } from '@mui/material';
import { CustomFieldsObj } from '../Opportunity/AddOrEditOpportunity';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type Props = {
  cf: CustomField;
  customFieldsObj: CustomFieldsObj;
  handleCustomFieldsObj: (e: any, id: string, fieldType?: string, isMultiSelect?: boolean) => void;
};

export default function DropdownForListDataTypeCF({ cf, customFieldsObj, handleCustomFieldsObj }: Props) {
  const handleChange = (e: SelectChangeEvent) => {
    try {
      handleCustomFieldsObj(e, cf._id as string, cf.fieldData.fieldType, cf.fieldData.isMultiSelect);
    } catch (error) {
      console.error("Error handling custom fields:", error);
    }
  };

  const isMultiSelect = cf.fieldData.isMultiSelect;
  const selectedValue = customFieldsObj?.[cf._id as string];

  // Ensure that selectedValue is treated as an array for multi-select
  const valueToDisplay = isMultiSelect 
    ? (Array.isArray(selectedValue) ? selectedValue : (selectedValue ? [selectedValue] : []))
    : (selectedValue || '');

  return (
    <FormControl size="small" fullWidth>
      <Select
        type="text"
        className="form-control select small"
        // placeholder="select"
        value={valueToDisplay}
        name="fieldType"
        onChange={handleChange}
        style={{ padding: '4px 0px' }}
        MenuProps={MenuProps}
        displayEmpty
        multiple={isMultiSelect}
        input={isMultiSelect ? <OutlinedInput /> : undefined}
        renderValue={(selected) => {
          if (isMultiSelect) {
            return (selected as string[])?.length === 0 ? 'Select' : (selected as string[])?.join(', ') || '';
          }
          return (selected as string) || 'Select';
        }}
      >
        {!isMultiSelect && (
          <MenuItem value="" disabled hidden>
            Select
          </MenuItem>
        )}
        {Array.isArray(cf.fieldData.dropdownValues) && cf.fieldData.dropdownValues.length > 0 &&
          cf.fieldData.dropdownValues.map((dropdownValue) => (
            <MenuItem
              key={dropdownValue}
              value={dropdownValue}
            >
              {isMultiSelect ? (
                <>
                  <Checkbox 
                    color='primary' 
                    checked={Array.isArray(selectedValue) && selectedValue.includes(dropdownValue)} 
                  />
                  {dropdownValue}
                </>
              ) : (
                dropdownValue
              )}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
