import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Stack, Stepper, Step, StepLabel, CircularProgress } from "@mui/material";
import {
  ArrowLeftIcon,
  ReportingIconActive,
  CancelIcon,
  ArrowRightWhiteIcon,
} from "../components/CustomIcons";
import { useTheme } from '@mui/material/styles';
import InitialData from "./InitialData";
import JourneySummary from "./JourneySummary";
import JourneyWorkflow from "./JourneyWorkflow";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "ReduxStore/Actions";
import { JourneyState } from "ReduxStore/Reducers/journey.reducer";
import { useLocation, useNavigate } from "react-router-dom";
import EijentChatWidget from "eijent/EijentCopilot/EijentChatWidget";
import { UserData } from "helpers/common";

const steps = ["Type", "Initial journey data", "Define Workflow", "Confirmation"];

const NewJourney: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { createJourneySuccess, loading, workflow, getJourneySuccess,generateBasicInfoSuccess ,message } = useSelector((state: any) => state.journey) as JourneyState;
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [skipped, setSkipped] = useState<Set<number>>(new Set());
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [initialName, setInitialName] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [errors, setErrors] = useState<{ generic?: string; name?: boolean; description?: boolean; }>({});
  const [isEditingExistingJourney, setIsEditingExistingJourney] = useState(false);
  const [userId, setUserId] = useState<string>('');
  const [isNextDisabled, setIsNextDisabled] = useState(true); // Controls Next button
  const [isModifiedManually, setIsModifiedManually] = useState({ name: false, description: false});
  // const isEdit = location.pathname === "/settings/my-eijents/edit";
  let isEdit = location.pathname.startsWith("/settings/my-eijents/");
  const eijentId = location.pathname.split('/').pop();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 200px)",
    height: "calc(100vh - 60px)",
    bgcolor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up('xs')]: { width: "calc(100% - 100px)", height: "calc(100vh - 40px)" },
    [theme.breakpoints.up('lg')]: { width: "calc(100% - 200px)", height: "calc(100vh - 60px)" },
  };

  const handleOpen = () => {
    setOpen(true);
  }
  
  const handleClose = () => {
    // Clear the name and description state values when closing
    setName('');
    setDescription('');
    setActiveStep(0);
    setIsModifiedManually({ name: false, description: false });  // Reset modifications
    setIsNextDisabled(true);
    setIsEditingExistingJourney(false);

    // If editing, navigate to '/settings/my-eijents'
    if (isEdit) {
      navigate('/settings/my-eijents');
    }

    // Close the modal
    setOpen(false);
    setErrors({});
    dispatch(Actions.resetState());
  };


  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {

    if (activeStep === 0) {
        interface JourneyParams {
        _id: string;
      }

      let bodyParams = {
        _id: workflow._id,
       
      };
     
      dispatch(Actions.generateBasicInfoRequest(bodyParams));

      // Do NOT move to Step 2 yet. Wait for createJourneySuccess.
      return;
    }
    if (activeStep === 1) {
      let newErrors = { generic: "", name: false, description: false };

      // Check if either field is empty
      if (name.trim().length === 0 || description.trim().length === 0) {
        newErrors.generic = "Please fill in all mandatory fields before proceeding.";
        newErrors.name = name.trim().length === 0;
        newErrors.description = description.trim().length === 0;
        setErrors(newErrors);
        return;
      }

     
      if (name.trim() && name.length >0 && name.length === 60) {
        setErrors({ generic: "Maximum 60 characters reached", name: true, description: false });
        return;
      }
      setErrors({}); // Clear errors if everything is valid
      if (name.trim() !== initialName.trim() || description.trim() !== initialDescription.trim()) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      interface JourneyParams {
        title: string;
        _id?: string;
        description: string;
        isModifiedManually: { name: boolean; description: boolean }; // Track modifications for both title and description
      }

      let bodyParams: JourneyParams = {
        title: name,
        description: description,
        _id: workflow._id ,
        isModifiedManually:isModifiedManually,
      };
     
      dispatch(Actions.createJourneyRequest(bodyParams));

      // Do NOT move to Step 2 yet. Wait for createJourneySuccess.
      return;
    }
  }

  if(name.trim() !== initialName.trim() || description.trim() !== initialDescription.trim()){
    // Allow navigation only if createJourneySuccess is true when moving to Step 2
    if (activeStep === 2 && !createJourneySuccess) return;
  }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 3) {
      handleClose();
      return;
  }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
 

  useEffect(() => {
    if (createJourneySuccess && activeStep === 1) {
      setActiveStep(2);
    }
    if (generateBasicInfoSuccess && activeStep === 0) {
      setActiveStep(1);
    }
  }, [createJourneySuccess,generateBasicInfoSuccess]);

  useEffect(() => {
    if(createJourneySuccess === false && message === 'Eijent name already exists'){
      setErrors({ generic: message, name: true, description: false });
    }
  }, [message]);


  const handleBack = () => {
    if (activeStep === 3 || activeStep === 2) {
      setIsEditingExistingJourney(true);
    }
    setActiveStep((prev) => prev - 1);
  }

  const clearErrors = () => {
    setErrors({});
  };

  useEffect(() => {
   const userData= JSON.parse(localStorage.getItem('user_details'));
    if (isEdit) {
      dispatch(Actions.getJourneyRequest({ eijentId: eijentId }));
    }
  }, [generateBasicInfoSuccess]);

  useEffect(() => {
    if (name && name.length >0 && (name.length === 60|| name.length > 60 )) {
      setErrors({ generic: "Maximum 60 characters reached", name: true, description: false });
    }else if(name.length < 60){
      setErrors({ generic: "", name: false, description: false });
    }
  }, [name]);


  useEffect(() => {
    if (isEdit || generateBasicInfoSuccess) {
      setName(workflow?.title);
      setDescription(workflow?.description);
      setInitialName(workflow?.title || ""); // Store initial name
      setInitialDescription(workflow?.description || ""); // Store initial description
      setOpen(true);
      setIsNextDisabled(false);
      setIsModifiedManually(workflow?.isModifiedManually);
      dispatch(Actions.getJourneySuccess(workflow));
    }
  }, [workflow,generateBasicInfoSuccess]);


  useEffect(() => {
    if (isEdit) {
   {
        setActiveStep(1);
      }
    }
  }, []);



  const getuserdetails = async () => {
      const userDetails = await UserData();
      const userId = userDetails._id;
      setUserId(userId);
    };

  useEffect(() => {
    getuserdetails();
  }, []);

  const handleMessageUpdate = (messages) => {
    const userMessageCount = messages.filter(msg => msg.sender === "user").length;
    const assistantMessageCount = messages.filter(msg => msg.sender === "assistant").length;
  
    // Enable Next button only if both user and assistant have at least 3 messages
    setIsNextDisabled(!(userMessageCount >= 3 && assistantMessageCount >= 3 && activeStep === 0));
  };

  return (
    <div>
      <Box onClick={handleOpen} sx={{ width: 158, cursor: "pointer", transitionDuration: "200ms", "&:hover": { backgroundColor: "rgb(0 0 0 / 5%)" } }} pl={4} pr={2} py={1.5} className="new-list-item">
        <Stack direction="row" alignItems="center" gap={2.25}>
          <ReportingIconActive />
          <Typography variant="subtitle2" sx={{ fontWeight: 400, lineHeight: "16px", color: "#fff" }}>Eijent</Typography>
        </Stack>
      </Box>

      <Modal open={open} aria-labelledby="modal-title" slotProps={{ backdrop: { timeout: 200, sx: { backdropFilter: "blur(4px)", backgroundColor: "rgba(255, 255, 255, 0.5)" } } }}>
        <Box sx={modalStyle}>
          <Stack pl={4} pr={2} py={2} borderBottom="1px solid #D9D9D9" direction="row" alignItems="center" justifyContent="space-between">
            <Typography id="modal-title" variant="h2">{isEdit ? 'Edit Eijent': 'New Eijent'}</Typography>
            <IconButton size="small" onClick={handleClose}><CancelIcon /></IconButton>
          </Stack>

          <Box flexGrow={1} sx={{ overflow: 'auto' }}>
            {activeStep === 0 && <EijentChatWidget onMessageUpdate={handleMessageUpdate} />}
            {activeStep === 1 && <InitialData
              name={name}
              description={description}
              setName={(value) => {
                setName(value); 
                setIsModifiedManually((prevState) => ({ ...prevState, name: true }));  // Mark name as modified
              }}
              setDescription={(value) => {
                setDescription(value); 
                setIsModifiedManually((prevState) => ({ ...prevState, description: true }));  // Mark description as modified
              }}
              errors={errors}
              clearErrors={clearErrors}
              isModifiedManually={isModifiedManually}  // Pass entire modification state
            />
            }
            {activeStep === 2 && <JourneyWorkflow />}
            {activeStep === 3 && <JourneySummary />}
          </Box>

          <Box sx={{ p: 2.5, display: "flex", alignItems: "center", justifyContent: "center", borderTop: "1px solid #D9D9D9" }}>
            <Stack direction="row" gap={1.5}>
              {!(activeStep === 0 || (isEdit && activeStep === 1)) && (
                <IconButton size="large" onClick={handleBack}>
                  <ArrowLeftIcon />
                </IconButton>
              )}
              <Button
                size="large"
                color="primary"
                disabled={loading || isNextDisabled} // Disable if loading
                variant="contained"
                sx={{ minWidth: 115, justifyContent: "space-between" }}
                endIcon={
                  loading  ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    activeStep !== 3 && <ArrowRightWhiteIcon />
                  )
                }
                onClick={handleNext}
              >
                {loading ? "Processing..." : activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
              {/* <Button size="large" color="primary" disabled={activeStep === 3} variant="contained" sx={{ minWidth: 115 }} endIcon={<ArrowRightWhiteIcon />} onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button> */}
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default NewJourney;
