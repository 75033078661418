import { createReducer } from 'reduxsauce';
import { Constants } from '../Actions';


// Define types for NotificationItem and State
export interface ChatMessage {
  role: 'system' | 'user' | 'assistant';
  content: string;
  timestamp: Date;
}

export interface Workflow {
  _id?: string;
  uniqueEijentId?: string;
  title: string;
  description: string;
  stateMachine?: any;
  sourceId?: string;
  copilotHistory?: ChatMessage[];
  status: "published" | "unpublished";
  source?: string;
  userId?: string;
  isDeleted?: Boolean;
  updatedAt?: string;
  isModifiedManually?: { name: boolean; description: boolean }; // Object for tracking manual modifications for each field
}
// Define types for JourneyState
export interface JourneyState {
  error: boolean;
  message: string | null;
  createJourneySuccess: boolean;
  getJourneySuccess: boolean;
  getJourneyListSuccess: boolean;
  deleteJourneySuccess: boolean;
  generateBasicInfoSuccess:boolean;
  loading: boolean;
  workflow: Workflow;
  copilotStatus: string;
  journeyList: Workflow[];
  totalResult: number;
}

// Initial state with proper type
const INITIAL_STATE: JourneyState = {
  error: false,
  message: null,
  createJourneySuccess: false,
  getJourneySuccess: false,
  getJourneyListSuccess: false,
  deleteJourneySuccess: false,
  generateBasicInfoSuccess:false,
  loading: false,
  workflow: {
    title: "",
    description: "",
    status: "unpublished"
  },
  copilotStatus: "",
  journeyList: [],
  totalResult: 0,
};


// Reducer handlers with type annotations
const createJourneyRequest = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: true,
    createJourneySuccess: false,
    error: false,
    message: null,
  };
};


const createJourneySuccess = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    createJourneySuccess: true,
    error: false,
    workflow: action.workflow
  };

};


const createJourneyError = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    createJourneySuccess: false,
    error: true,
    message: action.error,
  };
};


// Reducer handlers with type annotations
const getJourneyRequest = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: true,
    getJourneySuccess: false,
    error: false,
    message: null
  };
};

const getJourneySuccess = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    getJourneySuccess: true,
    error: false,
    copilotStatus: "",
    workflow: action.workflow
  };
};

const getJourneyError = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    getJourneySuccess: false,
    error: true,
    message: action.error,
  };
};
const updateCopilotStatus = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    copilotStatus: action.status,
  };
};
const updateStateMachine = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    workflow: { ...state.workflow, stateMachine: action.stateMachine }
  };
};
const updateExplanation = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    workflow: {
      ...state.workflow, stateMachine: {
        ...state.workflow.stateMachine,
        explanation: action.explanation,
      }
    }
  };
};
const getJourneyListRequest = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: true,
    getJourneyListSuccess: false,
    error: false,
    message: null,
    journeyList: [],
    totalResult: 0,
  };
};

const getJourneyListSuccess = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    getJourneyListSuccess: true,
    error: false,
    journeyList: action.journeyList,
    totalResult: action.totalResult
  };
};

const getJourneyListError = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    getJourneyListSuccess: false,
    error: true,
    journeyList: [],
    message: action.error,
  };
};


const deleteJourneyRequest = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: true,
    deleteJourneySuccess: false,
    error: false,
    message: null,
  };
};

const deleteJourneySuccess = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    deleteJourneySuccess: true,
    error: false,
  };
};

const deleteJourneyError = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    deleteJourneySuccess: false,
    error: true,
    message: action.error,
  };
};

const testJourney = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return state;
};
const clearWorkflowHistorySuccess = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  console.log("A C C C", action);
  if (action?.success) {
    return {
      ...state, workflow: {
        ...state.workflow, copilotHistory: INITIAL_STATE.workflow.copilotHistory,
        stateMachine: INITIAL_STATE.workflow.stateMachine
      }
    };
  }
  return state;
};
const clearWorkflowHistory = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return state;
};

const updateStatusRequest = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return state;
};
const updateStatusSuccess = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  const { _id, status } = action.data;
  const index = state.journeyList.findIndex((j) => j._id === _id);
  if (index > -1) {
    return {
      ...state,
      journeyList: state.journeyList.map((j, i) =>
        i === index ? { ...j, status } : j
      ),
    };
  }

  return state;
};


// Reducer handlers with type annotations
const generateBasicInfoRequest = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: true,
    generateBasicInfoSuccess: false,
    error: false,
    message: null,
  };
};


const generateBasicInfoSuccess = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    generateBasicInfoSuccess: true,
    error: false,
    workflow: action.workflow
  };

};


const generateBasicInfoError = (
  state: JourneyState = INITIAL_STATE,
  action: any
): JourneyState => {
  return {
    ...state,
    loading: false,
    generateBasicInfoSuccess: false,
    error: true,
    message: action.error,
  };
};

const resetState = (state: JourneyState = INITIAL_STATE): JourneyState => {
  return {
    ...INITIAL_STATE, // Reset all state properties
    journeyList: state.journeyList, // Preserve journeyList
    totalResult: state.totalResult, // Preserve totalResult
    getJourneySuccess: state.getJourneySuccess, // Preserve getJourneySuccess
    getJourneyListSuccess: state.getJourneyListSuccess, // Preserve getJourneyListSuccess
    deleteJourneySuccess: state.deleteJourneySuccess, // Preserve deleteJourneySuccess
  };
};

const HANDLERS = {
  [Constants.GENERATE_BASIC_INFO_REQUEST]: generateBasicInfoRequest,
  [Constants.GENERATE_BASIC_INFO_SUCCESS]: generateBasicInfoSuccess,
  [Constants.GENERATE_BASIC_INFO_ERROR]: generateBasicInfoError,
  [Constants.CREATE_JOURNEY_REQUEST]: createJourneyRequest,
  [Constants.CREATE_JOURNEY_SUCCESS]: createJourneySuccess,
  [Constants.CREATE_JOURNEY_ERROR]: createJourneyError,
  [Constants.GET_JOURNEY_REQUEST]: getJourneyRequest,
  [Constants.GET_JOURNEY_SUCCESS]: getJourneySuccess,
  [Constants.UPDATE_COPILOT_STATUS]: updateCopilotStatus,
  [Constants.GET_JOURNEY_ERROR]: getJourneyError,
  [Constants.GET_JOURNEY_LIST_REQUEST]: getJourneyListRequest,
  [Constants.GET_JOURNEY_LIST_SUCCESS]: getJourneyListSuccess,
  [Constants.GET_JOURNEY_LIST_ERROR]: getJourneyListError,
  [Constants.DELETE_JOURNEY_REQUEST]: deleteJourneyRequest,
  [Constants.DELETE_JOURNEY_SUCCESS]: deleteJourneySuccess,
  [Constants.DELETE_JOURNEY_ERROR]: deleteJourneyError,
  [Constants.TEST_JOURNEY]: testJourney,
  [Constants.UPDATE_STATE_MACHINE]: updateStateMachine,
  [Constants.UPDATE_EXPLANATION]: updateExplanation,
  [Constants.CLEAR_WORKFLOW_HISTORY]: clearWorkflowHistory,
  [Constants.CLEAR_WORKFLOW_HISTORY_SUCCESS]: clearWorkflowHistorySuccess,
  [Constants.UPDATE_STATUS_REQUEST]: updateStatusRequest,
  [Constants.UPDATE_STATUS_SUCCESS]: updateStatusSuccess,
  
  // reset handler
  [Constants.RESET_STATE]: resetState,

};

export default createReducer(INITIAL_STATE, HANDLERS);