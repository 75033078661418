import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Login from '../Pages/Login';
import { Url, showLogin } from '../constants/constants';
import { UserData } from 'helpers/common';
import withCustomRouter from '../Common/withCustomRouter';
import ForgotPassword from '../Pages/ForgotPassword';
import Register from '../Pages/Register';
import ConfirmAccount from '../Pages/ConfirmAccount';
import ResetPassword from '../Pages/ResetPassword';
import Home from 'eijent/internals/Home';
import PrivateUserRoute from './privateUserRoutes';
// import PageNotFound from 'Components/PageNotFound';
import PageNotFound from 'eijent/components/PageNotFound404';
import AiChatbot from 'Pages/AIChatbot';
import FileUpload from 'Pages/Files/FileUpload';
import { GmailProvider } from '../eijent/internals/Inbox/context/GmailContext';
import InboxRoutesWrapper from './InboxRoutesWrapper';
import { NotificationContainer } from 'react-notifications';
import TransactionEmailEditor from '../Pages/ProjectDashboard/ManagePage/transactionEmailEditor';
import OpportunityTransactionEmailEditor from 'Pages/Opportunity/Opportunity/OpportunityTransactionEmailEditor';
import Opportunities from 'eijent/internals/opportunityManagement/Opportunity/List/Opportunities';
import OpportunityOpportunitiesContextProvider from 'Contexts/OpportunityOpportunitiesProvider';
import { createNotification } from 'helpers';
import { xpAccountIntegrationGmailGetGmailId } from 'ReduxStore/API';
import Settings from 'eijent/internals/Settings';
import TransactionalEmailList from 'eijent/internals/Settings/TransactionalEmail/TransactionalEmailList';
import Profile from 'eijent/internals/Settings/Profile';
import MyAgents from 'eijent/internals/Settings/MyAgents/MyAgentsList';
import FieldManagement from 'eijent/internals/Settings/FieldManagement/FieldManagementList';
import StateManagement from 'eijent/internals/Settings/StateManagement/StateManagementList';
import NewJourney from 'eijent/NewJourney';
import TransactionalEmailView from 'eijent/internals/Settings/TransactionalEmail/TransactionalEmailView';
import EijentDashboard from 'eijent';
import { FilterTypesProvider } from 'eijent/internals/Filters/utils/FilterTypesContext';

class EijentRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.changeXpUsersWebSocketConnectionStatus =
      this.changeXpUsersWebSocketConnectionStatus.bind(this);
    this.handleWebSocketMessage = this.handleWebSocketMessage.bind(this);

    this.signalInterval = null;
    this.state = {
      initialFunctionsCalledAfterLoggedIn: false,
    };
  }

  componentDidMount() {
    if (UserData()) {
      this.setState({ initialFunctionsCalledAfterLoggedIn: true });
      this.startWSConnectionProcess();
      this.checkAndGetGmailIntegrationData();
      this.props.fetchUserPreferencesRequest(); 
    }
  }

  componentDidUpdate(prevProps) {
    if (UserData() && !this.state.initialFunctionsCalledAfterLoggedIn) {
      this.setState({ initialFunctionsCalledAfterLoggedIn: true });
      this.startWSConnectionProcess();
      this.checkAndGetGmailIntegrationData();
      this.props.fetchUserPreferencesRequest();
    }
  }

  startWSConnectionProcess() {
    const user = UserData();
    this.connectWS(user.idToken);
  }

  connectWS(token) {
    let webSocketUrl = process.env.REACT_APP_AWS_USER_WEBSOCKET_URL;
    if (token) {
      webSocketUrl += `?xpToken=bearer ${token}`;
    }
    let socket = new WebSocket(webSocketUrl);
    socket.onopen = (e) => this.handleWebSocketOpen(e, socket);

    socket.onmessage = this.handleWebSocketMessage;

    socket.onclose = (e) => this.handleWebSocketClose(e, token);

    socket.onerror = function (error) {
      console.error('xp users WebSocket error:', error);
    };
  }

  handleWebSocketOpen(event, socket) { 
    this.changeXpUsersWebSocketConnectionStatus(true);
    this.signalInterval = setInterval(() => {
      console.log('after 9 minutes');
      socket.send(
        JSON.stringify({ action: 'ping', message: 'hi, from client' }),
      );
    }, 1000 * 60 * 9); //60*9
  }

  handleWebSocketMessage(event) {
    let data = JSON.parse(event.data);
    if (data.type === 'eijentNotification') {
      this.props.eijentNotificationListSuccess(data);
      this.props.toasterNotificationListSuccess(data);
    }
    else if (data.type === 'notificationMark') {
      this.props.eijentNotificationListSuccess(data);
    }
    else if (data.type === 'smartNudges') {
      this.props.eijentNotificationListSuccess(data);
      this.props.toasterNotificationListSuccess(data);
    } else {
      this.props.setXpUsersWebSocketConnectionMessage(JSON.parse(event.data));
    }
  }

  handleWebSocketClose(event, token) {
    if (this.signalInterval) {
      clearInterval(this.signalInterval);
    }
    this.changeXpUsersWebSocketConnectionStatus(false);
    if (event.wasClean) {
      console.log(
        `xp users WebSocket - [close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
      );
    } else {
      this.connectWS(token);
    }
  }

  changeXpUsersWebSocketConnectionStatus(status) {
    this.props.changeXpUsersWebSocketConnectionStatus(status);
  }

  privateRoute = (path, Component) => {
    return (
      <Route
        path={path}
        element={
          <PrivateUserRoute
            element={
              <Component
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
        }
      />
    );
  };

  inboxRoutes = () => {
    return (
      <Route
        path="/inbox/*"
        element={
          <GmailProvider>
            <InboxRoutesWrapper />
          </GmailProvider>
        }
      />
    );
  };

  checkAndGetGmailIntegrationData = async () => {
    try {
      const response = await xpAccountIntegrationGmailGetGmailId();
      if (response?.data?.success) {
        this.props.setGmailConnectionState(true, response.data.details);
      } else {
        this.props.setGmailConnectionState(false, {});
      }
    } catch (error) {
      this.props.setGmailConnectionState(false, {});
      createNotification('error', error.message);
    }
  };

  render() {
    return (
      <>
        <Routes>
          <Route
            path="/"
            element={
              UserData() ? (
                <Navigate to="/dashboard" />
              ) : showLogin ? (
                <Login
                  navigate={this.props.navigate}
                  location={this.props.location}
                  params={this.props.params}
                />
              ) : (
                <Navigate to={Url.wixUrl} />
              )
            }
          />

          <Route
            path="/my-events"
            element={
              UserData() ? <Navigate to="/xp/my-events" /> : <Navigate to="/" />
            }
          />
          <Route
            path="/forgotPassword"
            element={
              <ForgotPassword
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
          <Route
            path="/register"
            element={
              <Register
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
          <Route
            path="/user/confirm-account"
            element={
              <ConfirmAccount
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
          <Route
            path="/resetPassword/:token"
            element={
              <ResetPassword
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          <Route
            path="/project-page/update-transactional-email-templates-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <TransactionEmailEditor
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/opportunity/opportunity/update-transactional-email-templates"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityTransactionEmailEditor
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          {this.privateRoute('/dashboard', Home)}
          {this.inboxRoutes()}
          {/* Ai chat boat urls */}
          {this.privateRoute('/aichatbot/general/:id', AiChatbot)}
          {this.privateRoute('/aichatbot/chatwidget/:id', AiChatbot)}
          {this.privateRoute('/aichatbot/settings/:id', AiChatbot)}
          {this.privateRoute('/aichatbot/dashboard/:id', AiChatbot)}
          {this.privateRoute('/aichatbot/integrations/:id', AiChatbot)}
          {/* Drive urls */}
          {this.privateRoute('/files/home', FileUpload)}
          {this.privateRoute('/files/images', FileUpload)}
          {this.privateRoute('/files/docs', FileUpload)}
          {this.privateRoute('/files/videos', FileUpload)}
          {this.privateRoute('/files/images/:folderId/*', FileUpload)}
          {this.privateRoute('/files/home/:folderId/*', FileUpload)}
          {this.privateRoute('/files/videos/:folderId/*', FileUpload)}
          {this.privateRoute('/files/docs/:folderId/*', FileUpload)}


          {/* Opportunity urls */}
          <Route
            path="/opportunities"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <FilterTypesProvider>
                      <Opportunities
                        navigate={this.props.navigate}
                        location={this.props.location}
                      />
                    </FilterTypesProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />

          <Route path="/settings/*" element={
            <EijentDashboard>
              <Settings>
                <Routes>
                  <Route path="my-settings" element={<PrivateUserRoute element={<Profile />} />} />
                  <Route path="transactional-emails" element={<PrivateUserRoute element={<TransactionalEmailList />} />} />

                  <Route path="field-management/opportunities" element={<PrivateUserRoute element={<FieldManagement type="opportunity" />} />} />
                  <Route path="field-management/accounts" element={<PrivateUserRoute element={<FieldManagement type="accounts" />} />} />
                  <Route path="field-management/contacts" element={<PrivateUserRoute element={<FieldManagement type="contacts" />} />} />
                  <Route path="field-management/meetings" element={<PrivateUserRoute element={<FieldManagement />} />} />

                  <Route path="state-management/opportunities" element={<PrivateUserRoute element={<StateManagement type="opportunity"/>} />} />
                  <Route path="state-management/accounts" element={<PrivateUserRoute element={<StateManagement type="accounts"/>} />} />
                  <Route path="state-management/contacts" element={<PrivateUserRoute element={<StateManagement type="contacts"/>} />} />

                  <Route path="my-eijents" element={<PrivateUserRoute element={<MyAgents />} />} />
                  <Route path="my-eijents/:id" element={<PrivateUserRoute element={<NewJourney />} />} />
                </Routes>
              </Settings>
            </EijentDashboard>
          } />

          <Route path="/settings/transactional-emails/template/:id" element={
            <PrivateUserRoute element={<EijentDashboard><TransactionalEmailView /></EijentDashboard>} />
          } />

          {/* <Route path="/my-eijents/edit" element={<PrivateUserRoute element={<NewJourney />} />} />  */}
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
        <NotificationContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  proutes: state.Proutes,
  userPreferences: state.UserPreferences,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      changeXpUsersWebSocketConnectionStatus:
        Actions.changeXpUsersWebSocketConnectionStatus,
      setXpUsersWebSocketConnectionMessage:
        Actions.setXpUsersWebSocketConnectionMessage,
      eijentNotificationListSuccess: Actions.eijentNotificationListSuccess,
      toasterNotificationListSuccess: Actions.toasterNotificationListSuccess,
      setGmailConnectionState: Actions.setGmailConnectionState,
      fetchUserPreferencesRequest: Actions.fetchUserPreferencesRequest,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withCustomRouter(EijentRoutes));
