import React from 'react';
import { Box, Typography, } from '@mui/material';
import Pagination from 'react-js-pagination';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Grid from '@mui/material/Grid2';

import "./style.scss";

import {
    PaginationArrowRightIcon,
    PaginationArrowLeftIcon,
} from '../CustomIcons';

// below function has to be exported to the Listing component and placed inside the useEffect where 
// response from API is to be set in a state
export const handleEndCountForPagination = (totalItems, limit, page_no, setEndCount) => {
    const lastPage = Math.ceil(totalItems / limit);
    if (totalItems <= limit) {
        setEndCount(totalItems);
    } else if (totalItems > limit) {
        if (page_no === lastPage) {
            setEndCount(totalItems);
        } else {
            setEndCount((page_no) * limit);
        }
    }
};

function PaginationFunctional({ startCount, setStartCount, endCount, limit, setLimit, page_no, setPage_no, totalItemsCount, listItemsFn }) {

    const handleLimitChange = (e) => {
        let pageNum = 1;
        setPage_no(pageNum);
        setStartCount(1);
        setLimit(e.target.value);
        listItemsFn(e.target.value, pageNum);
    };

    const handlePageChange = (pageNum) => {
        setStartCount(((pageNum - 1) * limit + 1));
        setPage_no(pageNum);
        listItemsFn(limit, pageNum); // Modify Parent Component's Listing Function
    };

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="eijent-pagination"
        >
            <Grid >
                <Typography variant={"caption"} sx={{ textTransform: 'uppercase', color: '#C1C1C1' }}>
                    Showing {startCount} - {endCount} of {totalItemsCount}
                </Typography>
            </Grid>
            <Grid >
                <Grid container alignItems="center">
                    <Grid >
                        <Typography variant={"caption"} sx={{ textTransform: 'uppercase', color: '#C1C1C1' }}>
                            Shows
                        </Typography>
                        <select
                            value={limit}
                            onChange={handleLimitChange}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <Typography variant={"caption"} sx={{ textTransform: 'uppercase', color: '#C1C1C1' }} >
                            Entries
                        </Typography>
                    </Grid>
                    <Grid >
                        <Pagination
                            activePage={page_no}
                            itemsCountPerPage={limit}
                            totalItemsCount={
                                totalItemsCount
                            }

                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            prevPageText={
                                <PaginationArrowLeftIcon />
                            }
                            nextPageText={
                                <PaginationArrowRightIcon />
                            }
                            itemClassFirst="first d-none"
                            itemClassLast="last d-none"
                        />

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PaginationFunctional;