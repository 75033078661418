import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
    // Fetch user preferences actions
    fetchUserPreferencesRequest: (data) => ({
        type: Types.FETCH_USER_PREFERENCES_REQUEST,
        data,
    }),
    fetchUserPreferencesSuccess: ['message', 'data'],
    fetchUserPreferencesError: ['error'],
    
    // Update user preferences actions
    updateUserPreferencesRequest: (data, signal) => ({
        type: Types.UPDATE_USER_PREFERENCES_REQUEST,
        data,
        signal,
    }),
    updateUserPreferencesSuccess: ['message', 'data'],
    updateUserPreferencesError: ['message'],

    // Delete filter actions
    deleteFilterRequest: (data) => ({
        type: Types.DELETE_FILTER_REQUEST,
        data,
    }),
    deleteFilterSuccess: ['message', 'data'],
    deleteFilterError: ['message'],

    // Set applied quick filter actions
    setAppliedQuickFilter: (data) => ({
        type: Types.SET_APPLIED_QUICK_FILTER,
        data,
    }),
}, { prefix: 'USER_PREFERENCES/' });

// Export the Types separately if needed
export const Constants = Types;

export default Creators;
