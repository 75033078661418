import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, FormControlLabel, Checkbox, Button, Tooltip } from "@mui/material";
import AddGroup from "./AddGroup";
import AddField from "./AddField";
import Search from "../../../components/Search";
import { Actions } from 'ReduxStore/Actions';
import { FieldManagementGroupsCreators } from "../../../../ReduxStore/Actions/fieldManagementGroups.action";
import { FieldManagementGroup } from "ReduxStore/Reducers/fieldManagementGroups.reducer.js";
import { FieldData } from "ReduxStore/Reducers/fieldManagementGroups.reducer";
import { FolderIcon, EditIcon, DeleteIcon, InfoIcon } from "../../../components/CustomIcons.js";
import Loader from "Components/Loader/loader";
import { createNotification } from 'helpers';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { debounce } from "lodash";
import ConfirmationModal from "./ConfirmationModal";
const tableHeadStyle: React.CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '10px',
  fontSize: '12px',
  lineHeight: 1,
  color: '#c1c1c1',
  borderColor: '#C1C1C1',
  backgroundColor: '#ffffff',
};

const checkboxStyle = {
  marginLeft: 0,
  gap: 1,
  "& .MuiTypography-root": {
    fontWeight: 400,
  },
};

type FieldManagementListProps = {
  type: "opportunity" | "accounts" | "contacts";
};


export default function FieldManagementList({ type }: FieldManagementListProps) {
  const customFieldTypeVar = type; 
  const dispatch = useDispatch();
  const { fieldManagementGroups, message, error, loading, lastAction } = useSelector((state: any) => state.FieldManagementGroups);
  //  New state for Fields (Replace `OpportunityCustomFields` with the correct reducer name)
  const { loading: fieldLoading, message: fieldMessage, error: fieldError, lastAction: fieldLastAction } = useSelector(
    (state: any) => state.OpportunityCustomFields // Adjust this based on your store
  );
  const [fieldGroups, setFieldGroups] = useState<FieldManagementGroup[]>([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openFieldDrawer, setOpenFieldDrawer] = React.useState(false);
  const [isEditMode, setEditMode] = React.useState(false);
  const [existingData, setExistingData] = useState<{ _id: string; name: string; internalName: string; } | null>(null);
  const [existingFieldData, setExistingFieldData] = useState<FieldData | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteGroupId, setDeleteGroupId] = useState<string | null>(null);
  const [deleteFieldId, setDeleteFieldId] = useState<string | null>(null);
  const isMounted = useRef(false);
  const [focusOnTooltip, setFocusOnTooltip] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteType, setDeleteType] = useState(null); // "group" or "field" 

  useEffect(() => {
    dispatch({ type: "CLEAR_MESSAGE" });
    dispatch({ type: "CLEAR_FIELD_MESSAGE" });

    const queryParams = { customFieldType: customFieldTypeVar };
    dispatch(FieldManagementGroupsCreators.getFieldGroupsListRequest(queryParams));
  }, [customFieldTypeVar, dispatch]);


  useEffect(() => {
    setFieldGroups(fieldManagementGroups);
  }, [fieldManagementGroups]);

  const handleOpenEdit = (group) => { 
    dispatch({ type: "CLEAR_MESSAGE" });
    setEditMode(true);
    setOpenDrawer(true);
    setExistingData({ _id: group?._id, name: group?.name, internalName: group?.internalName || "" }); // Use `setState`
  };

  //  Open confirmation modal
  const handleOpenDelete = (id, type) => {
    if (type == "group") {
      setDeleteGroupId(id);
    } else {
      setDeleteFieldId(id);
    }
    setDeleteType(type);
    setConfirmModalOpen(true);
  };

  // Handle delete action based on type
  const handleDelete = () => {
    if (deleteType === "group" && deleteGroupId) {
      dispatch(FieldManagementGroupsCreators.deleteFieldGroupRequest(deleteGroupId, customFieldTypeVar)); 
    } else if (deleteType === "field" && deleteFieldId) { 
      const queryParams = {
        customFieldType: customFieldTypeVar,
        reqFrom: "newDesign"
      };

      //  Define bodyParams
      const bodyParams = {
        fieldId: deleteFieldId
      };

      const paramsDel = {
        queryParams,
        bodyParams
      };
      //Dispatch delete request with structured data
      dispatch(Actions.deleteOpportunityCustomFieldSettingRequest(paramsDel));

    }
    setConfirmModalOpen(false); // Close modal after delete
    setDeleteGroupId(null); // Reset ID
    setDeleteFieldId(null); // Reset ID
    setDeleteType(null); // Reset Type
  };




  const handleSearch = (query) => {
    const trimmedQuery = query.trim();

    // Check if query has at least 3 characters OR at least one full word
    if (trimmedQuery !== "" && trimmedQuery.length < 3 && !/\s/.test(trimmedQuery)) return;

    const queryParams = { searchText: trimmedQuery };
    dispatch(FieldManagementGroupsCreators.getFieldGroupsListRequest(queryParams));
  };


  const handleOpenDrawer = () => setOpenDrawer(true);
  const handleCloseDrawer = () => setOpenDrawer(false); //  Function to close drawer
  const handleOpenFieldDrawer = () => setOpenFieldDrawer(true);
  const handleCloseFieldDrawer = () => setOpenFieldDrawer(false); //  Function to close

  const handleQuickAddChange = async (event, field) => {
    const updatedValue = !field.isQuickAdd; //  Toggle the value 
    //  Construct queryParams with required values
    const queryParams = {
      customFieldType: customFieldTypeVar,
      reqFrom: "newDesign"
    };

    //  Construct bodyParams with required `fieldData`
    const bodyParams = {
      fieldData: {
        ...field, //  Preserve existing fieldData
        isQuickAdd: updatedValue //  Update only isQuickAdd inside fieldData
      }
    };

    //  Dispatch action with formatted request payload
    await dispatch(Actions.updateOpportunityCustomFieldRequest({ queryParams, bodyParams }));
  };

  const handleCheckboxChange = async (event, field) => {
    const fieldKey = event.target.name; //  Get the field key dynamically
    const updatedValue = !field[fieldKey]; //  Toggle the correct field
    console.log(`Updated ${fieldKey} for ${field._id}:`, updatedValue);

    //  Construct queryParams
    const queryParams = {
      customFieldType: customFieldTypeVar,
      reqFrom: "newDesign"
    };

    // Construct bodyParams with required `fieldData`
    const bodyParams = {
      customFieldType: customFieldTypeVar,
      fieldData: {
        ...field, //  Preserve existing fieldData
        [fieldKey]: updatedValue // Update the dynamically fetched field
      }
    };
    //  Dispatch action with formatted request payload
    await dispatch(Actions.updateOpportunityCustomFieldRequest({ queryParams, bodyParams }));
  };



  const handleAddFieldClick = () => {
    setEditMode(false); // 
    dispatch({ type: "CLEAR_FIELD_MESSAGE" });
    setSelectedField({
      fieldId: "",
      fieldName: "",
      internalName: "",
      placeHolder: "",
      toolTip: "",
      groupId: "",
      fieldType: "",
      isMandatory: false,
      isReadonly: false,
      isQuickAdd: false,
      isUnique: false,
      trackEditHistory: false
    });
    setOpenFieldDrawer(true); //  Open drawer
  };


  const handleEditFieldClick = (field, focusOnTooltip = false) => {
    setEditMode(true); // Set edit mode 
    dispatch({ type: "CLEAR_FIELD_MESSAGE" });
    setSelectedField({
      fieldId: field.fieldId,
      fieldName: field.fieldName,
      internalName: field?.internalName,
      placeHolder: field?.placeHolder,
      toolTip: field?.toolTip,
      groupId: field.groupId,
      fieldType: field?.fieldType,
      dropdownString: field?.dropdownString,
      isMultiSelect: field?.isMultiSelect,
      isMandatory: field?.isMandatory,
      isReadonly: field?.isReadonly,
      isQuickAdd: field?.isQuickAdd,
      isUnique: field?.isUnique,
      trackEditHistory: field?.trackEditHistory,
    });
    setFocusOnTooltip(focusOnTooltip); // Set autofocus target
    setOpenFieldDrawer(true); // Open drawer
  };


  return (
    <Box
      sx={{
        backgroundColor: '#F4F6F7',
        height: '100%',
        maxHeight: '100%',
        borderRadius: '8px',
        overflow: 'auto'
      }}
    >
      <Stack sx={{ height: '100%', maxHeight: '100%' }}>

        <Stack py={2.5} px={3.75} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack spacing={2.75} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h2">
              Field Management
            </Typography>
          </Stack>
          {/* search */}
          <Search onSearch={(query) => {
            console.log(query);
            handleSearch(query);
          }} />

        </Stack>

        <Stack className={'field-header'} px={1.5} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack spacing={2.75} py={1.5} px={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>  </Stack>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {/* <AddField /> */}

            <Button onClick={handleAddFieldClick} variant="contained" color="primary">
              Add Field
            </Button>
            {/* button for add groups button */}
            <Button onClick={() => {
              dispatch({ type: "CLEAR_MESSAGE" });
              setEditMode(false);
              setOpenDrawer(true);
              setExistingData({ _id: "", name: "", internalName: "" });
            }} variant="contained" color="primary">
              Add Group
            </Button>
          </Stack>
        </Stack>

        {/* --Table List-- */}
        <Box className="field-managment" flexGrow={1} overflow={'auto'} px={1.25}>
          <TableContainer sx={{ maxHeight: '100%' }}>
            {
              fieldGroups.length > 0 ? (
                fieldGroups.map((group) => (
                  <React.Fragment key={group._id}>
                    {/* --Group Header-- */}
                    <Stack className={'field-header'} px={1.5} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                      <Stack spacing={2.75} py={1.5} px={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box display={'flex'} gap={3} alignItems={'baseline'}>
                          {/* Disable click for baisc information */}
                          {group.name === "Basic Information" ? (
                            <Typography
                              sx={{ fontSize: "21px", fontWeight: "700" }}
                            >{group.name}</Typography>
                          ) : (
                            <>
                              <FolderIcon />
                              <Typography
                                onClick={() => handleOpenEdit(group)}
                                sx={{ cursor: 'pointer', fontSize: "21px", fontWeight: "700" }}
                              >
                                {group.name}
                              </Typography>
                            </>
                          )}
                        </Box>

                        {/* --Edit and Delete Buttons-- */}
                        {group.name !== "Basic Information" && (
                          <IconButton
                            size="small"
                            variant="contained"
                            // @ts-ignore
                            color="transparent"
                            onClick={() => handleOpenDelete(group._id, "group")}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Stack>

                    {/* --Fields Table (Only if fieldData exists)-- */}
                    {group.fieldData && group.fieldData.length > 0 && (
                      <Table className="field-managment-table" sx={{ minWidth: 650 }} stickyHeader aria-label="Field Management List">
                        <TableBody>
                          {group.fieldData.map((field) => (
                            <TableRow sx={{ px: 2.25 }} key={field._id} >
                              <TableCell sx={{ minWidth: 300 }}>
                                <Typography variant="subtitle1" fontWeight={'700'} color="primary" >
                                  <Typography sx={{ cursor: 'pointer' }} onClick={() => handleEditFieldClick(field)}>
                                    {group._id === "no-group" ? (field as any)?.fieldData?.fieldName : field.fieldName}
                                  </Typography>
                                </Typography>
                              </TableCell>

                              <TableCell sx={{ width: 150, minWidth: 150 }}>
                                <FormControlLabel
                                  sx={checkboxStyle}
                                  control={
                                    <Checkbox
                                      name="isMandatory" //   Pass field key dynamically
                                      checked={!!field.isMandatory} //   Controlled checkbox
                                      onChange={(event) => handleCheckboxChange(event, group._id === "no-group" ? (field as any)?.fieldData : field)} //  Call dynamic handler
                                    />
                                  }
                                  label={
                                    <Box
                                      display={'flex'}
                                      gap={2}
                                      alignItems={'center'}
                                      onClick={(event) => handleCheckboxChange(event, group._id === "no-group" ? (field as any)?.fieldData : field)} //   Call the same handler for label click
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <Typography>
                                        Required
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </TableCell>


                              <TableCell sx={{ width: 150, minWidth: 150 }}>
                                <FormControlLabel
                                  sx={checkboxStyle}
                                  control={
                                    <Checkbox
                                      name="isQuickAdd" //   Pass field key dynamically
                                      checked={!!field.isQuickAdd} //   Controlled checkbox
                                      onChange={(event) => handleCheckboxChange(event, field)} //   Call dynamic handler
                                    />
                                  }
                                  label={
                                    <Box
                                      display={'flex'}
                                      gap={2}
                                      alignItems={'center'}
                                      onClick={(event) => handleCheckboxChange(event, field)} //   Call the same handler for label click
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <Typography>
                                        Quick Add
                                      </Typography>
                                    </Box>
                                  } 
                                />
                              </TableCell>

                              <TableCell sx={{ minWidth: 300 }}>
                                <Box
                                  sx={{
                                    borderRadius: '4px',
                                    background: '#d9d9d94d',
                                    padding: '6px 16px',
                                  }}
                                  display={'inline-flex'}
                                  gap={1}
                                  alignItems={'center'}
                                >

                                  <Tooltip placement="left" title={field?.toolTip ? field?.toolTip : "Tooltip not added"} arrow><Box><InfoIcon /></Box></Tooltip>
                                  <Typography color={field.toolTip ? "textPrimary" : "textDisabled"}>
                                    {field.toolTip ? "Tooltip added" : "Tooltip not added"}
                                  </Typography>
                                  <Typography
                                    component={"a"}
                                    color="primary"
                                    sx={{ fontWeight: 700, textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={() => handleEditFieldClick(field, true)}
                                  >
                                    {field?.toolTip ? "Edit" : "Add"}
                                  </Typography>
                                </Box>
                              </TableCell>

                              <TableCell sx={{ width: 50 }} align="right">
                                <IconButton
                                  size="small"
                                  variant="contained"
                                  // @ts-ignore
                                  color="transparent"
                                  onClick={() => handleOpenDelete(field.fieldId, "field")}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <Typography sx={{ p: 3, textAlign: "center" }}>No field groups available</Typography>
              )
            }
          </TableContainer>

          {(loading || fieldLoading) && <Loader />}
        </Box>
      </Stack>
      {openDrawer && (
        <AddGroup
          type={customFieldTypeVar}
          isEditMode={isEditMode}
          openDrawer={openDrawer}
          existingData={existingData}
          onClose={handleCloseDrawer} //  Pass function to close drawer
        />
      )}

      {openFieldDrawer && (<AddField
        type={customFieldTypeVar}
        isEditMode={isEditMode}
        existingFieldData={selectedField}
        openFieldDrawer={openFieldDrawer} //  Ensure it matches the correct prop name
        onClose={() => setOpenFieldDrawer(false)} //  Ensure correct function name
        focusOnTooltip={focusOnTooltip}
      />)}

      {/* Confirmation Modal */}
      {isConfirmModalOpen && (<ConfirmationModal
        isOpenModal={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleDelete}
      />)}
    </Box>
  );
}
