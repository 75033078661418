import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { Filter } from 'ReduxStore/Reducers/userPreferences.reducer';
interface SelectedFiltersProps {
  appliedQuickFilter: Filter;
}

const SelectedFilters: React.FC<SelectedFiltersProps> = ({
  appliedQuickFilter,
}: SelectedFiltersProps) => {
  
  if(!appliedQuickFilter?.name) return null;
  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      gap={0.4}
      alignItems={'center'}
    >
      <Typography variant="body2">Selected filter :</Typography>
        <Button
          //@ts-ignore
          variant="tag"
          size="small"
          sx={{
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#FFFFFF',
            },
          }}
        >
          {appliedQuickFilter?.name}
        </Button>
    </Stack>
  );
};

export default SelectedFilters;
