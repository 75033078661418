import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  //#region Field groups
  getFieldGroupsListRequest: ['queryParams'],
  getFieldGroupsListSuccess: ['fieldManagementGroups', "actionType"], 
  getFieldGroupsListFailure: ['error'],

  createFieldGroupRequest: ['data'],
  createFieldGroupSuccess: ["fieldManagementGroup", "message", "actionType"],  
  createFieldGroupFailure: ['error'],

  updateFieldGroupRequest: ['data'],
  updateFieldGroupSuccess: ["updatedGroup", "message", "actionType"],  
  updateFieldGroupFailure: ['error'], 
  
  deleteFieldGroupRequest: ['fieldManagementGroupId', 'customFieldType'],
  deleteFieldGroupSuccess: ['fieldManagementGroupId', 'message', "actionType"],
  deleteFieldGroupFailure: ['error'],


  //Add actions to clear messages/errors
  clearMessage: [],  // Clears success messages

  clearError: [],    // Clears error messages
  //#endregion
});

const Constants = Types;
export {
  Creators as FieldManagementGroupsCreators,
  Constants as FieldManagementGroupsConstants,
};
