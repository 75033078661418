import { call, delay, put } from 'redux-saga/effects';
import { FieldManagementGroupsCreators } from '../Actions/fieldManagementGroups.action';
import FieldManagementGroupsAPI from '../API/fieldManagementGroupsAPI';
import { removeStorage } from 'helpers/common';

function* getFieldGroupsList(action: { queryParams?: { searchText?: string; }; }) {
  try {
    const fieldManagementGroupsAPI = new FieldManagementGroupsAPI();
    const response = yield call(fieldManagementGroupsAPI.getFieldGroupsListAPI, action.queryParams);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(FieldManagementGroupsCreators.getFieldGroupsListSuccess(response.data.fieldManagementGroups));
      } else if (response.data.status === 403) {
        yield put(FieldManagementGroupsCreators.getFieldGroupsListFailure(response.data.message));
        removeStorage();
      } else {
        yield put(FieldManagementGroupsCreators.getFieldGroupsListFailure(response.data.message));
      }
    } else {
      yield put(FieldManagementGroupsCreators.getFieldGroupsListFailure(response.data.message));
    }
  } catch (error) {
    yield put(FieldManagementGroupsCreators.getFieldGroupsListFailure(error.message));
  }
}

function* createFieldGroup(action) {
  try {
    const fieldManagementGroupsAPI = new FieldManagementGroupsAPI();
    const response = yield call(fieldManagementGroupsAPI.createFieldGroupAPI, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(FieldManagementGroupsCreators.createFieldGroupSuccess(response.data.group, response.data.message));
      } else if (response.data.status === 403) {
        yield put(FieldManagementGroupsCreators.createFieldGroupFailure(response.data.message));
        removeStorage();
      } else {
        yield put(FieldManagementGroupsCreators.createFieldGroupFailure(response.data.message));
      }
    } else {
      yield put(FieldManagementGroupsCreators.createFieldGroupFailure(response.data.message));
    }
  } catch (error) {
    yield put(FieldManagementGroupsCreators.createFieldGroupFailure(error.message));
  }
}

function* updateFieldGroup(action) {
  try {
    const fieldManagementGroupsAPI = new FieldManagementGroupsAPI();
    const response = yield call(fieldManagementGroupsAPI.updateFieldGroupAPI, action.data); 
    if (response.status === 200) {
      if (response?.data?.data?.success) {
        const updatedGroup = response?.data?.data?.updatedGroup; //Access updatedGroup correctly
        const successMessage = response?.data?.data?.message;
        yield put(FieldManagementGroupsCreators.updateFieldGroupSuccess(updatedGroup, successMessage)); // Dispatch correct data

      } else if (response.data.status === 403) {
        yield put(FieldManagementGroupsCreators.updateFieldGroupFailure(response?.data?.data?.message));
        removeStorage();
      } else { 
        yield put(FieldManagementGroupsCreators.updateFieldGroupFailure(response?.data?.data?.message));
      }
    } else { 
      yield put(FieldManagementGroupsCreators.updateFieldGroupFailure(response?.data?.data?.message));
    }

  } catch (error) {
    yield put(FieldManagementGroupsCreators.updateFieldGroupFailure(error.message));
  }
} 

function* deleteFieldGroup(action) {
  try {
    const fieldManagementGroupsAPI = new FieldManagementGroupsAPI();

    // Destructure the action payload
    const { fieldManagementGroupId, customFieldType } = action;

    // Pass both ID and type to your API
    const response = yield call(
      fieldManagementGroupsAPI.deleteFieldGroupAPI,
      fieldManagementGroupId,
      customFieldType
    );

    if (response.status === 200 && response.data.success) {
      yield put(
        FieldManagementGroupsCreators.deleteFieldGroupSuccess(
          fieldManagementGroupId,
          response.data.message
        )
      );
    } else {
      yield put(FieldManagementGroupsCreators.deleteFieldGroupFailure(response.data.message));
    }
  } catch (error) {
    yield put(FieldManagementGroupsCreators.deleteFieldGroupFailure(error.message));
  }
}



const fieldManagementGroupsSaga = {
  getFieldGroupsList,
  createFieldGroup, 
  updateFieldGroup,
  deleteFieldGroup,
};

export default fieldManagementGroupsSaga;
