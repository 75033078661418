import React from 'react';
import { TableCell } from '@mui/material';
import { Opportunity } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import { SourceCell } from './SourceCell';
import { EditableNameCell } from './EditableNameCell';
import StateCell from './StateCell';
import { AssignedToCell } from './AssignedToCell';
import moment from 'moment';

interface StaticContentCellProps {
  header: {
    id: string;
    label: string;
  };
  opp: Opportunity;
  handleOpenDropdownSelectionPopup: (
    setOpenFn: React.Dispatch<React.SetStateAction<boolean>>,
    opportunityId?: string,
    assignedToId?: {_id: string, email: string}
  ) => void;
  setOpenAssignToPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DefaultHeadersContentCell: React.FC<StaticContentCellProps> = ({
  header,
  opp,
  handleOpenDropdownSelectionPopup,
  setOpenAssignToPopup,
}) => {
  // Render different cell types based on header.id
  const renderCellContent = () => {
    switch (header.id) {
      case 'name':
        return <EditableNameCell opp={opp} />;
      case 'source':
        return <SourceCell source={opp.source} />;
      case 'state':
        return <StateCell opp={opp} />;
      case 'assignedTo':
        return <AssignedToCell opportunity={opp} handleOpenDropdownSelectionPopup={handleOpenDropdownSelectionPopup} setOpenAssignToPopup={setOpenAssignToPopup} />;
      case 'updatedAt':
        return moment(opp.updatedAt).format(
          'MMM, Do YYYY, h:mm A',
        )
      default:
        return opp[header.id as keyof Opportunity];
    }
  };

  return (
    <TableCell sx={{ minWidth: '200px' }} key={header.id}>
      {renderCellContent()}
    </TableCell>
  );
};
