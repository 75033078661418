import Loader from "Components/Loader/loader";
import EijentDashboard from "eijent";
import PageTabs from "eijent/internals/Tabs";
import { useState } from "react";
import OpportunityList from "./OpportunityList";

export default function Opportunities() {
  const [isLoading, setIsLoading] = useState(false);

  const contentForWorkspace = () => {
    return <EijentDashboard>
      <PageTabs>{mainContent}</PageTabs>
      {isLoading && <Loader />}
    </EijentDashboard>;
  };

  const contentIndependentOfWorkspace = () => {
    return <EijentDashboard>
      {mainContent}
      {isLoading && <Loader />}
    </EijentDashboard>;
  };

  const mainContent = <OpportunityList />;
  return (
    <>
      {contentIndependentOfWorkspace()}
      {/* {contentForWorkspace()} */}
    </>
  );
}