
import { ReactNode, useState } from "react";


import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup, ButtonGroup, Tab, Checkbox, IconButton, TableSortLabel, TextField, ClickAwayListener, InputAdornment } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  ArrowDownIcon,
  AddNewIcon,

  HomeIconActive,
  InboxIconActive,
  TasksIcon,
  TasksIconActive,
  OpportunitiesIcon,
  OpportunitiesIconActive,
  AccountsIcon,
  ContactsIcon,
  DriveIcon,
  ContactsIconActive,
  StartIconOutlined,
  StartIcon,
  AttachmentIcon,
  UnCheckedIcon,
  CheckedIcon,
  CheckAllIcon,
  UnCheckAllIcon,
  DeleteIcon,
  CancelIcon,
  MoreIcon,
  EditIcon,
  EmailIcon,
  ProductIcon,
  ChatIcon,
  SearchIcon,
  SentWhiteIcon,
} from '../../../components/CustomIcons';
import React from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function createData(name: ReactNode,
  source: string,
  company: string,
  contact: string,
  eventDate: string,
  budget: string,
  assignedTo: ReactNode,) {
  return { name, source, company, contact, eventDate, budget, assignedTo, };
}

const rows = [
  createData(
    <Box>hello</Box>,
    'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000',
    <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Avatar sx={{ width: 32, height: 32 }} alt="Ajay Seth" src="/static/images/avatar/1.jpg" />),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch Book Launch', 'Website Website', 'Palantir', 'Peter Thiel Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Avatar sx={{ width: 32, height: 32 }} alt="Ajay Seth" src="/static/images/avatar/1.jpg" />),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Avatar sx={{ width: 32, height: 32 }} alt="Ajay Seth" src="/static/images/avatar/1.jpg" />),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Avatar sx={{ width: 32, height: 32 }} alt="Ajay Seth" src="/static/images/avatar/1.jpg" />),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),
  createData('Book Launch ', 'Website', 'Palantir', 'Peter Thiel', 'Nov 25, 2024', '$ 500,000', <Button variant="text" size="small" sx={{ fontSize: '15px', px: 0 }}>+ Click to Add</Button>),

];

const tableHeadStyle: React.CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '10px',
  fontSize: '12px',
  lineHeight: 1,
  color: '#c1c1c1',
  borderColor: '#C1C1C1',
  backgroundColor: '#ffffff',
};

export default function AccountsTab() {
  const [files, setFiles] = useState<File[]>([]);

  const handleChange = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    console.log("Files uploaded:", acceptedFiles);
  };

  return (

    <Stack
      className="opportunity-files"
      sx={{
        height: '100%',
        maxHeight: '100%',
      }}>
      {/* ---header--- */}
      <Stack direction={'row'} py={2} px={3.75} justifyContent={'space-between'} borderBottom={'1px solid #fff'}>
        <Typography variant="h4">
          Accounts
        </Typography>
        <TextField
          size="small"
          placeholder="Search"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderColor: "#D9D9D9 !important",
              backgroundColor: "#ffffff",
            },
          }}
          slotProps={{
            input: {
              endAdornment: <InputAdornment position="end"  >
                <IconButton
                  size="small"
                  // @ts-ignore
                  color='transparent'
                  variant='contained'
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>,
            },
          }}

        />

      </Stack>

      {/* --Files Stack-- */}
      <Stack py={2} px={3.75} sx={{ flexGrow: 1, overflow: 'auto' }}>
        {/* --Select and menu-- */}
        <Stack px={1.5} sx={{ backgroundColor: '#FFF7EE', }} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack spacing={2.75} py={1.5} px={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <Checkbox
                icon={<UnCheckAllIcon />}
                checkedIcon={<CheckAllIcon />}
              />
              <Typography>
                3X
              </Typography>
            </Box>

            <IconButton size="small" variant='contained'
              // @ts-ignore
              color="transparent">
              <DeleteIcon />
            </IconButton>

            <IconButton size="small" variant='contained'
              // @ts-ignore
              color="transparent">
              <OpportunitiesIcon />
            </IconButton>

            <IconButton size="small" variant='contained'
              // @ts-ignore
              color="transparent">
              <CancelIcon />
            </IconButton>

            <IconButton size="small" variant='contained'
              // @ts-ignore
              color="transparent">
              <MoreIcon />
            </IconButton>

          </Stack>
        </Stack>
        <TableContainer className="data-table" sx={{ maxHeight: '100%', backgroundColor: "#fff" }} >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="Email List">
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableHeadStyle, width: '44px', pl: 4 }} ></TableCell>
                <TableCell sx={{ ...tableHeadStyle, }}>
                  <TableSortLabel
                    IconComponent={KeyboardArrowDownIcon}
                    direction='desc'
                    active={true}
                  >
                    NAME
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ ...tableHeadStyle, }}>
                  <TableSortLabel
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    SOURCE
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ ...tableHeadStyle, }}>
                  <TableSortLabel
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    COMPANY
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ ...tableHeadStyle, }}>
                  <TableSortLabel
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    CONTACT
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ ...tableHeadStyle, }}>
                  <TableSortLabel
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    EVENT DATE
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ ...tableHeadStyle, }}>
                  <TableSortLabel
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    BUDGET
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ ...tableHeadStyle, maxWidth: '200px' }}>
                  <TableSortLabel
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    ASSIGNED TO
                  </TableSortLabel>
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  // @ts-ignore
                  key={row.name}

                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': { backgroundColor: '#E6FDF4' },
                    px: 2.25,
                  }}
                >
                  <TableCell sx={{ width: '44px', pl: 4 }} >
                    <Checkbox />
                  </TableCell>
                  <TableCell className="editable-cell" sx={{ minWidth: '200px', py: '1px' }}>
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ minWidth: '200px' }}>{row.source}</TableCell>
                  <TableCell sx={{ minWidth: '200px' }}>{row.company}</TableCell>
                  <TableCell sx={{ minWidth: '200px' }}>{row.contact}</TableCell>
                  <TableCell sx={{ minWidth: '200px' }}>{row.eventDate}</TableCell>
                  <TableCell sx={{ minWidth: '200px' }}>{row.budget}</TableCell>
                  <TableCell sx={{ minWidth: '200px', maxWidth: '200px', py: '1px' }}>{row.assignedTo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {/* --Add Account-- */}
      <Box
        sx={{
          backgroundColor: "#F8F8F8",
          py: 2.25,
          px: 3.75,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderTop: '1px solid #fff',
        }}
      >
        <Button variant="contained" size="large" fullWidth>
          Add Account
        </Button>

      </Box>

    </Stack >

  );
};