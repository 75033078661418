import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Alert, Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ListItemIcon, MenuItem, Select, Stack, TextField, Tooltip, Typography, ListItemText, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  CancelIcon,
  InfoIcon,
} from "../../../components/CustomIcons";
import { Actions } from 'ReduxStore/Actions';
import CustomSwitch from 'Components/customSwitch';
import {
  OpportunityState,
  OpportunityStates_State
} from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import ColorTilePicker from 'Components/ColorPicker/ColorTilePicker';
import { rgbToHex2 } from 'helpers/common';
import { SelectChangeEvent } from '@mui/material/Select';
import CircleIcon from '@mui/icons-material/Circle';

type ValidationErrors = {
  name?: string;
  stateType?: string;
  isDefault?: boolean;
};

type AddStateProps = {
  type: "opportunity" | "accounts" | "contacts";
  isEditMode?: boolean;
  existingStateData?: {
    _id: string;
    name: string;
    stateType: string;
    backgroundColor: string;
    description: string;
    isDefault: boolean;
  } | null;
  openStateDrawer: boolean;
  onClose: () => void;
};

const colorOptions = [
  { label: 'Mint Green', rgba: { r: 172, g: 248, b: 214, a: 1 } },
  { label: 'Light Pink', rgba: { r: 242, g: 170, b: 252, a: 1 } },
  { label: 'Soft Violet', rgba: { r: 153, g: 165, b: 253, a: 1 } },
  { label: 'Light Sky Blue', rgba: { r: 136, g: 228, b: 253, a: 1 } },
  { label: 'Soft Rose', rgba: { r: 254, g: 201, b: 221, a: 1 } },
  { label: 'Pale Lemon Yellow', rgba: { r: 253, g: 253, b: 150, a: 1 } },
  { label: 'Dusty Lavender', rgba: { r: 216, g: 191, b: 216, a: 1 } },
  { label: 'Peach Whisper', rgba: { r: 254, g: 218, b: 203, a: 1 } },
  { label: 'Seafoam Cyan', rgba: { r: 180, g: 244, b: 224, a: 1 } },
  { label: 'Coral Blush', rgba: { r: 254, g: 165, b: 165, a: 1 } },
];
// Default Opportunity State value
const opportunityStateDefaultValue: OpportunityState = {
  name: '',
  stateType: "Initial",
  description: '',
  textColor: undefined,
  backgroundColor: undefined
};

type AlertType = "success" | "error" | "warning" | "info";
interface AlertState {
  type: AlertType;
  message: string;
}
export default function AddState({
  type,
  isEditMode = false,
  existingStateData = null,
  openStateDrawer,
  onClose,
}: AddStateProps) {
  const customStateTypeVar = type;
  const dispatch = useDispatch();
  const theme = useTheme();
  const opportunityStateRedux = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;
  const [_id, setStateId] = React.useState("");
  const [name, setName] = React.useState("");
  const [stateType, setStateType] = React.useState<string>("");
  const [description, setDescription] = React.useState("");
  const [isDefault, setIsDefault] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<AlertState | null>(null);
  const [backgroundColor, setBackgroundColor] = React.useState('#ACF8D64d');
  const [textColor, setTextColor] = useState({ r: 0, g: 0, b: 86, a: 1 });
  const [opportunityState, setOpportunityState] = useState<OpportunityState>(opportunityStateDefaultValue as OpportunityState);
  const [hasHandledSuccess, setHasHandledSuccess] = React.useState(false);
  const [errors, setErrors] = React.useState<ValidationErrors>({
    name: '',
    stateType: '',
    isDefault: false
  });

  // Pre-fill form fields when in edit mode
  React.useEffect(() => {
    if (isEditMode && existingStateData) {
      setStateId(existingStateData._id);
      setName(existingStateData.name);
      setStateType(existingStateData.stateType);
      setDescription(existingStateData.description);
      setBackgroundColor(existingStateData.backgroundColor);
      setIsDefault(existingStateData.isDefault);
    }
  }, [isEditMode, existingStateData]);



  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    console.log("~~~~~~~~~~ name ~~~~~~~~~", name);
    if (name.length > 35) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "The name must not exceed 35 characters",
      }));
      handleAlert("error", "The name must not exceed 35 characters.");
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }
    setName(name);
  }; 
   
  const handleSave = async () => {
    try {
      const validationErrors = validateForm();

      if (Object.keys(validationErrors).length) {
        setErrors(validationErrors);
        handleAlert("error", "Please fill in all mandatory fields before proceeding.");
        return;
      }

    
      const queryParams = {
        opportunityStateType: customStateTypeVar,
        reqFrom: "newDesign"
      };

      let rawData = {
        name,
        stateType,
        description,
        opportunityStateType: customStateTypeVar,
        isDefault,
        backgroundColor,
        ...(isEditMode && { _id }), // Include `_id` only in edit mode
      };

      const data = {
        bodyParams: rawData,
        queryParams
      }; 
      if (isEditMode) {
        await dispatch(Actions.updateOpportunityStateRequest(data));
      } else {
        await dispatch(Actions.createOpportunityStateRequest(data));
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  }; 
 
  React.useEffect(() => {
    if (isEditMode && opportunityStateRedux.updateOpportunityStateSuccess) {
      dispatch({ type: "CLEAR_OPPORTUNITY_STATE_MESSAGE" }); // ✅ Move this up
      handleAlert("success", "State updated successfully!");
      setTimeout(() => {
        resetForm();
        onClose();
      }, 1500);
    } else if (!isEditMode && opportunityStateRedux.createOpportunityStateSuccess) {
      dispatch({ type: "CLEAR_OPPORTUNITY_STATE_MESSAGE" }); // ✅ Move this up
      handleAlert("success", "State created successfully!");
      setTimeout(() => {
        resetForm();
        onClose();
      }, 1500);
    }
  }, [
    opportunityStateRedux.createOpportunityStateSuccess,
    opportunityStateRedux.updateOpportunityStateSuccess
  ]);
  
  
  
  React.useEffect(() => { 
    if (opportunityStateRedux.error && opportunityStateRedux.message) {
      handleAlert("error", opportunityStateRedux.message);  
      if (
        opportunityStateRedux.message &&
        (opportunityStateRedux.message as string).toLowerCase().includes("already exists")
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: "This state name already exists",
        }));
      }
    }

    dispatch({ type: "CLEAR_OPPORTUNITY_STATE_MESSAGE" });
  }, [opportunityStateRedux.error, opportunityStateRedux.message]);
  
   

  const resetForm = () => {
    setStateId("");
    setName("");
    setStateType("");
    setDescription("");  
    setIsDefault(false);
    setAlertMessage(null);
    setErrors({
      name: '',
      stateType: '',
      isDefault: false,
    });
  };
  


  const validateForm = (): ValidationErrors => {
    let errors: ValidationErrors = {}; // ✅ Start with an empty object

    if (!name.trim()) {
      errors.name = "State name is required";
    }

    if (!stateType.trim()) {
      errors.stateType = "StateType is required";
    } 

    return errors; // ✅ Returns {} if no errors
  };
  const drawerStyle = {
    width: 690,
    flexGrow: 1,
    overflow: 'auto',
    borderRadius: 1,
    backgroundColor: '#fff',
    paddingY: '30px',
  };

  const checkboxStyle = {
    marginLeft: 0,
    gap: 1,
    "& .MuiTypography-root": {
      fontWeight: 400,
    },
  };

  const handleAlert = (type: AlertType, message: string) => {
    setAlertMessage({ type, message });
  };

  // Handle field type change
  const handleStateTypeChange = (event) => {
    setStateType(event.target.value);
  };
  // Handle input change for list options
  const handleListOptionsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setDescription(value);
  };

  const handleDefaultStateToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDefault(event.target.checked); // ✅ Directly update the state
  };


  const opportunityStateOptions = [
    { value: "Initial", label: "Initial" },
    { value: "Qualification", label: "Qualification" },
    { value: "Contact", label: "Contact" },
    { value: "Engagement", label: "Engagement" },
    { value: "Proposal", label: "Proposal" },
    { value: "Negotiation", label: "Negotiation" },
    { value: "Approval", label: "Approval" },
    { value: "Closed-Won", label: "Closed-Won" },
    { value: "Closed-Lost", label: "Closed-Lost" },
    { value: "Closed-No Decision", label: "Closed-No Decision" },
    { value: "Hold", label: "Hold" },
    { value: "Review", label: "Review" },
    { value: "Follow-Up", label: "Follow-Up" },
    { value: "Implementation", label: "Implementation" },
    { value: "Custom", label: "Custom" }
  ];

  const contactStateOptions = [
    { value: "Identification", label: "Identification" },
    { value: "Qualification", label: "Qualification" },
    { value: "Engagement", label: "Engagement" },
    { value: "Decision-Making", label: "Decision-Making" },
    { value: "Lead Nurturing", label: "Lead Nurturing" },
    { value: "Relationship Building", label: "Relationship Building" },
    { value: "User Support", label: "User Support" },
    { value: "Satisfaction/Feedback", label: "Satisfaction/Feedback" },
    { value: "Contact Update", label: "Contact Update" },
    { value: "Maintenance", label: "Maintenance" },
    { value: "Inactive/Idle", label: "Inactive/Idle" },
    { value: "Lost/Disqualified", label: "Lost/Disqualified" },
    { value: "Archived", label: "Archived" },
    { value: "Custom", label: "Custom" }
  ];

  const accountStateOptions = [
    { value: "Identification", label: "Identification" },
    { value: "Qualification", label: "Qualification" },
    { value: "Engagement", label: "Engagement" },
    { value: "Decision-Making", label: "Decision-Making" },
    { value: "Contract Negotiation", label: "Contract Negotiation" },
    { value: "Onboarding", label: "Onboarding" },
    { value: "Account Management", label: "Account Management" },
    { value: "Growth/Opportunity", label: "Growth/Opportunity" },
    { value: "Risk/Issue Management", label: "Risk/Issue Management" },
    { value: "Renewal", label: "Renewal" },
    { value: "Closure", label: "Closure" },
    { value: "Inactive/Idle", label: "Inactive/Idle" },
    { value: "Lost/Disqualified", label: "Lost/Disqualified" },
    { value: "Archived", label: "Archived" },
    { value: "Custom", label: "Custom" }
  ];

  const getStateOptions = (from) => {
    switch (from) {
      case 'contacts':
        return contactStateOptions;
      case 'accounts':
        return accountStateOptions;
      case 'opportunity':
        return opportunityStateOptions;
      default:
        return [];
    }
  };

  const handleChange = (e: SelectChangeEvent<string>) => {
    let cf = { ...opportunityState };

    setOpportunityState({
      ...cf,
      [e.target.name]: e.target.value as unknown as OpportunityState,
    });
  };


  const DrawerContent = (
    <Stack sx={drawerStyle} id="drawer-container" role="presentation" >

      <Box display={'flex'} px={6} pb={2} alignItems={'start'} justifyContent={'space-between'}
      >
        <Typography variant="h2">{isEditMode ? "Edit State" : "Add New State"}</Typography>
        <IconButton size="small" variant="contained"
          // @ts-ignore
          color="transparent"
          onClick={onClose}
          sx={{ position: 'absolute', top: 21, right: 21, }}
        >
          <CancelIcon />
        </IconButton>
      </Box>
      {/* --form-- */}
      <Box display={'flex'} flexGrow={1} px={6} flexDirection={'column'} overflow={'auto'} gap={2}>
        <FormControl fullWidth error={!!errors.name}>
          <FormLabel htmlFor="name">
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Typography>
                Name
              </Typography>
              <Typography component={'span'} ml={-1} color='error'>*</Typography>
            </Box>
          </FormLabel>
          <TextField
            id="name"
            type="text"
            name="name"
            placeholder="Please enter the name"
            required
            fullWidth
            value={name}
            variant="outlined"
            onChange={handleNameChange}
            error={!!errors.name} 
            // @ts-ignore
            size="large"
            color={errors.name ? 'error' : 'primary'}
          />
        </FormControl>

        <FormControl fullWidth error={!!errors.stateType}>
          <FormLabel htmlFor="fieldType">
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Typography>
                  State Type
              </Typography>
              <Typography component={'span'} ml={-1} color='error'>*</Typography>

            </Box>
          </FormLabel>
          <Select
            id="stateType"
            value={stateType}
            // disabled={isEditMode ? true : false}
            // onChange={(e) => setFieldType(e.target.value)}
            onChange={handleStateTypeChange}
            displayEmpty
            color={errors.stateType ? 'error' : 'primary'}
            // @ts-ignore
            size="large"
            variant="outlined"
            fullWidth
          >
            <MenuItem value="" disabled hidden>Select</MenuItem>
            {getStateOptions("opportunity").map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Description */}
        <FormControl fullWidth>
          <FormLabel htmlFor="description">
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Typography>
                Description
              </Typography>
            </Box>
          </FormLabel>
          <TextField
            name="description"
            placeholder="Enter Description"
            variant="outlined"
            fullWidth
            multiline
            value={description}
            onChange={handleListOptionsChange}
            maxRows={6}
            sx={{
              // maxWidth: "500px",
              "& .MuiOutlinedInput-root": {
                pr: "4px !important",
                minHeight: "8rem",
                height: "auto",
                alignItems: "start",
              },
            }}
            //@ts-ignore
            size="large"
          />

        </FormControl>

        {/* Customization Section */}

        <FormControl fullWidth>
          <FormLabel>
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Typography>
                Background Color
              </Typography>
            </Box>
          </FormLabel>
          <Select
            id="backgroundColor"
            value={JSON.stringify(backgroundColor)}
            onChange={(e) => {
              const val = e.target.value;
              setBackgroundColor(val ? JSON.parse(val) : null); // handle empty selection
            }}
            displayEmpty
            // @ts-ignore
            size="large"
            variant="outlined"
            fullWidth
          >
            <MenuItem value="">
              <em>None</em> {/* Visible and selectable empty option */}
            </MenuItem>
            {colorOptions.map(({ label, rgba }) => (
              <MenuItem key={label} value={JSON.stringify(rgba)}>
                <ListItemIcon>
                  <CircleIcon sx={{ color: `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})` }} />
                </ListItemIcon>
                <ListItemText>{label}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        <Box display={'flex'} gap={2} alignItems={'center'}>
          <Typography variant="body1">Default State</Typography>
          <Switch
            checked={isDefault} //  Reflects the latest state
            onChange={handleDefaultStateToggle} //  Updates state on toggle
            name="isDefault"
          />
        </Box>


        {/* ---CheckBox--- */}
        <Collapse in={!!alertMessage}>
          <Alert
            severity={alertMessage?.type}
            action={
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setAlertMessage(null)}
              >
                <CancelIcon />
              </IconButton>
            }
          >
            {alertMessage?.message.split("\n").map((msg, index) => (
              <Box key={index}>{msg}</Box>
            ))}
          </Alert>
        </Collapse>

      </Box>
      {/* ---Actions--- */}
      <Box px={6} pt={2} display={'flex'} gap={'6px'} justifyContent={'flex-end'}>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='large'
          onClick={handleSave}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Box>
    </Stack >
  );


  return (
    <div>
      <Drawer
        anchor='right'
        open={openStateDrawer}
        onClose={onClose}
        slotProps={{
          backdrop: {
            timeout: 200,
            sx: {
              // backdropFilter: "blur(4px)",
              backgroundColor: "rgba(255, 255, 255, 0.5)"
            }
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            border: 'none',
          },
        }}
        sx={{
          zIndex: 1202,
        }}
      >
        {DrawerContent}
      </Drawer>
    </div>
  );
}
