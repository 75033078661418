import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormControl, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import { EventTypes, RoutKeys } from '../../constants/enums/EventTypes';
import {
  AttachmentIcon,
  CancelIcon,
  SentWhiteIcon,
  SuggetionIcon,
} from "../components/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "ReduxStore/Actions";

// @ts-ignore
import EijentButtonLogo from "../assets/images/eijent-logo-compressed.gif";
import { JourneyState } from 'ReduxStore/Reducers/journey.reducer';
import ListLoader from 'eijent/components/ListLoader';
import { UserData } from 'helpers/common';

const style: React.CSSProperties = {
  height: "100%",
  maxHeight: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
};

const userMessage: React.CSSProperties = {
  padding: '14px 24px',
  borderRadius: '8px',
  display: 'flex',
  gap: 2,
  alignItems: 'center',
};

interface Props {
  step?: number;
  onUssrMessage?: (text: string, sender: 'user' | 'assistant', id: string) => void;
  userId?: string;
  onMessageUpdate?: (messages: { sender: string; text: string; initialMessage?: boolean }[]) => void;
}
interface WebsocketData {
  type: string;
  content: any;
  messageId?: string;
  index?: number;
}

interface Message {
  _id?: string;
  sender: 'user' | 'assistant' | 'system';
  text: string;
  timestamp?: string;
  index?: number;
  initialMessage?: boolean;
}

const EijentChatWidget: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const widgetRef = useRef<any>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [socket, setSocket] = useState<WebSocket>();
  const [loading, setLoading] = useState<boolean>(false);
  const [userMessage, setUserMessage] = useState<string>("");
  const { workflow } = useSelector((state: any) => state.journey) as JourneyState;
  const [rows, setRows] = useState(2);

  useEffect(() => {
    if (workflow?.copilotHistory?.length) {
      setMessages((workflow.copilotHistory.map((m) => ({ text: m.content, sender: m.role }))));
    } else {
      setMessages([]);
    }
  }, [workflow?.copilotHistory]);
  useEffect(() => {
    let webSocketUrl = process.env.REACT_APP_CHATBOT_WEBSOCKET_URL || '';
    const socket: WebSocket = new WebSocket(webSocketUrl);
    // if (socket) {
    //@ts-ignore
    // socket.onopen = (e) => {
    //   onConnect(e, socket);
    // };
    socket.onmessage = onMessage;
    setSocket(socket);
    return () => {
      socket.close();
    };
  }, []);


  useEffect(() => {
    if(props.onMessageUpdate){
    props.onMessageUpdate(messages); // Notify parent when messages update
    }
  }, [messages, props.onMessageUpdate]);


  const onMessage = (event: any) => {
    setLoading(false);
    const agentResponse = JSON.parse(event?.data);
    const data: WebsocketData = agentResponse?.data;
    console.log("WEbSOCKET DATA :", agentResponse);
    if (data?.type === 'error') {
      console.error('Error ', data.content);
      return;
    }
    // else if (data?.type === 'update') {
    //   updateHandler(data?.content);
    // }
    else if (data?.type === 'message') {
      setMessages((m) => {
        m.push({ text: data.content, sender: "assistant" });
        return [...m];
      });
    } else if (data?.type === "workflow") {
      if (data.content) {
        const stateMachine = JSON.parse(data?.content);
        dispatch(Actions.getJourneySuccess(stateMachine));
      }
    }


    else if (data?.type === "status") {
      if (data.content) {
        dispatch(Actions.updateCopilotStatus(data.content));
      }
    }
    else if (data?.type === "explanation") {
      console.log("Explanation", data.content);
      dispatch(Actions.updateExplanation(data.content));
    }

    else if (data?.type === "title") {
      console.log("Explanation", data.content);
      dispatch(Actions.updateExplanation(data.content));
    }
    else if (data?.type === "summary") {
      console.log("Explanation", data.content);
      dispatch(Actions.updateExplanation(data.content));
    }

    const messagecombined = agentResponse.message?.response || '';
    //console.log('M S G ', message);
    if (agentResponse?.message !== undefined) {
      if (widgetRef.current) {
        widgetRef.current.innerHTML = messagecombined;
      }
    } else {
      console.log('ERROR', agentResponse);
    }
    //setLoading(!loading);
  };


  const onUssrMessage = async (
  ) => {
    let userId = props.userId;
    if (!userId) {
      const userDetails = await UserData();
      userId = userDetails._id;
    }
    if (socket && socket.readyState === socket.OPEN) {
      const data = JSON.stringify({
        action: RoutKeys.CHATBOT,
        eventType: EventTypes.MESSAGE,
        service: RoutKeys.COPILOT,
        workflowId: workflow?._id,
        message: userMessage,
        userId: userId,
        step:props.step === 1 ? 'discussion':'generation'
      });
      //console.log('Socket Data', data);
      socket.send(data);
    } else {
      console.error('WebSocket connection not open or available.');
      // Handle the scenario when the socket is not open

    }
  };



  // const initializeSocketService = async () => {
  //   let webSocketUrl = process.env.REACT_APP_CHATBOT_WEBSOCKET_URL || '';
  //   const socket: WebSocket = new WebSocket(webSocketUrl);
  
  //   socket.onopen = () => {
  //     console.log("WebSocket connected successfully!");
  //   };
  
  //   socket.onmessage = onMessage;
  
  //   socket.onclose = (event) => {
  //     console.warn("WebSocket disconnected!", event);
  //   };
  
  //   socket.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //   };
  
  //   setSocket(socket);
  // };

  const initializeSocketService = async () => {
    let webSocketUrl = process.env.REACT_APP_CHATBOT_WEBSOCKET_URL || '';
    const socket: WebSocket = new WebSocket(webSocketUrl);
    let heartbeatInterval: NodeJS.Timeout | null = null; // For keeping track of the heartbeat
  
    socket.onopen = () => {
      console.log("WebSocket connected successfully!");
  
      // Start the heartbeat to keep connection alive
      heartbeatInterval = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          console.log("Sending heartbeat to keep WebSocket alive...");
          socket.send(JSON.stringify({ type: "ping" })); // Sending a ping message
        }
      }, 9 * 60 * 1000); // Every 1 minutes
    };
  
    socket.onmessage = onMessage;
  
    socket.onclose = (event) => {
      console.warn("WebSocket disconnected!", event);
      
      // Clear the heartbeat interval
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
        heartbeatInterval = null;
      }
  
      // Optionally: Reconnect after a delay
      setTimeout(() => {
        console.log("Reconnecting WebSocket...");
        initializeSocketService();
      }, 3000);
    };
  
    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  
    setSocket(socket);
  };
  
  

  // const onSend = () => {
  //   setMessages((mes) => {
  //     mes.push({ sender: "user", text: userMessage });
  //     return [...mes];
  //   });
  //   onUssrMessage();
  //   setUserMessage("");
  //   setLoading(!loading);
  // };
  const onSend = () => {
    if (loading || !userMessage.trim()) return; // Prevent sending if message is empty
    setMessages((mes) => [...mes, { sender: "user", text: userMessage }]);
    onUssrMessage();
    setUserMessage("");
    setLoading(true); // Set loading to true after sending a message
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserMessage(value);
  
    // Count number of newlines
    const lineCount = (value.match(/\n/g) || []).length + 1;
  
    // Adjust rows dynamically (minimum 2, maximum 6)
    setRows(Math.min(6, Math.max(2, lineCount)));
  };

  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);
 
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]); // Ensure to update when messages change
 

  return (
    <Box sx={style}>
      <Stack flexGrow={1} gap={2} overflow={'auto'} py={1.5} display="flex" alignItems="center" justifyContent="start">
        <Box display="flex" mt={4} gap={1} justifyContent="center" alignItems="center">
          <img
            src={EijentButtonLogo}
            width={36}
            height={36}
            alt="Eijent logo"
            style={{
              mixBlendMode: "multiply",
              filter: "contrast(1)",
            }}
          />
          <Typography>Eijent</Typography>
        </Box>

        <Typography variant="body2" align="center">
          What <strong>type of eijent</strong> would <br /> you like to create?
        </Typography>

        {/* <Typography variant="body2" align="center">
          Here’s some suggestions:
        </Typography> */}

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          mt={1}
          gap={0.75}
          sx={{ maxWidth: "800px" }}
        >
          {[
            "Eijent for an Account",
            "Eijent for an Opportunity",
            "Eijent for a Team",
          ].map((text, index) => (
            <Button key={index}
              //@ts-ignore
              variant="dotted"
              color="primary" size="medium" startIcon={<SuggetionIcon />}>
              {text}
            </Button>
          ))}
        </Stack> */}

        {/* --chat started-- */}
        <Stack spacing={2} style={{ width: "600px" }} justifyContent="right" alignItems="right">
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              <Typography variant="body2" align="right" fontWeight={message.sender === "assistant" && 'bold'}>
                {message.text}
              </Typography>
              {!message.initialMessage && <Box sx={{ userMessage }} >
                <div ref={widgetRef}></div>
              </Box>}
            </React.Fragment>
          ))}
          {/* Scroll to bottom anchor */}
          <Box mt={'0 !important'} ref={messagesEndRef} />
        </Stack>

      </Stack>
      {/* --Loading Message-- */}
      {loading && (
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="body2" color="textSecondary">
            <ListLoader />
          </Typography>
        </Box>
      )}
      {/* --Eijent Input Button-- */}

      <FormControl
        component={'form'}
        onSubmit={(e) => { e.preventDefault(); onSend(); }}
        fullWidth={true}
      >  <Box
        sx={{
          backgroundColor: "#F8F8F8",
          p: 2.25,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap:1
        }}
      >
          <TextField
            name="BCC"
            multiline={true}
            // rows={rows}
            maxRows={3}
            placeholder="Type your message here…"
            variant="outlined"
            fullWidth
            sx={{
              maxWidth: "500px",
              "& .MuiOutlinedInput-root": {
                pr: "4px !important",
                borderColor: "#D9D9D9 !important",
                minHeight: "3.25rem",
                height:"auto",
              },
            }}
            value={userMessage}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevents new line in multiline
                onSend();
              }
            }}
            //@ts-ignore
            size="large"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction={'row'} spacing={1.5}>

                      {/* @ts-ignore */}
                      <IconButton
                        size="medium"
                        variant="contained"
                        color="primary"
                        type='submit'>

                       {loading ? <StopRoundedIcon /> : <SentWhiteIcon />}
                      </IconButton>
                    </Stack>
                  </InputAdornment>
                ),
              },
            }}
          />
         
          {/* <IconButton
            size="medium"
            // variant="contained"
            color="secondary"
            onClick={() => dispatch(Actions.clearWorkflowHistory({ workflowId: workflow?._id }))}
            >
           <CancelIcon /> 
          </IconButton> */}
        </Box>
        
      </FormControl>
    </Box>
  );
};

export default EijentChatWidget;