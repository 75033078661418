import { call, put } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import { removeStorage } from 'helpers/common';
import JourneyAPI, { createJourneyAPI } from 'ReduxStore/API/journey/journeyAPI';

function* createJourney(action) {
  let response = yield call(createJourneyAPI, action.data);

  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.createJourneySuccess(
          response.data.workflow,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.createJourneyError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.createJourneyError(response.data.message));
    }
  } else {
    yield put(Actions.createJourneyError(response.data.message));
  }
}

function* updateStatus(action) {
  const journeyAPI = new JourneyAPI();
  const response = yield call(journeyAPI.updateStatus, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.updateStatusSuccess(
          response.data
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.createJourneyError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.createJourneyError(response.data.message));
    }
  } else {
    yield put(Actions.createJourneyError(response.data.message));
  }
}
function* clearWorkflowHistory(action) {
  const journeyAPI = new JourneyAPI();
  const response = yield call(journeyAPI.clrearWorkflowHistory, action.data);
  if (response.status === 200) {
    yield put(
      Actions.clearWorkflowHistorySuccess(
        response.data.success,
      ),
    );
  }
}

function* getJourney(action) {
  const journeyAPI = new JourneyAPI();
  const response = yield call(journeyAPI.getJourney, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.getJourneySuccess(
          response.data.workflow,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.getJourneyError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.getJourneyError(response.data.message));
    }
  } else {
    yield put(Actions.getJourneyError(response.data.message));
  }
}

function* getJourneylist(action) {
  const journeyAPI = new JourneyAPI();
  const response = yield call(journeyAPI.getJourneyList, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.getJourneyListSuccess(
          response.data.journeyList,
          response.data.totalResult,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.getJourneyListError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.getJourneyListError(response.data.message));
    }
  } else {
    yield put(Actions.getJourneyListError(response.data.message));
  }
}

function* deleteJourney(action) {
  const journeyAPI = new JourneyAPI();
  const response = yield call(journeyAPI.deleteJourney, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.deleteJourneySuccess(
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.deleteJourneyError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteJourneyError(response.data.message));
    }
  } else {
    yield put(Actions.deleteJourneyError(response.data.message));
  }
}

function* testJourney(action) {
  const journeyAPI = new JourneyAPI();
  const response = yield call(journeyAPI.testJourney, action.data);
  // if (response.status === 200) {
  //   if (response.data.success) {
  //     yield put(
  //       Actions.testJourney(
  //         response.data.message,
  //       ),
  //     );
  //   } else if (response.data.status === 403) {
  //     yield put(Actions.deleteJourneyError(response.data.message));
  //     removeStorage();
  //   } else {
  //     yield put(Actions.deleteJourneyError(response.data.message));
  //   }
  // } else {
  //   yield put(Actions.deleteJourneyError(response.data.message));
  // }
}

function* generateBasicInfo(action) {
  const journeyAPI = new JourneyAPI();
  const response = yield call(journeyAPI.generateBasicInfo, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.generateBasicInfoSuccess(
          response.data.workflow,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.generateBasicInfoError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.generateBasicInfoError(response.data.message));
    }
  } else {
    yield put(Actions.generateBasicInfoError(response.data.message));
  }
}


const journeySaga = {
  createJourney,
  getJourney,
  getJourneylist,
  deleteJourney,
  testJourney,
  clearWorkflowHistory,
  updateStatus,
  generateBasicInfo
};

export default journeySaga;