import { GlobalSettings, TransactionalEmail } from 'ReduxStore/Reducers/transactionalEmails.reducer';
import BaseAPI from './baseAPI';

class TransactionalEmailsAPI extends BaseAPI {
  getTransactionalEmailsAPI = async (data: {sortBy: string, sortOrder: string, limit: number, page_no: number}) => {
    return this.API(`/transactionalEmails?sortBy=${data.sortBy}&sortOrder=${data.sortOrder}&limit=${data.limit}&page_no=${data.page_no}`, {
      method: 'GET'
    });
  };

  createTransactionalEmailAPI = async (data: TransactionalEmail) => {
    return this.API('transactionalEmails', {
      method: 'POST',
      data: data,
    });
  };

  updateTransactionalEmailAPI = async (data: TransactionalEmail) => {
    return this.API('transactionalEmails', {
      method: 'PATCH',
      data: data,
    });
  };

  deleteTransactionalEmailAPIs = async (transactionalEmailIds: string[]) => {
    return this.API('transactionalEmails', {
      method: 'DELETE',
      data: {transactionalEmailIds},
    });
  };

  updateCompanyGlobalSettingsAPI = async (data: GlobalSettings) => {
    return this.API('transactionalEmails/companyGlobalSettings', {
      method: 'PATCH',
      data: {
        transactionalEmailSendFrom: data.senderName,
        transactionalEmailReplyToMailId: data.replyTo,
      }
    });
  };

  getAllCustomFieldsAPI = async () => {
    return this.API(`/transactionalEmails/allCustomFields`, {
      method: 'GET'
    });
  };
}

export default TransactionalEmailsAPI;
