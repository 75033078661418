import Grid from '@mui/material/Grid2';
import { useState } from 'react';

export default function DatePickers({value, onChange, filterType, condition}) {
    if (condition === "beforeDate") {
        return (
            <Grid container spacing={1}>
                <Grid size={{xs: 6}}>
                    <input
                        type="date"
                        className="form-control small"
                        name="start-date"
                        value={value || ''}
                        onChange={(event) => onChange(event)}
                        onClick={(e) => {
                            // Ensure the input is focusable and the picker opens
                            e.currentTarget.showPicker?.();
                        }}
                    />
                </Grid>
            </Grid>
        );
    } else if (condition === "afterDate") {
        return (
            <Grid container spacing={1}>
                <Grid size={{xs: 6,}}>
                    <input
                        type="date"
                        className="form-control small"
                        name="afterDate"
                        value={value || ''}
                        onChange={(event) => onChange(event)}
                        onClick={(e) => {
                            // Ensure the input is focusable and the picker opens
                            e.currentTarget.showPicker?.();
                        }}
                    />
                </Grid>

            </Grid>
        );
    } else {
        if (!Array.isArray(value)) {
            value = ['', '']; // Initialize as an array if not already
        }
        return (
            <Grid container spacing={1}>
                <Grid size={{xs: 6,}}>
                    <input
                        type="date"
                        className="form-control small"
                        name="start-date"
                        value={value?.[0] || ''}
                        onChange={(event) => {
                            onChange(event)
                        }}
                        onClick={(e) => {
                            // Ensure the input is focusable and the picker opens
                            e.currentTarget.showPicker?.();
                        }}
                    />
                </Grid>
                <Grid size={{xs: 6,}}>
                    <input
                        type="date"
                        className="form-control small"
                        name="end-date"
                        value={value?.[1] || ''}
                        onChange={(event) => {
                            onChange(event)
                        }}
                        onClick={(e) => {
                            // Ensure the input is focusable and the picker opens
                            e.currentTarget.showPicker?.();
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}
