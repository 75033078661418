import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack, SxProps, Theme } from "@mui/material";
import EijentChatWidget from "../EijentCopilot/EijentChatWidget";
import Grid from '@mui/material/Grid2';
import { createActor, createMachine, setup } from 'xstate';
import { useEffect, useRef } from "react";
import { createBrowserInspector } from '@statelyai/inspect';
import { useDispatch, useSelector } from "react-redux";
import { JourneyState } from "ReduxStore/Reducers/journey.reducer";

import { UserData } from 'ReduxStore/API';
const aiDividerStyle: SxProps<Theme> = {
  position: 'relative',
  transformStyle: 'preserve-3d',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '5px',
    right: '0',
    top: '0',
    bottom: '0',
    transform: 'translate3d(1px, 0px, -1px)',
    background: 'transparent linear-gradient(114deg, #5578FA 0%, #CBFFE3 100%) 0% 0% no-repeat padding-box',
    borderRadius: '26px',
    filter: 'blur(10px)',
  },
};
const JourneyWorkflow: React.FC = () => {
  const { workflow, createJourneySuccess, copilotStatus } = useSelector((state: any) => state.journey) as JourneyState;
  const [iframeKey, setIframeKey] = React.useState(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isIframeReady, setIsIFrameReady] = React.useState<boolean>(false);

  const [isIframeVisible, setIframeVisible] = React.useState(false); // State to toggle between views
  const [userId, setUserId] = React.useState<string>('');
  useEffect(() => {
    

    // let journey: any = {};
    // if (workflow.journey) {
    //   journey = workflow.journey;
    // } else {
    //   journey = workflow;
    // }

    if (!isIframeReady) return;

    if (!workflow?.stateMachine?.config) {
      console.error("Error: State machine config is missing in the API response.");
      return;
    }
    let { config, setup: _setup } = workflow?.stateMachine;


    // Ensure config and states are valid
    if (!config || !config.states) {
      console.error("Error: State machine config or states is missing.", config);
      return;
    }
    const inspector = createBrowserInspector({
      iframe: iframeRef.current,
    });
    const machine = createMachine(config);

    const actor = createActor(machine, { inspect: inspector.inspect });
    actor.start();
    return () => {
      actor.stop();
      inspector.stop();
    };
  }, [workflow.stateMachine, isIframeReady]);


  // Function to toggle view on button click
  const handleToggleView = () => {
    setIframeVisible(!isIframeVisible);
  };

  useEffect(() => {
    getuserdetails();
  }, []);

  const getuserdetails = async () => {
    const userDetails = await UserData();
    const userId = userDetails._id;
    setUserId(userId);
  };
  return (
    <Grid container
      height="100%"
      width="100%">
      {/* Left Panel - Journey Summary */}
      <Grid size={{ xs: 6, }} sx={aiDividerStyle}>

        <Stack sx={{ backgroundColor: "#fff", height: "100%" }}>
          {/* Header */}
          <Box sx={{ borderBottom: "1px solid #D9D9D9", padding: 1.5 }}>
            <Typography variant="h4" align="center" color="primary">
              {workflow?.title}
            </Typography>
          </Box>

          {/* Body */}
          <Stack p={4} alignItems="center" justifyContent="space-between" flexGrow={1} overflow={'auto'}>
            {isIframeVisible ?
              <iframe
                key={iframeKey}
                ref={iframeRef}
                onLoad={() => setIsIFrameReady(true)}
                style={{ width: "100%", height: "100%", border: "1px solid #ccc" }}
              ></iframe> :
              <Typography variant="body2" align="left" width={"100%"} sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
                {workflow?.stateMachine?.explanation}
              </Typography>
            }
            <Typography variant="body2" align="center"   >
              {copilotStatus}
            </Typography>
            {copilotStatus && (
              <>
                <Button variant="contained" size="medium" color="primary" onClick={handleToggleView}>
                  {isIframeVisible ? 'Switch to Summary View' : 'Switch to Chart View'}
                </Button>
              </>
            )}
          </Stack>
        </Stack>

      </Grid>



      {/* Right Panel - Copilot */}
      <Grid size={{ xs: 6, }} maxHeight={'100%'}>
        <EijentChatWidget  userId={userId} />
      </Grid>
    </Grid>
  );
};

export default JourneyWorkflow;