import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';

import DashboardLayout from '../../Layouts/DashboardLayout'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CreateProjectStep1 from '../../Pages/Projects/CreateProjectStep1';
import CreateProjectStep2 from '../../Pages/Projects/CreateProjectStep2';
import CreateProjectStep3 from '../../Pages/Projects/CreateProjectStep3';
import FinishIcon from '../../assets/images/icons/finish-popup-icon.png';
import FinishIcon1 from '../../assets/images/icons/finish-pop-1.svg';
import FinishIcon2 from '../../assets/images/icons/finish-pop-2.svg';
import FinishIcon3 from '../../assets/images/icons/finish-pop-3.svg';
import ArrowRight from '../../assets/images/icons/arrow-right-btn.svg';
import HybridIcon from '../../assets/images/icons/step1-icon1.svg';
import icon2 from '../../assets/images/icons/step1-icon2.svg';
import icon3 from '../../assets/images/icons/step1-icon3.svg';
import icon4 from '../../assets/images/icons/step1-icon4.svg';
import icon5 from '../../assets/images/icons/step1-icon5.svg';
import icon6 from '../../assets/images/icons/step1-icon6.svg';
import PopUpVideo1 from '../../assets/images/play-img.jpg';
import { makeStyles, withStyles } from '@mui/styles';
import { createNotification } from '../../helpers';
import { theme } from '../../theme/theme';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ThemeProvider } from '@mui/material';

import { display } from '@mui/system';
import { eventColorType, eventListColorType, stepperIcons } from '../EventList/config'
import { generateUrl } from 'helpers/common';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#FF4170',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#FF4170',
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: '#EDECF5',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#EDECF5',
    zIndex: 1,
    color: '#000056',
    width: 46,
    height: 46,
    display: 'flex',
    borderRadius: 16,
    fontSize: 16,
    fontWeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
    border: '4px solid #ffffff',
  },
  active: {
    backgroundColor: '#FF4170',
    color: '#ffffff',
  },
  completed: {
    backgroundColor: '#FF4170',
    color: '#ffffff',
  },
});

let isIconImage = false

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: !isIconImage ? <span id="stepperIcon">1</span> : <img alt='stepperIcon' id="stepperIcon" src={HybridIcon} />,
    2: <span id="stepperIcon2">2</span>,
    3: <span id="stepperIcon3">3</span>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**The label displayed in the step icon.
   * 
   */
  icon: PropTypes.node,
};

const styles = theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: '8px',
  },
  instructions: {
    marginTop: '8px',
    marginBottom: '8px',
    height: 'calc(100vh - 50px)',
    overflow: 'auto',
  },
});

class CreateProject extends Component {
  constructor(props) {
    super(props)
    this.permData = {};
    const userData = localStorage.getItem('user_details');
    const user = JSON.parse(userData);
    this.state = {
      activeStep: 0,
      Questionvalue: 0,
      Tabvalue: 0,
      theme: '',
      title: 'Create Event',
      pageTitle: 'Create Event',
      eventType: 'registration',
      steps: [
        {
          stepTitle: 'Project Type',
          stepIcon: '',
          stepColor: ''
        },
        {
          stepTitle: 'Project Template',
          stepIcon: '',
          stepColor: ''
        },
        {
          stepTitle: 'Project Details',
          stepIcon: '',
          stepColor: ''
        }
      ],
      projectTypeId: "",
      templateId: "",
      perms: user.permissions,
      servicePlansData: {},
      userDetail:user
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,

    })
  }



  handleProjectTypeId = (projectTypeRes) => {
    this.setState({
      projectTypeId: projectTypeRes._id,
    })

    const { steps } = this.state;
    steps[0]['stepTitle'] = projectTypeRes.title;
    steps[0]['stepIcon'] = stepperIcons[projectTypeRes._id];
    steps[0]['stepColor'] = eventListColorType[projectTypeRes._id];

    this.setState({
      steps: steps,
      changeIcon: this.changeUp(projectTypeRes)
    })

    setTimeout(
      () => this.setState({ position: 1, changeIcon: this.changeUp(projectTypeRes) }),
      10
    );

  }

  changeUp = (projectTypeRes) => {    
    isIconImage = true
    var elements = document.getElementById('stepperIcon');
    elements.parentElement.style.backgroundColor = eventListColorType[projectTypeRes._id];


    switch (projectTypeRes._id) {
      case "61b1bcf7a72cac2a825c4910":
        return elements.src = HybridIcon
        break;
      case "61b1be73a72cac2a825c4911":
        return elements.src = icon2
        break;
      case "61b1cac4a72cac2a825c4917":
        return elements.src = icon3
        break;
      case "61b1cae9a72cac2a825c4918":
        return elements.src = icon4
        break;
      case "61b1cb1ba72cac2a825c4919":
        return elements.src = icon5
        break;
      case "61b1cb4ea72cac2a825c491a":
        return elements.src = icon6
        break;
      default: elements.src = ''
        break;
    }
  }


  handleTemplateId = (tempRes) => {
    if (tempRes == 'blank') {
      this.setState({
        templateId: tempRes,
      })
      const { steps } = this.state;
      steps[1]['stepTitle'] = 'Blank';
      var elementStep2 = document.getElementById('stepperIcon2');
      this.setState({
        steps: steps,
        changeIcon: elementStep2.parentElement.style.backgroundColor = '#F26135'
      })
      setTimeout(
        () => this.setState({ position: 1, changeIcon: elementStep2.parentElement.style.backgroundColor = eventListColorType[tempRes._id] }),
        10
      );
    } else {
      this.setState({
        templateId: tempRes._id,
      })
      const { steps } = this.state;
      if (tempRes?.title) {
        steps[1]['stepTitle'] = tempRes.title;

      } else if (tempRes?.projectTemplateTitle) {
        steps[1]['stepTitle'] = tempRes.projectTemplateTitle;
      }
      var elementStep2 = document.getElementById('stepperIcon2');
      this.setState({
        steps: steps,
        changeIcon: elementStep2.parentElement.style.backgroundColor = eventListColorType[tempRes._id]
      })
      setTimeout(
        () => this.setState({ position: 1, changeIcon: elementStep2.parentElement.style.backgroundColor = eventListColorType[tempRes._id] }),
        10
      );

    }
  }

  async componentDidMount() {
    this.setState({
      servicePlansData: this.props?.servicePlan?.servicePlanDetails
    })
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position.coords.latitude) {
        
      }
    })

    this.permData = this.getPreparePermission(this.state.perms);

    let isExists = true; 
     if(this.state.userDetail.role == 1 || this.state.userDetail.role == 3){
      isExists = false;
     }
    if (this.permData ) {
      Object.keys(this.permData).forEach((data) => {
        if (['create project'].indexOf(data.toLowerCase()) !== -1) {
          isExists = false;
        }
      });
       if (isExists) {
        createNotification('error', "33333 Unauthorized to access this resource");
      }  

      setTimeout(() => {
        this.setState({ redirect: isExists })
      }, 1000);
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.events.doContinue && !prevProps.events.doContinue){
      this.handleNext()
    }
  }

  getPreparePermission(state) {
    let perms = {};
    if (Array.isArray(state)) {
      state.forEach((data) => {
        perms[data.permission_name] = true
      })
    }
    return perms;
  }

  handleEventType = (eventType) => {
    this.setState({ eventType: eventType })
  }



  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        var elements = document.getElementById('stepperIcon');
        if (elements) {
          elements.parentElement.style.backgroundColor = eventListColorType[this.state.projectTypeId];
        }

        return <CreateProjectStep1 projectTypeParams={(params) => { this.handleProjectTypeId(params) }} />;
      case 1:
        var elements2 = document.getElementById('stepperIcon2');
        if (elements2) {
          elements2.parentElement.style.backgroundColor = eventListColorType[this.state.projectTypeId];
        }
        var lineEle = document.getElementsByClassName('MuiStepConnector-line');
        if (lineEle) {
          for (var i = 0; i < lineEle.length; i++) {
            lineEle[0].style.backgroundColor = eventListColorType[this.state.projectTypeId];
          }
        }
        return <CreateProjectStep2 projectTypeTemplates={this.state.projectTypeId} projectTemplateParams={(tempParams) => { this.handleTemplateId(tempParams) }} />;
      case 2:
        var elements3 = document.getElementById('stepperIcon3');
        if (elements3) {
          elements3.parentElement.style.backgroundColor = eventListColorType[this.state.projectTypeId];
        }
        var lineEle = document.getElementsByClassName('MuiStepConnector-line');
        if (lineEle) {
          for (var i = 0; i < lineEle.length; i++) {
            lineEle[1].style.backgroundColor = eventListColorType[this.state.projectTypeId];
          }
        }
        return <CreateProjectStep3 saveTemplateParams={{ projectTypeId: this.state.projectTypeId, projectTemplateId: this.state.templateId }} stepperFinish={() => { this.handleNext() }} />;
      default:
        return 'Project Details';
    }
  }

  handleNext = () => {

    const { activeStep } = this.state;

    if (this.state.activeStep === 0) {
      if (this.state.steps[0]['stepTitle'] !== "Project Type") {
        this.setState({
          activeStep: activeStep + 1
        });
      } else {
        createNotification("error", "Please select a Project Type")
      }
    } else if (this.state.activeStep === 1) {
      if (this.state.steps[1]['stepTitle'] !== "Project Template" && this.state.templateId !== '') {
        this.setState({
          activeStep: activeStep + 1
        });
      } else {
        createNotification("error", "Please select a Project Template")
      }
    } else {
      this.setState({
        activeStep: activeStep + 1
      });
    }

  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.props.handleDoContinue(false) 
    if(activeStep === 0){
      this.props.navigate(generateUrl("/my-events"))
      return
    }

    this.setState({

      activeStep: activeStep - 1,
    });

    var elements2 = document.getElementById('stepperIcon2');
    if (elements2) {
      elements2.parentElement.style.backgroundColor = '#EDECF5';
      var lineEleBack = document.getElementsByClassName('MuiStepConnector-line');
      if (lineEleBack) {
        for (var i = 0; i < lineEleBack.length; i++) {
          lineEleBack[0].style.backgroundColor = '#EDECF5';
        }
      }
    }

    var elements3 = document.getElementById('stepperIcon3');
    if (elements3) {
      elements3.parentElement.style.backgroundColor = '#EDECF5';
      var lineEleBack3 = document.getElementsByClassName('MuiStepConnector-line');
      if (lineEleBack3) {
        for (var i = 0; i < lineEleBack3.length; i++) {
          lineEleBack3[1].style.backgroundColor = '#EDECF5';
        }
      }
    }
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  getUserDetailsFromLocalStorage = () => {
    return  JSON.parse(localStorage.getItem('user_details'))
  }

  findIfAllowedFromSuperadminRequest = () => {
    if (!window.location.href.includes('/project-dashboard')) {
      let userDetails = this.getUserDetailsFromLocalStorage()
      let servicePlanId = userDetails.servicePlanId
      let reqData = {
        servicePlanId,
      }
      this.props.findIfAllowedFromSuperadminOuterRequest(reqData)
    }
  }

  render() {
    const {
      activeStep,
      steps,
    } = this.state
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <DashboardLayout title="My Projects" pageTitle="My Projects">
          <div className="dashboard-containor">
            <div className="steper-outer">
              <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                {
                  steps.map((label) => (
                    <Step key={label.stepTitle}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label.stepTitle}</StepLabel>
                    </Step>
                  ))}
              </Stepper>
              <div>
                {activeStep === steps.length ? (
                  <div>
                    <div className="finish-popup-outer">
                      <div className="finish-popup-head">
                        <h2 className="subsecondary"> Your project is created </h2>
                        <p> Pages, Campaigns, Agenda, Notifications, Groups, Custom Fields,  Transactional Email Templates & Challenges details are copied from the selected project template.</p>
                        <div className="finish-single-btn">
                          <Button variant="contained" color="primary" className="step2-use"> Go to project Overview </Button>
                        </div>
                        <div className="finish-popup-btns">
                          <Button variant="outlined" color="primary"> <img alt="" src={FinishIcon1} /> Pages </Button>
                          <Button variant="outlined" color="primary"> <img alt="" src={FinishIcon2} /> Campaigns </Button>
                          <Button variant="outlined" color="primary"> <img alt="" src={FinishIcon3} /> Agenda </Button>
                        </div>
                      </div>
                      <div className="finish-popup-footer">
                        <div className="finish-footer-lft">
                          <div className="finish-footer-lft-content">
                            <span> Video </span>
                            <p> Learn how to create new pages by using the templates </p>
                          </div>
                          <img alt='' src={PopUpVideo1} />
                        </div>
                        <div className="finish-footer-lft">
                          <div className="finish-footer-lft-content">
                            <span className="new-bg-green"> New </span>
                            <p> Learn how to create new pages by using the templates </p>
                          </div>
                          <img alt='' src={PopUpVideo1} />
                        </div>
                      </div>
                    </div>
                    <Button onClick={this.handleReset}>Reset</Button>
                  </div>
                ) : (
                  <div>
                    <Typography className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>
                    <Grid container spacing={1} className="next-back-stepper-btns">
                      <Grid alignItems='center' display="flex">
                        <Box sx={{ display: 'flex', }}>
                          <Button
                            onClick={this.handleBack}
                            className={classes.backButton}
                            variant="outlined"
                          >
                            <KeyboardBackspaceIcon />
                          </Button>
                          <Box component="h2" ml={{ xs: (0), md: (2) }} className='heading'>
                            {this.props.users.languageStateForRedux?.newProject?.createProject} 
                          </Box>
                        </Box>

                      </Grid>
                      {/* <Grid >
                        <Button disableElevation className={activeStep == steps.length - 1 && 'btn-hidden'} variant="contained" color="primary" endIcon={<img alt='ArrowRight' src={ArrowRight} />} onClick={this.handleNext}> 
                         {activeStep === steps.length - 1 ? '' : 'Continue'} 
                        </Button>
                      </Grid> */}
                    </Grid>
                  </div>
                )}
              </div>

            </div>

          </div>
        </DashboardLayout>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
  servicePlan: state.ServicePlan,
  users: state.Users
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      createEvent: Actions.eventsRequest,
      checkEventDomain: Actions.checkEventDomainRequest,
      handleDoContinue: Actions.handleDoContinueState,
      findIfAllowedFromSuperadminOuterRequest: Actions.findIfAllowedFromSuperadminOuterRequest
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(CreateProject))
