import * as React from 'react';
import {
  Box,
  Button,
  Typography,
  Modal,
  Stack,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch } from 'react-redux';
import { Creators as UserPreferencesActions } from 'ReduxStore/Actions/userPreferences.action';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Filter, UserPreferencesState } from 'ReduxStore/Reducers/userPreferences.reducer';
import { useEffect } from 'react';
import Loader from 'Components/Loader/loader';
import { validateDuplicateFilterNameAPI } from 'ReduxStore/API';
import { LoadingButton } from '@mui/lab';

const PopUpStyle = {
  width: '100%',
  maxWidth: '420px',
  backgroundColor: '#F4F6F7',
  borderRadius: '8px',
  padding: '16px',
  boxShadow: '0px 3px 6px #00000029',
};

const SaveFilterModel: React.FC<{
  open: boolean;
  onClose: () => void;
  filterRows: any;
  isUpdating: boolean;
  appliedQuickFilter: Filter;
  handleCloseFilter: () => void;
}> = ({ open, onClose, filterRows, isUpdating, appliedQuickFilter, handleCloseFilter }) => {
  const dispatch = useDispatch();

  const userPreferences = useSelector(
    (state: any) => state.UserPreferences,
  ) as UserPreferencesState;

  const [filterName, setFilterName] = React.useState('');
  const [color, setColor] = React.useState('#ACF8D64d');
  const [saveSorting, setSaveSorting] = React.useState(false);
  const [error, setError] = React.useState('');

  useEffect(() => {
    if (isUpdating && appliedQuickFilter?._id) {
      setFilterName(appliedQuickFilter.name);
      setColor(appliedQuickFilter.color);
      // setSaveSorting(appliedQuickFilter.saveSorting);
    } else {
      setFilterName('');
      setColor('#ACF8D64d');
    }
  }, [isUpdating, appliedQuickFilter?._id]);

  useEffect(() => {
    if (filterName.length > 0) {
      const clearTimeoutId = setTimeout(() => {
        validateDuplicateName();
      }, 500);
      return () => clearTimeout(clearTimeoutId);
    }
  }, [filterName]);

  const validateDuplicateName = async () => {
    const data = {
      moduleName: location.pathname.split('/')[1],
      filterName: filterName,
      filterId: appliedQuickFilter._id,
    };
    const res = await validateDuplicateFilterNameAPI(data);
    if (!res.data.success) {
      setError(res.data.message);
    } else {
      setError('');
    }
  };

  useEffect(() => {
    if (userPreferences?.updateUserPreferencesSuccess) {
      // handleCloseFilter();
      // onClose();
    }
  }, [userPreferences?.updateUserPreferencesSuccess]);

  const location = useLocation();

  const colorOptions = [
    { value: '#ACF8D64d', color: '#ACF8D64d', label: 'Mint Green' },
    { value: '#F2AAFC4d', color: '#F2AAFC4d', label: 'Light Pink' },
    { value: '#99A5FD4d', color: '#99A5FD4d', label: 'Soft Violet' },
    { value: '#88E4FD4d', color: '#88E4FD4d', label: 'Light Sky Blue' },
    { value: '#FEC9DD4d', color: '#FEC9DD4d', label: 'Soft Rose' },
  ];

  // const handleOpen = () => setOpenSaveFilter(true);
  // const handleClose = () => setOpenSaveFilter(false);

  const handleSaveFilter = async () => {
    if (filterName === '' || filterName.length === 0) {
      setError('Filter name is required');
      return;
    }
    if (error.length > 0) {
      return;
    }
    try {
      const query = filterRows.map((row) => {
        if (row.filterType.isCustomField) {
          return {
            column: `customFields.${row.filterType.value}`,
            condition: row.operator,
            value: row.value,
            fieldType: row.filterType.type,
            isCustomField: row.filterType.isCustomField,
            label: row.filterType.label,
          };
        } else {
          return {
            column: row.filterType.value,
            condition: row.operator,
            value: row.value,
            fieldType: row.filterType.type,
            isCustomField: row.filterType.isCustomField,
            label: row.filterType.label,
          };
        }
      });
      const data: any = {
        query: query,
        name: filterName,
        color,
        saveSorting,
      };

      if (isUpdating) {
        data._id = appliedQuickFilter._id;
      }

      dispatch(
        UserPreferencesActions.updateUserPreferencesRequest([
          {
            moduleName: location.pathname.split('/')[1],
            filters: data,
          },
        ]),
      );
    } catch (error) {
      console.error('Error saving filter:', error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            timeout: 200,
            sx: {
              backdropFilter: 'blur(4px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
          },
        }}
      >
        <Stack alignItems={'center'} justifyContent={'center'} height={'100%'}>
          <Box sx={PopUpStyle}>
            <Stack spacing={2}>
              <Box>
                <Typography variant="subtitle1" fontWeight="500">
                  {isUpdating ? 'Update Filter' : 'Save Filter'}
                </Typography>
                <Divider />
              </Box>

              <FormControl fullWidth>
                <FormLabel htmlFor="filterName" sx={{ fontWeight: '400' }}>
                  Filter Name
                </FormLabel>
                <TextField
                  error={error.length > 0}
                  helperText={error}
                  id="filterName"
                  type="text"
                  name="filterName"
                  placeholder="Filter Name"
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                // color={filterName ? 'error' : 'primary'}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormLabel htmlFor="replyTo" sx={{ fontWeight: '400' }}>
                  Choose Color
                </FormLabel>
                <Select
                  variant="outlined"
                  size="small"
                  defaultValue="1"
                  fullWidth
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  {colorOptions.map(({ value, color, label }) => (
                    <MenuItem key={value} value={value}>
                      <ListItemIcon>
                        <CircleIcon sx={{ color }} />
                      </ListItemIcon>
                      <ListItemText>{label}</ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* <FormControlLabel
                control={<Switch sx={{ mr: 1 }} />}
                label="Save Sorting"
              /> */}

              <Stack direction={'row'} gap={1}>
                <LoadingButton
                  loading={userPreferences?.isLoading}
                  // loadingPosition="start"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSaveFilter}
                >
                  {isUpdating ? 'Update' : 'Save'}
                </LoadingButton>
                <Button
                  size="small"
                  variant="contained"
                  // @ts-ignore
                  color="white"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Modal>
      {/* {userPreferences?.isLoading && <Loader />} */}
    </>
  );
};

export default SaveFilterModel;
