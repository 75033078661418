import { SelectChangeEvent } from '@mui/material';
import { FilterType } from '../../utils/FilterTypesContext';
import { OpportunityStates } from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import { useSelector } from 'react-redux';
import CommonMultiSelect from './CommonMultiSelect';

export default function DropdownForStates({
  value,
  onChange,
  filterType,
}: {
  value: string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  filterType: FilterType;
}) {
  const opportunityStates = useSelector(
    (state: any) => state.OpportunityStates.opportunityStates,
  ) as OpportunityStates;

  return (
    <CommonMultiSelect
      value={value}
      onChange={onChange}
      options={opportunityStates.map((state) => ({
        id: state._id,
        label: state.name
      }))}
      singularLabel="State"
      pluralLabel="States"
      selectedItemsToString={(selectedIds) =>
        selectedIds
          .map(id => opportunityStates.find(state => state._id === id)?.name)
          .filter(Boolean)
          .join('\n')
      }
    />
  );
}
