import { call, put } from 'redux-saga/effects';
import {
  TransactionalEmailCreators
} from '../Actions/transactionalEmails.action';
import TransactionalEmailsAPI from '../API/transactionalEmailsAPI';
import { removeStorage } from 'helpers/common';

function* getTransactionalEmails(action) {
  try {
    const transactionalEmailsAPI = new TransactionalEmailsAPI();
    const response = yield call(transactionalEmailsAPI.getTransactionalEmailsAPI, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(TransactionalEmailCreators.getTransactionalEmailsSuccess(response.data.transactionalEmails, response.data.totalCount, response.data.globalSettings));
      } else if (response.data.status === 403) {
        yield put(TransactionalEmailCreators.getTransactionalEmailsFailure(response.data.message));
        removeStorage();
      } else {
        yield put(TransactionalEmailCreators.getTransactionalEmailsFailure(response.data.message));
      }
    } else {
      yield put(TransactionalEmailCreators.getTransactionalEmailsFailure(response.data.message));
    }    
  } catch (error) {
    yield put(TransactionalEmailCreators.getTransactionalEmailsFailure(error.message));
  }
}

function* createTransactionalEmail(action) {
  try {
    const transactionalEmailsAPI = new TransactionalEmailsAPI();
    const response = yield call(transactionalEmailsAPI.createTransactionalEmailAPI, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(TransactionalEmailCreators.createTransactionalEmailSuccess(response.data.transactionalEmail, response.data.message));
      } else if (response.data.status === 403) {
        yield put(TransactionalEmailCreators.createTransactionalEmailFailure(response.data.message));
        removeStorage();
      } else {
        yield put(TransactionalEmailCreators.createTransactionalEmailFailure(response.data.message));
      }
    } else {
      yield put(TransactionalEmailCreators.createTransactionalEmailFailure(response.data.message));
    }    
  } catch (error) {
    yield put(TransactionalEmailCreators.createTransactionalEmailFailure(error.message));
  }
}

function* updateTransactionalEmail(action) {
  try {
    const transactionalEmailsAPI = new TransactionalEmailsAPI();
    const response = yield call(transactionalEmailsAPI.updateTransactionalEmailAPI, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(TransactionalEmailCreators.updateTransactionalEmailSuccess(response.data.transactionalEmail, response.data.message));
      } else if (response.data.status === 403) {
        yield put(TransactionalEmailCreators.updateTransactionalEmailFailure(response.data.message));
        removeStorage();
      } else {
        yield put(TransactionalEmailCreators.updateTransactionalEmailFailure(response.data.message));
      }
    } else {
      yield put(TransactionalEmailCreators.updateTransactionalEmailFailure(response.data.message));
    }  
  } catch (error) {
    yield put(TransactionalEmailCreators.updateTransactionalEmailFailure(error.message));
  }
}

function* deleteTransactionalEmails(action) {
  try {
    const transactionalEmailsAPI = new TransactionalEmailsAPI();
    const response = yield call(transactionalEmailsAPI.deleteTransactionalEmailAPIs, action.transactionalEmailIds);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(TransactionalEmailCreators.deleteTransactionalEmailsSuccess(response.data.transactionalEmailIds, response.data.message));
      } else if (response.data.status === 403) {
        yield put(TransactionalEmailCreators.deleteTransactionalEmailsFailure(response.data.message));
        removeStorage();
      } else {
        yield put(TransactionalEmailCreators.deleteTransactionalEmailsFailure(response.data.message));
      }
    } else {
      yield put(TransactionalEmailCreators.deleteTransactionalEmailsFailure(response.data.message));
    }  
  } catch (error) {
    yield put(TransactionalEmailCreators.deleteTransactionalEmailsFailure(error.message));
  }
}

function* updateCompanyGlobalSettings(action) {
  try {
    const transactionalEmailsAPI = new TransactionalEmailsAPI();
    const response = yield call(transactionalEmailsAPI.updateCompanyGlobalSettingsAPI, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(TransactionalEmailCreators.updateCompanyGlobalSettingsSuccess(response.data.globalSettings, response.data.message));
      } else if (response.data.status === 403) {
        yield put(TransactionalEmailCreators.updateCompanyGlobalSettingsFailure(response.data.message));
        removeStorage();
      } else {
        yield put(TransactionalEmailCreators.updateCompanyGlobalSettingsFailure(response.data.message));
      }
    } else {
      yield put(TransactionalEmailCreators.updateCompanyGlobalSettingsFailure(response.data.message));
    }    
  } catch (error) {
    yield put(TransactionalEmailCreators.updateCompanyGlobalSettingsFailure(error.message));
  }
}

const transactionalEmailsSaga = {
    getTransactionalEmails,
    createTransactionalEmail,
    updateTransactionalEmail,
    deleteTransactionalEmails,
    updateCompanyGlobalSettings
};

export default transactionalEmailsSaga;
