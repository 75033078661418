import BaseAPI from '../baseAPI';
import NewBaseAPI from '../newBaseAPI';

class OpportunityStatesAPI extends NewBaseAPI {
    createOpportunityState = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunityStates', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };   
    
    updateOpportunityState = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunityStates/update', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };

    deleteOpportunityState = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunityStates', {
            method: 'DELETE',
            data: bodyParams,
            params: queryParams
        });
    };
    
    fetchAllOpportunityStates = async (params: Object) => {
        return this.API('opportunityManagement/opportunityStates', {
            method: 'GET',
            params,
        });
    };

    updateOpportunityStatesOrder = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/opportunityStates/updateOrder', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };
}

export default OpportunityStatesAPI;